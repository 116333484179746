import type { FrescoAppliance, FrescoAttachment } from 'shared/types/appliance';
import type { FrescoEntity, FrescoEntityUri } from 'shared/types/entity';
import type { FrescoMedia, FrescoVideo } from 'shared/types/media';

export interface FrescoTip extends FrescoEntity {
  caption: string;
  image: FrescoMedia | null;
  video: FrescoVideo | null;
}

export enum FrescoStepTemperatureUnits {
  C = 'c',
  F = 'f',
  S = 's',
}

export interface FrescoStepTemperatureNumeric {
  unit: FrescoStepTemperatureUnits.C | FrescoStepTemperatureUnits.F;
  value?: number;
}

export interface FrescoStepTemperatureString {
  unit: FrescoStepTemperatureUnits.S;
  value?: string;
}

export type FrescoStepTemperature =
  | FrescoStepTemperatureNumeric
  | FrescoStepTemperatureString;

export enum FrescoStepType {
  Action = 'action',
  Ingredient = 'ingredient',
  Transfer = 'transfer',
}

export type FrescoStepVesselFlag = Partial<Record<string, boolean>>;

export type FrescoStepVesselFlags = Partial<
  Record<string, FrescoStepVesselFlag>
>;

export interface FrescoStepFlags {
  timed?: boolean;
  vessels?: FrescoStepVesselFlags;
}

export interface FrescoStepAction extends FrescoEntity {
  name: string;
  type: string;
}

export interface FrescoStepPreset extends FrescoEntity {
  name: string;
}

export interface FrescoStepSetting extends FrescoEntity {
  name: string;
}

export interface ApiStepWeb extends FrescoEntity {
  action1: FrescoStepAction | null;
  isVirtual: boolean;
  type: FrescoStepType;
  toContainer: FrescoEntityUri | null;
  fromContainer: FrescoEntityUri | null;
  ingredientGroup: FrescoEntityUri | null;
  appliance: FrescoAppliance | null;
  attachment: FrescoAttachment | null;
  preset?: FrescoStepPreset | null;
  sentence?: string;
  setting: FrescoStepSetting | null;
  setting2: FrescoStepSetting | null;
  setting3: FrescoStepSetting | null;
  setting4: FrescoStepSetting | null;
  temperature: FrescoStepTemperature | null;
  time: number | null;
  tips: FrescoTip[];
  flags?: FrescoStepFlags;
  position?: number;
  sourceText?: string;
  timeSetting: number | null;
}
