import { makeStyles, Grid, Typography } from '@material-ui/core';
import type { FC } from 'react';
import { memo } from 'react';
import { useSelector } from 'react-redux';

import { selectCurrentLocale } from 'features/translation/localeSwitcher/localeSlice';
import { StepIngredient } from 'shared/components/stepViewEntities/StepIngredient';
import { noTranslationSentence } from 'shared/constants';
import type { FrescoStepIngredientGroupI18n } from 'shared/types/i18n';
import { ApiLocale } from 'shared/types/i18n';
import { getIsTranslatableLocale } from 'shared/utils/translation';

interface StepIngredientsProps {
  enUsIngredientGroup?: FrescoStepIngredientGroupI18n | null;
  localeIngredientGroup: FrescoStepIngredientGroupI18n | null;
}

const useStyles = makeStyles((theme) => ({
  subheader: {
    ...theme.typography.overline,
    transform: 'uppercase',
  },
}));

export const StepIngredients: FC<StepIngredientsProps> = memo(
  function StepIngredients({ localeIngredientGroup, enUsIngredientGroup }) {
    const classes = useStyles();
    const locale = useSelector(selectCurrentLocale);

    if (
      !localeIngredientGroup?.ingredients ||
      !enUsIngredientGroup?.ingredients
    ) {
      return null;
    }

    return (
      <Grid item xs={localeIngredientGroup?.ingredients.length > 1 ? 12 : 4}>
        <Typography className={classes.subheader}>Ingredients</Typography>
        <Grid container direction="row" spacing={2}>
          {localeIngredientGroup?.ingredients.map(
            ({ id, ingredient, quantityString }, index) => (
              <Grid
                item
                xs={localeIngredientGroup?.ingredients.length > 1 ? 4 : 12}
                key={id}
              >
                <StepIngredient
                  ingredientImage={ingredient.image}
                  ingredientName={
                    ingredient.nameTranslations?.[ApiLocale.EnUS] || ''
                  }
                  ingredientColor={ingredient.color}
                  quantity={
                    enUsIngredientGroup.ingredients[index].quantityString
                  }
                />
                {getIsTranslatableLocale(locale) && (
                  <StepIngredient
                    ingredientImage={ingredient.image}
                    ingredientName={
                      ingredient.nameTranslations?.[locale] ||
                      `${noTranslationSentence} ${locale}`
                    }
                    ingredientColor={ingredient.color}
                    quantity={quantityString}
                  />
                )}
              </Grid>
            )
          )}
        </Grid>
      </Grid>
    );
  }
);
