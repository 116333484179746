import type { FrescoPaginatedResponse } from 'api/types';
import type { FrescoContainer } from 'shared/types/container';
import type { FrescoEntity, FrescoId } from 'shared/types/entity';
import type { ApiIngredientGroup } from 'shared/types/ingredient';
import type { FrescoMedia } from 'shared/types/media';
import type { ApiStepWeb } from 'shared/types/step';
import type { FrescoUserClient } from 'shared/types/user';

export interface FrescoTag extends FrescoEntity {
  name: string;
}

export interface FrescoRecipeContainer extends FrescoEntity {
  container: FrescoContainer;
  handle: string | null;
  quantity: number;
}

export enum FrescoTimeType {
  Preparation = 'preparation',
  Baking = 'baking',
  Cook = 'cook',
  Other = 'other',
}

export enum FrescoRecipeStatus {
  Draft,
  Official,
  LiveTier2,
  Template,
  InReview,
  Reviewed,
  ForTranslation,
  Translated,
  OnHold,
  NotSuitable,
}

export enum RecipeCreatedWith {
  Creator1 = 'creator1',
  Creator2 = 'creator2',
}

export const dropRecipeStatusNamesMap: Record<FrescoRecipeStatus, string> = {
  [FrescoRecipeStatus.Draft]: 'Draft',
  [FrescoRecipeStatus.Official]: 'Official',
  [FrescoRecipeStatus.LiveTier2]: 'Live Tier 2',
  [FrescoRecipeStatus.Template]: 'Template',
  [FrescoRecipeStatus.InReview]: 'In Review',
  [FrescoRecipeStatus.Reviewed]: 'Reviewed',
  [FrescoRecipeStatus.ForTranslation]: 'For Translation',
  [FrescoRecipeStatus.Translated]: 'Translated',
  [FrescoRecipeStatus.OnHold]: 'On hold',
  [FrescoRecipeStatus.NotSuitable]: 'Not suitable',
};

export interface FrescoTime {
  time: number;
  type: FrescoTimeType;
}

export interface FrescoNutrition {
  servings: number;
  yield: number;
}

export interface FrescoRecipeFlags {
  isExternal: boolean;
  isPremium: boolean;
  isCommunity: boolean;
}

export interface FrescoYield extends FrescoEntity {
  name: string;
  type: string;
}

export interface ApiRecipeWeb extends FrescoEntity {
  containers: FrescoRecipeContainer[];
  createdWith: RecipeCreatedWith;
  difficulty: number;
  flags: FrescoRecipeFlags;
  image: FrescoMedia | null;
  images: FrescoMedia[];
  ingredientGroups: ApiIngredientGroup[];
  introduction?: string;
  name: string;
  nutrition: FrescoNutrition;
  sourceUrl: string | null;
  steps: ApiStepWeb[];
  tags: FrescoTag[];
  times: FrescoTime[];
  user: FrescoUserClient;
  yieldTerm: FrescoYield | null;
  sourceText?: ApiRecipeSourceText;
  status: number;
}

export interface FrescoRecipeLean extends FrescoEntity {
  name: string;
  analyticsId: string;
  slug: string;
  user: FrescoUserClient;
  createdAt: number;
  publishedAt: number;
  introduction: string;
  image: FrescoMedia | null;
  flags: {
    isCommunity: boolean;
    isExternal: boolean;
  };
  time: number;
  tags: FrescoTag[];
  rating: number;
  status: FrescoRecipeStatus;
  isConverted: boolean;
  isFavorited: boolean;
  favoritesCount: number;
  ingredientsCount: number;
  reviewsCount: number;
  badges: unknown[];
  sourceUrl: string | null;
}

export type FrescoRecipesGetResponse =
  FrescoPaginatedResponse<FrescoRecipeLean>;

export interface ApiRecipeSourceText {
  directions: string;
  ingredients: string;
  keywords?: string;
}

export enum SmartSuggestionsStatus {
  Completed = 'COMPLETED',
  Progress = 'IN_PROGRESS',
  Submitted = 'SUBMITTED',
}

export interface RecipeSmartSuggestionsStatus {
  recipeId: FrescoId;
  status: SmartSuggestionsStatus;
}
