import snakecaseKeys from 'snakecase-keys';

import { fetchJson } from 'api/fetchJson';
import type {
  ApiRequestFn,
  FrescoPaginatedResponse,
  LocationResponse,
} from 'api/types';
import { ApiResponseRequestVariant, HttpMethod } from 'api/types';
import { appConfig } from 'shared/config';
import type { FrescoId } from 'shared/types/entity';
import type { FrescoUser, FrescoProfile } from 'shared/types/user';

interface ApiUsersSearchGetRequest {
  searchTerm: string | null;
}

export const apiUsersGet =
  ({
    searchTerm,
  }: ApiUsersSearchGetRequest): ApiRequestFn<
    FrescoPaginatedResponse<FrescoUser>
  > =>
  (apiContext) => {
    const params = new URLSearchParams();
    if (searchTerm) {
      params.append('q', searchTerm);
    }
    return fetchJson<FrescoPaginatedResponse<FrescoUser>>({
      responseVariant: ApiResponseRequestVariant.Web,
      httpMethod: HttpMethod.Get,
      apiContext,
      url: `${appConfig.apiUrl()}/users?${params.toString()}`,
    });
  };

export const apiUserGet =
  (id: FrescoId): ApiRequestFn<FrescoUser> =>
  (apiContext) =>
    fetchJson<FrescoUser>({
      responseVariant: ApiResponseRequestVariant.Web,
      httpMethod: HttpMethod.Get,
      apiContext,
      url: `${appConfig.apiUrl()}/users/${id}`,
    });

export interface AuthorCreate {
  fullName: string;
  profile: FrescoProfile;
  email: string;
  password: string;
}

export const apiUsersCreate =
  (author: AuthorCreate): ApiRequestFn<LocationResponse> =>
  (apiContext) =>
    fetchJson<LocationResponse>({
      responseVariant: ApiResponseRequestVariant.Default,
      httpMethod: HttpMethod.Post,
      apiContext,
      url: `${appConfig.apiUrl()}/users`,
      body: snakecaseKeys(author),
      mergeDataFromHeadersMap: {
        location: 'Location',
      },
    });
