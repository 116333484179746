import { createSlice } from '@reduxjs/toolkit';

import type { RootState } from 'app/rootReducer';
import { logoutSuccess } from 'features/login/loginSlice';
import {
  recipeFetchFinished,
  recipeFetchRequested,
  recipeFetchFailed,
} from 'features/recipe/recipeSlice/recipeActions';

export interface ApiState {
  isFetched: boolean;
  isFetching: boolean;
  error?: string;
}

export const initialState: ApiState = {
  isFetching: false,
  isFetched: false,
};

const apiSlice = createSlice({
  name: 'recipe/apiSlice',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(recipeFetchRequested, () => ({
      isFetched: false,
      isFetching: true,
    }));

    builder.addCase(recipeFetchFinished, () => ({
      isFetched: true,
      isFetching: false,
    }));

    builder.addCase(recipeFetchFailed, (_state, { payload }) => ({
      isFetched: false,
      isFetching: false,
      error: payload,
    }));
    builder.addCase(logoutSuccess, () => initialState);
  },
});

export const { reducer: apiReducer } = apiSlice;

export const selectRecipeFetching = (state: RootState): boolean =>
  state.recipe.api.isFetching;

export const selectRecipeFetched = (state: RootState): boolean =>
  state.recipe.api.isFetched;

export const selectRecipeError = (state: RootState): string | undefined =>
  state.recipe.api.error;
