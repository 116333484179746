import type { FC } from 'react';
import { memo, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { selectStepById } from 'features/recipe/steps/stepsSlice';
import { StepAppliance as StepApplianceLayout } from 'shared/components/stepViewEntities/StepAppliance';
import type { FrescoAppliance, FrescoVesselFlag } from 'shared/types/appliance';
import type { FrescoId } from 'shared/types/entity';
import { FrescoStepTemperatureUnits } from 'shared/types/step';
import { getApplianceVesselFlagsWithStepValue } from 'shared/utils/stepData';

interface StepApplianceProps {
  appliance: FrescoAppliance | null;
  stepId: FrescoId;
}

interface FrescoVesselFlagStepValue extends FrescoVesselFlag {
  value?: boolean;
}

export const StepAppliance: FC<StepApplianceProps> = memo(
  function StepAppliance({ appliance, stepId }) {
    const step = useSelector(selectStepById(stepId));

    const applianceVesselFlagsWithStepValue:
      | FrescoVesselFlagStepValue[]
      | undefined = useMemo(() => {
      const applianceVesselFlags = appliance?.profile?.vessels;
      const stepVesselFlags = step?.flags?.vessels;
      if (!applianceVesselFlags || !stepVesselFlags) {
        return undefined;
      }

      return getApplianceVesselFlagsWithStepValue(
        applianceVesselFlags,
        stepVesselFlags
      );
    }, [appliance?.profile?.vessels, step]);

    if (!appliance || !step) {
      return null;
    }

    const {
      setting,
      setting2,
      setting3,
      setting4,
      preset,
      attachment,
      temperature,
      timeSetting,
    } = step;

    const temperatureUnitString =
      temperature?.unit !== FrescoStepTemperatureUnits.S
        ? temperature?.unit
        : '';

    const temperatureString = `${
      temperature?.value
    } ${temperatureUnitString?.toUpperCase()}`;

    const profilePreset = preset && appliance?.profile?.presets?.[preset?.id];
    const currentSettings = profilePreset || appliance?.profile?.settings;

    return (
      <StepApplianceLayout
        showHeader
        applianceName={appliance.name}
        applianceImage={appliance.image}
        presetName={preset?.name}
        settingHeader={currentSettings?.setting1?.name}
        settingName={setting?.name}
        setting2Header={currentSettings?.setting2?.name}
        setting2Name={setting2?.name}
        setting3Header={currentSettings?.setting3?.name}
        setting3Name={setting3?.name}
        setting4Header={currentSettings?.setting4?.name}
        setting4Name={setting4?.name}
        attachmentName={attachment?.name}
        applianceVesselFlagsWithStepValue={applianceVesselFlagsWithStepValue}
        temperatureString={temperature?.value ? temperatureString : null}
        timeSetting={timeSetting}
      />
    );
  }
);
