import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice, createAction } from '@reduxjs/toolkit';
import { put, call, takeLatest } from 'redux-saga/effects';

import { createRequestApiSaga } from 'api/createRequestApiSaga';
import { apiTagTermsGet } from 'api/terms';
import type { FrescoPaginatedResponse, ApiResponse } from 'api/types';
import type { RootState } from 'app/rootReducer';
import { logoutSuccess } from 'features/login/loginSlice';
import type { FrescoTag } from 'shared/types/recipe';
import { getErrorString } from 'shared/utils/common';

interface TermsTagsState {
  apiError?: string;
  apiPending: boolean;
  entities: FrescoTag[];
}

export const initialState: TermsTagsState = {
  apiPending: false,
  entities: [],
};

const termsTagsSlice = createSlice({
  name: 'termsTagsSlice',
  initialState,
  reducers: {
    termsTagsSuccess(state, { payload }: PayloadAction<FrescoTag[]>) {
      state.entities = payload.sort((a, b) =>
        a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1
      );
      state.apiPending = false;
      state.apiError = undefined;
    },
    termsTagsApiPending(state) {
      state.apiPending = true;
      state.apiError = undefined;
    },
    termsTagsApiError(state, { payload }: PayloadAction<string>) {
      state.apiPending = false;
      state.apiError = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(logoutSuccess, () => initialState);
  },
});

export const {
  reducer: termsTagsReducer,
  actions: { termsTagsSuccess, termsTagsApiPending, termsTagsApiError },
} = termsTagsSlice;

const selectTermsTagsState = (state: RootState): TermsTagsState =>
  state.terms.tags;

export const selectTermsTagsEntities = (state: RootState): FrescoTag[] =>
  selectTermsTagsState(state).entities;

export const selectTermsTagsApiPending = (state: RootState): boolean =>
  selectTermsTagsState(state).apiPending;

export const selectTermsTagsApiError = (state: RootState): string | undefined =>
  selectTermsTagsState(state).apiError;

export const apiGetTagTermsSaga = createRequestApiSaga(
  apiTagTermsGet,
  'Tags loading'
);

export function* requestTagTerms() {
  try {
    yield put(termsTagsApiPending());
    const response: ApiResponse<FrescoPaginatedResponse<FrescoTag>> =
      yield call(apiGetTagTermsSaga);
    if (!response.ok) {
      yield put(termsTagsApiError(response.details.message));
      return;
    }
    yield put(termsTagsSuccess(response.data.results));
    return;
  } catch (e) {
    yield put(termsTagsApiError(getErrorString(e)));
  }
}

export const tagTermsFetchRequested = createAction(
  'terms/tagTermsFetchRequested'
);

export function* requestTagTermsFetchWatcher() {
  yield takeLatest(tagTermsFetchRequested, requestTagTerms);
}

export const tagTermsSagas = [requestTagTermsFetchWatcher];
