import { UsersIcon, AddIcon } from '@dropkitchen/icons-react';
import {
  Grid,
  TextField,
  Box,
  List,
  Button,
  ListSubheader,
  Paper,
  ListItem,
  makeStyles,
  CircularProgress,
  Typography,
  MenuItem,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import produce from 'immer';
import React, { useState, useEffect } from 'react';
import type { ReactNode } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { selectCreateAuthorEnabled } from 'features/developerMenu/featureFlagsSlice';
import { selectLoginAuthData } from 'features/login/loginSlice';
import { MediaUpload } from 'features/mediaUpload/MediaUpload';
import { selectRecipeFetched } from 'features/recipe/api/apiSlice';
import {
  selectDetails,
  selectRecipeId,
  selectDetailsSaving,
} from 'features/recipe/details/detailsSlice';
import {
  recipeCreateEntityRequested,
  RecipeEditEntityType,
} from 'features/recipe/edit/editSlice';
import { recipePatchRequested } from 'features/recipe/recipeSlice/recipeActions';
import {
  selectTermsAuthorsApiPending,
  authorTermsFetchRequested,
  selectTermsAuthorsEntities,
} from 'features/terms/authorsSlice';
import { ListSkeleton } from 'shared/components/Skeletons';
import { TermDropDownLoading } from 'shared/components/TermDropDownLoading';
import { OrganisationName } from 'shared/constants';
import { useSelectUserHasAccessGroupRights } from 'shared/hooks/useSelectUserHasAccessGroupRights';
import { usersAccessGroups, usersWriteAccessGroups } from 'shared/permissions';
import type { FrescoMedia, AppImage } from 'shared/types/media';
import { FrescoMediaMimetype, FrescoMediaClass } from 'shared/types/media';
import type { FrescoUserClient } from 'shared/types/user';
import { getIdFromUri } from 'shared/utils/common';

const useStyles = makeStyles((theme) => ({
  submitButton: {
    display: 'flex',
    justifyContent: 'flex-end',
    paddingTop: theme.spacing(2),
  },
  container: {
    display: 'flex',
    width: '100%',
  },
  detailsPane: {
    flexGrow: 1,
    marginRight: theme.spacing(3),
  },
  imagesPane: {
    display: 'flex',
    flexDirection: 'column',
    alignContent: 'flex-end',
  },
  imagesContainer: {
    flex: '1 1',
    flexBasis: ' 0px',
    overflowY: 'scroll',
  },
  subheader: {
    lineHeight: 'unset',
    marginTop: theme.spacing(2),
  },
  loadingIcon: {
    alignSelf: 'flex-end',
  },
}));

export const noUserAdminRightsWarning =
  'You do not have the correct access rights to change the recipes author';

export const RecipeDetailsPage: React.FC = React.memo(
  function RecipeDetailsPage() {
    const dispatch = useDispatch();
    const classes = useStyles();
    const details = useSelector(selectDetails);
    const isSaving = useSelector(selectDetailsSaving);
    const recipeId = useSelector(selectRecipeId);
    const authData = useSelector(selectLoginAuthData);
    const authorsTerms = useSelector(selectTermsAuthorsEntities);
    const authorsTermsPending = useSelector(selectTermsAuthorsApiPending);
    const isCreateAuthorFlagEnabled = useSelector(selectCreateAuthorEnabled);
    const showFrescoOrganisationFields =
      authData?.userOrganisationName === OrganisationName.Fresco;
    const userHasAdminUsersReadRights =
      useSelectUserHasAccessGroupRights(usersAccessGroups);

    const userHasAdminUsersWriteRights = useSelectUserHasAccessGroupRights(
      usersWriteAccessGroups
    );

    const isFetched = useSelector(selectRecipeFetched);

    const [isAuthorsOpen, setIsAuthorsOpen] = useState(false);
    const [searchTerm, setSearchTerm] = useState<string>('');

    const [name, setName] = useState<string | null>();
    const [sourceUrl, setSourceUrl] = useState<string | null>();
    const [isExternal, setIsExternal] = useState<boolean | null>();
    const [introduction, setIntroduction] = useState<string | null>();
    const [image, setImage] = useState<FrescoMedia | null>();
    const [images, setImages] = useState<FrescoMedia[]>([]);
    const [author, setAuthor] = useState<FrescoUserClient | null>(
      details.author ?? null
    );

    useEffect(() => {
      if (userHasAdminUsersReadRights) {
        dispatch(authorTermsFetchRequested(searchTerm));
      }
    }, [dispatch, searchTerm, userHasAdminUsersReadRights]);

    useEffect(() => {
      if (!isSaving && isFetched) {
        setName(details.name);
        setSourceUrl(details.sourceUrl || null);
        setIsExternal(details.isExternal);
        setIntroduction(details.introduction || null);
        setImage(details.image || null);
        setImages(details.images || []);
        setAuthor(details.author ?? null);
      }
    }, [details, isSaving, isFetched, showFrescoOrganisationFields]);

    const heroImage: AppImage | undefined = image
      ? {
          uri: image?.data.uri || '',
          previewUrl: image?.data.uri || '',
        }
      : undefined;
    const secondaryImages: AppImage[] | [] =
      images?.map((secondaryimage) => ({
        uri: secondaryimage.data.uri,
        previewUrl: secondaryimage.data.uri,
      })) || [];

    const changeImages = (imageFile: AppImage, index: number) => {
      setImages(
        produce(images, (draft) => {
          draft[index] = {
            uri: imageFile?.uri,
            id: getIdFromUri(imageFile?.uri),
            data: {
              uri: imageFile?.previewUrl,
            },
          };
        })
      );
    };

    const deleteImage = (index: number) => {
      setImages(
        produce(images, (draft) => {
          draft.splice(index, 1);
        })
      );
    };

    const addImages = (imageFile: AppImage) => {
      setImages(
        produce(images, (draft) => {
          draft.push({
            uri: imageFile?.uri,
            id: getIdFromUri(imageFile?.uri),
            data: {
              uri: imageFile?.previewUrl,
            },
          });
        })
      );
    };

    const saveDetails = (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      if (!recipeId || !name || !introduction || !author) {
        return;
      }
      dispatch(
        recipePatchRequested({
          recipe: {
            name,
            sourceUrl,
            flags: {
              isExternal: isExternal ?? false,
              isCommunity: details.isCommunity,
              isPremium: details.isPremium,
            },
            introduction,
            image,
            images,
            user: {
              uri: author.uri,
            },
          },
        })
      );
    };

    const handleAddAuthorClick = () => {
      dispatch(
        recipeCreateEntityRequested({
          editEntityType: RecipeEditEntityType.Author,
        })
      );
    };

    if (!isFetched && !isSaving) {
      return (
        <Paper>
          <Box p={2} mt={1}>
            <ListSkeleton rows={3} />
            <Grid className={classes.submitButton} item xs={12}>
              <CircularProgress
                className={classes.loadingIcon}
                aria-label="Saving indicator"
              />
            </Grid>
          </Box>
        </Paper>
      );
    }

    const loading = isAuthorsOpen && authorsTermsPending;

    return (
      <Paper>
        <Box p={2} mt={1}>
          <form onSubmit={saveDetails}>
            <div className={classes.container}>
              <div className={classes.detailsPane}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextField
                      autoFocus
                      fullWidth
                      variant="outlined"
                      value={name || ''}
                      onChange={(event) => {
                        setName(event.target.value);
                      }}
                      label="Recipe Name"
                      id="name"
                      required
                    />
                  </Grid>
                  {showFrescoOrganisationFields && (
                    <>
                      <Grid item xs={12} md={8}>
                        <TextField
                          fullWidth
                          variant="outlined"
                          label="Recipe URL"
                          id="sourceUrl"
                          value={sourceUrl || ''}
                          onChange={(event) => {
                            setSourceUrl(event.target.value);
                          }}
                        />
                      </Grid>
                      <Grid item container xs={12} md={4} alignContent="center">
                        <TextField
                          select
                          label="From the web"
                          id="isExternal"
                          variant="outlined"
                          value={isExternal ? 1 : 0}
                          onChange={(event) =>
                            setIsExternal(!!event.target.value)
                          }
                          fullWidth
                          inputProps={{ 'data-testid': 'isExternal' }}
                        >
                          <MenuItem value={1}>Yes</MenuItem>
                          <MenuItem value={0}>No</MenuItem>
                        </TextField>
                      </Grid>
                    </>
                  )}
                  <Grid item xs={12}>
                    <Autocomplete
                      id="author"
                      options={authorsTerms}
                      disabled={!userHasAdminUsersReadRights}
                      getOptionLabel={(option) =>
                        `${option.fullName || option?.email}`
                      }
                      renderOption={(option) => (
                        <Grid container justify="space-between">
                          <Grid item>{option.fullName}</Grid>
                          <Grid item>{option?.email}</Grid>
                        </Grid>
                      )}
                      getOptionSelected={(option, value) =>
                        option.uri === value.uri
                      }
                      value={author}
                      onOpen={() => {
                        setIsAuthorsOpen(true);
                      }}
                      onClose={() => {
                        setIsAuthorsOpen(false);
                      }}
                      onChange={(_e, value) => {
                        setAuthor(value);
                      }}
                      onInputChange={(_e, value) => {
                        setSearchTerm(value);
                      }}
                      PaperComponent={({
                        children,
                        ...other
                      }: {
                        children?: ReactNode;
                      }) => (
                        <Paper {...other}>
                          {children}
                          {isCreateAuthorFlagEnabled &&
                            userHasAdminUsersWriteRights && (
                              <Button
                                aria-label="Add Author"
                                fullWidth
                                variant="contained"
                                color="secondary"
                                onMouseDown={(event) => {
                                  event.stopPropagation();
                                  handleAddAuthorClick();
                                }}
                              >
                                <Grid container justify="space-between">
                                  <Grid item>
                                    <Grid
                                      container
                                      alignItems="center"
                                      justify="center"
                                    >
                                      <UsersIcon fontSize="large" /> ADD NEW
                                      AUTHOR
                                    </Grid>
                                  </Grid>
                                  <Grid item>
                                    <AddIcon fontSize="large" />
                                  </Grid>
                                </Grid>
                              </Button>
                            )}
                        </Paper>
                      )}
                      renderInput={(params) => (
                        <TermDropDownLoading
                          loading={loading}
                          required
                          params={params}
                          label="Recipe Author"
                        />
                      )}
                    />
                    {!userHasAdminUsersReadRights && (
                      <Typography variant="caption" color="error">
                        {noUserAdminRightsWarning}
                      </Typography>
                    )}
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      variant="outlined"
                      label="Description"
                      id="description"
                      value={introduction || ''}
                      onChange={(event) => {
                        setIntroduction(event.target.value);
                      }}
                      multiline
                      rows={5}
                      required
                    />
                  </Grid>
                </Grid>
              </div>
              <div className={classes.imagesPane}>
                <Paper className={classes.imagesContainer} variant="outlined">
                  <List>
                    <ListSubheader className={classes.subheader} disableSticky>
                      Hero image
                    </ListSubheader>
                    <ListItem>
                      <MediaUpload
                        acceptTypes={[
                          FrescoMediaMimetype.Jpeg,
                          FrescoMediaMimetype.Png,
                        ]}
                        mediaId="recipe.heroImage"
                        value={heroImage}
                        apiImageSizeType={FrescoMediaClass.RecipeImage}
                        onChange={(imageFile) => {
                          if (!imageFile) {
                            setImage(null);
                            return;
                          }
                          setImage({
                            uri: imageFile?.uri,
                            id: getIdFromUri(imageFile?.uri),
                            data: {
                              uri: imageFile?.previewUrl,
                            },
                          });
                        }}
                      />
                    </ListItem>
                    <ListSubheader className={classes.subheader} disableSticky>
                      Additional images
                    </ListSubheader>
                    {secondaryImages?.map((secondaryimage, index) => (
                      <ListItem key={secondaryimage.uri}>
                        <MediaUpload
                          mediaId={`recipe.secondaryImage.${index}`}
                          value={secondaryimage}
                          apiImageSizeType={FrescoMediaClass.RecipeImages}
                          onChange={(imageFile) => {
                            if (!imageFile) {
                              deleteImage(index);
                              return;
                            }
                            changeImages(imageFile, index);
                          }}
                        />
                      </ListItem>
                    ))}
                    <ListItem>
                      <MediaUpload
                        mediaId={`recipe.secondaryImage.${secondaryImages.length}`}
                        onChange={(imageFile) => {
                          if (imageFile) {
                            addImages(imageFile);
                          }
                        }}
                        apiImageSizeType={FrescoMediaClass.RecipeImages}
                      />
                    </ListItem>
                  </List>
                </Paper>
              </div>
            </div>
            <Grid className={classes.submitButton} item xs={12}>
              {isSaving ? (
                <CircularProgress
                  className={classes.loadingIcon}
                  aria-label="Saving indicator"
                />
              ) : (
                <Grid>
                  <Button variant="text" color="primary" type="submit">
                    Save
                  </Button>
                </Grid>
              )}
            </Grid>
          </form>
        </Box>
      </Paper>
    );
  }
);
