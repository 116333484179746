export enum AccessGroup {
  AdminContent = 'admin:content',
  AdminFeeds = 'admin:feeds',
  AdminUsersRead = 'admin:users:read',
  AdminDevices = 'admin:devices',
  AdminOrganizations = 'admin:organizations',
  AdminContentEnUS = 'admin:content:en-us',
  AdminContentEnGB = 'admin:content:en-gb',
  AdminContentEnAU = 'admin:content:en-au',
  AdminContentFrFR = 'admin:content:fr-fr',
  AdminContentEsES = 'admin:content:es-es',
  AdminContentItIT = 'admin:content:it-it',
  AdminContentCsCZ = 'admin:content:cs-cz',
  AdminContentDeDE = 'admin:content:de-de',
  AdminContentNlNL = 'admin:content:nl-nl',
  AdminContentPlPL = 'admin:content:pl-pl',
  AdminContentNbNO = 'admin:content:nb-no',
  AdminContentSvSE = 'admin:content:sv-se',
  AdminContentFiFI = 'admin:content:fi-fi',
  AdminContentRuRU = 'admin:content:ru-ru',
  AdminContentDaDK = 'admin:content:da-dk',
  AdminContentPtPT = 'admin:content:pt-pt',
  AdminDevicesOTASRead = 'admin:devices:otas:read',
  AdminDevicesOTASWrite = 'admin:devices:otas:write',
  AdminDevicesRegistryRead = 'admin:devices:registry:read',
  AdminDevicesRegistryWrite = 'admin:devices:registry:write',
  AdminDevicesAppliancesRead = 'admin:devices:appliances:read',
  AdminDevicesAppliancesWrite = 'admin:devices:appliances:write',
  AdminUsersWrite = 'admin:users:write',
  AdminUsersGroup = 'admin:users:groups',
}

export const recipesContentAdmin: RequiredPermissions = {
  allOf: [AccessGroup.AdminContentEnUS, AccessGroup.AdminContent],
};
export const usersRead: RequiredPermissions = {
  allOf: [AccessGroup.AdminUsersRead],
};
export const usersWrite: RequiredPermissions = {
  allOf: [AccessGroup.AdminUsersWrite],
};
export const feedsAdmin: RequiredPermissions = {
  allOf: [AccessGroup.AdminFeeds],
};
export const translationAdmin: RequiredPermissions = {
  allOf: [AccessGroup.AdminContent, /admin:content:.*/],
};

export const devicesRead: RequiredPermissions = {
  allOf: [AccessGroup.AdminDevices],
};

export const devicesOTA: RequiredPermissions = {
  allOf: [AccessGroup.AdminDevices, AccessGroup.AdminDevicesOTASRead],
};

export const adminAccessGroups: PermissionsCheckRuleSet = {
  anyOf: [recipesContentAdmin, usersRead, feedsAdmin],
};

export const usersAccessGroups: PermissionsCheckRuleSet = {
  anyOf: [usersRead],
};

export const usersWriteAccessGroups: PermissionsCheckRuleSet = {
  anyOf: [usersWrite],
};

export const recipeAccessGroups: PermissionsCheckRuleSet = {
  anyOf: [recipesContentAdmin],
};

export const marketingAccessGroups: PermissionsCheckRuleSet = {
  anyOf: [feedsAdmin],
};

export const translationAccessGroups: PermissionsCheckRuleSet = {
  anyOf: [translationAdmin],
};

export const machinaAccessGroups: PermissionsCheckRuleSet = {
  anyOf: [devicesRead],
};

export const machinaOTAAccessGroups: PermissionsCheckRuleSet = {
  anyOf: [devicesOTA],
};

export const machinaRegisteryAccessGroups: PermissionsCheckRuleSet = {
  anyOf: [
    { allOf: [AccessGroup.AdminDevices, AccessGroup.AdminDevicesRegistryRead] },
  ],
};

export const machinaOnlineAppliancesAccessGroups: PermissionsCheckRuleSet = {
  anyOf: [
    {
      allOf: [AccessGroup.AdminDevices, AccessGroup.AdminDevicesAppliancesRead],
    },
  ],
};

export const backStageAccessGroup: PermissionsCheckRuleSet = {
  anyOf: [
    translationAdmin,
    recipesContentAdmin,
    usersRead,
    feedsAdmin,
    devicesRead,
  ],
};

export type PermissionCheckRule = AccessGroup | RegExp;

interface RequiredPermissions {
  allOf: PermissionCheckRule[];
}

export interface PermissionsCheckRuleSet {
  anyOf: RequiredPermissions[];
}
