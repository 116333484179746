import { Tooltip } from '@material-ui/core';
import type { ButtonProps as MuiButtonProps } from '@material-ui/core/Button';
import MuiButton from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  spinner: {
    marginLeft: theme.spacing(1),
  },
}));

export interface ButtonProps extends MuiButtonProps {
  loading?: boolean;
  tooltip?: React.ReactNode;
  type?: 'button' | 'submit';
  disabled?: boolean;
}

export const ButtonWithSpinner: React.FC<ButtonProps> = React.memo(
  function ButtonWithSpinner({
    loading,
    type = 'button',
    variant = 'contained',
    disabled = false,
    startIcon,
    tooltip,
    ...restProps
  }: ButtonProps) {
    const classes = useStyles();
    return (
      <Tooltip title={(loading ? '' : tooltip) || ''}>
        <MuiButton
          {...restProps}
          disabled={loading || disabled}
          variant={variant}
          type={type}
          startIcon={
            loading ? (
              <CircularProgress
                className={classes.spinner}
                size={24}
                color="inherit"
              />
            ) : (
              startIcon
            )
          }
        />
      </Tooltip>
    );
  }
);
