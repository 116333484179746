import {
  Grid,
  FormControlLabel,
  Fab,
  Checkbox,
  TextField,
  Chip,
  Typography,
  makeStyles,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import SaveIcon from '@material-ui/icons/Save';
import Autocomplete from '@material-ui/lab/Autocomplete';
import compact from 'lodash/compact';
import isNil from 'lodash/isNil';
import round from 'lodash/round';
import type { FC } from 'react';
import { useEffect, useState, memo } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { EditSidebarActions } from 'features/recipe/edit/EditSidebarActions';
import { EditSidebarContent } from 'features/recipe/edit/EditSidebarContent';
import { EditSidebarForm } from 'features/recipe/edit/EditSidebarForm';
import { EditSidebarTitle } from 'features/recipe/edit/EditSidebarTitle';
import {
  selectRecipeEditMode,
  RecipeEditMode,
  selectRecipeEditEntityId,
  recipeEditEntityFinished,
} from 'features/recipe/edit/editSlice';
import {
  ingredientAddRequested,
  selectIngredientById,
  ingredientEditRequested,
} from 'features/recipe/ingredients/ingredientsSlice';
import {
  selectTermsIngredientsEntities,
  selectTermsIngredientsApiPending,
  ingredientTermsFetchRequested,
} from 'features/terms/ingredientsSlice';
import {
  selectTermsPreparationsEntities,
  selectTermsPreparationsApiPending,
  preparationTermsFetchRequested,
} from 'features/terms/preparationsSlice';
import {
  ListboxComponent,
  renderGroup,
} from 'shared/components/VirtualizedAutocomplete';
import { units as unitsTerms } from 'shared/constants';
import { useIngredientsInTempGroup } from 'shared/hooks/useIngredientsInTempGroup';
import type { FrescoTerm } from 'shared/types/entity';
import type {
  FrescoIngredient,
  ApiIngredientGroupIngredient,
} from 'shared/types/ingredient';

const useStyles = makeStyles((theme) => ({
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
}));

export const IngredientForm: FC = memo(function IngredientForm() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const limitPreparations = 3;
  const isEditMode = useSelector(selectRecipeEditMode) === RecipeEditMode.Edit;
  const editingEnityId = useSelector(selectRecipeEditEntityId);
  const editEntity = useSelector(selectIngredientById(editingEnityId ?? ''));
  const { ingredientsInTemporaryGroup, temporaryGroupIds } =
    useIngredientsInTempGroup();

  const [quantityError, setQuanityError] = useState<boolean>(false);
  const ingredients = useSelector(selectTermsIngredientsEntities);
  const ingredientsPending = useSelector(selectTermsIngredientsApiPending);
  const preparationsTerms = useSelector(selectTermsPreparationsEntities);
  const preparationsTermsPending = useSelector(
    selectTermsPreparationsApiPending
  );

  const [disablePreparationsInput, setDisablePreparationsInput] =
    useState(false);
  const [ingredient, setIngredient] = useState<FrescoIngredient | null>(
    editEntity?.ingredient ?? null
  );
  const [amount, setAmount] = useState<number | null>(
    editEntity?.amount ?? null
  );
  const [units, setUnits] = useState<string | null>(editEntity?.units ?? null);
  const [optional, setOptional] = useState(
    !isNil(editEntity?.flags?.required) ? !editEntity?.flags?.required : false
  );
  const [preparations, setPreparations] = useState<FrescoTerm[]>(
    compact([
      editEntity?.preparation1,
      editEntity?.preparation2,
      editEntity?.preparation3,
    ])
  );
  const [advancedPreparations, setAdvancedPreparations] = useState(
    editEntity?.flags.advancePrep || false
  );

  useEffect(() => {
    if (!ingredients.length && !ingredientsPending) {
      dispatch(ingredientTermsFetchRequested());
    }
  }, [dispatch, ingredients, ingredientsPending]);

  useEffect(() => {
    if (!preparationsTerms.length && !preparationsTermsPending) {
      dispatch(preparationTermsFetchRequested());
    }
  }, [dispatch, preparationsTerms, preparationsTermsPending]);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (!ingredient || amount === 0) {
      return;
    }

    const newIngredient = {
      ingredient,
      amount,
      units,
      preparation1: preparations[0] ?? null,
      preparation2: preparations[1] ?? null,
      preparation3: preparations[2] ?? null,
      flags: {
        advancePrep: preparations.length ? advancedPreparations : false,
        required: !optional,
      },
    };

    if (isEditMode) {
      dispatch(
        ingredientEditRequested({
          ...editEntity,
          ...newIngredient,
        } as ApiIngredientGroupIngredient)
      );
    } else {
      dispatch(
        ingredientAddRequested({
          groupId: temporaryGroupIds[0],
          ingredient: newIngredient,
          ingredients: ingredientsInTemporaryGroup,
        })
      );
    }
  };

  return (
    <EditSidebarForm aria-label="Ingredient form" onSubmit={handleSubmit}>
      <EditSidebarTitle
        text={`${isEditMode ? 'Edit' : 'Add'} Ingredient`}
        onClosePayloadAction={recipeEditEntityFinished()}
      />
      <EditSidebarContent>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Autocomplete
              data-testid="ingredient"
              value={ingredient}
              disableListWrap
              ListboxComponent={
                ListboxComponent as React.ComponentType<
                  React.HTMLAttributes<HTMLElement>
                >
              }
              renderGroup={renderGroup}
              onChange={(_e, value) => {
                setIngredient(value);
              }}
              options={ingredients}
              getOptionLabel={(value) => value.name}
              getOptionSelected={(option, value) => option.uri === value.uri}
              renderInput={(params) => (
                <TextField
                  {...params}
                  autoFocus
                  required
                  variant="outlined"
                  label="Ingredient"
                />
              )}
              renderOption={(option) => (
                <Typography noWrap>{option.name}</Typography>
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={1} direction="row">
              <Grid item xs={6}>
                <TextField
                  type="number"
                  label="Quantity"
                  id="quantity"
                  variant="outlined"
                  fullWidth
                  required={!!units}
                  value={amount || amount === 0 ? amount : ''}
                  InputProps={{ inputProps: { min: 0, step: 'any' } }}
                  helperText={quantityError ? 'Quantity cannot be 0' : ''}
                  onChange={(event) => {
                    const value = round(parseFloat(event.target.value), 2);
                    if (value === 0) {
                      setQuanityError(true);
                    } else {
                      setQuanityError(false);
                    }
                    setAmount(value);
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <Autocomplete
                  data-testid="units"
                  options={unitsTerms}
                  value={units}
                  onChange={(_e, value) => {
                    setUnits(value);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      required={!!amount}
                      variant="outlined"
                      fullWidth
                      label="Units"
                    />
                  )}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={optional}
                  onChange={(event) => {
                    setOptional(event.target.checked);
                  }}
                  color="primary"
                  name="optional"
                />
              }
              label="Optional"
            />
          </Grid>
          <Grid item xs={12}>
            <Autocomplete
              disabled={disablePreparationsInput}
              multiple
              data-testid="preparations"
              options={preparationsTerms}
              getOptionLabel={(preparation) => preparation.name}
              getOptionSelected={(option, value) => option.uri === value.uri}
              value={preparations}
              onChange={(_event, newValue) => {
                setDisablePreparationsInput(
                  newValue.length >= limitPreparations
                );
                setPreparations(newValue);
              }}
              renderTags={(tagValue, getTagProps) =>
                tagValue.map((option, index) => (
                  <Chip
                    key={index}
                    label={option?.name}
                    {...getTagProps({ index })}
                    disabled={false}
                  />
                ))
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label="Preparations"
                />
              )}
            />
          </Grid>
          {preparations.length > 0 && (
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    color="primary"
                    checked={advancedPreparations}
                    onChange={(event) => {
                      setAdvancedPreparations(event.target.checked);
                    }}
                    name="advancedPreparations"
                  />
                }
                label="Advanced Preparations"
              />
            </Grid>
          )}
        </Grid>
      </EditSidebarContent>
      <EditSidebarActions>
        <Fab type="submit" color="primary" variant="extended">
          {isEditMode ? (
            <SaveIcon className={classes.extendedIcon} />
          ) : (
            <AddIcon className={classes.extendedIcon} />
          )}
          {isEditMode ? 'Save' : 'Add'}
        </Fab>
      </EditSidebarActions>
    </EditSidebarForm>
  );
});
