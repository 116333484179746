import { CircleHelpIcon } from '@dropkitchen/icons-react';
import {
  TextField,
  Paper,
  makeStyles,
  Typography,
  Box,
  Grid,
  Tooltip,
  IconButton,
} from '@material-ui/core';
import classNames from 'classnames';
import produce from 'immer';
import type { FC } from 'react';
import { memo, useState, useMemo, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { selectCurrentLocale } from 'features/translation/localeSwitcher/localeSlice';
import {
  selectRecipeStepById,
  selectRecipeStepsTotal,
  recipeStepEditRequested,
  TranslationSuggestionType,
  selectRecipeTranslationSuggestion,
} from 'features/translation/recipe/recipeSlice';
import { StepAction } from 'features/translation/recipe/stepViewEntities/StepAction';
import { StepAppliance } from 'features/translation/recipe/stepViewEntities/StepAppliance';
import { StepContainers } from 'features/translation/recipe/stepViewEntities/StepContainers';
import { StepIngredients } from 'features/translation/recipe/stepViewEntities/StepIngredients';
import { StepTips } from 'features/translation/recipe/stepViewEntities/StepTips';
import { FrescoIcon } from 'shared/components/FrescoIcon';
import { noTranslationSentence } from 'shared/constants';
import type { FrescoId } from 'shared/types/entity';
import { ApiLocale } from 'shared/types/i18n';
import { isVirtualStep } from 'shared/utils/common';
import { getIsTranslatableLocale } from 'shared/utils/translation';

export const useStyles = makeStyles((theme) => ({
  header: {
    padding: theme.spacing(2),
  },
  virtual: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2),
  },
  content: {
    backgroundColor: 'rgb(230, 230, 230, 0.3)',
    padding: theme.spacing(2),
    paddingBottom: theme.spacing(3),
  },
  subheader: {
    ...theme.typography.overline,
  },
  text: {
    ...theme.typography.h6,
    color: '#645C5C',
    lineHeight: '24px',
    fontSize: 20,
    fontWeight: 'unset',
    marginBottom: theme.spacing(1),
  },
  translationText: {
    ...theme.typography.h6,
    fontWeight: 500,
    color: '#212121',
    marginBottom: theme.spacing(1),
  },
  icon: {
    paddingRight: theme.spacing(1),
  },
  bold: {
    fontSize: 16,
    fontWeight: 'bold',
  },
  iconDisableBackground: {
    '&:hover': {
      backgroundColor: 'transparent !important',
    },
  },
  suggested: {
    backgroundColor: '#bae3c8',
  },
}));

interface StepProps {
  stepId: FrescoId;
  stepIndex: number;
}

export const automaticallyGenerated = 'Automatically generated';

export const GeneratedSentenceToolTip: FC = () => {
  return (
    <>
      <Typography>
        This sentence is being automatically generated by the system using the
        elements (action, container, ingredients etc) contained in the step.
      </Typography>
      <Typography>
        These elements can be edited outside of this recipe, which may affect
        the automatically generated sentence
      </Typography>
      <Typography>
        Manually translating and saving the sentence will prevent the sentence
        being affected by editing the step elements
      </Typography>
    </>
  );
};

export const Step: FC<StepProps> = memo(function Step({ stepId, stepIndex }) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const locale = useSelector(selectCurrentLocale);
  const stepLocaleSelector = useMemo(
    () => selectRecipeStepById(stepId, locale),
    [stepId, locale]
  );

  const stepEnUsSelector = useMemo(
    () => selectRecipeStepById(stepId, ApiLocale.EnUS),
    [stepId]
  );
  const stepLocale = useSelector(stepLocaleSelector);
  const stepEnUs = useSelector(stepEnUsSelector);
  const stepsCount = useSelector(selectRecipeStepsTotal(locale));

  // Check if locale sentence (grammar engine) is same as enUS raw sentence
  // https://github.com/dropkitchen/drop-babel/pull/66
  // Bug created by https://getdrop.atlassian.net/browse/IDI-724
  // If there is a raw sentence for the requested locale, use it.
  // Else, use the value of sentence (non-raw) unless it’s equal to the en_US raw
  const isLocaleSentenceEqualToEnUSSentence =
    stepLocale?.sentence ===
    stepEnUs?.sentenceRawTranslations?.[ApiLocale.EnUS];

  const localeHasRawTranslationSentence =
    !!stepLocale?.sentenceRawTranslations?.[locale];
  const localeSentence = !isLocaleSentenceEqualToEnUSSentence
    ? stepLocale?.sentence
    : undefined;

  const stepEnUsSentence =
    stepEnUs?.sentenceRawTranslations?.[ApiLocale.EnUS] || stepEnUs?.sentence;

  const [stepLocaleSentence, setStepLocaleSentence] = useState(
    localeHasRawTranslationSentence
      ? stepLocale?.sentenceRawTranslations?.[locale]
      : localeSentence
  );

  const translatedSentence = useSelector(
    selectRecipeTranslationSuggestion(
      locale,
      TranslationSuggestionType.Step,
      stepId
    )
  );

  const isGeneratedEnUSSentence =
    stepEnUs?.sentence && !stepEnUs?.sentenceRawTranslations?.[ApiLocale.EnUS];
  const isGeneratedLocaleSentence =
    stepLocale?.sentence && !stepLocale?.sentenceRawTranslations?.[locale];

  const noTranslation = `${noTranslationSentence} ${locale}`;

  useEffect(() => {
    if (
      stepLocale &&
      stepLocaleSentence !== stepLocale.sentenceRawTranslations[locale] &&
      stepLocaleSentence !== stepLocale?.sentence &&
      stepLocaleSentence !== stepEnUs?.sentenceRawTranslations?.[ApiLocale.EnUS]
    ) {
      const updatedStep = produce(stepLocale, (draftState) => {
        draftState.sentenceRawTranslations[locale] = stepLocaleSentence;
      });

      dispatch(
        recipeStepEditRequested({ step: updatedStep, stepIndex, locale })
      );
    }
  }, [dispatch, stepLocaleSentence, stepLocale, stepEnUs, locale, stepIndex]);

  useEffect(() => {
    if (translatedSentence) {
      setStepLocaleSentence(translatedSentence);
    }
  }, [translatedSentence]);

  if (!stepLocale) {
    return null;
  }

  const isVirtual = isVirtualStep(stepLocale.uri);

  if (isVirtual) {
    return (
      <Paper>
        <Box className={classes.virtual}>
          <Grid container justify="space-between">
            <Typography variant="body2">
              Step {stepIndex + 1}/{stepsCount} - Virtual Step
            </Typography>
          </Grid>
        </Box>
        <Box className={classes.virtual}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography className={classes.subheader}>
                EN_US sentence:
              </Typography>
              <Typography className={classes.text}>
                {stepEnUs?.sentence}
              </Typography>
              {getIsTranslatableLocale(locale) && (
                <>
                  <Typography className={classes.subheader}>
                    {locale} sentence:
                  </Typography>
                  <Typography className={classes.text}>
                    {stepLocale.sentence}
                  </Typography>
                </>
              )}
            </Grid>
            {stepLocale?.attachment?.nameTranslations?.[ApiLocale.EnUS] && (
              <Grid item xs={12}>
                <Typography className={classes.subheader}>
                  Attachment
                </Typography>
                <Grid container alignItems="center" direction="row">
                  <Grid className={classes.icon} item>
                    <FrescoIcon
                      color="#000000"
                      image={stepLocale.attachment.image}
                      name={
                        stepLocale.attachment.nameTranslations?.[
                          ApiLocale.EnUS
                        ] || ''
                      }
                    />
                  </Grid>
                  <Grid item>
                    <Typography className={classes.bold} variant="body2">
                      {
                        stepLocale?.attachment?.nameTranslations?.[
                          ApiLocale.EnUS
                        ]
                      }
                    </Typography>
                  </Grid>
                </Grid>
                {getIsTranslatableLocale(locale) && (
                  <Grid container alignItems="center" direction="row">
                    <Grid className={classes.icon} item>
                      <FrescoIcon
                        image={stepLocale.attachment.image}
                        name={
                          stepLocale.attachment.nameTranslations?.[locale] || ''
                        }
                      />
                    </Grid>
                    <Grid item>
                      <Typography className={classes.bold} variant="body2">
                        {stepLocale?.attachment?.nameTranslations?.[locale] ||
                          noTranslation}
                      </Typography>
                    </Grid>
                  </Grid>
                )}
              </Grid>
            )}
          </Grid>
          <StepTips stepIndex={stepIndex} tips={stepLocale?.tips} />
        </Box>
      </Paper>
    );
  }

  return (
    <Paper>
      <Box className={classes.header}>
        <Grid container justify="space-between">
          <Typography variant="body2">
            Step {stepIndex + 1}/{stepsCount}
          </Typography>
        </Grid>
        {stepEnUsSentence && (
          <Box mt={1}>
            <Typography className={classes.subheader}>
              {isGeneratedEnUSSentence && (
                <Tooltip title={<GeneratedSentenceToolTip />}>
                  <IconButton className={classes.iconDisableBackground}>
                    <CircleHelpIcon />
                  </IconButton>
                </Tooltip>
              )}
              {isGeneratedEnUSSentence && `${automaticallyGenerated} `}Step
              sentence:
            </Typography>

            <Typography className={classes.text}>{stepEnUsSentence}</Typography>
          </Box>
        )}
      </Box>
      {getIsTranslatableLocale(locale) && (
        <Box className={classes.content}>
          <Typography className={classes.subheader}>
            {isGeneratedLocaleSentence && stepLocaleSentence && (
              <Tooltip title={<GeneratedSentenceToolTip />}>
                <IconButton className={classes.iconDisableBackground}>
                  <CircleHelpIcon />
                </IconButton>
              </Tooltip>
            )}
            {isGeneratedLocaleSentence && stepLocaleSentence
              ? `${automaticallyGenerated} : ${locale}`
              : locale}
          </Typography>
          <TextField
            inputProps={{
              'data-testid': `${stepLocale.id}-${locale}`,
            }}
            className={classNames(classes.translationText, {
              [classes.suggested]: !!translatedSentence,
            })}
            variant="outlined"
            fullWidth
            placeholder={noTranslation}
            value={stepLocaleSentence || ''}
            onChange={(event) => setStepLocaleSentence(event.target.value)}
            InputLabelProps={{ shrink: true }}
          />
        </Box>
      )}
      <Box className={classes.content}>
        <Grid container spacing={2}>
          <StepAction action={stepLocale?.action1} />
          <StepContainers toContainer={stepLocale.toContainer} />
          <StepIngredients
            enUsIngredientGroup={stepEnUs?.ingredientGroup}
            localeIngredientGroup={stepLocale?.ingredientGroup}
          />
          <StepAppliance appliance={stepLocale?.appliance} step={stepLocale} />
        </Grid>
        <StepTips stepIndex={stepIndex} tips={stepLocale?.tips} />
      </Box>
    </Paper>
  );
});
