import { makeStyles } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';
import { useSnackbar } from 'notistack';
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import {
  selectSnackbar,
  snackbarDismissed,
  snackbarRemoved,
} from 'features/snackbar/snackbarSlice';

let displayed: string[] = [];

const useStyles = makeStyles(() => ({
  alert: {
    width: 400,
  },
}));

export const Snackbar: React.FC = React.memo(function Snackbar() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const notifications = useSelector(selectSnackbar);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const storeDisplayed = (key: string) => {
    displayed.push(key);
  };

  const removeDisplayed = (key: string) => {
    displayed = displayed.filter((x) => key !== x);
  };

  useEffect(() => {
    notifications.forEach(({ key, text, severity, dismissed = false }) => {
      if (dismissed) {
        closeSnackbar(key);
        return;
      }

      if (displayed.includes(key)) return;

      enqueueSnackbar(text, {
        key,
        variant: severity,
        persist: severity === 'error' || severity === 'warning',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'center',
        },
        content: (_, message) => (
          <Alert
            onClose={() => {
              dispatch(snackbarDismissed({ key }));
            }}
            severity={severity}
            className={classes.alert}
          >
            {message}
          </Alert>
        ),
        onExited: () => {
          dispatch(snackbarRemoved({ key }));
          removeDisplayed(key);
        },
      });

      storeDisplayed(key);
    });
  }, [notifications, closeSnackbar, enqueueSnackbar, dispatch, classes]);

  return null;
});
