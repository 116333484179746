import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import isNil from 'lodash/isNil';
import React from 'react';
import { useSelector } from 'react-redux';

import {
  selectLayoutHeaderTitle,
  selectLayoutHeaderRecipeStatus,
} from 'features/layout/layoutSlice';
import { dropRecipeStatusNamesMap } from 'shared/types/recipe';

const useStyles = makeStyles((theme) => ({
  title: {
    overflow: 'hidden',
    flexShrink: 1,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    display: 'inline',
  },
  subTitle: {
    display: 'inline',
    color: theme.palette.primary.light,
  },
}));

export const HeaderTitle: React.FC = React.memo(function HeaderTitle() {
  const classes = useStyles();
  const title = useSelector(selectLayoutHeaderTitle);
  const recipeStatus = useSelector(selectLayoutHeaderRecipeStatus);

  return (
    <>
      <Typography className={classes.title} variant="h6">
        {title}
      </Typography>
      {!isNil(recipeStatus) && (
        <Typography className={classes.subTitle}>
          {` - ${dropRecipeStatusNamesMap[recipeStatus]}`}
        </Typography>
      )}
    </>
  );
});
