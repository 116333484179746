import { combineReducers } from '@reduxjs/toolkit';

import { apiReducer } from 'features/recipe/api/apiSlice';
import { containersReducer } from 'features/recipe/containers/containersSlice';
import { detailsReducer } from 'features/recipe/details/detailsSlice';
import { editReducer } from 'features/recipe/edit/editSlice';
import { ingredientsReducer } from 'features/recipe/ingredients/ingredientsSlice';
import { lintReportReducer } from 'features/recipe/linting/lintReportSlice';
import { publishStatusReducer } from 'features/recipe/publish/publishStatusSlice';
import { stepsReducer } from 'features/recipe/steps/stepsSlice';

export const recipeReducer = combineReducers({
  api: apiReducer,
  details: detailsReducer,
  containers: containersReducer,
  ingredients: ingredientsReducer,
  steps: stepsReducer,
  edit: editReducer,
  lintReport: lintReportReducer,
  publishStatus: publishStatusReducer,
});
