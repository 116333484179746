import type { FrescoContainer } from 'shared/types/container';
import type {
  FrescoEntity,
  FrescoEntityUri,
  FrescoId,
} from 'shared/types/entity';
import type { FrescoMedia } from 'shared/types/media';
import type {
  FrescoStepTemperatureUnits,
  FrescoStepAction,
} from 'shared/types/step';

export interface FrescoApplianceAttachment {
  uri: string;
  name: string;
  capabilities?: FrescoEntityUri[];
}

export interface FrescoVesselFlag {
  key: string;
  name: string;
  type: 'bool';
}

export interface FrescoVessel {
  flags: FrescoVesselFlag[];
}

export interface FrescoProfileTemperatureRangeEnumValue {
  name: string;
  value: string;
}

export interface FrescoProfileTemperatureRangeNumberValue {
  c?: number;
  f?: number;
}

export interface FrescoProfileTemperatureRangeNumber {
  type: 'number';
  start: FrescoProfileTemperatureRangeNumberValue;
  end: FrescoProfileTemperatureRangeNumberValue;
  step: FrescoProfileTemperatureRangeNumberValue;
  default?: number;
}

export interface FrescoProfileTemperatureRangeEnum {
  type: 'enum';
  values: FrescoProfileTemperatureRangeEnumValue[];
  default?: string;
}

export type FrescoProfileTemperatureRange =
  | FrescoProfileTemperatureRangeEnum
  | FrescoProfileTemperatureRangeNumber;

export interface FrescoPresetTemperature {
  ranges?: FrescoProfileTemperatureRange[];
  required?: boolean;
}

export interface FrescoPresetTimeValues {
  max: number;
  min: number;
  step: number;
}

export interface FrescoPresetTime {
  name: string;
  type: 'number';
  values: FrescoPresetTimeValues[];
  default: number;
  required?: boolean;
}

export interface FrescoApplianceProfilePreset
  extends FrescoApplianceProfileSettings {
  name: string;
  /**
   * Some presets may have 'null' temperature if they don't support temperature
   */
  temperature?: FrescoPresetTemperature | null;
  time?: FrescoPresetTime | null;
}
export interface FrescoAppliancePresets {
  [key: string]: FrescoApplianceProfilePreset;
}

export interface FrescoApplianceProfileTemperature
  extends FrescoPresetTemperature {
  defaultUnit?: FrescoStepTemperatureUnits.C | FrescoStepTemperatureUnits.F;
  supportedUnits?: (
    | FrescoStepTemperatureUnits.C
    | FrescoStepTemperatureUnits.F
  )[];
}

export interface FrescoAppliancePresetSetting {
  name: string;
  value: number;
}

export interface FrescoApplianceSetting {
  id: FrescoId;
  uri: string;
  name: string;
}

export function isPresetSetting(
  setting: FrescoApplianceProfileSettingEnumValue
): setting is FrescoAppliancePresetSetting {
  return !!(setting as FrescoAppliancePresetSetting).value;
}

export type FrescoApplianceProfileSettingEnumValue =
  | FrescoAppliancePresetSetting
  | FrescoApplianceSetting;

export interface FrescoApplianceProfileSettingEnum {
  name: string;
  type: 'enum';
  values: FrescoApplianceProfileSettingEnumValue[];
  default?: number;
  required?: boolean;
}

export interface FrescoApplianceProfileSettings {
  setting1?: FrescoApplianceProfileSettingEnum;
  setting2?: FrescoApplianceProfileSettingEnum;
  setting3?: FrescoApplianceProfileSettingEnum;
  setting4?: FrescoApplianceProfileSettingEnum;
}

export interface FrescoPresetTimeWithPrompt extends FrescoPresetTime {
  prompt: string;
}

// Currently only ever going to be Time, but could be FrescoApplianceProfileTemperature or any other type in future
export interface FrescoApplianceProfileSettingsTimeSetting {
  timeSetting: FrescoPresetTimeWithPrompt;
}

export type FrescoApplianceProfileSettingsOptions = Record<
  string,
  FrescoApplianceProfileSettingsTimeSetting
>;

export type FrescoApplianceProfileVessels = Record<string, FrescoVessel>;
export interface FrescoApplianceProfile {
  settingsOptions?: FrescoApplianceProfileSettingsOptions;
  presets?: FrescoAppliancePresets;
  attachments?: FrescoApplianceAttachment[];
  vessels?: FrescoApplianceProfileVessels;
  settings?: FrescoApplianceProfileSettings;
  temperature?: FrescoApplianceProfileTemperature;
  time?: FrescoPresetTime | null;
  requiredPresetId?: boolean;
}

export enum FrescoApplianceType {
  Dummy = 'dummy',
  Smart = 'smart',
  Local = 'local',
}
export enum FrescoAppliancePairType {
  Ownable = 'ownable',
}
export interface FrescoAppliance extends FrescoEntity {
  image: FrescoMedia | null;
  name: string;
  profile?: FrescoApplianceProfile;
  type?: FrescoApplianceType;
  capabilities?: FrescoStepAction[];
  containers?: FrescoContainer[];
  pairType: FrescoAppliancePairType | null;
}

export interface FrescoAttachment extends FrescoEntity {
  image: FrescoMedia;
  name: string;
  color?: string;
  capabilities?: FrescoStepAction[];
}
