import type { PayloadAction } from '@reduxjs/toolkit';
import {
  select,
  put,
  call,
  takeLatest,
  takeEvery,
  take,
} from 'redux-saga/effects';

import { createRequestApiSaga } from 'api/createRequestApiSaga';
import type { GetRecipeRequest, RecipeTranslations } from 'api/recipe';
import {
  apiPostRecipeSuggestTranslations,
  apiGetRecipeTranslation,
  apiPatchRecipeTranslation,
} from 'api/recipe';
import type { ApiResponse, JobResponse, LocationResponse } from 'api/types';
import {
  jobFetchFailed,
  jobFetchFinished,
  jobFetchRequested,
  selectJobResult,
} from 'features/jobs/jobSlice';
import { apiPostPublishStateSaga } from 'features/recipe/publish/publishStatusSlice';
import { recipesSmartTranslationsStatusRequested } from 'features/recipes/recipesSlice';
import { snackbarOpen } from 'features/snackbar/snackbarSlice';
import {
  recipeFetchFailed,
  recipeFetchFinished,
  recipeFetchRequested,
  recipeSaveRequested,
  recipeSaveFailed,
  recipeSaveFinished,
  selectPatchRecipeTranslation,
  recipeFetchTranslationsRequested,
  recipeFetchTranslationsFailed,
  recipeFetchTranslationsFinished,
  selectRecipeStatusSaved,
  selectRecipe,
} from 'features/translation/recipe/recipeSlice';
import type {
  PatchRecipeRequest,
  FetchTranslationsPayload,
} from 'features/translation/recipe/recipeSlice';
import type { ApiRecipeI18n } from 'shared/types/i18n';
import { ApiLocale } from 'shared/types/i18n';
import { JobTranslationStatus } from 'shared/types/job';
import { getErrorString } from 'shared/utils/common';
import {
  areSuggestionsEnabled,
  getIsTranslatableLocale,
} from 'shared/utils/translation';

export const apiGetRecipeSaga = createRequestApiSaga(
  apiGetRecipeTranslation,
  'Recipe loading'
);

export const apiPatchRecipeSaga = createRequestApiSaga(
  apiPatchRecipeTranslation,
  'Recipe saving'
);

export const apiPostSuggestTranslationsSaga = createRequestApiSaga(
  apiPostRecipeSuggestTranslations,
  'Recipe translations loading'
);

export function* requestRecipe({
  payload: { recipeId, locale },
}: PayloadAction<GetRecipeRequest>) {
  try {
    let recipeLocaleResponseData: ApiRecipeI18n | undefined;
    if (locale !== ApiLocale.EnUS) {
      const recipeLocaleResponse: ApiResponse<ApiRecipeI18n> = yield call(
        apiGetRecipeSaga,
        {
          recipeId,
          locale,
        }
      );
      if (!recipeLocaleResponse.ok) {
        yield put(recipeFetchFailed(recipeLocaleResponse.details.message));
        return;
      }
      recipeLocaleResponseData = recipeLocaleResponse.data;
    }
    const recipeEnUSResponse: ApiResponse<ApiRecipeI18n> = yield call(
      apiGetRecipeSaga,
      {
        recipeId,
        locale: ApiLocale.EnUS,
      }
    );

    if (!recipeEnUSResponse.ok) {
      yield put(recipeFetchFailed(recipeEnUSResponse.details.message));
      return;
    }

    yield put(
      recipeFetchFinished({
        recipeLocale: recipeLocaleResponseData,
        recipeEnUs: recipeEnUSResponse.data,
        locale,
      })
    );
    if (areSuggestionsEnabled(locale) && getIsTranslatableLocale(locale)) {
      yield put(
        recipesSmartTranslationsStatusRequested({
          locale,
          recipeIds: [recipeId],
        })
      );
    }
  } catch (e) {
    yield put(recipeFetchFailed(getErrorString(e)));
  }
}

export function* patchRecipe({
  payload: { recipeId, locale },
}: PayloadAction<PatchRecipeRequest>) {
  try {
    const recipeTranslations: RecipeTranslations = yield select(
      selectPatchRecipeTranslation(locale)
    );
    const response: ApiResponse<ApiRecipeI18n> = yield call(
      apiPatchRecipeSaga,
      {
        recipeId,
        locale,
        recipeTranslations,
      }
    );

    if (!response.ok) {
      yield put(recipeSaveFailed(response.details.message));
      return;
    }

    const isStatusSaved = (yield select(selectRecipeStatusSaved)) as boolean;
    if (!isStatusSaved) {
      const { status } = (yield select(selectRecipe(locale))) as ApiRecipeI18n;
      const publishResponse: ApiResponse<LocationResponse> = yield call(
        apiPostPublishStateSaga,
        {
          recipeId,
          locales: { locales: [{ locale, status }] },
        }
      );

      if (!publishResponse.ok) {
        yield put(recipeSaveFailed(publishResponse.details.message));
        return;
      }
    }
    yield put(recipeSaveFinished());
  } catch (e) {
    yield put(recipeSaveFailed(getErrorString(e)));
  }
}

export function* suggestTranslations({
  payload: { locale, sentences },
}: PayloadAction<FetchTranslationsPayload>) {
  try {
    const emptySentences = sentences.map((sentence) => sentence.original);
    const response: ApiResponse<JobResponse> = yield call(
      apiPostSuggestTranslationsSaga,
      {
        locale,
        sentences: emptySentences,
      }
    );
    if (!response.ok) {
      yield put(recipeFetchTranslationsFailed(response.details.message));
      return;
    }

    yield put(jobFetchRequested({ jobId: response.data.jobId }));
    yield take([jobFetchFinished, jobFetchFailed]);
    const jobResponse: ReturnType<typeof selectJobResult> = yield select(
      selectJobResult
    );
    if (jobResponse?.status === JobTranslationStatus.Error) {
      yield put(recipeFetchTranslationsFailed(jobResponse?.message));
      return;
    }

    yield put(
      recipeFetchTranslationsFinished({
        locale,
        sentences,
        suggestions: jobResponse?.details.suggestions ?? [],
      })
    );

    yield put(
      snackbarOpen({
        text: `${sentences.length} sentences have been automatically translated`,
        severity: 'success',
      })
    );
  } catch (e) {
    yield put(recipeFetchTranslationsFailed(getErrorString(e)));
  }
}

function* requestRecipeFetchWatcher() {
  yield takeEvery(recipeFetchRequested, requestRecipe);
}

function* requestRecipePostWatcher() {
  yield takeLatest(recipeSaveRequested, patchRecipe);
}

function* requestRecipeSuggestTranslationsPostWatcher() {
  yield takeLatest(recipeFetchTranslationsRequested, suggestTranslations);
}

export const recipeTranslationSagas = [
  requestRecipeFetchWatcher,
  requestRecipePostWatcher,
  requestRecipeSuggestTranslationsPostWatcher,
];
