import {
  Grid,
  TextField,
  Box,
  Button,
  Paper,
  MenuItem,
  makeStyles,
  CircularProgress,
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import ConfettiExplosion from '@reonomy/react-confetti-explosion';
import times from 'lodash/times';
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import type { AppDispatch } from 'app/store';
import { selectRecipeLintEnabled } from 'features/developerMenu/featureFlagsSlice';
import { selectRecipeFetched } from 'features/recipe/api/apiSlice';
import {
  selectDetails,
  selectRecipeId,
  selectDetailsSaving,
} from 'features/recipe/details/detailsSlice';
import { LintReport } from 'features/recipe/linting/LintReport';
import { lintReportReset } from 'features/recipe/linting/lintReportSlice';
import { PublishRecipe } from 'features/recipe/publish/PublishRecipe';
import { recipePatchRequested } from 'features/recipe/recipeSlice/recipeActions';
import {
  selectTermsTagsEntities,
  tagTermsFetchRequested,
  selectTermsTagsApiPending,
} from 'features/terms/tagsSlice';
import {
  selectTermsYieldsApiPending,
  selectTermsYieldsEntities,
  yieldTermsFetchRequested,
} from 'features/terms/yieldSlice';
import { ListSkeleton } from 'shared/components/Skeletons';
import { TimeInput } from 'shared/components/TimerInput';
import type { FrescoTag } from 'shared/types/recipe';
import { FrescoTimeType } from 'shared/types/recipe';

const useStyles = makeStyles((theme) => ({
  submitButton: {
    display: 'flex',
    justifyContent: 'flex-end',
    paddingTop: theme.spacing(2),
  },
  container: {
    display: 'flex',
    width: '100%',
  },
  detailsPane: {
    flexGrow: 1,
    marginRight: theme.spacing(3),
  },
  removeMargin: {
    margin: 0,
    padding: 0,
  },
  yieldInput: {
    width: 40,
  },
  yieldTypeAutocomplete: {
    flexGrow: 1,
  },
  loadingIcon: {
    alignSelf: 'flex-end',
  },
}));

const bigExplodeProps = {
  force: 0.7,
  duration: 5000,
  particleCount: 300,
  floorHeight: 900,
  floorWidth: 1600,
};

export const RecipeReviewPage: React.FC = React.memo(
  function RecipeReviewPage() {
    const classes = useStyles();
    const dispatch: AppDispatch = useDispatch();
    const yieldTerms = useSelector(selectTermsYieldsEntities);
    const yieldTermsPending = useSelector(selectTermsYieldsApiPending);
    const tags = useSelector(selectTermsTagsEntities);
    const tagsPending = useSelector(selectTermsTagsApiPending);
    const isRecipeLintEnabled = useSelector(selectRecipeLintEnabled);
    const recipeId = useSelector(selectRecipeId);
    const details = useSelector(selectDetails);
    const isSaving = useSelector(selectDetailsSaving);
    const isFetched = useSelector(selectRecipeFetched);
    const [firstSave, setFirstSave] = useState(true);
    const maxDifficulty = 5;

    const [difficulty, setDifficulty] = useState<number | undefined>(0);
    const [preparationTime, setPreparationTime] = useState<number | undefined>(
      0
    );
    const [cookTime, setCookTime] = useState<number | undefined>(0);
    const [otherTime, setOtherTime] = useState<number | undefined>(0);
    const [servings, setServings] = useState<number | undefined>(0);
    const [recipeYield, setRecipeYield] = useState<number | undefined>(0);
    const [yieldTermUri, setYieldTermUri] = useState<string | null>();
    const [recipeTags, setRecipeTags] = useState<FrescoTag[]>([]);

    useEffect(() => {
      dispatch(lintReportReset());
    }, [dispatch]);

    useEffect(() => {
      if (!tags.length && !tagsPending) {
        dispatch(tagTermsFetchRequested());
      }
    }, [dispatch, tags, tagsPending]);

    useEffect(() => {
      if (!yieldTerms.length && !yieldTermsPending) {
        dispatch(yieldTermsFetchRequested());
      }
    }, [dispatch, yieldTerms, yieldTermsPending]);

    useEffect(() => {
      let timeout: ReturnType<typeof setTimeout>;
      if (!isSaving && isFetched) {
        setDifficulty(details.difficulty);
        setPreparationTime(details.preparationTime);
        setCookTime(details.cookTime);
        setOtherTime(details.otherTime);
        setServings(details.servings);
        setRecipeYield(details.yield);
        setYieldTermUri(details.yieldTermUri);
        setRecipeTags(details.tags || []);
      }
      if (isSaving && firstSave) {
        timeout = setTimeout(() => setFirstSave(false), 500);
      }
      return () => clearTimeout(timeout);
    }, [details, isSaving, isFetched, firstSave]);

    const saveDetails = (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      if (!recipeId) {
        return;
      }
      dispatch(
        recipePatchRequested({
          recipe: {
            difficulty,
            times: [
              {
                type: FrescoTimeType.Preparation,
                time: preparationTime || 0,
              },
              { type: FrescoTimeType.Cook, time: cookTime || 0 },
              { type: FrescoTimeType.Other, time: otherTime || 0 },
            ],
            nutrition: {
              // Serving + yield cannot be 0
              servings: servings || 1,
              yield: Number(recipeYield) || 1,
            },
            yieldTerm: yieldTermUri ? { uri: yieldTermUri } : undefined,
            tags: recipeTags?.map(({ uri }) => ({ uri })),
          },
        })
      );
    };

    if (!isFetched || yieldTermsPending) {
      return (
        <Paper>
          <Box p={2} mt={1}>
            <ListSkeleton rows={3} />
            <Grid className={classes.submitButton} item xs={12}>
              <CircularProgress
                className={classes.loadingIcon}
                aria-label="Saving indicator"
              />
            </Grid>
          </Box>
        </Paper>
      );
    }

    const parseNumberValues = (value: string | undefined) =>
      value ? parseInt(value) : undefined;

    return (
      <>
        <Paper>
          <Box p={2} mt={1}>
            <form onSubmit={saveDetails}>
              <div className={classes.container}>
                <div className={classes.detailsPane}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Grid container spacing={2}>
                        <Grid item xs={6} md={4}>
                          {!yieldTermsPending && (
                            <TextField
                              autoFocus
                              fullWidth
                              type="number"
                              label="Yield"
                              id="Yield"
                              variant="outlined"
                              value={recipeYield}
                              onChange={(event) => {
                                setRecipeYield(
                                  parseNumberValues(event.target.value)
                                );
                              }}
                              required
                              InputProps={{
                                inputProps: {
                                  className: classes.yieldInput,
                                },
                                endAdornment: (
                                  <TextField
                                    fullWidth
                                    select
                                    value={yieldTermUri || ''}
                                    className={classes.yieldTypeAutocomplete}
                                    id="YieldType"
                                    onChange={(event) => {
                                      setYieldTermUri(event.target.value);
                                    }}
                                    InputProps={{
                                      disableUnderline: true,
                                      required: true,
                                    }}
                                  >
                                    {yieldTerms.map((term) => (
                                      <MenuItem key={term.uri} value={term.uri}>
                                        {term.name}
                                      </MenuItem>
                                    ))}
                                  </TextField>
                                ),
                              }}
                              InputLabelProps={{
                                shrink: true,
                              }}
                            />
                          )}
                        </Grid>
                        <Grid item xs={6} md={4}>
                          <TextField
                            value={servings}
                            fullWidth
                            type="number"
                            label="Number of servings"
                            id="servings"
                            required
                            variant="outlined"
                            onChange={(event) => {
                              setServings(
                                parseNumberValues(event.target.value)
                              );
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <TextField
                            select
                            label="Difficulty"
                            id="difficulty"
                            variant="outlined"
                            value={difficulty}
                            onChange={(event) =>
                              setDifficulty(
                                Number(event.target.value) ?? undefined
                              )
                            }
                            fullWidth
                            inputProps={{ 'data-testid': 'difficulty' }}
                          >
                            {times(maxDifficulty, (i) => (
                              <MenuItem key={i} value={i + 1}>
                                {i + 1}
                              </MenuItem>
                            ))}
                          </TextField>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      <Grid container spacing={2}>
                        <Grid item xs={6} md={4}>
                          <TimeInput
                            label="Preparation"
                            id="Preparation"
                            value={preparationTime}
                            onChange={setPreparationTime}
                          />
                        </Grid>
                        <Grid item xs={6} md={4}>
                          <TimeInput
                            label="Cooking"
                            id="Cooking"
                            value={cookTime}
                            onChange={setCookTime}
                          />
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <TimeInput
                            label="Other"
                            id="Other"
                            value={otherTime}
                            onChange={setOtherTime}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      <Autocomplete
                        multiple
                        fullWidth
                        id="tags"
                        onChange={(_event, newValue) => {
                          setRecipeTags(newValue);
                        }}
                        options={tags}
                        getOptionLabel={(tag) => tag.name}
                        getOptionSelected={(option, value) =>
                          option.uri === value.uri
                        }
                        value={recipeTags}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            label="Tags"
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Grid container spacing={2}>
                        <PublishRecipe />
                      </Grid>
                    </Grid>
                  </Grid>
                </div>
              </div>

              <Grid className={classes.submitButton} item xs={12}>
                {isSaving && firstSave && (
                  <ConfettiExplosion {...bigExplodeProps} />
                )}
                {isSaving ? (
                  <CircularProgress
                    className={classes.loadingIcon}
                    aria-label="Saving indicator"
                  />
                ) : (
                  <Grid>
                    <Button variant="text" color="primary" type="submit">
                      Save
                    </Button>
                  </Grid>
                )}
              </Grid>
            </form>
          </Box>
        </Paper>
        {isRecipeLintEnabled && <LintReport />}
      </>
    );
  }
);
