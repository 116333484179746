import { Grid } from '@material-ui/core';
import type { FC } from 'react';
import { memo } from 'react';
import { useSelector } from 'react-redux';

import { selectCurrentLocale } from 'features/translation/localeSwitcher/localeSlice';
import { StepContainers as StepContainersLayout } from 'shared/components/stepViewEntities/StepContainers';
import { noTranslationSentence } from 'shared/constants';
import type { FrescoStepToContainerI18n } from 'shared/types/i18n';
import { ApiLocale } from 'shared/types/i18n';
import { getIsTranslatableLocale } from 'shared/utils/translation';

interface StepContainersProps {
  toContainer: FrescoStepToContainerI18n | null;
}

export const StepContainers: FC<StepContainersProps> = memo(
  function StepContainers({ toContainer }) {
    const locale = useSelector(selectCurrentLocale);

    if (!toContainer) {
      return null;
    }

    return (
      <Grid item xs={4}>
        <StepContainersLayout
          showHeader
          containerImage={toContainer?.container.image}
          containerName={
            toContainer?.container.nameTranslations?.[ApiLocale.EnUS] || ''
          }
          color="#000000"
        />
        {getIsTranslatableLocale(locale) && (
          <StepContainersLayout
            showHeader={false}
            containerImage={toContainer?.container.image}
            containerName={
              toContainer?.container.nameTranslations?.[locale] ||
              `${noTranslationSentence} ${locale}`
            }
            color="#000000"
          />
        )}
      </Grid>
    );
  }
);
