import { Grid, TextField, MenuItem, Typography } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import type { AppDispatch } from 'app/store';
import {
  selectPublishStatusApiPending,
  selectPublishStatusApiError,
  selectPublishStatusLocalesStatus,
  publishStatusFetchRequested,
  publishLocaleEditRequested,
} from 'features/recipe/publish/publishStatusSlice';
import type { ApiLocale } from 'shared/types/i18n';
import type { FrescoRecipeStatus } from 'shared/types/recipe';
import { dropRecipeStatusNamesMap } from 'shared/types/recipe';

export const PublishRecipe: React.FC = React.memo(function PublishRecipe() {
  const dispatch: AppDispatch = useDispatch();

  const isPending = useSelector(selectPublishStatusApiPending);
  const error = useSelector(selectPublishStatusApiError);
  const localesStatus = useSelector(selectPublishStatusLocalesStatus);

  useEffect(() => {
    if (!isPending && !localesStatus) {
      dispatch(publishStatusFetchRequested());
    }
  }, [dispatch, isPending, localesStatus]);

  if (error) {
    return <Typography>{error}</Typography>;
  }

  if (isPending) {
    return (
      <Grid item xs={12}>
        <Grid container>
          <Grid item xs={6} md={4}>
            <Typography>Recipe Publish Status</Typography>
            <Skeleton height={64} />
          </Grid>
        </Grid>
      </Grid>
    );
  }

  return (
    <>
      <Grid item xs={12}>
        <Typography>Recipe Publish Status</Typography>
      </Grid>
      {localesStatus &&
        Object.keys(localesStatus).map((key) => (
          <Grid key={key} item xs={6} md={4}>
            <TextField
              select
              fullWidth
              variant="outlined"
              value={localesStatus?.[key as ApiLocale]?.status}
              onChange={(event) => {
                dispatch(
                  publishLocaleEditRequested({
                    locale: key as ApiLocale,
                    status: Number(event.target.value),
                  })
                );
              }}
              label={key}
              id={key}
            >
              {Object.keys(dropRecipeStatusNamesMap)
                .map<FrescoRecipeStatus>(Number)
                .map((value) => (
                  <MenuItem key={value} value={value}>
                    {dropRecipeStatusNamesMap[value]}
                  </MenuItem>
                ))}
            </TextField>
          </Grid>
        ))}
    </>
  );
});
