import { DeleteIcon } from '@dropkitchen/icons-react';
import { Grid, IconButton } from '@material-ui/core';
import minBy from 'lodash/minBy';
import type { FC } from 'react';
import { useCallback, memo } from 'react';

import { TimeInput } from 'shared/components/TimerInput';
import type { FrescoPresetTime } from 'shared/types/appliance';
import { bigRange } from 'shared/utils/bigRange';

interface StepTimerProps {
  stepTimer: number;
  timerLabel?: string;
  timeRanges?: FrescoPresetTime | null;
  onChangeTimer: (time: number | null) => void;
}

export const StepTimer: FC<StepTimerProps> = memo(function StepTimer({
  stepTimer,
  timerLabel,
  timeRanges,
  onChangeTimer,
}) {
  const timerRequired = timeRanges?.required;

  const handleNumericInputBlur = useCallback(() => {
    const numberRanges = timeRanges?.values;
    if (!numberRanges?.length) {
      return;
    }

    const possibleValues = numberRanges.flatMap((range) => {
      const { min, max, step } = range;
      return bigRange(min, max, step);
    });
    const closestValue = minBy(possibleValues, (v) =>
      v.minus(stepTimer).abs().toNumber()
    );
    if (closestValue && !closestValue.eq(stepTimer)) {
      onChangeTimer(closestValue.toNumber());
    }
  }, [onChangeTimer, timeRanges, stepTimer]);

  return (
    <Grid item xs={12}>
      <Grid item container spacing={1} direction="row">
        <Grid item xs={timerRequired ? 12 : 10}>
          <TimeInput
            variant="outlined"
            label={timerLabel || 'Time'}
            id="Time"
            includeSeconds
            value={stepTimer}
            onBlur={handleNumericInputBlur}
            onChange={onChangeTimer}
            required={timerRequired}
          />
        </Grid>
        {!timerRequired && (
          <Grid item xs={2}>
            <IconButton
              aria-label="Delete"
              color="primary"
              onClick={() => onChangeTimer(null)}
            >
              <DeleteIcon />
            </IconButton>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
});
