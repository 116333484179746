export const getIdFromUri = (uri: string) =>
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  uri.split('/').pop()!;

export const getIngredientGroupId = (uri: string) =>
  uri.split(/\/ingredient-groups\/|\/ingredients\//)[1];

export const getErrorString = (e: unknown): string =>
  (e as Error).message || String(e);

export const isValidUrl =
  /^(https:\/\/|http:\/\/)[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/;

export const isVirtualTip = (uri: string): boolean => uri.includes('vtips');

export const isVirtualStep = (uri: string): boolean => uri.includes('vsteps');

export const goToExternalUrl = (uri: string) => {
  window.open(uri, '_blank', 'noopener');
};
