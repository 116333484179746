import { MenuItem, TextField } from '@material-ui/core';
import type { FC } from 'react';

import type { FrescoRecipeStatus } from 'shared/types/recipe';
import { dropRecipeStatusNamesMap } from 'shared/types/recipe';

interface RecipeStatusSelectorProps {
  currentStatus?: FrescoRecipeStatus;
  disabled?: boolean;
  id?: string;
  label?: string;
  onChange: (status: string) => void;
}

export const RecipeStatusSelector: FC<RecipeStatusSelectorProps> = ({
  currentStatus,
  disabled = false,
  id = 'status',
  label = 'Status',
  onChange,
}) => {
  return (
    <TextField
      select
      fullWidth
      variant="outlined"
      value={currentStatus ?? ''}
      onChange={(e) => onChange(e.target.value)}
      label={label}
      id={id}
      disabled={disabled}
    >
      {Object.keys(dropRecipeStatusNamesMap)
        .map<FrescoRecipeStatus>(Number)
        .map((value) => (
          <MenuItem key={value} value={value}>
            {dropRecipeStatusNamesMap[value]}
          </MenuItem>
        ))}
    </TextField>
  );
};
