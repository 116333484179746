import { Container, Tab, makeStyles } from '@material-ui/core';
import { TabContext, TabList, TabPanel } from '@material-ui/lab';
import type React from 'react';
import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, Link } from 'react-router-dom';

import { createRecipeRoutesPath } from 'app/routes/constants';
import { selectCreateFromUrlEnabled } from 'features/developerMenu/featureFlagsSlice';
import { layoutHeaderTitleSet } from 'features/layout/layoutSlice';
import { CreateFromText } from 'features/recipe/create/CreateFromText';
import { CreateFromUrl } from 'features/recipe/create/CreateFromUrl';
import { SecondaryAppBar } from 'shared/components/SecondaryAppBar';
import { TabNameCreate } from 'shared/constants';

const useStyles = makeStyles((theme) => ({
  tabIndicator: {
    backgroundColor: theme.palette.primary.light,
    bottom: 0,
  },
}));

export const CreatePage: React.FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const isCreateFromUrlEnabled = useSelector(selectCreateFromUrlEnabled);

  const { ...restRouteParams } = useParams<{
    tab: TabNameCreate;
  }>();
  const tab = (restRouteParams.tab as TabNameCreate) || TabNameCreate.FromUrl;

  useEffect(() => {
    dispatch(layoutHeaderTitleSet('Create recipe'));
  }, [dispatch]);

  if (!isCreateFromUrlEnabled) {
    return (
      <TabContext value={tab}>
        <Container maxWidth="md">
          <CreateFromText />
        </Container>
      </TabContext>
    );
  }

  return (
    <TabContext value={tab}>
      <SecondaryAppBar>
        <TabList
          centered
          TabIndicatorProps={{
            className: classes.tabIndicator,
          }}
        >
          <Tab
            label="Create From Url"
            value={TabNameCreate.FromUrl}
            component={Link}
            to={`${createRecipeRoutesPath}/${TabNameCreate.FromUrl}`}
          />
          <Tab
            label="Create From Text"
            value={TabNameCreate.FromText}
            component={Link}
            to={`${createRecipeRoutesPath}/${TabNameCreate.FromText}`}
          />
        </TabList>
      </SecondaryAppBar>
      <Container maxWidth="md">
        <TabPanel value={TabNameCreate.FromText}>
          <CreateFromText />
        </TabPanel>
        <TabPanel value={TabNameCreate.FromUrl}>
          <CreateFromUrl />
        </TabPanel>
      </Container>
    </TabContext>
  );
};
