import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice, createAction } from '@reduxjs/toolkit';
import { put, call, select, takeLatest } from 'redux-saga/effects';

import { apiGetCollections } from 'api/collection';
import { createRequestApiSaga } from 'api/createRequestApiSaga';
import type { ApiResponse } from 'api/types';
import type { RootState } from 'app/rootReducer';
import { logoutSuccess } from 'features/login/loginSlice';
import { selectCurrentLocale } from 'features/translation/localeSwitcher/localeSlice';
import type {
  FrescoCollectionsGetResponse,
  FrescoCollection,
} from 'shared/types/collection';
import { TranslationStatus } from 'shared/types/i18n';
import type { FrescoTag } from 'shared/types/recipe';
import { getErrorString } from 'shared/utils/common';

export interface Filters {
  search?: string;
  tags?: FrescoTag[];
  translationStatus?: TranslationStatus;
}

export interface CollectionsState {
  apiError?: string;
  apiPending: boolean;
  page: number;
  filters: Filters;
  pageSize: number;
  totalPages: number;
  entites: FrescoCollection[];
}

export const initialState: CollectionsState = {
  apiPending: false,
  page: 1,
  pageSize: 25,
  entites: [],
  totalPages: 0,
  filters: {
    search: '',
    tags: [],
    translationStatus: TranslationStatus.All,
  },
};

const collectionsSlice = createSlice({
  name: 'collectionsSlice',
  initialState,
  reducers: {
    collectionsApiSuccess(
      state,
      { payload }: PayloadAction<FrescoCollectionsGetResponse>
    ) {
      state.apiPending = false;
      state.apiError = undefined;
      state.totalPages = payload.meta.total;
      state.entites = payload.results;
    },
    collectionsApiPending(state) {
      state.apiPending = true;
      state.apiError = undefined;
    },
    collectionsApiError(state, { payload }: PayloadAction<string>) {
      state.apiPending = false;
      state.apiError = payload;
      state.entites = [];
    },
    collectionsPageSet(state, { payload }: PayloadAction<number>) {
      state.page = payload;
    },
    collectionsPageSizeSet(state, { payload }: PayloadAction<number>) {
      state.pageSize = payload;
      state.page = 1;
    },
    collectionsTotalPagesSet(state, { payload }: PayloadAction<number>) {
      state.totalPages = payload;
    },
    collectionsFiltersSetSearch(state, { payload }: PayloadAction<string>) {
      state.filters.search = payload;
      state.page = 1;
    },
    collectionsFiltersSetTags(state, { payload }: PayloadAction<FrescoTag[]>) {
      state.filters.tags = payload;
      state.page = 1;
    },
    collectionsFiltersSetTranslationStatus(
      state,
      { payload }: PayloadAction<TranslationStatus>
    ) {
      state.filters.translationStatus = payload;
      state.page = 1;
    },
    collectionsFiltersReset(state) {
      state.filters = initialState.filters;
      state.page = 1;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(logoutSuccess, () => initialState);
  },
});

export const {
  reducer: collectionsReducer,
  actions: {
    collectionsApiSuccess,
    collectionsApiPending,
    collectionsApiError,
    collectionsPageSet,
    collectionsFiltersSetSearch,
    collectionsFiltersSetTranslationStatus,
    collectionsFiltersSetTags,
    collectionsPageSizeSet,
    collectionsTotalPagesSet,
    collectionsFiltersReset,
  },
} = collectionsSlice;

export const selectCollections = (state: RootState): FrescoCollection[] =>
  state.collections.entites;

export const selectCollectionsPage = (state: RootState): number =>
  state.collections.page;

export const selectCollectionsPageSize = (state: RootState): number =>
  state.collections.pageSize;

export const selectCollectionsTotalPages = (state: RootState): number =>
  state.collections.totalPages;

export const selectCollectionsFilters = (
  state: RootState
): Filters | undefined => state.collections.filters;

export const selectCollectionsApiError = (
  state: RootState
): string | undefined => state.collections.apiError;

export const selectCollectionsApiPending = (state: RootState): boolean =>
  state.collections.apiPending;

export const selectCollectionsFiltersSearch = (
  state: RootState
): string | undefined => state.collections.filters?.search;

export const selectCollectionsFiltersTags = (
  state: RootState
): FrescoTag[] | undefined => state.collections.filters?.tags;

export const selectCollectionsFiltersTranslationStatus = (
  state: RootState
): TranslationStatus | undefined =>
  state.collections.filters?.translationStatus;

export const apiGetCollectionsSaga = createRequestApiSaga(
  apiGetCollections,
  'Collections loading'
);

export function* requestCollections() {
  yield put(collectionsApiPending());

  const page: ReturnType<typeof selectCollectionsPage> = yield select(
    selectCollectionsPage
  );
  const pageSize: ReturnType<typeof selectCollectionsPageSize> = yield select(
    selectCollectionsPageSize
  );
  const searchTerm: ReturnType<typeof selectCollectionsFiltersSearch> =
    yield select(selectCollectionsFiltersSearch);
  const tags: ReturnType<typeof selectCollectionsFiltersTags> = yield select(
    selectCollectionsFiltersTags
  );
  const translationStatus: ReturnType<
    typeof selectCollectionsFiltersTranslationStatus
  > = yield select(selectCollectionsFiltersTranslationStatus);
  const locale: ReturnType<typeof selectCurrentLocale> = yield select(
    selectCurrentLocale
  );

  try {
    const response: ApiResponse<FrescoCollectionsGetResponse> = yield call(
      apiGetCollectionsSaga,
      { page, pageSize, searchTerm, tags, translationStatus, locale }
    );
    if (!response.ok) {
      yield put(collectionsApiError(response.details.message));
      return;
    }
    yield put(collectionsApiSuccess(response.data));
    return;
  } catch (e) {
    yield put(collectionsApiError(getErrorString(e)));
  }
}

export const collectionsFetchRequested = createAction(
  'collectionsSlice/fetchRequested'
);

function* requestCollectionsFetchWatcher() {
  yield takeLatest(collectionsFetchRequested, requestCollections);
}
export const collectionsSagas = [requestCollectionsFetchWatcher];
