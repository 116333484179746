import { Paper, Box, Grid, TextField, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Autocomplete from '@material-ui/lab/Autocomplete';
import debounce from 'lodash/debounce';
import { useMemo, useEffect } from 'react';
import type React from 'react';
import type { FC } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import {
  collectionsFetchRequested,
  collectionsFiltersSetSearch,
  collectionsFiltersSetTags,
  selectCollectionsFiltersSearch,
  selectCollectionsFiltersTags,
  collectionsFiltersReset,
  collectionsFiltersSetTranslationStatus,
  selectCollectionsFiltersTranslationStatus,
} from 'features/collections/collectionsSlice';
import {
  selectTermsTagsEntities,
  selectTermsTagsApiPending,
  tagTermsFetchRequested,
} from 'features/terms/tagsSlice';
import { selectCurrentLocale } from 'features/translation/localeSwitcher/localeSlice';
import { TranslationStatusFilter } from 'shared/components/TranslationStatusFilter';
import { entityFiltersHeight } from 'shared/constants';

const useStyles = makeStyles((theme) => ({
  filtersHeader: {
    marginTop: theme.spacing(1),
  },
  searchContainer: {
    marginTop: theme.spacing(0.5),
    marginBottom: theme.spacing(0.5),
    alignItems: 'center',
  },
  container: {
    height: `${theme.spacing(entityFiltersHeight)}px`,
  },
}));

export const CollectionsFilters: FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const tags = useSelector(selectTermsTagsEntities);
  const tagsPending = useSelector(selectTermsTagsApiPending);
  const searchTerm = useSelector(selectCollectionsFiltersSearch);
  const filterByTags = useSelector(selectCollectionsFiltersTags);
  const status = useSelector(selectCollectionsFiltersTranslationStatus);
  const locale = useSelector(selectCurrentLocale);

  const debounceFilters = useMemo(
    () => debounce(() => dispatch(collectionsFetchRequested()), 500),
    [dispatch]
  );

  useEffect(() => {
    if (!tags.length && !tagsPending) {
      dispatch(tagTermsFetchRequested());
    }
  }, [dispatch, tags, tagsPending]);

  useEffect(() => {
    debounceFilters();
  }, [debounceFilters, filterByTags, locale, searchTerm, status]);

  // FIXME: this flag is to hide search and tags filters.
  // We can't use them right now as there is a bug in platform and searching by q and type makes server skip type filter.
  // So if a q is entered, all the collections, not only the featured ones, are returned.
  const showFilters = false;

  return (
    <Box pt={3} pb={1} className={classes.container}>
      <Paper>
        <Box px={2} py={1}>
          <Grid container className={classes.searchContainer} spacing={2}>
            {showFilters && (
              <>
                <Grid item xs={12} sm={5}>
                  <TextField
                    fullWidth
                    type="text"
                    variant="outlined"
                    value={searchTerm}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                      dispatch(collectionsFiltersSetSearch(event.target.value))
                    }
                    label="Search Collections"
                    id="search"
                  />
                </Grid>
                <Grid item xs={12} sm={5}>
                  <Autocomplete
                    multiple
                    fullWidth
                    id="tags"
                    onChange={(_event, newValue) => {
                      dispatch(collectionsFiltersSetTags(newValue));
                    }}
                    options={tags}
                    getOptionLabel={(tag) => tag.name}
                    getOptionSelected={(option, value) =>
                      option.uri === value.uri
                    }
                    value={filterByTags || []}
                    renderInput={(params) => (
                      <TextField {...params} variant="outlined" label="Tags" />
                    )}
                  />
                </Grid>
              </>
            )}
            <TranslationStatusFilter
              filterSetStatusAction={collectionsFiltersSetTranslationStatus}
              selectFilterTranslationStatus={
                selectCollectionsFiltersTranslationStatus
              }
            />
            <Grid item xs={12} sm={2}>
              <Button
                fullWidth
                variant="contained"
                color="primary"
                onClick={() => {
                  dispatch(collectionsFiltersReset());
                }}
              >
                Reset
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Paper>
    </Box>
  );
};
