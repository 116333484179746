import type { PayloadAction } from '@reduxjs/toolkit';
import type { EventChannel } from 'redux-saga';
import { eventChannel } from 'redux-saga';
import { call, take, put, select, takeEvery } from 'redux-saga/effects';

import type { ApiLoginResponse, ApiLoginRequest } from 'api/auth';
import { apiLogin, apiRefreshToken, apiRevokeToken } from 'api/auth';
import { createRequestApiSaga } from 'api/createRequestApiSaga';
import type { ApiResponse, SuccessResponse } from 'api/types';
import type { AppAuthData } from 'features/login/loginSlice';
import {
  loginRequested,
  logoutRequested,
  selectLoginApiPending,
  selectLoginIsAuthenticated,
  selectLoginAuthData,
  logoutSuccess,
  loginApiPendingUpdated,
  loginSuccess,
  loginApiError,
  userHasGroupRights,
} from 'features/login/loginSlice';
import { snackbarOpen } from 'features/snackbar/snackbarSlice';
import { appConfig } from 'shared/config';
import { organisations } from 'shared/constants';
import { recipesContentAdmin, backStageAccessGroup } from 'shared/permissions';
import { getErrorString } from 'shared/utils/common';
import { getTime } from 'shared/utils/getTimes';
import {
  localStorageUser,
  localStorageTokenIsRefreshing,
} from 'shared/utils/localStorage';

export const tokenRefreshPeriod = 60 * 60 * 1000;

const isBackStage = appConfig.appBackstage();

export const userAccessRightsError = isBackStage
  ? 'You do not have access rights to access Backstage'
  : 'You must have content admin rights to access Creator 2.0';

export const apiLoginSaga = createRequestApiSaga(apiLogin, 'Request Login');

export const apiLogoutSaga = createRequestApiSaga(
  apiRevokeToken,
  'Request Logout'
);

export const apiRefreshTokenSaga = createRequestApiSaga(
  apiRefreshToken,
  'Refresh Token'
);

/**
 * Put a special value in localStorage to let other tabs know that token refresh is running
 */
export const lockTokenRefresh = () => {
  localStorageTokenIsRefreshing.setValue(true);

  // Just in case if user closed tab
  window.addEventListener('beforeunload', unlockTokenRefresh);
};

/**
 * Remove a special value in localStorage to let other tabs know that token refresh is finished
 */
export const unlockTokenRefresh = () => {
  localStorageTokenIsRefreshing.removeValue();
  window.removeEventListener('beforeunload', unlockTokenRefresh);
};

export function* handleLogInResponse(
  response: SuccessResponse<ApiLoginResponse>
) {
  const responseUserGroups = response.data.user.groups;
  const hasBackStageAccess = userHasGroupRights(
    backStageAccessGroup,
    responseUserGroups
  );

  const hasCreatorAccess = userHasGroupRights(
    { anyOf: [recipesContentAdmin] },
    responseUserGroups
  );

  const hasAccessRights = isBackStage ? hasBackStageAccess : hasCreatorAccess;
  if (!hasAccessRights) {
    yield call(localStorageUser.setValue, null);
    yield put(logoutSuccess());
    yield put(loginApiError(userAccessRightsError));
    yield put(
      snackbarOpen({
        text: userAccessRightsError,
        severity: 'error',
      })
    );
    return;
  }

  const time: ReturnType<typeof getTime> = yield call(getTime);

  const data: AppAuthData = {
    expiresAt: time + response.data.expiresIn * 1000,
    refreshToken: response.data.refreshToken,
    token: response.data.accessToken,
    userId: response.data.user.id.toString(),
    userEmail: response.data.user.email,
    userOrganisationName:
      organisations[Number(response.data.user.organization.id)],
    userOrganisationId: Number(response.data.user.organization.id),
    user: response.data.user,
  };

  yield call(localStorageUser.setValue, data);
  yield put(loginSuccess(data));
}

export function* requestLogin({
  payload: { email, password, clientId },
}: PayloadAction<ApiLoginRequest>) {
  const isLoginApiPending: boolean = yield select(selectLoginApiPending);
  if (isLoginApiPending) {
    return;
  }

  yield put(loginApiPendingUpdated(true));

  try {
    const loginResponse: ApiResponse<ApiLoginResponse> = yield call(
      apiLoginSaga,
      {
        email,
        password,
        clientId,
      }
    );
    if (!loginResponse.ok) {
      yield put(logoutSuccess());
      yield put(loginApiError(loginResponse.details.message));
      return;
    }

    yield call(handleLogInResponse, loginResponse);
  } catch (e) {
    yield put(logoutSuccess());
    yield put(
      snackbarOpen({
        text: userAccessRightsError,
        severity: 'error',
      })
    );
  }
}

export function* requestLogOut() {
  const authData: AppAuthData = yield select(selectLoginAuthData);
  if (!authData) {
    return;
  }

  try {
    const logoutResponse: ApiResponse<ApiLoginResponse> = yield call(
      apiLogoutSaga,
      {
        token: authData.token,
        orgId: authData.userOrganisationId,
      }
    );
    if (!logoutResponse.ok) {
      yield put(loginApiError(getErrorString(logoutResponse.details.message)));
    }
  } catch (e) {
    yield put(loginApiError(getErrorString(e)));
  } finally {
    yield put(logoutSuccess());
  }
}

export const createTokenIsRefreshingChannel = () =>
  eventChannel<boolean>((emit) =>
    localStorageTokenIsRefreshing.subscribe((value) => {
      emit(!!value);
    })
  );

export const createLocalStorageUserChannel = () =>
  eventChannel<AppAuthData | false>((emit) =>
    localStorageUser.subscribe((value) => {
      emit(value || false);
    })
  );

export function* tokenIsRefreshingWatcher() {
  const tokenIsRefreshingChannel: EventChannel<boolean> = yield call(
    createTokenIsRefreshingChannel
  );
  while (true) {
    const isRefreshing: boolean = yield take(tokenIsRefreshingChannel);
    yield put(loginApiPendingUpdated(isRefreshing));
  }
}

export function* localStorageUserWatcher() {
  const localStorageUserChannel: EventChannel<AppAuthData | false> = yield call(
    createLocalStorageUserChannel
  );
  while (true) {
    const authData: AppAuthData | false = yield take(localStorageUserChannel);
    if (authData) {
      yield put(loginSuccess(authData));
    } else {
      yield put(logoutSuccess());
    }
  }
}

export function* requestTokenRefresh() {
  const isPending: ReturnType<typeof selectLoginApiPending> = yield select(
    selectLoginApiPending
  );

  const isAuthenticated: ReturnType<typeof selectLoginIsAuthenticated> =
    yield select(selectLoginIsAuthenticated);
  if (isPending || !isAuthenticated) {
    return;
  }

  const authData: AppAuthData = yield select(selectLoginAuthData);

  yield put(loginApiPendingUpdated(true));
  yield call(lockTokenRefresh);

  try {
    const refreshTokenResponse: ApiResponse<ApiLoginResponse> = yield call(
      apiRefreshTokenSaga,
      {
        refreshToken: authData.refreshToken,
        orgId: authData.userOrganisationId,
      }
    );
    if (!refreshTokenResponse.ok) {
      yield put(loginApiError(refreshTokenResponse.details.message));
      // If HTTP code is 4xx - it is probably something wrong with token, let's log out
      if (refreshTokenResponse.httpStatus?.toString()[0] === '4') {
        yield put(logoutSuccess());
      }
      return;
    }

    yield call(handleLogInResponse, refreshTokenResponse);
  } catch (e) {
    yield put(logoutSuccess());
    yield put(loginApiError(getErrorString(e)));
  } finally {
    yield call(unlockTokenRefresh);
  }
}

function* logoutUser() {
  yield call(localStorageUser.setValue, null);
}

export function* logoutRequestedWatcher() {
  yield takeEvery(logoutRequested, requestLogOut);
}

export function* loginRequestedWatcher() {
  yield takeEvery(loginRequested, requestLogin);
}

export function* logoutSuccessWatcher() {
  yield takeEvery(logoutSuccess, logoutUser);
}

export const loginSagas = [
  logoutRequestedWatcher,
  loginRequestedWatcher,
  tokenIsRefreshingWatcher,
  localStorageUserWatcher,
  logoutSuccessWatcher,
];
