import type { PayloadAction, Update, EntityId } from '@reduxjs/toolkit';
import { createSlice, createAction } from '@reduxjs/toolkit';

import type { RootState } from 'app/rootReducer';
import { logoutSuccess } from 'features/login/loginSlice';
import {
  recipeFetchFinished,
  recipeFetchRequested,
  recipeFetchFailed,
} from 'features/recipe/recipeSlice/recipeActions';
import type { UpdateWithId } from 'shared/redux/saveableEntityAdapter';
import { createSaveableEntityAdapter } from 'shared/redux/saveableEntityAdapter';
import type { FrescoContainer } from 'shared/types/container';
import type { FrescoRecipeContainer } from 'shared/types/recipe';

export interface ContainerRequestPayload {
  container: FrescoContainer;
  quantity: number;
}

export const containersAdapter =
  createSaveableEntityAdapter<FrescoRecipeContainer>();

export const initialState = containersAdapter.getInitialState();

const containersSlice = createSlice({
  name: 'recipe/containersSlice',
  initialState,
  reducers: {
    containerAdded: (
      state,
      { payload }: PayloadAction<{ entity: FrescoRecipeContainer }>
    ) => {
      containersAdapter.addOne(state, payload);
    },
    containerUpdated: (
      state,
      { payload }: PayloadAction<{ update: Update<FrescoRecipeContainer> }>
    ) => {
      containersAdapter.updateOne(state, payload);
    },
    containerUpdatedWithNewId: (
      state,
      {
        payload,
      }: PayloadAction<{ update: UpdateWithId<FrescoRecipeContainer> }>
    ) => {
      containersAdapter.updateWithNewId(state, payload);
    },
    containerRemoved: (state, { payload }: PayloadAction<{ id: EntityId }>) => {
      containersAdapter.removeOne(state, payload);
    },
    containerRemoveRequested: (
      state,
      { payload }: PayloadAction<{ id: EntityId }>
    ) => {
      containersAdapter.saveRequest(state, payload);
    },
    containerSaveRequested: (
      state,
      { payload }: PayloadAction<{ id: EntityId }>
    ) => {
      containersAdapter.saveRequest(state, payload);
    },
    containerSaveFinished: (
      state,
      { payload }: PayloadAction<{ id: EntityId }>
    ) => {
      containersAdapter.saveFinish(state, payload);
    },
    containerSaveFailed: (
      state,
      { payload }: PayloadAction<{ id: EntityId; error: string }>
    ) => {
      containersAdapter.saveFail(state, payload);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(recipeFetchRequested, () => initialState);
    builder.addCase(recipeFetchFinished, (state, { payload: recipe }) =>
      containersAdapter.loadAll(state, {
        entities: recipe.containers || [],
      })
    );
    builder.addCase(recipeFetchFailed, () => initialState);
    builder.addCase(logoutSuccess, () => initialState);
  },
});

export const {
  reducer: containersReducer,
  actions: {
    containerAdded,
    containerUpdated,
    containerUpdatedWithNewId,
    containerRemoved,
    containerSaveRequested,
    containerRemoveRequested,
    containerSaveFinished,
    containerSaveFailed,
  },
} = containersSlice;

export const {
  selectAll: selectContainersAll,
  selectById: selectContainerById,
  selectIsSavingById: selectContainerIsSavingById,
  selectIsSavedById: selectContainerIsSavedById,
  selectSaveErrorById: selectContainerSaveErrorById,
} = containersAdapter.getSelectors<RootState>(
  (state) => state.recipe.containers
);

export const containerAddRequested = createAction<ContainerRequestPayload>(
  'recipe/containersSlice/containerAddRequested'
);

export const containerEditRequested = createAction<ContainerRequestPayload>(
  'recipe/containersSlice/containerEditRequested'
);
