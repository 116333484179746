import {
  Box,
  TextField,
  Button,
  Paper,
  Grid,
  makeStyles,
  Typography,
} from '@material-ui/core';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { selectIsRecipeCreating } from 'features/recipe/edit/editSlice';
import { recipePostRequested } from 'features/recipe/recipeSlice/recipeActions';

import RecipeIllustration from './pulling-details-loading.gif';

const useStyles = makeStyles((theme) => ({
  marginTop: {
    marginTop: theme.spacing(1),
  },
  headerMargins: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(2),
  },
}));

export const CreateFromText: React.FC = React.memo(function CreateFromText() {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [name, setName] = useState<string | undefined>();
  const [introduction, setIntroduction] = useState<string | undefined>();
  const [ingredients, setIngredients] = useState<string | undefined>();
  const [directions, setDirections] = useState<string | undefined>();
  const posting = useSelector(selectIsRecipeCreating);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (!name) {
      return;
    }
    dispatch(
      recipePostRequested({
        name,
        introduction,
        sourceText: {
          ingredients,
          directions,
        },
      })
    );
  };

  return (
    <Box p={3}>
      <Paper>
        <Box p={2} mt={1}>
          <form autoComplete="off" onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              {posting ? (
                <Grid
                  direction="column"
                  justify="center"
                  alignItems="center"
                  container
                >
                  <img
                    src={RecipeIllustration}
                    alt="loading..."
                    className={classes.marginTop}
                    width="240px"
                    height="156px"
                  />
                  <Typography variant="h6" className={classes.headerMargins}>
                    Creating recipe from text
                  </Typography>
                </Grid>
              ) : (
                <>
                  <Grid item xs={12}>
                    <TextField
                      autoFocus
                      variant="outlined"
                      fullWidth
                      value={name || ''}
                      onChange={(event) => {
                        setName(event?.target.value);
                      }}
                      label="Recipe Title"
                      id="Recipe Title"
                      required
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      variant="outlined"
                      value={introduction || ''}
                      onChange={(event) => {
                        setIntroduction(event?.target.value);
                      }}
                      multiline
                      fullWidth
                      label="Description"
                      id="Description"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      multiline
                      variant="outlined"
                      value={ingredients || ''}
                      onChange={(event) => {
                        setIngredients(event?.target.value);
                      }}
                      fullWidth
                      label="Ingredients"
                      id="Ingredients"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      multiline
                      variant="outlined"
                      value={directions || ''}
                      onChange={(event) => {
                        setDirections(event?.target.value);
                      }}
                      fullWidth
                      label="Directions"
                      id="Directions"
                    />
                  </Grid>
                  <Grid container justify="flex-end">
                    <Button variant="text" color="primary" type="submit">
                      Create Recipe
                    </Button>
                  </Grid>
                </>
              )}
            </Grid>
          </form>
        </Box>
      </Paper>
    </Box>
  );
});
