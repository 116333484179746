import type { FrescoEntity } from './entity';

export enum ErrorObjectTypes {
  Recipe = 'recipe',
  Tag = 'tag',
  Step = 'step',
  Container = 'container',
  IngredientGroup = 'ingredientgroup',
  IngredientGroupIngredient = 'ingredientgroupingredient',
  Appliance = 'appliance',
  Attachment = 'attachment',
}

interface ErrorObject extends FrescoEntity {
  type: ErrorObjectTypes;
  name?: string;
}

export enum ErrorSeverity {
  High = 'high',
  Low = 'low',
  Medium = 'medium',
  Fail = 'fail',
}

interface TotalErrors {
  [ErrorSeverity.Fail]: number;
  [ErrorSeverity.High]: number;
  [ErrorSeverity.Medium]: number;
  [ErrorSeverity.Low]: number;
}

export const severityOrderMap: Record<ErrorSeverity, number> = {
  [ErrorSeverity.Fail]: 1,
  [ErrorSeverity.High]: 2,
  [ErrorSeverity.Medium]: 3,
  [ErrorSeverity.Low]: 4,
};

export interface Error {
  code: string;
  severity: ErrorSeverity;
  message: string;
  description: string;
  location?: string;
  value: Record<
    string,
    null | string | number | [] | Record<string, null | string | number | []>
  >;
}
export interface ApiLintReportResponse {
  object: ErrorObject;
  report:
    | Record<string, { errors: Error[]; object: ErrorObject }[]>
    | Record<string, never>;
  score: number;
  total: TotalErrors | Record<string, never>;
  version: string;
}

export interface FormattedLintReport {
  object: ErrorObject;
  report: Error[];
  score: number;
  total: TotalErrors | Record<string, never>;
}
