import { makeStyles, Grid, Typography } from '@material-ui/core';
import type { FC } from 'react';
import { memo } from 'react';

import { FrescoIcon } from 'shared/components/FrescoIcon';
import type { FrescoMedia } from 'shared/types/media';

const useStyles = makeStyles((theme) => ({
  ingredientIconCircle: {
    backgroundColor: 'white',
    paddingRight: theme.spacing(1),
  },
  stepEntityBackground: {
    backgroundColor: '#ffffff',
    padding: theme.spacing(1),
  },
  bold: {
    fontSize: 16,
    fontWeight: 'bold',
  },
  quantity: {
    fontSize: 14,
  },
}));

interface StepIngredientProps {
  ingredientName: string;
  ingredientImage: FrescoMedia | null;
  ingredientColor?: string;
  quantity?: string;
}

export const StepIngredient: FC<StepIngredientProps> = memo(
  function StepIngredients({
    ingredientName,
    ingredientImage,
    ingredientColor,
    quantity,
  }) {
    const classes = useStyles();

    if (!ingredientName) {
      return null;
    }

    return (
      <Grid
        className={classes.stepEntityBackground}
        container
        alignItems="center"
        direction="row"
      >
        <Grid className={classes.ingredientIconCircle} item>
          <FrescoIcon
            image={ingredientImage}
            name={ingredientName}
            color={ingredientColor}
          />
        </Grid>
        <Grid item>
          <Typography className={classes.bold} variant="body2">
            {ingredientName}
          </Typography>
          <Typography className={classes.quantity} variant="body2">
            {quantity}
          </Typography>
        </Grid>
      </Grid>
    );
  }
);
