import { makeStyles, TextField } from '@material-ui/core';
import classNames from 'classnames';
import type { FC } from 'react';
import { memo, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import {} from 'features/jobs/jobSlice';
import { selectCurrentLocale } from 'features/translation/localeSwitcher/localeSlice';
import {
  selectRecipeNameTranslationsByLocaleAndType,
  recipeDetailsTranslationEditRequested,
  selectRecipeTranslationSuggestion,
  TranslationSuggestionType,
} from 'features/translation/recipe/recipeSlice';
import { noTranslationSentence } from 'shared/constants';
import { RecipeDetailsTranslationType } from 'shared/types/i18n';
import { getIsTranslatableLocale } from 'shared/utils/translation';

export const useStyles = makeStyles((theme) => ({
  inputPadding: {
    marginTop: theme.spacing(1.5),
    marginBottom: theme.spacing(1),
  },
  suggested: {
    backgroundColor: '#bae3c8',
  },
}));

const fieldTypeToSuggestionType = (
  type: RecipeDetailsTranslationType
): TranslationSuggestionType => {
  switch (type) {
    case RecipeDetailsTranslationType.Name:
      return TranslationSuggestionType.Name;
    case RecipeDetailsTranslationType.Introduction:
      return TranslationSuggestionType.Introduction;
    case RecipeDetailsTranslationType.CompletionCaption:
      return TranslationSuggestionType.CompletionCaption;
    default:
      return type;
  }
};

interface RecipeDetailProps {
  type: RecipeDetailsTranslationType;
  header: string;
  maxRows?: number;
}

export const RecipeDetail: FC<RecipeDetailProps> = memo(function RecipeDetail({
  type,
  header,
  maxRows,
}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const locale = useSelector(selectCurrentLocale);

  const translationLocale = useSelector(
    selectRecipeNameTranslationsByLocaleAndType(locale, type)
  );

  const translatedSentence = useSelector(
    selectRecipeTranslationSuggestion(locale, fieldTypeToSuggestionType(type))
  );
  const [localeSentence, setLocaleSentence] = useState(translationLocale);

  useEffect(() => {
    if (translationLocale !== localeSentence) {
      dispatch(
        recipeDetailsTranslationEditRequested({
          localeSentence,
          locale,
          type,
        })
      );
    }
  }, [dispatch, localeSentence, translationLocale, locale, type]);

  useEffect(() => {
    if (translationLocale) {
      setLocaleSentence(translationLocale);
    }
  }, [translationLocale]);

  useEffect(() => {
    if (translatedSentence) {
      setLocaleSentence(translatedSentence);
    }
  }, [translatedSentence]);

  const noTranslation = `${noTranslationSentence} ${locale}`;

  if (!getIsTranslatableLocale(locale)) {
    return null;
  }
  return (
    <TextField
      multiline={!!maxRows}
      rows={maxRows}
      variant="outlined"
      className={classNames(classes.inputPadding, {
        [classes.suggested]: !!translatedSentence,
      })}
      fullWidth
      value={localeSentence || ''}
      onChange={(event) => {
        setLocaleSentence(event.target.value);
      }}
      placeholder={noTranslation}
      id={`${locale} ${header}`}
      label={`${locale} ${header}`}
      InputLabelProps={{ shrink: true }}
    />
  );
});
