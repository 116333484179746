import { FrescoMediaClass } from 'shared/types/media';

interface ImageSize {
  height: number;
  width: number;
}

export const getImageSize = (file: Blob): Promise<ImageSize> =>
  new Promise((res) => {
    const img = new Image();
    img.onload = () => {
      const { height, width } = img;
      URL.revokeObjectURL(img.src);
      res({
        height,
        width,
      });
    };
    img.src = URL.createObjectURL(file);
  });

export const isImageBelowMinimum = (
  size: ImageSize,
  apiImageSizeType: FrescoMediaClass
): boolean => {
  switch (apiImageSizeType) {
    case FrescoMediaClass.RecipeImage:
      return size.width < 640 || size.height < 360;
    case FrescoMediaClass.RecipeImages:
      return size.width < 640 || size.height < 360;
    case FrescoMediaClass.StepTips:
      return size.width < 864 || size.height < 864;
    default:
      return false;
  }
};

export const isImageBelowRecommended = (
  size: ImageSize,
  apiImageSizeType: FrescoMediaClass
): boolean =>
  apiImageSizeType === FrescoMediaClass.RecipeImage &&
  (size.width < 1920 || size.height < 1080);
