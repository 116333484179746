import { Box, makeStyles } from '@material-ui/core';
import React from 'react';
import { useSelector } from 'react-redux';

import { AuthorForm } from 'features/recipe/author/AuthorForm';
import { ContainerForm } from 'features/recipe/containers/ContainerForm';
import {
  selectRecipeEditEntityType,
  RecipeEditEntityType,
  selectRecipeSplitEntityType,
  RecipeSplitEntityType,
} from 'features/recipe/edit/editSlice';
import { IngredientForm } from 'features/recipe/ingredients/IngredientForm';
import { IngredientSplitForm } from 'features/recipe/ingredients/IngredientSplitForm';
import { StepForm } from 'features/recipe/steps/StepForm';

const useStyles = makeStyles((theme) => ({
  topForm: {
    zIndex: theme.zIndex.appBar + 1,
    position: 'absolute',
    backgroundColor: theme.palette.background.paper,
    width: '100%',
    height: '100%',
  },
}));

export const EditSidebar: React.FC = React.memo(function EditSidebar() {
  const classes = useStyles();

  const editEntityType = useSelector(selectRecipeEditEntityType);
  const splitEntityType = useSelector(selectRecipeSplitEntityType);

  return (
    <>
      {editEntityType === RecipeEditEntityType.Container && <ContainerForm />}
      {editEntityType === RecipeEditEntityType.Ingredient && <IngredientForm />}
      {editEntityType === RecipeEditEntityType.Step && <StepForm />}
      {editEntityType === RecipeEditEntityType.Author && <AuthorForm />}
      {splitEntityType === RecipeSplitEntityType.Ingredient && (
        <Box className={classes.topForm}>
          <IngredientSplitForm />
        </Box>
      )}
    </>
  );
});
