import { DeleteIcon, EditIcon, MoreMenuIcon } from '@dropkitchen/icons-react';
import {
  Paper,
  Box,
  Typography,
  Table,
  TableHead,
  TableRow,
  TableBody,
  Grid,
  Button,
  TableCell,
  makeStyles,
  useTheme,
  IconButton,
  CircularProgress,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Tooltip,
} from '@material-ui/core';
import type { EntityId } from '@reduxjs/toolkit';
import classNames from 'classnames';
import partition from 'lodash/partition';
import React, { useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { selectRecipeFetched } from 'features/recipe/api/apiSlice';
import {
  selectContainersAll,
  selectContainerById,
  selectContainerIsSavingById,
  containerRemoveRequested,
} from 'features/recipe/containers/containersSlice';
import {
  recipeCreateEntityRequested,
  RecipeEditEntityType,
  recipeEditEntityRequested,
  selectRecipeEditMode,
  selectRecipeEditEntityType,
  RecipeEditMode,
  selectRecipeEditEntityId,
  selectIsSideBarOpen,
} from 'features/recipe/edit/editSlice';
import {
  selectDoesContainerExistInStep,
  selectStepsAll,
} from 'features/recipe/steps/stepsSlice';
import { FrescoIcon } from 'shared/components/FrescoIcon';
import { TableSkeleton } from 'shared/components/Skeletons';
import type { FrescoRecipeContainer } from 'shared/types/recipe';

import { ReactComponent as ContainersIllustraion } from './containers-empty-state.svg';

const useStyles = makeStyles((theme) => ({
  cell: {
    padding: theme.spacing(1),
  },
  icon: {
    height: theme.spacing(4),
    width: theme.spacing(4),
  },
  iconCell: {
    width: theme.spacing(5),
  },
  editCell: {
    width: theme.spacing(3),
  },
  headerContainer: {
    display: 'flex',
  },
  headerText: {
    flexGrow: 1,
  },
  menuItem: {
    display: 'flex',
  },
  menuIcon: {
    justifyContent: 'flex-end',
  },
  marginTop: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  addButton: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
  active: {
    border: `2px solid ${theme.palette.primary.main}`,
  },
  tableHeader: {
    ...theme.typography.overline,
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(2),
  },
  tableMargin: {
    marginBottom: theme.spacing(2),
  },
}));

export const Containers: React.FC = React.memo(function Containers() {
  const classes = useStyles();
  const theme = useTheme();
  const recipeContainers = useSelector(selectContainersAll);
  const steps = useSelector(selectStepsAll);
  const isFetched = useSelector(selectRecipeFetched);
  const isCreating = useSelector(selectRecipeEditMode);

  const editEntityType = useSelector(selectRecipeEditEntityType);
  const showCreatingText =
    isCreating === RecipeEditMode.Create &&
    editEntityType === RecipeEditEntityType.Container;

  const dispatch = useDispatch();
  const handleAddClick = () => {
    dispatch(
      recipeCreateEntityRequested({
        editEntityType: RecipeEditEntityType.Container,
      })
    );
  };

  const [usedContainers, unUsedContainers] = useMemo(
    () =>
      partition(recipeContainers, (container) =>
        steps.some(
          (step) =>
            step?.toContainer?.uri && container.uri === step.toContainer.uri
        )
      ),
    [recipeContainers, steps]
  );

  return (
    <Paper>
      <Box p={2}>
        <div className={classes.headerContainer}>
          <Typography variant="h6" className={classes.headerText}>
            Containers
          </Typography>
        </div>
        {isFetched && !recipeContainers?.length && (
          <Grid
            direction="column"
            justify="center"
            alignItems="center"
            container
          >
            <ContainersIllustraion className={classes.marginTop} />
            {showCreatingText ? (
              <Typography variant="h6" className={classes.marginTop}>
                Once added, containers will show here
              </Typography>
            ) : (
              <>
                <Typography variant="h6" className={classes.marginTop}>
                  Begin by adding a container
                </Typography>
                <Button
                  className={classes.addButton}
                  color="primary"
                  variant="contained"
                  onClick={handleAddClick}
                >
                  Add Container
                </Button>
              </>
            )}
          </Grid>
        )}
        {!isFetched && (
          <TableSkeleton
            columnsCount={4}
            rowsCount={3}
            cellClassName={classes.cell}
            skeletonItemHeight={theme.spacing(5)}
          />
        )}
        {!!usedContainers?.length && (
          <ContainerTable
            containers={usedContainers}
            header="Assigned to recipe steps"
          />
        )}
        {!!unUsedContainers?.length && (
          <ContainerTable containers={unUsedContainers} header="Unassigned" />
        )}
        {!!recipeContainers?.length && (
          <Button
            className={classes.addButton}
            variant="text"
            aria-label="Add container"
            color="primary"
            onClick={handleAddClick}
          >
            Add Container
          </Button>
        )}
      </Box>
    </Paper>
  );
});

interface ContainerTableProps {
  containers: FrescoRecipeContainer[];
  header: string;
}

const ContainerTable: React.FC<ContainerTableProps> = React.memo(
  function ContainerTable({ containers, header }) {
    const classes = useStyles();

    if (!containers) {
      return null;
    }
    return (
      <>
        <Typography className={classes.tableHeader}>{header}</Typography>
        <Table className={classes.tableMargin}>
          <TableHead>
            <TableRow>
              <TableCell
                align="left"
                className={classNames(classes.cell, classes.iconCell)}
              >
                <Typography variant="caption">Icon</Typography>
              </TableCell>
              <TableCell align="left" className={classes.cell}>
                <Typography variant="caption">Container name</Typography>
              </TableCell>
              <TableCell align="left" className={classes.cell}>
                <Typography variant="caption">Quantity</Typography>
              </TableCell>
              <TableCell
                align="left"
                className={classNames(classes.cell, classes.editCell)}
              />
            </TableRow>
          </TableHead>
          {!!containers?.length && (
            <TableBody>
              {containers.map((recipeContainer) => (
                <Row
                  key={recipeContainer.id}
                  recipeContainerId={recipeContainer.id}
                />
              ))}
            </TableBody>
          )}
        </Table>
      </>
    );
  }
);
interface RowProps {
  recipeContainerId: EntityId;
}

const Row: React.FC<RowProps> = React.memo(function Row({ recipeContainerId }) {
  const classes = useStyles();
  const theme = useTheme();
  const dispatch = useDispatch();
  const editMode = useSelector(selectRecipeEditMode);
  const editingEntityId = useSelector(selectRecipeEditEntityId);
  const isSideBarOpen = useSelector(selectIsSideBarOpen);

  const isEditing = editMode === RecipeEditMode.Edit;

  const recipeContainer = useSelector(selectContainerById(recipeContainerId));
  const isSaving = useSelector(selectContainerIsSavingById(recipeContainerId));
  const isContainerUsedInStep = useSelector(
    selectDoesContainerExistInStep(recipeContainer?.uri)
  );

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const handleOpenEditDelete = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseEditDelete = () => {
    setAnchorEl(null);
  };

  const handleEditClick = () => {
    dispatch(
      recipeEditEntityRequested({
        editEntityType: RecipeEditEntityType.Container,
        editEntityId: recipeContainerId,
      })
    );
    setAnchorEl(null);
  };

  const handleDeleteClick = () => {
    dispatch(containerRemoveRequested({ id: recipeContainerId }));
    setAnchorEl(null);
  };

  if (!recipeContainer) {
    return null;
  }

  const isBeingEdited = isEditing && editingEntityId === recipeContainerId;

  return (
    <TableRow
      className={classNames({
        [classes.active]: isBeingEdited,
      })}
      key={recipeContainerId}
    >
      <TableCell align="left" className={classes.cell}>
        <FrescoIcon
          image={recipeContainer.container.image}
          name={recipeContainer.container.name}
        />
      </TableCell>
      <TableCell align="left" className={classes.cell}>
        {recipeContainer.container.name}
      </TableCell>
      <TableCell align="left" className={classes.cell}>
        {recipeContainer.quantity}
      </TableCell>
      <TableCell align="left" className={classes.cell}>
        {!isSaving ? (
          <>
            <Tooltip
              aria-label="Container Warning"
              disableHoverListener={!isContainerUsedInStep}
              title="You cannot edit or delete a container that is currently used in a step"
            >
              <span>
                <IconButton
                  aria-label="Container Menu"
                  size="small"
                  color="inherit"
                  edge="start"
                  disabled={isContainerUsedInStep || isSideBarOpen}
                  onClick={handleOpenEditDelete}
                >
                  <MoreMenuIcon />
                </IconButton>
              </span>
            </Tooltip>
            <Menu
              disableEnforceFocus
              disableRestoreFocus
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleCloseEditDelete}
            >
              <MenuItem className={classes.menuItem} onClick={handleEditClick}>
                <ListItemText primary="Edit Container" />
                <ListItemIcon className={classes.menuIcon}>
                  <EditIcon />
                </ListItemIcon>
              </MenuItem>
              <MenuItem
                className={classes.menuItem}
                onClick={handleDeleteClick}
              >
                <ListItemText primary="Delete Container" />
                <ListItemIcon className={classes.menuIcon}>
                  <DeleteIcon />
                </ListItemIcon>
              </MenuItem>
            </Menu>
          </>
        ) : (
          <CircularProgress
            aria-label="Saving indicator"
            size={theme.spacing(4)}
            style={{ verticalAlign: 'middle' }}
          />
        )}
      </TableCell>
    </TableRow>
  );
});
