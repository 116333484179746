import { fetchJson } from 'api/fetchJson';
import type { ApiRequestFn } from 'api/types';
import { ApiResponseRequestVariant, HttpMethod } from 'api/types';
import { appConfig } from 'shared/config';
import type { FrescoFeaturesGetResponse } from 'shared/types/feature';

export interface GetFeaturesRequest {
  page: number;
  pageSize: number;
}

export const apiGetFeatures =
  ({
    page,
    pageSize,
  }: GetFeaturesRequest): ApiRequestFn<FrescoFeaturesGetResponse> =>
  (apiContext) => {
    const params = new URLSearchParams({
      page: page.toString(),
      // eslint-disable-next-line @typescript-eslint/naming-convention
      per_page: pageSize.toString(),
      // eslint-disable-next-line @typescript-eslint/naming-convention
      order_by: 'name',
    });

    return fetchJson<FrescoFeaturesGetResponse>({
      apiContext,
      responseVariant: ApiResponseRequestVariant.Web,
      httpMethod: HttpMethod.Get,
      url: `${appConfig.apiUrl()}/features?${params.toString()}`,
    });
  };
