import type { PayloadAction } from '@reduxjs/toolkit';
import { call, delay, put, takeLatest } from 'redux-saga/effects';

import { createRequestApiSaga } from 'api/createRequestApiSaga';
import { apiGetJobTranslation } from 'api/job';
import type { ApiResponse } from 'api/types';
import {
  jobFetchFailed,
  jobFetchFinished,
  jobFetchRequested,
} from 'features/jobs/jobSlice';
import type { ApiJob, GetJobRequest } from 'shared/types/job';
import { JobStatus } from 'shared/types/job';
import { getErrorString } from 'shared/utils/common';

export const delayJobFetchRetry = 3000;

export const apiGetJobSaga = createRequestApiSaga(
  apiGetJobTranslation,
  'Job loading'
);

export function* requestJob({
  payload: { jobId },
}: PayloadAction<GetJobRequest>) {
  while (true) {
    try {
      const response: ApiResponse<ApiJob> = yield call(apiGetJobSaga, {
        jobId,
      });
      if (!response.ok) {
        yield put(jobFetchFailed(response.details.message));
        return;
      }
      if (response.data.status !== JobStatus.Finished) {
        yield delay(delayJobFetchRetry);
      } else {
        yield put(jobFetchFinished(response.data));
        return;
      }
    } catch (e) {
      yield put(jobFetchFailed(getErrorString(e)));
    }
  }
}

function* requestJobFetchWatcher() {
  yield takeLatest(jobFetchRequested, requestJob);
}

export const jobSagas = [requestJobFetchWatcher];
