import { Grid, MenuItem, TextField } from '@material-ui/core';
import type { ActionCreatorWithPayload } from '@reduxjs/toolkit';
import type { ChangeEvent, FC } from 'react';
import { memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import type { RootState } from 'app/rootReducer';
import { TranslationStatus } from 'shared/types/i18n';

export const noLocaleErrorText = 'Please, select a language first';

export interface TranslationStatusFilterProps {
  filterSetStatusAction: ActionCreatorWithPayload<TranslationStatus, string>;
  selectFilterTranslationStatus: (
    state: RootState
  ) => TranslationStatus | undefined;
}

export const TranslationStatusFilter: FC<TranslationStatusFilterProps> = memo(
  function TranslationStatusFilter({
    filterSetStatusAction,
    selectFilterTranslationStatus,
  }) {
    const dispatch = useDispatch();

    const status = useSelector(selectFilterTranslationStatus);

    const handleTranslatedStatusChange = (
      event: ChangeEvent<HTMLInputElement>
    ) => {
      dispatch(filterSetStatusAction(event.target.value as TranslationStatus));
    };

    return (
      <Grid item xs={12} sm={10}>
        <TextField
          id="status"
          inputProps={{ 'data-testid': 'translation-status' }}
          label="Status"
          onChange={handleTranslatedStatusChange}
          select
          variant="outlined"
          value={status}
          style={{ width: '200px' }}
        >
          <MenuItem value={TranslationStatus.All}>All</MenuItem>
          <MenuItem value={TranslationStatus.Untranslated}>
            Untranslated
          </MenuItem>
          <MenuItem value={TranslationStatus.Translated}>Translated</MenuItem>
        </TextField>
      </Grid>
    );
  }
);
