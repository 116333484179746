import { OrganisationName } from 'shared/constants';
import type { ApiLocale } from 'shared/types/i18n';

export enum AppEnvironment {
  Development = 'development',
  Production = 'production',
  Staging = 'staging',
  Test = 'test',
  Partners = 'partners',
}

const suggestionsForbiddenLocales =
  (process.env.REACT_APP_SUGGESTIONS_FORBIDDEN_LOCALES?.split(
    ','
  ) as ApiLocale[]) ?? [];

const suggestionsAllowedLocales =
  (process.env.REACT_APP_SUGGESTIONS_ALLOWED_LOCALES?.split(
    ','
  ) as ApiLocale[]) ?? [];

const envVarsGetters = {
  /*  eslint-disable @typescript-eslint/no-non-null-assertion */
  appEnv: (): string => process.env.REACT_APP_ENV! as AppEnvironment,
  appBackstage: (): boolean => !!process.env.REACT_APP_BACKSTAGE || false,
  v3Url: (): string => process.env.REACT_APP_REAPER_API_URL!,
  platformUrl: (): string => process.env.REACT_APP_PLATFORM_API_URL!,
  authorEmailPrefix: (): string => process.env.REACT_APP_AUTHOR_EMAIL_PREFIX!,
  authorEmailSuffix: (): string => process.env.REACT_APP_AUTHOR_EMAIL_SUFFIX!,
  authorPassword: (): string => process.env.REACT_APP_AUTHOR_PASSWORD!,
  isTestEnv: (): boolean => process.env.NODE_ENV === AppEnvironment.Test,
  suggestionsForbiddenLocales: (): ApiLocale[] => suggestionsForbiddenLocales,
  suggestionsAllowedLocales: (): ApiLocale[] => suggestionsAllowedLocales,
  /* eslint-enable @typescript-eslint/no-non-null-assertion */
};

const dropApiPrefix = (): string => '/api/v1';

const apiV3Prefix = (): string =>
  envVarsGetters.appEnv() === AppEnvironment.Development
    ? '/api/v4'
    : `${envVarsGetters.v3Url()}/api/v4`;

const dropApi = (): string =>
  envVarsGetters.appEnv() === AppEnvironment.Development ||
  process.env.NODE_ENV === AppEnvironment.Test
    ? ''
    : envVarsGetters.platformUrl();

const apiUrl = (): string => `${dropApi()}${dropApiPrefix()}`;
const apiCollectionsUrl = (): string =>
  `${dropApi()}${dropApiPrefix()}/collections`;
const apiRecipesUrl = (): string => `${dropApi()}${dropApiPrefix()}/recipes`;
const apiV3PrefixRecipes = (): string => `${apiV3Prefix()}/recipes`;

const organizationName = (): OrganisationName =>
  new RegExp(`${Object.values(OrganisationName).join('|')}`).exec(
    window.location.hostname
  )?.[0] as OrganisationName;

const isProduction = (): boolean =>
  envVarsGetters.appEnv() === AppEnvironment.Production;
const enviromentUrl =
  envVarsGetters.appEnv() === AppEnvironment.Development
    ? '-staging'
    : `-${envVarsGetters.appEnv()}`;

export const applicationName = (): string =>
  envVarsGetters.appBackstage() ? 'Backstage' : 'Creator 2.0';

export const adminBaseUrl = (): string =>
  isProduction()
    ? 'https://admin.getdrop.io'
    : `https://admin${enviromentUrl}.getdrop.io`;

export const babelBaseUrl = (): string =>
  isProduction()
    ? 'https://babel.getdrop.io'
    : `https://babel${enviromentUrl}.getdrop.io`;

export const machinaBaseUrl = (): string =>
  isProduction()
    ? 'https://machina.kitchenos.com/'
    : `https://machina${enviromentUrl}.getdrop.io`;

export const accountBaseUrl = (): string =>
  isProduction()
    ? `https://${organizationName()}.account.frescocooks.com`
    : `https://${organizationName()}.account${enviromentUrl}.getdrop.io`;

export const getCurrentResetPasswordUri = () =>
  `${accountBaseUrl()}/recover-password`;

export const appConfig = {
  ...envVarsGetters,
  organizationName,
  applicationName,
  dropApiPrefix,
  apiV3Prefix,
  dropApi,
  apiUrl,
  apiCollectionsUrl,
  apiRecipesUrl,
  apiV3PrefixRecipes,
  adminBaseUrl,
  babelBaseUrl,
  machinaBaseUrl,
  getCurrentResetPasswordUri,
  isProduction,
  origin,
};
