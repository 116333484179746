import { Fab, TextField, Grid, makeStyles } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import SaveIcon from '@material-ui/icons/Save';
import { Autocomplete } from '@material-ui/lab';
import type { FC } from 'react';
import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import {
  selectContainerById,
  containerAddRequested,
  containerEditRequested,
} from 'features/recipe/containers/containersSlice';
import { EditSidebarActions } from 'features/recipe/edit/EditSidebarActions';
import { EditSidebarContent } from 'features/recipe/edit/EditSidebarContent';
import { EditSidebarForm } from 'features/recipe/edit/EditSidebarForm';
import { EditSidebarTitle } from 'features/recipe/edit/EditSidebarTitle';
import {
  selectRecipeEditMode,
  RecipeEditMode,
  selectRecipeEditEntityId,
  recipeEditEntityFinished,
} from 'features/recipe/edit/editSlice';
import {
  containerTermsFetchRequested,
  selectTermsContainersEntities,
  selectTermsContainersApiPending,
} from 'features/terms/containersSlice';
import type { FrescoContainer } from 'shared/types/container';

const useStyles = makeStyles((theme) => ({
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
}));

export const ContainerForm: FC = () => {
  const classes = useStyles();
  const containersTerms = useSelector(selectTermsContainersEntities);
  const containersTermsPending = useSelector(selectTermsContainersApiPending);
  const isEditMode = useSelector(selectRecipeEditMode) === RecipeEditMode.Edit;
  const editingEnityId = useSelector(selectRecipeEditEntityId);
  const editEntity = useSelector(selectContainerById(editingEnityId ?? ''));

  const dispatch = useDispatch();

  const [container, setContainer] = useState<FrescoContainer | null>(
    editEntity?.container ?? null
  );
  const [quantity, setQuantity] = useState<number | null>(
    editEntity?.quantity ?? 1
  );

  useEffect(() => {
    if (!containersTerms.length && !containersTermsPending) {
      dispatch(containerTermsFetchRequested());
    }
  }, [dispatch, containersTermsPending, containersTerms]);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (!container?.uri || !quantity) {
      return;
    }

    if (isEditMode) {
      dispatch(
        containerEditRequested({
          ...editEntity,
          container,
          quantity,
        })
      );
    } else {
      dispatch(
        containerAddRequested({
          container,
          quantity,
        })
      );
    }
  };

  return (
    <EditSidebarForm aria-label="Container form" onSubmit={handleSubmit}>
      <EditSidebarTitle
        text={`${isEditMode ? 'Edit' : 'Add'} Container`}
        onClosePayloadAction={recipeEditEntityFinished()}
      />
      <EditSidebarContent>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Autocomplete
              id="containerName"
              options={containersTerms}
              getOptionLabel={(option) => option.name}
              getOptionSelected={(option, value) => option.uri === value.uri}
              value={container}
              onChange={(_e, value) => {
                setContainer(value);
              }}
              renderInput={(params) => (
                <TextField
                  autoFocus
                  variant="outlined"
                  {...params}
                  required
                  label="Container name"
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Quantity"
              id="quantity"
              type="number"
              variant="outlined"
              required
              value={quantity || ''}
              InputProps={{ inputProps: { min: 1 } }}
              onChange={(e) => {
                setQuantity(parseInt(e.target.value) || null);
              }}
            />
          </Grid>
        </Grid>
      </EditSidebarContent>
      <EditSidebarActions>
        <Fab type="submit" color="primary" variant="extended">
          {isEditMode ? (
            <SaveIcon className={classes.extendedIcon} />
          ) : (
            <AddIcon className={classes.extendedIcon} />
          )}
          {isEditMode ? 'Save' : 'Add'}
        </Fab>
      </EditSidebarActions>
    </EditSidebarForm>
  );
};
