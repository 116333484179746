import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice, createAction } from '@reduxjs/toolkit';
import { put, call, takeEvery } from 'redux-saga/effects';

import { createRequestApiSaga } from 'api/createRequestApiSaga';
import { apiYieldTermsGet } from 'api/terms';
import type { ApiResponse, FrescoPaginatedResponse } from 'api/types';
import type { RootState } from 'app/rootReducer';
import { logoutSuccess } from 'features/login/loginSlice';
import type { FrescoYield } from 'shared/types/recipe';
import { getErrorString } from 'shared/utils/common';

interface TermsYieldsState {
  apiError?: string;
  apiPending: boolean;
  entities: FrescoYield[];
}

export const initialState: TermsYieldsState = {
  apiPending: false,
  entities: [],
};

const termsYieldsSlice = createSlice({
  name: 'termsYieldsSlice',
  initialState,
  reducers: {
    termsYieldsSuccess(state, { payload }: PayloadAction<FrescoYield[]>) {
      state.entities = payload;
      state.apiPending = false;
      state.apiError = undefined;
    },
    termsYieldsApiPending(state) {
      state.apiPending = true;
      state.apiError = undefined;
    },
    termsYieldsApiError(state, { payload }: PayloadAction<string>) {
      state.apiPending = false;
      state.apiError = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(logoutSuccess, () => initialState);
  },
});

export const {
  reducer: termsYieldsReducer,
  actions: { termsYieldsSuccess, termsYieldsApiPending, termsYieldsApiError },
} = termsYieldsSlice;

const selectTermsYieldsState = (state: RootState): TermsYieldsState =>
  state.terms.yields;

export const selectTermsYieldsEntities = (state: RootState): FrescoYield[] =>
  selectTermsYieldsState(state).entities;

export const selectTermsYieldsApiPending = (state: RootState): boolean =>
  selectTermsYieldsState(state).apiPending;

export const selectTermsYieldsApiError = (
  state: RootState
): string | undefined => selectTermsYieldsState(state).apiError;

export const apiGetYieldTermsSaga = createRequestApiSaga(
  apiYieldTermsGet,
  'Yields loading'
);

export function* requestYieldTerms() {
  try {
    yield put(termsYieldsApiPending());
    const response: ApiResponse<FrescoPaginatedResponse<FrescoYield>> =
      yield call(apiGetYieldTermsSaga);
    if (!response.ok) {
      yield put(termsYieldsApiError(response.details.message));
      return;
    }
    yield put(termsYieldsSuccess(response.data.results));
    return;
  } catch (e) {
    yield put(termsYieldsApiError(getErrorString(e)));
  }
}

export const yieldTermsFetchRequested = createAction(
  'terms/yieldTermsFetchRequested'
);

function* requestYieldTermsFetchWatcher() {
  yield takeEvery(yieldTermsFetchRequested, requestYieldTerms);
}

export const yieldTermsSagas = [requestYieldTermsFetchWatcher];
