import {
  Paper,
  Box,
  Typography,
  Table,
  TableHead,
  Grid,
  TableRow,
  TableBody,
  TableCell,
  makeStyles,
  useTheme,
} from '@material-ui/core';
import classNames from 'classnames';
import React from 'react';
import { useSelector } from 'react-redux';

import { selectRecipeFetched } from 'features/recipe/api/apiSlice';
import { selectRecipeAppliances } from 'features/recipe/steps/stepsSlice';
import { FrescoIcon } from 'shared/components/FrescoIcon';
import { TableSkeleton } from 'shared/components/Skeletons';

import { ReactComponent as AppliancesIllustraion } from './appliances-empty-state.svg';

const useStyles = makeStyles((theme) => ({
  cell: {
    padding: theme.spacing(1),
    paddingLeft: 0,
  },
  icon: {
    height: theme.spacing(4),
    width: theme.spacing(4),
  },
  iconCell: {
    width: theme.spacing(5),
  },
  marginTop: {
    marginTop: theme.spacing(1),
  },
}));

export const Appliances: React.FC = React.memo(function Appliances() {
  const classes = useStyles();
  const theme = useTheme();
  const appliances = useSelector(selectRecipeAppliances);
  const isFetched = useSelector(selectRecipeFetched);

  return (
    <Paper>
      <Box p={2}>
        <Typography variant="h6">Appliances</Typography>
        {isFetched && !appliances.length && (
          <Grid
            direction="column"
            justify="center"
            alignItems="center"
            container
          >
            <AppliancesIllustraion className={classes.marginTop} />
            <Typography variant="h6" className={classes.marginTop}>
              Appliances used in Direction steps will show here
            </Typography>
          </Grid>
        )}
        {!isFetched && (
          <TableSkeleton
            columnsCount={2}
            rowsCount={3}
            cellClassName={classes.cell}
            skeletonItemHeight={theme.spacing(5)}
          />
        )}
        {!!appliances.length && (
          <Table>
            <TableHead>
              <TableRow>
                <TableCell
                  align="left"
                  className={classNames(classes.cell, classes.iconCell)}
                >
                  <Typography variant="caption">Icon</Typography>
                </TableCell>
                <TableCell align="left" className={classes.cell}>
                  <Typography variant="caption">Appliance name</Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {appliances.map((appliance) => (
                <TableRow key={appliance.id}>
                  <TableCell align="left" className={classes.cell}>
                    <FrescoIcon image={appliance.image} name={appliance.name} />
                  </TableCell>
                  <TableCell align="left" className={classes.cell}>
                    {appliance.name}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )}
      </Box>
    </Paper>
  );
});
