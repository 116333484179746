import { fetchJson } from 'api/fetchJson';
import type { ApiRequestFn } from 'api/types';
import { ApiResponseRequestVariant, HttpMethod } from 'api/types';
import { appConfig } from 'shared/config';
import type {
  FrescoVideo,
  FrescoMedia,
  FrescoMediaClass,
} from 'shared/types/media';

const imagesApiUrl = `${appConfig.dropApi()}${appConfig.dropApiPrefix()}/images`;

export interface ApiMediaCreateRequest {
  file: Blob;
  sizes: FrescoMediaClass;
}

export interface ApiImageCreateResponse {
  imageUrl: string;
}

export const apiImageCreate =
  ({
    file,
    sizes,
  }: ApiMediaCreateRequest): ApiRequestFn<ApiImageCreateResponse> =>
  (apiContext) => {
    const body = new FormData();
    body.append('file', file);
    body.append('sizes', sizes);

    return fetchJson<ApiImageCreateResponse>({
      apiContext,
      httpMethod: HttpMethod.Post,
      responseVariant: ApiResponseRequestVariant.Web,
      url: imagesApiUrl,
      body,
      mergeDataFromHeadersMap: {
        imageUrl: 'Location',
      },
    });
  };

export interface ApiVideoCreateResponse {
  videoUrl: string;
}

export const apiVideoCreate =
  ({
    file,
    sizes,
  }: ApiMediaCreateRequest): ApiRequestFn<ApiVideoCreateResponse> =>
  (apiContext) => {
    const body = new FormData();
    body.append('file', file);
    body.append('sizes', sizes);

    return fetchJson<ApiVideoCreateResponse>({
      httpMethod: HttpMethod.Post,
      responseVariant: ApiResponseRequestVariant.Web,
      apiContext,
      url: `${appConfig.dropApi()}${appConfig.dropApiPrefix()}/videos`,
      body,
      mergeDataFromHeadersMap: {
        videoUrl: 'Location',
      },
    });
  };

interface ApiMediaGetRequest {
  uri: string;
}

export const apiImageGet =
  ({ uri }: ApiMediaGetRequest): ApiRequestFn<FrescoMedia> =>
  (apiContext) =>
    fetchJson<FrescoMedia>({
      apiContext,
      httpMethod: HttpMethod.Get,
      responseVariant: ApiResponseRequestVariant.Full,
      url: `${appConfig.dropApi()}${uri}`,
    });

export const apiVideoGet =
  ({ uri }: ApiMediaGetRequest): ApiRequestFn<FrescoVideo> =>
  (apiContext) =>
    fetchJson<FrescoVideo>({
      apiContext,
      httpMethod: HttpMethod.Get,
      responseVariant: ApiResponseRequestVariant.Web,
      url: `${appConfig.dropApi()}${uri}`,
    });
