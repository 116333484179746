import { Paper, Box, Grid, TextField, makeStyles } from '@material-ui/core';
import type { FC } from 'react';
import { memo } from 'react';
import { useSelector } from 'react-redux';

import { RecipeDetail } from 'features/translation/recipe/RecipeDetail';
import { selectRecipeNameTranslationsByLocaleAndType } from 'features/translation/recipe/recipeSlice';
import { ApiLocale, RecipeDetailsTranslationType } from 'shared/types/i18n';

export const useStyles = makeStyles((theme) => ({
  inputPadding: {
    marginTop: theme.spacing(1.5),
    marginBottom: theme.spacing(1),
  },
}));

export const RecipeDetails: FC = memo(function RecipeDetails() {
  const classes = useStyles();
  const nameTranslationEnUS = useSelector(
    selectRecipeNameTranslationsByLocaleAndType(
      ApiLocale.EnUS,
      RecipeDetailsTranslationType.Name
    )
  );

  const introductionTranslationEnUS = useSelector(
    selectRecipeNameTranslationsByLocaleAndType(
      ApiLocale.EnUS,
      RecipeDetailsTranslationType.Introduction
    )
  );

  const completionCaptionTranslationEnUS = useSelector(
    selectRecipeNameTranslationsByLocaleAndType(
      ApiLocale.EnUS,
      RecipeDetailsTranslationType.CompletionCaption
    )
  );

  return (
    <Paper>
      <Box p={2} mt={1}>
        <Grid direction="row" container spacing={2}>
          <Grid item xs={6}>
            <TextField
              disabled
              className={classes.inputPadding}
              variant="outlined"
              fullWidth
              value={nameTranslationEnUS || ''}
              id="en_US Name"
              label="en_US Name"
              InputLabelProps={{ shrink: true }}
            />
            <RecipeDetail
              type={RecipeDetailsTranslationType.Name}
              header="Name"
            />
            <TextField
              className={classes.inputPadding}
              disabled
              variant="outlined"
              fullWidth
              value={completionCaptionTranslationEnUS || ''}
              id="en_US Completion Caption"
              label="en_US Completion Caption"
              InputLabelProps={{ shrink: true }}
            />
            <RecipeDetail
              type={RecipeDetailsTranslationType.CompletionCaption}
              header="Completion Caption"
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              className={classes.inputPadding}
              multiline
              rows={5}
              disabled
              variant="outlined"
              fullWidth
              value={introductionTranslationEnUS || ''}
              id="en_US Introduction"
              label="en_US Introduction"
              InputLabelProps={{ shrink: true }}
            />
            <RecipeDetail
              type={RecipeDetailsTranslationType.Introduction}
              header="Introduction"
              maxRows={5}
            />
          </Grid>
        </Grid>
      </Box>
    </Paper>
  );
});
