import { TextField, makeStyles } from '@material-ui/core';
import classNames from 'classnames';
import produce from 'immer';
import type { FC } from 'react';
import { memo, useState, useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { selectCurrentLocale } from 'features/translation/localeSwitcher/localeSlice';
import {
  selectRecipeStepTipByIndex,
  recipeStepTipEditRequested,
  selectRecipeTranslationSuggestion,
  TranslationSuggestionType,
} from 'features/translation/recipe/recipeSlice';
import { StepTip as StepTipLayout } from 'shared/components/stepViewEntities/StepTip';
import { noTranslationSentence } from 'shared/constants';
import { ApiLocale } from 'shared/types/i18n';
import { isVirtualTip } from 'shared/utils/common';
import { getIsTranslatableLocale } from 'shared/utils/translation';

const useStyles = makeStyles(() => ({
  caption: {
    fontSize: 14,
  },
  suggested: {
    backgroundColor: '#bae3c8',
  },
}));

interface StepTipsProps {
  stepIndex: number;
  tipIndex: number;
}

export const StepTip: FC<StepTipsProps> = memo(function StepTip({
  stepIndex,
  tipIndex,
}) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const locale = useSelector(selectCurrentLocale);
  const tipSelector = useMemo(
    () => selectRecipeStepTipByIndex(stepIndex, tipIndex, locale),
    [stepIndex, tipIndex, locale]
  );
  const tip = useSelector(tipSelector);

  const [tipLocaleSentence, setTipLocaleSentence] = useState(
    tip?.captionTranslations[locale]
  );

  const translatedSentence = useSelector(
    selectRecipeTranslationSuggestion(
      locale,
      TranslationSuggestionType.Tip,
      tip?.id
    )
  );

  useEffect(() => {
    if (tip && tipLocaleSentence !== tip.captionTranslations[locale]) {
      const updatedTip = produce(tip, (draftState) => {
        draftState.captionTranslations[locale] = tipLocaleSentence;
      });

      dispatch(
        recipeStepTipEditRequested({
          tip: updatedTip,
          tipIndex,
          stepIndex,
          locale,
        })
      );
    }
  }, [dispatch, tipLocaleSentence, locale, tip, tipIndex, stepIndex]);

  useEffect(() => {
    if (translatedSentence) {
      setTipLocaleSentence(translatedSentence);
    }
  }, [translatedSentence]);

  if (!tip) {
    return null;
  }

  const isVirtual = isVirtualTip(tip.uri);

  return (
    <>
      <StepTipLayout
        caption={tip.captionTranslations?.[ApiLocale.EnUS] || ''}
        video={tip.video}
        image={tip.image}
        uri={tip.uri}
      />
      {getIsTranslatableLocale(locale) && !isVirtual && (
        <TextField
          inputProps={{
            className: classes.caption,
            'data-testid': `${tip.id}-${locale}`,
          }}
          variant="outlined"
          fullWidth
          placeholder={`${noTranslationSentence} ${locale}`}
          onChange={(event) => setTipLocaleSentence(event.target.value)}
          value={tipLocaleSentence || ''}
          InputLabelProps={{ shrink: true }}
          className={classNames({
            [classes.suggested]: !!translatedSentence,
          })}
        />
      )}
    </>
  );
});
