import type { SvgIconProps } from '@material-ui/core/SvgIcon';
import type { FunctionComponent } from 'react';

import FrescoLogo from 'logos/FrescoLogo';
import InstantBrandsLogo from 'logos/InstantBrandsLogo';
import KenwoodLogo from 'logos/KenwoodLogo';
import { appConfig } from 'shared/config';
import { AcceptLanguageLocale, ApiLocale } from 'shared/types/i18n';

export const acceptHeader = 'application/vnd.drop.v4';
export const defaultLocale = AcceptLanguageLocale.EnUS;

export const datadogLogsClientToken = 'pub1f1fa41d9d8aa645c6068dcb06a52e49';
export const datadogRumClientToken = 'pub31d42fafc455955d2f2615f8bd69a3f7';
export const datadogApplicationId = 'f271258a-6704-473a-93be-c064d9ed8a54';

export const noTranslationSentence = 'No translation exists in';

export const units = [
  'cup',
  'dash',
  'fluid ounce',
  'gram',
  'handful',
  'head',
  'kilogram',
  'knob',
  'liter',
  'milliliter',
  'ounce',
  'pinch',
  'pound',
  'slice',
  'sprig',
  'tablespoon',
  'teaspoon',
  'units',
  'wedge',
];

export const getDebounceInputs = () => (appConfig.isTestEnv() ? 0 : 500);

// Heights are meant to be used with theme.spacing() for more consistent look
export const primaryAppBarHeaderHeight = 8;
export const secondaryAppBarHeaderHeight = 6;
export const appActionsBarHeight = 16;
export const recipeFiltersHeight = 18;
export const entityFiltersHeight = 15;
export const sidebarWidth = 56;

export enum TabName {
  Details = 'details',
  Prep = 'prep',
  Directions = 'directions',
  Review = 'review',
}

export enum TabNameCreate {
  FromUrl = 'fromurl',
  FromText = 'fromtext',
}

export enum OrganisationName {
  // FIXME: delete drop once the old domains are deleted and redirected to the new ones
  Drop = 'drop',
  Fresco = 'fresco',
  Kenwood = 'kenwood',
  InstantBrands = 'instantbrands',
}

export enum OrganisationId {
  // FIXME: delete drop once the old domains are deleted and redirected to the new ones
  Drop = 1,
  Fresco = 1,
  Kenwood = 2,
  InstantBrands = 6,
}

export const organisationClientIds: Record<number, string> = {
  [OrganisationId.Fresco]: 'f8b8d6cb-6ca3-4cc7-be58-00b02a0064b1',
  [OrganisationId.Kenwood]: '57da03d1-e1b5-49fb-b55d-296b5d1830cc',
  [OrganisationId.InstantBrands]: 'e25db709-78e7-4486-8e27-f44c271ee6a7',
};

export const getCurrentOrganizationClientId = () =>
  organisationClientIds[getCurrentOrganizationId()];

export const organisationLogos: Record<
  number,
  FunctionComponent<SvgIconProps>
> = {
  // eslint-disable-next-line react/display-name
  [OrganisationId.Fresco]: (props) => <FrescoLogo {...props} />,
  // eslint-disable-next-line react/display-name
  [OrganisationId.Kenwood]: (props) => <KenwoodLogo {...props} />,
  // eslint-disable-next-line react/display-name
  [OrganisationId.InstantBrands]: (props) => <InstantBrandsLogo {...props} />,
};

export const organisations: Record<number, OrganisationName> = {
  // FIXME: delete drop once the old domains are deleted and redirected to the new ones
  [OrganisationId.Drop]: OrganisationName.Drop,
  [OrganisationId.Fresco]: OrganisationName.Fresco,
  [OrganisationId.Kenwood]: OrganisationName.Kenwood,
  [OrganisationId.InstantBrands]: OrganisationName.InstantBrands,
};

export const organisationIds: Record<OrganisationName, OrganisationId> = {
  // FIXME: delete drop once the old domains are deleted and redirected to the new ones
  [OrganisationName.Drop]: OrganisationId.Drop,
  [OrganisationName.Fresco]: OrganisationId.Fresco,
  [OrganisationName.Kenwood]: OrganisationId.Kenwood,
  [OrganisationName.InstantBrands]: OrganisationId.InstantBrands,
};

export const getCurrentOrganizationId = () =>
  organisationIds[appConfig.organizationName()];

export const tier1Locales = [
  ApiLocale.EsES,
  ApiLocale.FrFR,
  ApiLocale.ItIT,
  ApiLocale.EnGB,
  ApiLocale.EnAU,
];
