import { Paper, makeStyles, Typography, Grid } from '@material-ui/core';
import type { EntityId } from '@reduxjs/toolkit';
import React from 'react';
import { useSelector } from 'react-redux';

import { StepTips } from 'features/recipe/steps/stepViewEntities/StepTips';
import {
  selectStepById,
  selectStepsTotal,
} from 'features/recipe/steps/stepsSlice';
import { FrescoIcon } from 'shared/components/FrescoIcon';

export const useStyles = makeStyles((theme) => ({
  virtual: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2),
  },
  subheader: {
    ...theme.typography.overline,
    transform: 'uppercase',
  },
  text: {
    ...theme.typography.h6,
    color: '#645C5C',
    lineHeight: '24px',
    fontSize: 20,
    fontWeight: 'unset',
    marginBottom: theme.spacing(3),
  },
  bold: {
    fontSize: 16,
    fontWeight: 'bold',
  },
  icon: {
    paddingRight: theme.spacing(1),
  },
}));

interface VirtualStepProps {
  stepId: EntityId;
  index: number;
}

export const VirtualStep: React.FC<VirtualStepProps> = React.memo(
  function VirtualStep({ stepId, index }) {
    const classes = useStyles();
    const step = useSelector(selectStepById(stepId));
    const stepsCount = useSelector(selectStepsTotal);

    if (!step) {
      return null;
    }

    const { attachment } = step;

    return (
      <Paper>
        <div className={classes.virtual}>
          <Grid container justify="space-between">
            <Typography variant="body2">
              Step {index + 1}/{stepsCount} - Virtual Step
            </Typography>
          </Grid>
        </div>
        <div className={classes.virtual}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography className={classes.subheader}>
                {step?.appliance?.name}
              </Typography>
              <Typography className={classes.text}>{step.sentence}</Typography>
            </Grid>
            {attachment && (
              <Grid item xs={12}>
                <Typography className={classes.subheader}>
                  Attachment
                </Typography>
                <Grid container alignItems="center" direction="row">
                  <Grid className={classes.icon} item>
                    <FrescoIcon
                      color={attachment?.color}
                      image={attachment.image}
                      name={attachment.name}
                    />
                  </Grid>
                  <Grid item>
                    <Typography className={classes.bold} variant="body2">
                      {attachment.name}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            )}
          </Grid>
          <StepTips tips={step?.tips} />
        </div>
      </Paper>
    );
  }
);
