import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
  Collapse,
} from '@material-ui/core';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import type { ReactElement, FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import type { SubMenuName } from 'features/layout/layoutSlice';
import {
  drawerSubMenuOpenUpdated,
  selectSubMenuOpenByName,
} from 'features/layout/layoutSlice';
import { MenuItem } from 'features/layout/menus/CollapsibleMenuItem';
import type { LinkItem } from 'features/layout/menus/CollapsibleMenuItem';

const useStyles = makeStyles(() => ({
  header: {
    color: 'rgba(0, 0, 0, 0.87)',
    fontSize: '12px',
  },
}));

interface CollapsibleMenuProps {
  subMenuName: SubMenuName;
  listTitle: string;
  mainIcon: () => ReactElement;
  listItems: LinkItem[];
}

export const CollapsibleMenu: FC<CollapsibleMenuProps> = ({
  subMenuName,
  listTitle,
  mainIcon,
  listItems,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const open = useSelector(selectSubMenuOpenByName(subMenuName));

  const handleClick = () => {
    dispatch(
      drawerSubMenuOpenUpdated({
        subMenuName,
        value: !open,
      })
    );
  };

  return (
    <List>
      <ListItem button className={classes.header} onClick={handleClick}>
        <ListItemIcon>{mainIcon()}</ListItemIcon>
        <ListItemText primary={listTitle} />
        {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {listItems.map((listItem: LinkItem) => (
            <MenuItem key={listItem.url} listItem={listItem} />
          ))}
        </List>
      </Collapse>
    </List>
  );
};
