import type { PayloadAction, EntityId } from '@reduxjs/toolkit';
import { push } from 'connected-react-router';
import { put, call, takeLatest, select } from 'redux-saga/effects';

import { createRequestApiSaga } from 'api/createRequestApiSaga';
import type { ApiRecipePost } from 'api/recipe';
import {
  apiGetCreatorRecipe,
  apiPostRecipe,
  apiPatchRecipe,
  apiPostRecipeByUrl,
  apiDeleteRecipe,
} from 'api/recipe';
import type { ApiResponse } from 'api/types';
import { recipesRoutesPathPrefix } from 'app/routes/constants';
import { containersSagas } from 'features/recipe/containers/containersSagas';
import {
  detailsSaveFinished,
  detailsSaveRequested,
  detailsSaveFailed,
  selectRecipeId,
} from 'features/recipe/details/detailsSlice';
import {
  recipeCreateRequested,
  RecipeEditEntityType,
  recipeCreateSuccess,
  recipeCreateFailed,
} from 'features/recipe/edit/editSlice';
import { ingredientSagas } from 'features/recipe/ingredients/ingredientsSagas';
import { lintReportSagas } from 'features/recipe/linting/lintReportSlice';
import {
  publishStatusSagas,
  requestPostPublishStatus,
  selectPublishStatusIsSaved,
} from 'features/recipe/publish/publishStatusSlice';
import {
  recipeFetchFailed,
  recipeFetchFinished,
  recipeFetchRequested,
  recipePostRequested,
  recipePatchRequested,
  recipePostByUrlRequested,
  recipeDeleteRequested,
} from 'features/recipe/recipeSlice/recipeActions';
import { stepSagas, requestStepsGet } from 'features/recipe/steps/stepsSagas';
import {
  stepSaveFailed,
  stepMoved,
  selectStepsAll,
} from 'features/recipe/steps/stepsSlice';
import {
  recipesFetchRequested,
  recipesApiPending,
  recipesApiError,
} from 'features/recipes/recipesSlice';
import type { FrescoId } from 'shared/types/entity';
import type { ApiRecipeWeb } from 'shared/types/recipe';
import type { ApiStepWeb } from 'shared/types/step';
import { getErrorString } from 'shared/utils/common';

export const apiGetRecipeSaga = createRequestApiSaga(
  apiGetCreatorRecipe,
  'Recipe loading'
);

export const apiDeleteRecipeSaga = createRequestApiSaga(
  apiDeleteRecipe,
  'Deleting Recipe'
);

export const apiPostRecipeSaga = createRequestApiSaga(
  apiPostRecipe,
  'Recipe Saving'
);

export const apiPostRecipeByUrlSaga = createRequestApiSaga(
  apiPostRecipeByUrl,
  'Recipe Saving'
);

export const apiPatchRecipeSaga = createRequestApiSaga(
  apiPatchRecipe,
  'Recipe Saving'
);

export function* requestRecipe({ payload }: PayloadAction<EntityId>) {
  try {
    const response: ApiResponse<ApiRecipeWeb> = yield call(apiGetRecipeSaga, {
      recipeId: payload,
    });

    if (!response.ok) {
      yield put(recipeFetchFailed(response.details.message));
      return;
    }
    yield put(recipeFetchFinished(response.data));
  } catch (e) {
    yield put(recipeFetchFailed(getErrorString(e)));
  }
}

export function* deleteRecipe({ payload }: PayloadAction<EntityId>) {
  yield put(recipesApiPending());
  try {
    const response: ApiResponse<void> = yield call(apiDeleteRecipeSaga, {
      recipeId: payload,
    });

    if (!response.ok) {
      yield put(recipesApiError(response.details.message));
    }
  } catch (e) {
    yield put(recipesApiError(getErrorString(e)));
  }
  yield put(recipesFetchRequested());
}

export function* postRecipe({ payload }: PayloadAction<ApiRecipePost>) {
  yield put(
    recipeCreateRequested({ editEntityType: RecipeEditEntityType.Recipe })
  );
  try {
    const response: ApiResponse<ApiRecipeWeb> = yield call(apiPostRecipeSaga, {
      recipe: payload,
    });

    if (!response.ok) {
      yield put(
        recipeCreateFailed({ editEntityType: RecipeEditEntityType.Recipe })
      );
      yield put(recipeFetchFailed(response.details.message));
      return;
    }
    yield put(
      recipeCreateSuccess({ editEntityType: RecipeEditEntityType.Recipe })
    );
    yield put(recipeFetchFinished(response.data));
    yield put(push(`${recipesRoutesPathPrefix}/${response.data.id}`));
  } catch (e) {
    yield put(
      recipeCreateFailed({ editEntityType: RecipeEditEntityType.Recipe })
    );
    yield put(recipeFetchFailed(getErrorString(e)));
  }
}

export function* postRecipeByUrl({
  payload: { sourceUrl },
}: PayloadAction<{ sourceUrl: string }>) {
  yield put(
    recipeCreateRequested({ editEntityType: RecipeEditEntityType.Recipe })
  );
  try {
    const response: ApiResponse<ApiRecipeWeb> = yield call(
      apiPostRecipeByUrlSaga,
      {
        sourceUrl,
      }
    );
    if (!response.ok) {
      yield put(
        recipeCreateFailed({ editEntityType: RecipeEditEntityType.Recipe })
      );
      yield put(recipeFetchFailed(response.details.message));
      return;
    }
    yield put(
      recipeCreateSuccess({ editEntityType: RecipeEditEntityType.Recipe })
    );
    yield put(recipeFetchFinished(response.data));
    yield put(push(`${recipesRoutesPathPrefix}/${response.data.id}`));
  } catch (e) {
    yield put(
      recipeCreateFailed({ editEntityType: RecipeEditEntityType.Recipe })
    );
    yield put(recipeFetchFailed(getErrorString(e)));
  }
}

export function* patchRecipe({
  payload: { recipe },
}: PayloadAction<{ recipe: Partial<ApiRecipePost> }>) {
  const id: FrescoId = yield select(selectRecipeId);
  yield put(detailsSaveRequested());
  try {
    const response: ApiResponse<ApiRecipeWeb> = yield call(apiPatchRecipeSaga, {
      recipe,
      recipeId: id,
    });
    if (!response.ok) {
      yield put(recipeFetchFailed(response.details.message));
      yield put(detailsSaveFailed(response.details.message));
      return;
    }
    const recipePublishStatusIsSaved: boolean = yield select(
      selectPublishStatusIsSaved
    );
    if (!recipePublishStatusIsSaved) {
      yield call(requestPostPublishStatus);
    }
    yield put(recipeFetchRequested(id));
    yield put(detailsSaveFinished());
  } catch (e) {
    yield put(detailsSaveFailed(getErrorString(e)));
    yield put(recipeFetchFailed(getErrorString(e)));
  }
}

export function* patchRecipeStepsPosition({
  payload: { id },
}: PayloadAction<{ id: EntityId }>) {
  const recipeId: FrescoId = yield select(selectRecipeId);
  const steps: ApiStepWeb[] = yield select(selectStepsAll);
  const updateStepPositions = steps
    .filter((step) => !step.isVirtual)
    .map((step, index) => ({
      uri: step.uri,
      position: index,
    }));
  try {
    const response: ApiResponse<ApiRecipeWeb> = yield call(apiPatchRecipeSaga, {
      recipe: {
        steps: updateStepPositions,
      },
      recipeId,
    });
    if (!response.ok) {
      yield put(
        stepSaveFailed({
          id,
          error: response.details.message,
        })
      );
      return;
    }
    yield call(requestStepsGet, id);
  } catch (e) {
    yield put(
      stepSaveFailed({
        id,
        error: getErrorString(e),
      })
    );
  }
}

function* requestRecipeFetchWatcher() {
  yield takeLatest(recipeFetchRequested, requestRecipe);
}

function* requestRecipeDeleteWatcher() {
  yield takeLatest(recipeDeleteRequested, deleteRecipe);
}

function* requestRecipePostWatcher() {
  yield takeLatest(recipePostRequested, postRecipe);
}

function* requestRecipePostByUrlWatcher() {
  yield takeLatest(recipePostByUrlRequested, postRecipeByUrl);
}

function* requestRecipePatchWatcher() {
  yield takeLatest(recipePatchRequested, patchRecipe);
}

function* requestRecipeStepsPatchWatcher() {
  yield takeLatest(stepMoved, patchRecipeStepsPosition);
}

export const recipeSagas = [
  requestRecipeFetchWatcher,
  requestRecipeDeleteWatcher,
  requestRecipePostWatcher,
  requestRecipePatchWatcher,
  requestRecipeStepsPatchWatcher,
  requestRecipePostByUrlWatcher,
  ...containersSagas,
  ...ingredientSagas,
  ...stepSagas,
  ...lintReportSagas,
  ...publishStatusSagas,
];
