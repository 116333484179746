import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice, createAction } from '@reduxjs/toolkit';
import { put, call, takeLatest } from 'redux-saga/effects';

import { createRequestApiSaga } from 'api/createRequestApiSaga';
import { apiIngredientTermsGet } from 'api/terms';
import type { ApiResponse, FrescoPaginatedResponse } from 'api/types';
import type { RootState } from 'app/rootReducer';
import { logoutSuccess } from 'features/login/loginSlice';
import type { FrescoIngredient } from 'shared/types/ingredient';
import { getErrorString } from 'shared/utils/common';

interface TermsIngredientsState {
  apiError?: string;
  apiPending: boolean;
  entities: FrescoIngredient[];
}

export const initialState: TermsIngredientsState = {
  apiPending: false,
  entities: [],
};

const termsIngredientsSlice = createSlice({
  name: 'termsIngredientsSlice',
  initialState,
  reducers: {
    termsIngredientsSuccess(
      state,
      { payload }: PayloadAction<FrescoIngredient[]>
    ) {
      state.entities = payload;
      state.apiPending = false;
      state.apiError = undefined;
    },
    termsIngredientsApiPending(state) {
      state.apiPending = true;
      state.apiError = undefined;
    },
    termsIngredientsApiError(state, { payload }: PayloadAction<string>) {
      state.apiPending = false;
      state.apiError = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(logoutSuccess, () => initialState);
  },
});

export const {
  reducer: termsIngredientsReducer,
  actions: {
    termsIngredientsSuccess,
    termsIngredientsApiPending,
    termsIngredientsApiError,
  },
} = termsIngredientsSlice;

const selectTermsIngredientsState = (state: RootState): TermsIngredientsState =>
  state.terms.ingredients;

export const selectTermsIngredientsEntities = (
  state: RootState
): FrescoIngredient[] => selectTermsIngredientsState(state).entities;

export const selectTermsIngredientsApiPending = (state: RootState): boolean =>
  selectTermsIngredientsState(state).apiPending;

export const selectTermsIngredientsApiError = (
  state: RootState
): string | undefined => selectTermsIngredientsState(state).apiError;

export const apiGetIngredientTermsSaga = createRequestApiSaga(
  apiIngredientTermsGet,
  'Ingredients loading'
);

export function* requestIngredientTerms() {
  try {
    yield put(termsIngredientsApiPending());
    const response: ApiResponse<FrescoPaginatedResponse<FrescoIngredient>> =
      yield call(apiGetIngredientTermsSaga);
    if (!response.ok) {
      yield put(termsIngredientsApiError(response.details.message));
      return;
    }
    yield put(termsIngredientsSuccess(response.data.results));
    return;
  } catch (e) {
    yield put(termsIngredientsApiError(getErrorString(e)));
  }
}

export const ingredientTermsFetchRequested = createAction(
  'terms/ingredientTermsFetchRequested'
);

function* requestIngredientTermsFetchWatcher() {
  yield takeLatest(ingredientTermsFetchRequested, requestIngredientTerms);
}

export const ingredientTermsSagas = [requestIngredientTermsFetchWatcher];
