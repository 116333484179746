import { Grid, TextField, MenuItem } from '@material-ui/core';
import isNil from 'lodash/isNil';
import minBy from 'lodash/minBy';
import type { FC } from 'react';
import { memo, useCallback, useMemo } from 'react';

import type {
  FrescoApplianceProfileTemperature,
  FrescoProfileTemperatureRange,
  FrescoApplianceProfilePreset,
  FrescoProfileTemperatureRangeNumber,
  FrescoProfileTemperatureRangeEnum,
  FrescoProfileTemperatureRangeEnumValue,
  FrescoAppliance,
} from 'shared/types/appliance';
import type {
  FrescoStepTemperature,
  FrescoStepTemperatureNumeric,
  FrescoStepTemperatureString,
} from 'shared/types/step';
import { FrescoStepTemperatureUnits } from 'shared/types/step';
import { bigRange } from 'shared/utils/bigRange';

export const testIdTemperatureUnit = 'temperatureunit';
export const testIdTemperaureValue = 'temperaturevalue';

interface StepTemperatureProps {
  currentPreset?: FrescoApplianceProfilePreset;
  currentAppliance?: FrescoAppliance;
  stepTemperature: FrescoStepTemperature | null;
  onSetStepTemperature: (action: FrescoStepTemperature | null) => void;
}

const isEnumRange = (
  range: FrescoProfileTemperatureRange
): range is FrescoProfileTemperatureRangeEnum => range.type === 'enum';

const isNumberRange = (
  range: FrescoProfileTemperatureRange
): range is FrescoProfileTemperatureRangeNumber => range.type === 'number';

export const StepTemperature: FC<StepTemperatureProps> = memo(
  function StepTemperature({
    currentPreset,
    stepTemperature,
    currentAppliance,
    onSetStepTemperature,
  }) {
    const temperature: FrescoApplianceProfileTemperature | null = currentPreset
      ? currentPreset?.temperature || null
      : currentAppliance?.profile?.temperature || null;

    const { unitsMenuItems, valuesMenuItems } = useMemo(() => {
      const units: JSX.Element[] = [];
      const values: JSX.Element[] = [];

      if (temperature?.ranges?.some((range) => range.type === 'number')) {
        if (temperature.supportedUnits) {
          units.push(
            ...temperature.supportedUnits.map(
              (unit: FrescoStepTemperatureUnits) => (
                <MenuItem key={unit} value={unit}>
                  {unit === FrescoStepTemperatureUnits.C ? '°C' : '°F'}
                </MenuItem>
              )
            )
          );
        }
      }

      const enumRange = temperature?.ranges?.find(isEnumRange);
      if (enumRange) {
        units.push(
          <MenuItem
            key={FrescoStepTemperatureUnits.S}
            value={FrescoStepTemperatureUnits.S}
          >
            Predefined
          </MenuItem>
        );

        values.push(
          ...enumRange.values.map(
            (value: FrescoProfileTemperatureRangeEnumValue) => (
              <MenuItem key={value.value} value={value.value}>
                {value.name}
              </MenuItem>
            )
          )
        );
      }

      return { unitsMenuItems: units, valuesMenuItems: values };
    }, [temperature]);

    const handleNumericInputBlur = useCallback(
      (value) => {
        const numberRanges = temperature?.ranges?.filter(isNumberRange);
        if (
          !numberRanges?.length ||
          !stepTemperature?.unit ||
          !value ||
          isNil(value) ||
          stepTemperature?.unit === FrescoStepTemperatureUnits.S
        ) {
          return;
        }

        const { unit } = stepTemperature;
        const possibleValues = numberRanges.flatMap((range) => {
          const start = range.start[unit];
          const end = range.end[unit];
          const step = range.step[unit];
          return bigRange(start, end, step);
        });

        const closestValue = minBy(possibleValues, (v) =>
          v.minus(value).abs().toNumber()
        );

        if (closestValue && !closestValue.eq(value)) {
          onSetStepTemperature({
            value: closestValue.toNumber(),
            unit,
          } as FrescoStepTemperatureNumeric);
        }
      },
      [onSetStepTemperature, temperature, stepTemperature]
    );

    const handlePredefinedChange = (value: string) => {
      onSetStepTemperature({
        value: value ? value.toString() : undefined,
        unit: FrescoStepTemperatureUnits.S,
      } as FrescoStepTemperatureString);
    };

    const handleNumericChange = (value: string) => {
      onSetStepTemperature({
        value: value ? parseFloat(value) : undefined,
        unit: stepTemperature?.unit,
      } as FrescoStepTemperatureNumeric);
    };

    const handleUnitChange = (value: FrescoStepTemperatureUnits | string) => {
      if (value === 'None') {
        onSetStepTemperature(null);
        return;
      }
      let unit;
      if (value === FrescoStepTemperatureUnits.S) {
        unit = value;
      } else {
        unit =
          value === FrescoStepTemperatureUnits.C
            ? FrescoStepTemperatureUnits.C
            : FrescoStepTemperatureUnits.F;
      }
      onSetStepTemperature({
        value: undefined,
        unit,
      });
    };

    return (
      <>
        {temperature?.ranges && (
          <Grid item xs>
            <TextField
              select
              inputProps={{ 'data-testid': testIdTemperatureUnit }}
              label="Temperature unit"
              id={testIdTemperatureUnit}
              variant="outlined"
              required={temperature.required}
              value={stepTemperature?.unit || ''}
              onChange={(event) => handleUnitChange(event.target.value)}
              fullWidth
            >
              {!temperature.required && (
                <MenuItem value="None">
                  <em>None</em>
                </MenuItem>
              )}
              {unitsMenuItems}
            </TextField>
          </Grid>
        )}
        {!!stepTemperature?.unit && (
          <Grid item xs={6}>
            {stepTemperature?.unit === FrescoStepTemperatureUnits.S ? (
              <TextField
                select
                label="Temperature value"
                value={stepTemperature?.value || ''}
                inputProps={{ 'data-testid': testIdTemperaureValue }}
                id={testIdTemperaureValue}
                variant="outlined"
                onChange={(event) => {
                  handlePredefinedChange(event.target.value);
                }}
                fullWidth
                required
              >
                {valuesMenuItems}
              </TextField>
            ) : (
              <TextField
                label="Temperature value"
                inputProps={{ 'data-testid': testIdTemperaureValue }}
                id={testIdTemperaureValue}
                type="number"
                variant="outlined"
                required
                onBlur={(event) => handleNumericInputBlur(event.target.value)}
                onChange={(event) => {
                  handleNumericChange(event.target.value);
                }}
                value={stepTemperature?.value?.toString()}
                fullWidth
              />
            )}
          </Grid>
        )}
      </>
    );
  }
);
