import {
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
} from '@material-ui/core';
import { push } from 'connected-react-router';
import type { FC } from 'react';
import { useDispatch } from 'react-redux';

import { getCurrentOrganizationId } from 'shared/constants';
import type { OrganisationId } from 'shared/constants';
import { useSelectUserHasAccessGroupRights } from 'shared/hooks/useSelectUserHasAccessGroupRights';
import type { PermissionsCheckRuleSet } from 'shared/permissions';
import { goToExternalUrl } from 'shared/utils/common';

const useStyles = makeStyles((theme) => ({
  nested: {
    ...theme.typography.h6,
    paddingLeft: theme.spacing(4),
  },
}));

export interface LinkItem {
  title: string;
  icon: FC;
  url: string;
  accessGroups: PermissionsCheckRuleSet;
  org?: OrganisationId[];
  isInternal?: boolean;
}

interface MenuItemProps {
  listItem: LinkItem;
}

export const MenuItem: FC<MenuItemProps> = ({ listItem }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const userHasAccess = useSelectUserHasAccessGroupRights(
    listItem.accessGroups
  );

  const isUserOrg = listItem?.org
    ? listItem.org.some((org) => org === getCurrentOrganizationId())
    : true;

  if (!userHasAccess || !isUserOrg) {
    return null;
  }

  return (
    <ListItem
      onClick={() => {
        if (listItem.isInternal) {
          dispatch(push(listItem.url));
        } else {
          goToExternalUrl(listItem.url);
        }
      }}
      key={listItem.url}
      button
      className={classes.nested}
    >
      <ListItemIcon>
        <listItem.icon />
      </ListItemIcon>
      <ListItemText primary={listItem.title} />
    </ListItem>
  );
};
