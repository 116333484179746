import { CircleHelpIcon } from '@dropkitchen/icons-react';
import {
  Box,
  Paper,
  Grid,
  makeStyles,
  CircularProgress,
  Typography,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  Tooltip,
  IconButton,
} from '@material-ui/core';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import WarningIcon from '@material-ui/icons/Warning';
import ConfettiExplosion from '@reonomy/react-confetti-explosion';
import classNames from 'classnames';
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import type { AppDispatch } from 'app/store';
import {
  selectLintReport,
  selectLintReportPending,
  selectLintReportError,
  lintReportRequested,
} from 'features/recipe/linting/lintReportSlice';
import { ErrorSeverity } from 'shared/types/lintReport';

const useStyles = makeStyles((theme) => ({
  tableHeader: {
    marginTop: theme.spacing(2),
  },
  severity: {
    paddingLeft: theme.spacing(1),
  },
  totals: {
    paddingRight: theme.spacing(3),
  },
  fail: {
    backgroundColor: '#f792a2',
  },
  high: {
    backgroundColor: '#ffdbb6',
  },
  medium: {
    backgroundColor: '#fef1c3',
  },
  low: {
    backgroundColor: '#eff6c6',
  },
  noErrors: {
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(8),
  },
}));

const bigExplodeProps = {
  force: 0.7,
  duration: 5000,
  particleCount: 300,
  floorHeight: 900,
  floorWidth: 1600,
};

export const LintReport: React.FC = React.memo(function LintReport() {
  const classes = useStyles();
  const dispatch: AppDispatch = useDispatch();
  const lintReport = useSelector(selectLintReport);
  const isPending = useSelector(selectLintReportPending);
  const lintReportError = useSelector(selectLintReportError);

  useEffect(() => {
    if (!isPending && !lintReport) {
      dispatch(lintReportRequested());
    }
  }, [dispatch, isPending, lintReport]);

  if (lintReportError) {
    return (
      <Paper>
        <Box p={2} mt={1}>
          <Grid container alignItems="center" justify="center">
            <Typography>{lintReportError}</Typography>
          </Grid>
        </Box>
      </Paper>
    );
  }

  if (isPending) {
    return (
      <Paper>
        <Box p={2} mt={1}>
          <Grid container alignItems="center" justify="center">
            <CircularProgress aria-label="Pending indicator" />
          </Grid>
        </Box>
      </Paper>
    );
  }

  if (!lintReport) {
    return null;
  }

  const {
    report,
    total: { fail, high, medium, low },
  } = lintReport;

  return (
    <Paper>
      <Box p={2} mt={1}>
        {lintReport && (
          <>
            <Grid>
              <Grid container alignItems="center">
                <Typography variant="h6">
                  {report?.length
                    ? 'Errors Identified'
                    : 'No Errors Identified'}
                </Typography>
                <Tooltip
                  title={
                    <>
                      <div>
                        Fail: Recipe contains errors that will break application
                        or the recipe is not cookable
                      </div>
                      <div>
                        High :Recipe contains errors that will impact user
                        experience but the recipe is cookable
                      </div>
                      <div>
                        Medium : Recipe is cookable but the general user
                        experience may be non-consistent or poor
                      </div>
                      <div>
                        Low : Recipe contains low-impact errors. Such errors may
                        not be noticeable to users
                      </div>
                    </>
                  }
                >
                  <IconButton>
                    <CircleHelpIcon />
                  </IconButton>
                </Tooltip>
              </Grid>
              <Grid>
                <Typography className={classes.totals}>
                  Fail : {fail}
                </Typography>
                <Typography className={classes.totals}>
                  High : {high}
                </Typography>
                <Typography className={classes.totals}>
                  Medium : {medium}
                </Typography>
                <Typography className={classes.totals}>Low : {low}</Typography>
              </Grid>
            </Grid>
            {report?.length ? (
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell width="80px" align="left">
                      <Typography variant="caption">Severity</Typography>
                    </TableCell>
                    <TableCell align="left">
                      <Typography variant="caption">Code</Typography>
                    </TableCell>
                    <TableCell align="left">
                      <Typography variant="caption">Description</Typography>
                    </TableCell>
                    <TableCell align="left">
                      <Typography variant="caption">Message</Typography>
                    </TableCell>
                    <TableCell align="left">
                      <Typography variant="caption">Location</Typography>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {report?.map((error, index) => {
                    const isFail = error.severity === ErrorSeverity.Fail;
                    const isHigh = error.severity === ErrorSeverity.High;
                    const isMedium = error.severity === ErrorSeverity.Medium;
                    const isLow = error.severity === ErrorSeverity.Low;
                    return (
                      <TableRow
                        className={classNames({
                          [classes.fail]: isFail,
                          [classes.high]: isHigh,
                          [classes.medium]: isMedium,
                          [classes.low]: isLow,
                        })}
                        key={`${error.code}-${index}`}
                      >
                        <TableCell align="left">
                          <Grid container alignItems="center">
                            {isFail && <WarningIcon />}
                            {isHigh && <ErrorOutlineIcon />}
                            {isMedium && <ErrorOutlineIcon />}
                            {isLow && <ErrorOutlineIcon />}
                            <Typography
                              variant="caption"
                              className={classes.severity}
                            >
                              {error.severity}
                            </Typography>
                          </Grid>
                        </TableCell>
                        <TableCell align="left">
                          <Typography variant="caption">
                            {error.code}
                          </Typography>
                        </TableCell>
                        <TableCell align="left">
                          <Typography variant="caption">
                            {error.description}
                          </Typography>
                        </TableCell>
                        <TableCell align="left">
                          <Typography variant="caption">
                            {error.message}
                          </Typography>
                        </TableCell>
                        <TableCell align="left">
                          <Typography variant="caption">
                            {error.location}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            ) : (
              <Grid
                className={classes.noErrors}
                container
                direction="column"
                alignItems="center"
                justify="center"
              >
                <Grid>
                  <ConfettiExplosion {...bigExplodeProps} />
                  <Typography variant="h6">Congratulations!</Typography>
                </Grid>
                <Grid>
                  <Typography variant="h6">
                    Your recipe contains no errors.
                  </Typography>
                </Grid>
              </Grid>
            )}
          </>
        )}
      </Box>
    </Paper>
  );
});
