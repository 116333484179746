import {
  Paper,
  AppBar,
  Container,
  TextField,
  Grid,
  Typography,
  makeStyles,
  Button,
  useTheme,
} from '@material-ui/core';
import type React from 'react';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { layoutHeaderTitleSet } from 'features/layout/layoutSlice';
import {
  loginRequested,
  selectLoginApiPending,
} from 'features/login/loginSlice';
import { ReactComponent as FrescoLogo } from 'logos/fresco.svg';
import { ButtonWithSpinner } from 'shared/components/ButtonWithSpinner';
import { appConfig } from 'shared/config';
import {
  primaryAppBarHeaderHeight,
  getCurrentOrganizationClientId,
} from 'shared/constants';
import { goToExternalUrl } from 'shared/utils/common';

const useStyles = makeStyles((theme) => ({
  header: {
    height: theme.spacing(primaryAppBarHeaderHeight),
  },
  container: {
    width: 350,
    padding: theme.spacing(3),
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'left',
    minHeight: '90vh',
    color: 'white',
    transition: 'all 300ms ease',
  },
  loginText: {
    marginBottom: theme.spacing(2),
  },
  loginField: {
    paddingTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
  button: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  logo: {
    marginLeft: theme.spacing(2),
  },
  logoText: {
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(2),
  },
}));

export const LoginPage: React.FC = () => {
  const theme = useTheme();
  const classes = useStyles();
  const dispatch = useDispatch();

  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');

  const isPending = useSelector(selectLoginApiPending);

  const clientId = getCurrentOrganizationClientId();

  const resetPasswordUri = appConfig.getCurrentResetPasswordUri();

  useEffect(() => {
    dispatch(layoutHeaderTitleSet(''));
  }, [dispatch]);

  return (
    <>
      <AppBar className={classes.header} color="secondary" position="sticky">
        <FrescoLogo
          className={classes.logo}
          width="100px"
          fill={theme.palette.background.default}
        />
      </AppBar>
      <Container fixed maxWidth="xs">
        <form
          onSubmit={(e) => {
            e.preventDefault();
            dispatch(
              loginRequested({
                email,
                password,
                clientId,
              })
            );
          }}
          className={classes.form}
        >
          <Grid container spacing={4} alignItems="flex-start">
            <Paper className={classes.container}>
              <FrescoLogo fill={theme.palette.primary.main} />
              <Typography className={classes.loginText} variant="h6">
                Enter your login details
              </Typography>
              <Grid item xs={12}>
                <TextField
                  autoFocus
                  className={classes.loginField}
                  variant="outlined"
                  fullWidth
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  label="Email"
                  type="email"
                  id="email"
                  required
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  className={classes.loginField}
                  label="Password"
                  type="password"
                  id="password"
                  variant="outlined"
                  fullWidth
                />
              </Grid>
              <Grid
                container
                justify="space-between"
                alignItems="center"
                direction="row-reverse"
              >
                <Grid item>
                  <ButtonWithSpinner
                    className={classes.button}
                    type="submit"
                    loading={isPending}
                    color="primary"
                  >
                    Login
                  </ButtonWithSpinner>
                </Grid>
                <Grid item>
                  <Button
                    color="primary"
                    variant="text"
                    onClick={() => {
                      goToExternalUrl(resetPasswordUri);
                    }}
                  >
                    Forgot password?
                  </Button>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </form>
      </Container>
    </>
  );
};
