import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice, createAction } from '@reduxjs/toolkit';
import { select, put, call, takeLatest } from 'redux-saga/effects';

import { createRequestApiSaga } from 'api/createRequestApiSaga';
import type { RecipePublishResponse } from 'api/recipe';
import {
  apiGetRecipePublishState,
  apiPostRecipePublishState,
} from 'api/recipe';
import type { ApiResponse, LocationResponse } from 'api/types';
import type { RootState } from 'app/rootReducer';
import {
  logoutSuccess,
  selectLoginUserOrgSupportedLocales,
} from 'features/login/loginSlice';
import { selectRecipeId } from 'features/recipe/details/detailsSlice';
import {
  recipeFetchFinished,
  recipeFetchRequested,
  recipeFetchFailed,
} from 'features/recipe/recipeSlice/recipeActions';
import type { FrescoId } from 'shared/types/entity';
import { ApiLocale } from 'shared/types/i18n';
import type { FrescoRecipeStatus } from 'shared/types/recipe';
import { getErrorString } from 'shared/utils/common';

export interface FrescoRecipeLocaleStatus {
  locale: ApiLocale;
  status: FrescoRecipeStatus;
}

export type LocalesStatusState = Partial<
  Record<ApiLocale, FrescoRecipeLocaleStatus>
>;
export interface PublishStatusState {
  apiPending: boolean;
  apiError?: string;
  isSaving: boolean;
  isSaved: boolean;
  saveError?: string;
  locales?: LocalesStatusState;
}

export const initialState: PublishStatusState = {
  apiPending: false,
  isSaving: false,
  isSaved: false,
};

const publishStatusSlice = createSlice({
  name: 'recipe/publishStatusSlice',
  initialState,
  reducers: {
    publishStatusSuccess(
      state,
      {
        payload: { recipeLocales, orgLocales },
      }: PayloadAction<{
        recipeLocales: FrescoRecipeLocaleStatus[];
        orgLocales: ApiLocale[];
      }>
    ) {
      state.locales = recipeLocales.reduce((map, locale) => {
        if (
          orgLocales.includes(locale.locale) &&
          // Ignoring other locales for the moment
          // Issues with what the user can edit in Creator vs publish
          // Remove locale.locale === ApiLocale.EnUS to deal with all possible locales/languages
          locale.locale === ApiLocale.EnUS
        ) {
          map[locale.locale] = { locale: locale.locale, status: locale.status };
        }
        return map;
      }, {} as LocalesStatusState);
      state.apiPending = false;
      state.isSaving = false;
      state.isSaved = true;
      state.apiError = undefined;
    },
    publishStatusApiPending(state) {
      state.apiPending = true;
      state.apiError = undefined;
    },
    publishStatusApiError(state, { payload }: PayloadAction<string>) {
      state.apiPending = false;
      state.apiError = payload;
    },
    publishStatusSaveRequested(state) {
      state.isSaving = true;
      state.isSaved = false;
    },
    publishStatusSaveFinished(state) {
      state.isSaving = false;
      state.isSaved = true;
      state.apiError = undefined;
    },
    publishStatusSaveFailed(state, { payload }: PayloadAction<string>) {
      state.isSaved = false;
      state.isSaving = false;
      state.saveError = payload;
    },
    publishLocaleEditRequested(
      state,
      { payload }: PayloadAction<FrescoRecipeLocaleStatus>
    ) {
      const editLocale = state.locales?.[payload.locale];
      if (editLocale) {
        editLocale.status = payload.status;
      }
      state.isSaved = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(recipeFetchRequested, () => initialState);
    builder.addCase(recipeFetchFinished, () => initialState);
    builder.addCase(recipeFetchFailed, () => initialState);
    builder.addCase(logoutSuccess, () => initialState);
  },
});

export const {
  reducer: publishStatusReducer,
  actions: {
    publishStatusSuccess,
    publishStatusApiPending,
    publishStatusApiError,
    publishStatusSaveRequested,
    publishStatusSaveFinished,
    publishStatusSaveFailed,
    publishLocaleEditRequested,
  },
} = publishStatusSlice;

export const selectPublishStatusLocalesStatus = (
  state: RootState
): LocalesStatusState | undefined => state.recipe.publishStatus.locales;

export const selectPublishStatusApiPending = (state: RootState): boolean =>
  state.recipe.publishStatus.apiPending;

export const selectPublishStatusIsSaved = (state: RootState): boolean =>
  state.recipe.publishStatus.isSaved;

export const selectPublishStatusIsSaving = (state: RootState): boolean =>
  state.recipe.publishStatus.isSaving;

export const selectPublishStatusApiError = (
  state: RootState
): string | undefined => state.recipe.publishStatus?.apiError;

export const selectPublishStatusSaveError = (
  state: RootState
): string | undefined => state.recipe.publishStatus?.saveError;

export const apiGetPublishStateSaga = createRequestApiSaga(
  apiGetRecipePublishState,
  'Publish Status loading'
);

export const apiPostPublishStateSaga = createRequestApiSaga(
  apiPostRecipePublishState,
  'Publishing Recipe'
);

export function* requestPublishStatus() {
  const recipeId: FrescoId = yield select(selectRecipeId);
  const orgLocales: ApiLocale[] = yield select(
    selectLoginUserOrgSupportedLocales
  );
  try {
    yield put(publishStatusApiPending());
    const response: ApiResponse<RecipePublishResponse> = yield call(
      apiGetPublishStateSaga,
      {
        recipeId,
      }
    );
    if (!response.ok) {
      yield put(publishStatusApiError(response.details.message));
      return;
    }

    yield put(
      publishStatusSuccess({ recipeLocales: response.data.locales, orgLocales })
    );
  } catch (e) {
    yield put(publishStatusApiError(getErrorString(e)));
  }
}

export function* requestPostPublishStatus() {
  const recipeId: FrescoId = yield select(selectRecipeId);
  const locales: LocalesStatusState = yield select(
    selectPublishStatusLocalesStatus
  );

  if (!locales) {
    return;
  }

  try {
    yield put(publishStatusSaveRequested());
    const response: ApiResponse<LocationResponse> = yield call(
      apiPostPublishStateSaga,
      {
        recipeId,
        locales: {
          locales: Object.keys(locales).map(
            (key) => locales?.[key as ApiLocale]
          ) as FrescoRecipeLocaleStatus[],
        },
      }
    );
    if (!response.ok) {
      yield put(publishStatusSaveFailed(response.details.message));
      return;
    }
    yield put(publishStatusSaveFinished());
  } catch (e) {
    yield put(publishStatusSaveFailed(getErrorString(e)));
  }
}

export const publishStatusFetchRequested = createAction(
  'recipe/publishStatusSlice/publishStatusFetchRequested'
);

export function* requestPublishStatusFetchWatcher() {
  yield takeLatest(publishStatusFetchRequested, requestPublishStatus);
}

export const publishStatusSagas = [requestPublishStatusFetchWatcher];
