import { combineReducers } from '@reduxjs/toolkit';

import { termsActionsReducer } from 'features/terms/actionsSlice';
import { termsAppliancesReducer } from 'features/terms/appliancesSlice';
import { termsAuthorsReducer } from 'features/terms/authorsSlice';
import { termsContainersReducer } from 'features/terms/containersSlice';
import { termsIngredientsReducer } from 'features/terms/ingredientsSlice';
import { termsPreparationsReducer } from 'features/terms/preparationsSlice';
import { termsTagsReducer } from 'features/terms/tagsSlice';
import { termsYieldsReducer } from 'features/terms/yieldSlice';

export const termsReducer = combineReducers({
  appliances: termsAppliancesReducer,
  actions: termsActionsReducer,
  containers: termsContainersReducer,
  ingredients: termsIngredientsReducer,
  yields: termsYieldsReducer,
  preparations: termsPreparationsReducer,
  tags: termsTagsReducer,
  authors: termsAuthorsReducer,
});
