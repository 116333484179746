import {
  Grid,
  TextField,
  Button,
  makeStyles,
  Tooltip,
} from '@material-ui/core';
import React from 'react';

import { MediaUpload } from 'features/mediaUpload/MediaUpload';
import type { FrescoMedia, FrescoVideo, AppImage } from 'shared/types/media';
import { FrescoMediaMimetype, FrescoMediaClass } from 'shared/types/media';
import type { FrescoTip } from 'shared/types/step';
import { getIdFromUri, isVirtualTip } from 'shared/utils/common';

const useStyles = makeStyles((theme) => ({
  row: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingBottom: theme.spacing(1),
  },
  padding: {
    paddingBottom: theme.spacing(1),
  },
}));

interface StepTipsProps {
  stepIndex: number;
  stepTip: FrescoTip;
  index: number;
  onChangeTipCaption: (caption: string, index: number) => void;
  onChangeTipMedia: (
    media: FrescoMedia | FrescoVideo | undefined,
    index: number,
    type:
      | FrescoMediaMimetype.Jpeg
      | FrescoMediaMimetype.Png
      | FrescoMediaMimetype.Mp4
      | undefined
  ) => void;
  onDeleteStepTip: (index: number) => void;
}

export const virtualTipToolTip = 'Virtual Tips cannot be edited or removed';

export const StepTip: React.FC<StepTipsProps> = React.memo(function StepTip({
  stepIndex,
  stepTip,
  index,
  onChangeTipCaption,
  onChangeTipMedia,
  onDeleteStepTip,
}) {
  const classes = useStyles();
  const virtualTip = isVirtualTip(stepTip.uri);
  const videoUri =
    stepTip?.video?.image?.data?.uri || stepTip?.video?.data?.uri;
  const imageUri = stepTip?.image?.data?.uri;
  return (
    <Tooltip title={!virtualTip ? '' : virtualTipToolTip}>
      <Grid key={stepTip.id} item xs={12} className={classes.row}>
        <MediaUpload
          disabled={virtualTip}
          mediaId={`step.${stepIndex}.tip.${index}`}
          apiImageSizeType={FrescoMediaClass.StepTips}
          value={{
            uri: imageUri || videoUri || '',
            previewUrl: imageUri || videoUri || '',
          }}
          acceptTypes={[
            FrescoMediaMimetype.Jpeg,
            FrescoMediaMimetype.Png,
            FrescoMediaMimetype.Mp4,
          ]}
          onChange={(
            image: AppImage | undefined,
            type?:
              | FrescoMediaMimetype.Jpeg
              | FrescoMediaMimetype.Png
              | FrescoMediaMimetype.Mp4
          ) => {
            onChangeTipMedia(
              image && {
                uri: image?.uri,
                id: getIdFromUri(image?.uri),
                data: {
                  uri: image?.previewUrl,
                },
              },
              index,
              type
            );
          }}
        />
        <Grid item xs={6}>
          <TextField
            disabled={virtualTip}
            multiline
            rows={2}
            required
            fullWidth
            onChange={(event) => onChangeTipCaption(event.target.value, index)}
            variant="outlined"
            value={stepTip.caption}
            className={classes.padding}
          />
          <Button
            disabled={virtualTip}
            onClick={() => {
              onDeleteStepTip(index);
            }}
            fullWidth
            variant="outlined"
            color="primary"
          >
            REMOVE TIP
          </Button>
        </Grid>
      </Grid>
    </Tooltip>
  );
});
