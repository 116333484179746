import {
  Fab,
  TextField,
  Grid,
  Typography,
  makeStyles,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import type { FC } from 'react';
import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { selectCreateAuthorEnabled } from 'features/developerMenu/featureFlagsSlice';
import { EditSidebarActions } from 'features/recipe/edit/EditSidebarActions';
import { EditSidebarContent } from 'features/recipe/edit/EditSidebarContent';
import { EditSidebarForm } from 'features/recipe/edit/EditSidebarForm';
import { EditSidebarTitle } from 'features/recipe/edit/EditSidebarTitle';
import { recipeEditEntityFinished } from 'features/recipe/edit/editSlice';
import {
  selectTermsAuthorsApiPending,
  authorTermsFetchRequested,
  selectTermsAuthorsEntities,
  authorTermsCreateRequested,
  createPartnersEmail,
} from 'features/terms/authorsSlice';
import { useSelectUserHasAccessGroupRights } from 'shared/hooks/useSelectUserHasAccessGroupRights';
import { usersWriteAccessGroups } from 'shared/permissions';

const useStyles = makeStyles((theme) => ({
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
}));

export const AuthorForm: FC = () => {
  const classes = useStyles();
  const isCreateAuthorEnabled = useSelector(selectCreateAuthorEnabled);
  const dispatch = useDispatch();

  const authorTerms = useSelector(selectTermsAuthorsEntities);
  const authorsTermsPending = useSelector(selectTermsAuthorsApiPending);
  const userHasAdminUsersWriteRights = useSelectUserHasAccessGroupRights(
    usersWriteAccessGroups
  );

  const [name, setName] = useState('');
  const [url, setUrl] = useState('');

  useEffect(() => {
    if (userHasAdminUsersWriteRights) {
      dispatch(authorTermsFetchRequested(name));
    }
  }, [dispatch, name, userHasAdminUsersWriteRights]);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (!userHasAdminUsersWriteRights || !isCreateAuthorEnabled) {
      return;
    }

    dispatch(
      authorTermsCreateRequested({
        fullName: name,
        email: createPartnersEmail(name),
        profile: {
          website: url.replace(/\s/g, '').toLowerCase(),
          firstName: name,
        },
      })
    );
  };

  const authorExists = authorTerms.some(
    (author) => author.email === createPartnersEmail(name)
  );

  return (
    <EditSidebarForm aria-label="Author form" onSubmit={handleSubmit}>
      <EditSidebarTitle
        text="Add Author"
        onClosePayloadAction={recipeEditEntityFinished()}
      />
      <EditSidebarContent>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <TextField
              error={authorExists}
              label="Author Name"
              fullWidth
              id="name"
              variant="outlined"
              required
              value={name}
              onChange={(e) => {
                setName(e.target.value);
              }}
              helperText={authorExists ? 'Author already exists' : ''}
            />
            {!authorExists && authorsTermsPending && name && (
              <Typography variant="caption">Checking for duplicates</Typography>
            )}
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Website"
              id="website"
              variant="outlined"
              fullWidth
              required
              value={url}
              onChange={(e) => {
                setUrl(e.target.value);
              }}
            />
          </Grid>
          {!userHasAdminUsersWriteRights && (
            <Typography variant="overline" color="error">
              You must have admin:users:write rights to add an author
            </Typography>
          )}
        </Grid>
      </EditSidebarContent>
      <EditSidebarActions>
        <Fab
          disabled={authorExists || !userHasAdminUsersWriteRights}
          type="submit"
          color="primary"
          variant="extended"
        >
          <AddIcon className={classes.extendedIcon} /> Add
        </Fab>
      </EditSidebarActions>
    </EditSidebarForm>
  );
};
