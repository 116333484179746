import type { EntityId } from '@reduxjs/toolkit';
import differenceBy from 'lodash/differenceBy';
import isEqual from 'lodash/isEqual';
import { all, put, call, select } from 'redux-saga/effects';

import { createRequestApiSaga } from 'api/createRequestApiSaga';
import type {
  ApiStepTipCreate,
  ApiStepTipUpdate,
  ApiStepTipDelete,
} from 'api/tips';
import { apiTipDelete, apiTipCreate, apiTipUpdate } from 'api/tips';
import type { ApiResponse, LocationResponse } from 'api/types';
import { selectRecipeId } from 'features/recipe/details/detailsSlice';
import { stepSaveFailed } from 'features/recipe/steps/stepsSlice';
import type { FrescoId } from 'shared/types/entity';
import type { FrescoTip } from 'shared/types/step';
import { getErrorString } from 'shared/utils/common';

interface PostFrescoTip {
  stepId: FrescoId;
  tip: Partial<FrescoTip>;
}

export const apiTipDeleteSaga = createRequestApiSaga(
  apiTipDelete,
  'Deleteing tip'
);

export const apiTipPostSaga = createRequestApiSaga(
  apiTipCreate,
  'Creating tip'
);

export const apiTipPatchSaga = createRequestApiSaga(
  apiTipUpdate,
  'Editing tip'
);

export function* updateStepTips({
  previousStateStepTips,
  stepTips,
  stepId,
}: {
  previousStateStepTips: FrescoTip[];
  stepTips: FrescoTip[];
  stepId: EntityId;
}) {
  const removedTips = differenceBy(previousStateStepTips, stepTips ?? [], 'id');
  const newTips = differenceBy(stepTips, previousStateStepTips || [], 'id');
  const editedTips = stepTips?.filter((tip) =>
    previousStateStepTips?.find(
      (previousTip) => previousTip.id === tip.id && !isEqual(previousTip, tip)
    )
  );

  if (removedTips?.length) {
    yield all(
      removedTips.map((tip) =>
        call(requestTipDelete, {
          stepId,
          tip,
        })
      )
    );
  }
  if (newTips?.length) {
    yield all(
      newTips.map((tip) =>
        call(requestTipPost, {
          stepId,
          tip: {
            image: tip.image,
            caption: tip.caption,
            video: tip.video,
          },
        })
      )
    );
  }

  if (editedTips?.length) {
    yield all(
      editedTips.map((tip) =>
        call(requestTipPatch, {
          stepId,
          tip,
        })
      )
    );
  }
}

export function* requestTipPatch({
  stepId,
  tip: { id, image, caption, video },
}: PostFrescoTip) {
  const recipeId: FrescoId = yield select(selectRecipeId);

  try {
    const response: ApiResponse<LocationResponse> = yield call(
      apiTipPatchSaga,
      {
        recipeId,
        stepId,
        image,
        caption,
        video,
        tipId: id,
      } as ApiStepTipUpdate
    );
    if (!response.ok) {
      yield put(
        stepSaveFailed({
          id: stepId,
          error: response.details.message,
        })
      );
      return;
    }
    return;
  } catch (e) {
    yield put(
      stepSaveFailed({
        id: stepId,
        error: getErrorString(e),
      })
    );
  }
}

export function* requestTipPost({
  stepId,
  tip: { image, caption, video },
}: PostFrescoTip) {
  const recipeId: FrescoId = yield select(selectRecipeId);

  try {
    const response: ApiResponse<LocationResponse> = yield call(apiTipPostSaga, {
      recipeId,
      stepId,
      image,
      caption,
      video,
    } as ApiStepTipCreate);
    if (!response.ok) {
      yield put(
        stepSaveFailed({
          id: stepId,
          error: response.details.message,
        })
      );
      return;
    }
    return;
  } catch (e) {
    yield put(
      stepSaveFailed({
        id: stepId,
        error: getErrorString(e),
      })
    );
  }
}

export function* requestTipDelete({ stepId, tip: { id } }: PostFrescoTip) {
  const recipeId: FrescoId = yield select(selectRecipeId);

  try {
    const response: ApiResponse<LocationResponse> = yield call(
      apiTipDeleteSaga,
      {
        recipeId,
        stepId,
        tipId: id,
      } as ApiStepTipDelete
    );
    if (!response.ok) {
      yield put(
        stepSaveFailed({
          id: stepId,
          error: response.details.message,
        })
      );
      return;
    }
  } catch (e) {
    yield put(
      stepSaveFailed({
        id: stepId,
        error: getErrorString(e),
      })
    );
  }
}
