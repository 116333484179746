import { useSelector } from 'react-redux';

import {
  selectIngredientsAll,
  selectIngredientsByGroupId,
} from 'features/recipe/ingredients/ingredientsSlice';
import { selectTemporaryIngredientGroup } from 'features/recipe/steps/stepsSlice';
import type { ApiIngredientGroupIngredient } from 'shared/types/ingredient';
import { getIngredientGroupId } from 'shared/utils/common';

export function useIngredientsInTempGroup() {
  const recipeIngredients: ApiIngredientGroupIngredient[] =
    useSelector(selectIngredientsAll);
  const ingredientGroupIds = recipeIngredients
    .map((ingredient) => getIngredientGroupId(ingredient.uri))
    .filter((groupdId) => groupdId);
  const temporaryGroupIds: string[] = useSelector(
    selectTemporaryIngredientGroup(ingredientGroupIds)
  );
  const ingredientsInTemporaryGroup: ApiIngredientGroupIngredient[] =
    useSelector(selectIngredientsByGroupId(temporaryGroupIds[0]));

  return {
    ingredientsInTemporaryGroup,
    temporaryGroupIds,
  };
}
