import { DeleteIcon, UploadIcon, ImageIcon } from '@dropkitchen/icons-react';
import {
  Box,
  Tooltip,
  Card,
  CardMedia,
  CircularProgress,
  IconButton,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import React, { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useDidUpdate } from 'rooks';

import type { AppDispatch } from 'app/store';
import type { MediaFileUpload } from 'features/mediaUpload/mediaUploadSlice';
import {
  imageMediaUploadRequested,
  videoMediaUploadRequested,
  selectMediaUploadsIsSavingById,
  selectMediaUploadById,
  selectMediaUploadsIsSavedById,
  mediaUploadRemoved,
  removeFileFromMediaStorage,
  addFileToMediaStorage,
} from 'features/mediaUpload/mediaUploadSlice';
import { snackbarOpen } from 'features/snackbar/snackbarSlice';
import type { AppImage, FrescoMediaClass } from 'shared/types/media';
import { FrescoMediaMimetype } from 'shared/types/media';
import {
  getImageSize,
  isImageBelowMinimum,
  isImageBelowRecommended,
} from 'shared/utils/media';

const useStyles = makeStyles((theme) => ({
  card: {
    borderRadius: '4px',
    border: '1px solid #c4c4c4',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    boxShadow: 'none',
    width: 180,
    height: 120,
    '&:not(.disabled):hover': {
      '& $imagePreview': {
        marginLeft: `-${theme.spacing(8)}px`,
        marginRight: theme.spacing(8),
      },
    },
  },
  imagePreview: {
    height: '100%',
    width: '100%',
    borderRight: '1px solid #c4c4c4',
    flexShrink: 0,
    zIndex: theme.zIndex.appBar - 1,
    transition: ' margin 0.3s',
  },
  placeholder: {
    backgroundColor: 'white',
    height: '100%',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& svg': {
      height: theme.spacing(6),
      width: theme.spacing(6),
    },
  },
  actions: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    marginLeft: `-${theme.spacing(8)}px`,
    width: theme.spacing(8),
    height: '100%',
    '& button': {
      borderRadius: 0,
      flexGrow: 1,
    },
  },
  input: {
    display: 'none',
  },
}));

export const MediaUpload: React.FC<{
  disabled?: boolean;
  mediaId: string;
  apiImageSizeType: FrescoMediaClass;
  value?: AppImage;
  acceptTypes?: FrescoMediaMimetype[];
  onChange?: (
    image: AppImage | undefined,
    type?:
      | FrescoMediaMimetype.Jpeg
      | FrescoMediaMimetype.Png
      | FrescoMediaMimetype.Mp4
  ) => void;
}> = React.memo(function MediaUpload({
  disabled,
  apiImageSizeType,
  value,
  acceptTypes,
  mediaId,
  onChange,
}) {
  const classes = useStyles();
  const inputRef = useRef<HTMLInputElement>(null);
  const dispatch: AppDispatch = useDispatch();
  const isUploading = useSelector(selectMediaUploadsIsSavingById(mediaId));
  const isUploaded = useSelector(selectMediaUploadsIsSavedById(mediaId));

  const uploadedFile = useSelector(selectMediaUploadById(mediaId));

  useDidUpdate(() => {
    if (isUploaded && uploadedFile?.uri && uploadedFile?.previewUrl) {
      onChange?.(
        {
          uri: uploadedFile.uri,
          previewUrl: uploadedFile.previewUrl,
        },
        uploadedFile.type
      );
    }
  }, [uploadedFile, isUploaded]);

  const handleClick = () => {
    inputRef.current?.click();
  };

  const handleChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (
      (file && file.type.includes(FrescoMediaMimetype.Jpeg)) ||
      (file && file.type.includes(FrescoMediaMimetype.Png))
    ) {
      const imageSize = await getImageSize(file);
      if (isImageBelowMinimum(imageSize, apiImageSizeType)) {
        dispatch(
          snackbarOpen({
            text: 'This image is too small',
            severity: 'error',
          })
        );
        if (inputRef.current) {
          inputRef.current.value = '';
        }
        return;
      }
      if (isImageBelowRecommended(imageSize, apiImageSizeType)) {
        dispatch(
          snackbarOpen({
            text: 'This image is smaller than the recommended minimum and should be tested on a device before publishing',
            severity: 'warning',
          })
        );
      }

      const upload: MediaFileUpload = {
        id: mediaId,
        file,
        type: file.type.includes(FrescoMediaMimetype.Jpeg)
          ? FrescoMediaMimetype.Jpeg
          : FrescoMediaMimetype.Png,
        sizes: apiImageSizeType,
      };

      addFileToMediaStorage(upload);
      dispatch(imageMediaUploadRequested(mediaId));
    }
    if (file && file.type.includes(FrescoMediaMimetype.Mp4)) {
      const upload: MediaFileUpload = {
        id: mediaId,
        file,
        type: FrescoMediaMimetype.Mp4,
        sizes: apiImageSizeType,
      };

      addFileToMediaStorage(upload);
      dispatch(videoMediaUploadRequested(mediaId));
    }
  };

  const handleDelete = () => {
    removeFileFromMediaStorage(mediaId);
    dispatch(mediaUploadRemoved({ id: mediaId }));
    onChange?.(undefined);
  };

  if (isUploading) {
    return (
      <Card className={classes.card}>
        <CircularProgress />
      </Card>
    );
  }

  const acceptFilesOfTypes = acceptTypes
    ? `${acceptTypes?.join(`,`)}`
    : `${FrescoMediaMimetype.Jpeg}, ${FrescoMediaMimetype.Png}`;

  return (
    <Card className={classNames(classes.card, disabled ? 'disabled' : '')}>
      <CardMedia className={classes.imagePreview} image={value?.previewUrl}>
        {!value && (
          <Box color="text.secondary" className={classes.placeholder}>
            <ImageIcon color="inherit" />
          </Box>
        )}
      </CardMedia>
      {!disabled && (
        <div className={classes.actions}>
          <Tooltip title={value ? 'Change media' : 'Upload media'}>
            <IconButton color="primary" onClick={handleClick}>
              <UploadIcon />
            </IconButton>
          </Tooltip>
          {value && (
            <Tooltip title="Remove media">
              <IconButton
                aria-label="Delete"
                color="primary"
                onClick={handleDelete}
              >
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          )}
          <input
            className={classes.input}
            data-testid="mediaUpload"
            accept={acceptFilesOfTypes}
            ref={inputRef}
            type="file"
            onChange={handleChange}
          />
        </div>
      )}
    </Card>
  );
});
