import { makeStyles } from '@material-ui/core';
import type React from 'react';

const useStyles = makeStyles((theme) => ({
  content: { overflowY: 'auto', flexGrow: 1, padding: `${theme.spacing(3)}px` },
}));

export const EditSidebarContent: React.FC = ({ children }) => {
  const classes = useStyles();
  return <div className={classes.content}>{children}</div>;
};
