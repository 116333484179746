import {
  Grid,
  Container,
  TableBody,
  TableRow,
  TableCell,
  Table,
  Paper,
  makeStyles,
  Typography,
} from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import times from 'lodash/times';
import React from 'react';

export const skeletonAriaLabel = 'Loading indicator';

interface ItemSkeletonProps {
  height?: number;
}

export const ItemSkeleton: React.FC<ItemSkeletonProps> = ({ height = 64 }) => (
  <Skeleton width="100%" height={height} />
);

interface ListSkeletonProps {
  rows?: number;
}

export const ListSkeleton: React.FC<ListSkeletonProps> = ({ rows = 5 }) => (
  <Container maxWidth="md">
    <Grid container aria-label={skeletonAriaLabel}>
      {times(rows, (i) => (
        <Grid item xs={12} key={i}>
          <ItemSkeleton />
        </Grid>
      ))}
    </Grid>
  </Container>
);

interface TableSkeletonProps {
  cellClassName?: string;
  rowsCount: number;
  columnsCount: number;
  skeletonItemHeight?: number;
}

export const TableSkeleton: React.FC<TableSkeletonProps> = React.memo(
  function TableSkeleton({
    cellClassName,
    rowsCount,
    columnsCount,
    skeletonItemHeight: skeletonHeight,
  }) {
    return (
      <Table>
        <TableBody aria-label={skeletonAriaLabel}>
          {times(rowsCount, (i) => (
            <TableRow key={i}>
              {times(columnsCount, (j) => (
                <TableCell key={j} align="left" className={cellClassName}>
                  <ItemSkeleton height={skeletonHeight} />
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    );
  }
);

export const useStyles = makeStyles((theme) => ({
  header: {
    padding: theme.spacing(2),
  },
  content: {
    backgroundColor: 'rgb(230, 230, 230, 0.3)',
    padding: theme.spacing(2),
    paddingBottom: theme.spacing(3),
  },
}));

export const StepSkeleton: React.FC = () => {
  const stylesStep = useStyles();
  return (
    <Paper>
      <div className={stylesStep.header}>
        <Typography variant="body2">
          <Skeleton width={60} />
        </Typography>
      </div>
      <div className={stylesStep.content}>
        <Typography variant="body2">
          <Skeleton width={60} />
        </Typography>
        <Typography variant="h6">
          <Skeleton />
          <Skeleton />
          <Skeleton />
        </Typography>
      </div>
    </Paper>
  );
};
