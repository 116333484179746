import { Menu, MenuItem, ListItemText, makeStyles } from '@material-ui/core';
import type { EntityId } from '@reduxjs/toolkit';
import type { FC } from 'react';
import { memo } from 'react';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles(() => ({
  menuItem: {
    display: 'flex',
  },
}));

export const useGetTranslateAction = (route: string) => {
  const history = useHistory();
  return {
    label: 'Translate',
    onClick: (id: EntityId | null) => {
      history.push(`${route}/${id}`);
    },
  };
};

export interface DataGridAction {
  label: string;
  onClick: (id: EntityId | null) => void;
}

interface DataGridActionsMenuProps {
  actions: DataGridAction[];
  anchorEl: HTMLElement | null;
  onClose: () => void;
  selectedId: EntityId | null;
}

export const DataGridActionsMenu: FC<DataGridActionsMenuProps> = memo(
  function DataGridActionsMenu({ actions, anchorEl, onClose, selectedId }) {
    const classes = useStyles();

    return (
      <Menu
        disableEnforceFocus
        disableRestoreFocus
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={onClose}
      >
        {actions.map((action) => (
          <MenuItem
            className={classes.menuItem}
            key={action.label}
            onClick={() => action.onClick(selectedId)}
          >
            <ListItemText primary={action.label} />
          </MenuItem>
        ))}
      </Menu>
    );
  }
);
