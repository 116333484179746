import type { PayloadAction, EntityId } from '@reduxjs/toolkit';
import { all, take, call, select, put, takeEvery } from 'redux-saga/effects';

import { createRequestApiSaga } from 'api/createRequestApiSaga';
import type {
  ApiStepPatchResponse,
  ApiCreateRecipeStepRequest,
} from 'api/steps';
import {
  apiStepPost,
  apiStepPatch,
  apiStepsGet,
  apiStepDelete,
} from 'api/steps';
import type {
  ApiResponse,
  LocationResponse,
  FrescoPaginatedResponse,
} from 'api/types';
import { selectRecipeId } from 'features/recipe/details/detailsSlice';
import { recipeEditEntityFinished } from 'features/recipe/edit/editSlice';
import { requestIngredientsGroupDelete } from 'features/recipe/ingredients/ingredientsSagas';
import {
  ingredientGroupFetched,
  ingredientGroupSaveRequested,
} from 'features/recipe/ingredients/ingredientsSlice';
import { recipeFetchFailed } from 'features/recipe/recipeSlice/recipeActions';
import {
  updateStepTips,
  requestTipPost,
} from 'features/recipe/steps/stepFormEntities/tipsSagas';
import type {
  StepEditRequestedPayload,
  StepAddRequestedPayload,
  StepIngredientGroupsUpdate,
  IngredientGroup,
} from 'features/recipe/steps/stepsSlice';
import {
  stepAddRequested,
  stepUpdated,
  stepSaveRequested,
  stepsAdapter,
  stepSaveFailed,
  selectStepById,
  stepEditRequested,
  stepRemoveRequested,
  stepAdded,
  stepsFetchedSuccess,
} from 'features/recipe/steps/stepsSlice';
import type { FrescoEntityUri, FrescoId } from 'shared/types/entity';
import type {
  ApiIngredientGroup,
  ApiIngredientGroupIngredient,
} from 'shared/types/ingredient';
import type { ApiStepWeb } from 'shared/types/step';
import { getErrorString } from 'shared/utils/common';

// eslint-disable-next-line @typescript-eslint/naming-convention
const { NODE_ENV } = process.env;
export const apiDelayCalls = NODE_ENV === 'test' ? 0 : 1000;

export const apiStepPostSaga = createRequestApiSaga(apiStepPost, 'Saving step');

export const apiStepPatchSaga = createRequestApiSaga(
  apiStepPatch,
  'Saving step'
);

export const apiStepsGetSaga = createRequestApiSaga(
  apiStepsGet,
  'Getting steps'
);

export const apiStepDeleteSaga = createRequestApiSaga(
  apiStepDelete,
  'Deleting step'
);

export function* requestStepAdd({
  payload: { step, stepIngredientGroup, temporaryIngredientGroup },
}: PayloadAction<StepAddRequestedPayload>) {
  const tempId = stepsAdapter.generateNewTempId();
  yield put(
    stepAdded({
      entity: {
        ...step,
        id: tempId,
        uri: tempId,
      },
    })
  );
  yield put(
    stepSaveRequested({
      id: tempId,
      ingredientsUpdate: {
        stepIngredientGroup,
        temporaryIngredientGroup,
      },
    })
  );
  yield put(recipeEditEntityFinished());
}

export function* requestStepEdit({
  payload: { step, stepIngredientGroup, temporaryIngredientGroup },
}: PayloadAction<StepEditRequestedPayload>) {
  if (step.id) {
    const previousStepStateSelector: ReturnType<typeof selectStepById> =
      yield call(selectStepById, step.id);
    const previousStepState: ApiStepWeb = yield select(
      previousStepStateSelector
    );
    yield put(
      stepUpdated({
        update: {
          id: step.id,
          changes: step,
        },
      })
    );
    yield put(
      stepSaveRequested({
        id: step.id,
        previousStepState,
        ingredientsUpdate: {
          stepIngredientGroup,
          temporaryIngredientGroup,
        },
      })
    );
    yield put(recipeEditEntityFinished());
  }
}

export function* requestStepSave({
  payload: { id, previousStepState, ingredientsUpdate },
}: PayloadAction<{
  id: EntityId;
  previousStepState?: ApiStepWeb;
  ingredientsUpdate?: StepIngredientGroupsUpdate;
}>) {
  if (!stepsAdapter.existsOnPlatform(id)) {
    yield call(requestStepPost, { id, ingredientsUpdate });
  } else {
    yield call(requestStepPatch, { id, previousStepState, ingredientsUpdate });
  }
}

function* requestSaveIngredientGroup({
  stepId,
  ingredientGroup,
}: {
  stepId: EntityId;
  ingredientGroup?: IngredientGroup;
}) {
  if (ingredientGroup?.ingredients.length) {
    yield put(
      ingredientGroupSaveRequested({
        groupId: ingredientGroup?.groupId,
        ingredients: ingredientGroup?.ingredients,
      })
    );
    const {
      payload: { response },
    }: PayloadAction<{
      response: ApiResponse<ApiIngredientGroup>;
      ingredients?: ApiIngredientGroupIngredient[];
    }> = yield take(ingredientGroupFetched);
    if (!response.ok) {
      yield put(
        stepSaveFailed({
          id: stepId,
          error: response.details.message,
        })
      );
      return;
    }
    return response.data.uri;
  }
}

export function* requestStepPost({
  id,
  ingredientsUpdate,
}: {
  id: EntityId;
  ingredientsUpdate?: StepIngredientGroupsUpdate;
}) {
  const recipeId: FrescoId = yield select(selectRecipeId);
  const step: ApiStepWeb = yield select(selectStepById(id));

  const ingredientGroupUri: FrescoEntityUri = {
    uri: '',
  };

  // Request save for step ingredient group
  const stepIngredientGroupResponse: string = yield call(
    requestSaveIngredientGroup,
    {
      ingredientGroup: ingredientsUpdate?.stepIngredientGroup,
      stepId: id,
    }
  );
  if (stepIngredientGroupResponse) {
    ingredientGroupUri.uri = stepIngredientGroupResponse;
  }

  // Request save for temporary ingredient group
  yield call(requestSaveIngredientGroup, {
    stepId: id,
    ingredientGroup: ingredientsUpdate?.temporaryIngredientGroup,
  });

  try {
    const response: ApiResponse<ApiStepPatchResponse> = yield call(
      apiStepPostSaga,
      {
        recipeId,
        sentenceRaw: step?.sentence,
        position: step?.position,
        action1: step?.action1?.uri
          ? {
              uri: step.action1.uri,
            }
          : null,
        toContainer: step?.toContainer,
        preset: step?.preset,
        appliance: step?.appliance,
        setting: step?.setting,
        setting2: step?.setting2,
        setting3: step?.setting3,
        setting4: step?.setting4,
        temperature: step?.temperature,
        attachment: step?.attachment,
        flags: step?.flags,
        time: step?.time,
        ingredientGroup: ingredientsUpdate?.stepIngredientGroup?.ingredients
          .length
          ? ingredientGroupUri
          : null,
        timeSetting: step.timeSetting,
      } as ApiCreateRecipeStepRequest
    );
    if (!response.ok) {
      yield put(
        stepSaveFailed({
          id,
          error: response.details.message,
        })
      );
      return;
    }
    const returnedId = Number(response.data.location.split('/').pop());

    if (returnedId) {
      if (step.tips.length) {
        yield all(
          step.tips.map((tip) =>
            call(requestTipPost, {
              stepId: returnedId,
              tip: {
                image: tip.image,
                caption: tip.caption,
                video: tip.video,
              },
            })
          )
        );
      }

      yield call(requestIngredientsGroupDelete, {
        ingredientsUpdate,
        stepId: id,
      });

      yield call(requestStepsGet, id);
    }
  } catch (e) {
    yield put(
      stepSaveFailed({
        id,
        error: getErrorString(e),
      })
    );
  }
}

export function* requestStepPatch({
  id,
  previousStepState,
  ingredientsUpdate,
}: {
  id: EntityId;
  previousStepState?: ApiStepWeb;
  ingredientsUpdate?: StepIngredientGroupsUpdate;
}) {
  const recipeId: FrescoId = yield select(selectRecipeId);
  const step: ApiStepWeb = yield select(selectStepById(id));

  const ingredientGroupUri: FrescoEntityUri = {
    uri: step?.ingredientGroup?.uri ?? '',
  };

  const stepIngredientGroupResponse: string = yield call(
    requestSaveIngredientGroup,
    {
      ingredientGroup: ingredientsUpdate?.stepIngredientGroup,
      stepId: id,
    }
  );
  if (stepIngredientGroupResponse) {
    ingredientGroupUri.uri = stepIngredientGroupResponse;
  }

  // Request save for temporary ingredient group
  yield call(requestSaveIngredientGroup, {
    stepId: id,
    ingredientGroup: ingredientsUpdate?.temporaryIngredientGroup,
  });

  try {
    const response: ApiResponse<ApiStepPatchResponse> = yield call(
      apiStepPatchSaga,
      {
        id: step.id,
        recipeId,
        action1: step.action1?.uri
          ? {
              uri: step.action1.uri,
            }
          : null,
        sentenceRaw: step.sentence ?? null,
        toContainer: step?.toContainer ?? null,
        preset: step?.preset ?? null,
        appliance: step.appliance ?? null,
        setting: step.setting ?? null,
        setting2: step.setting2 ?? null,
        setting3: step.setting3 ?? null,
        setting4: step.setting4 ?? null,
        temperature: step?.temperature ?? null,
        attachment: step?.attachment ?? null,
        flags: step?.flags,
        position: step?.position,
        time: step?.time ?? null,
        ingredientGroup: ingredientsUpdate?.stepIngredientGroup?.ingredients
          .length
          ? ingredientGroupUri
          : null,
        timeSetting: step.timeSetting,
      }
    );
    if (!response.ok) {
      yield put(
        stepSaveFailed({
          id,
          error: response.details.message,
        })
      );
      return;
    }

    yield call(updateStepTips, {
      previousStateStepTips: previousStepState?.tips ?? [],
      stepTips: step.tips ?? [],
      stepId: id,
    });

    yield call(requestIngredientsGroupDelete, {
      ingredientsUpdate,
      stepId: id,
    });

    yield call(requestStepsGet, id);
  } catch (e) {
    yield put(
      stepSaveFailed({
        id,
        error: getErrorString(e),
      })
    );
  }
}

export function* requestStepsGet(stepId: EntityId) {
  const recipeId: FrescoId = yield select(selectRecipeId);
  try {
    // TODO : Remove FrescoPaginatedResponse when platform updates response
    const getResponse: ApiResponse<FrescoPaginatedResponse<ApiStepWeb>> =
      yield call(apiStepsGetSaga, {
        recipeId,
      });
    if (!getResponse.ok) {
      if (stepId) {
        yield put(
          stepSaveFailed({ id: stepId, error: getResponse.details.message })
        );
        return;
      }
      yield put(recipeFetchFailed(getResponse.details.message));
      return;
    }
    // TODO : Remove .results when platform updates response
    yield put(stepsFetchedSuccess(getResponse.data.results));
  } catch (e) {
    yield put(stepSaveFailed({ id: stepId, error: getErrorString(e) }));
  }
}

export function* requestStepDelete({
  payload: { id },
}: PayloadAction<{ id: EntityId }>) {
  const recipeId: FrescoId = yield select(selectRecipeId);
  try {
    const response: ApiResponse<LocationResponse> = yield call(
      apiStepDeleteSaga,
      {
        recipeId,
        stepId: id,
      }
    );
    if (!response.ok) {
      yield put(
        stepSaveFailed({
          id,
          error: response.details.message,
        })
      );
      return;
    }
    yield call(requestStepsGet, id);
  } catch (e) {
    yield put(
      stepSaveFailed({
        id,
        error: getErrorString(e),
      })
    );
  }
}

function* stepAddRequestedWatcher() {
  yield takeEvery(stepAddRequested, requestStepAdd);
}

function* stepEditRequestedWatcher() {
  yield takeEvery(stepEditRequested, requestStepEdit);
}

function* stepSaveRequestedWatcher() {
  yield takeEvery(stepSaveRequested, requestStepSave);
}

export function* stepRemoveRequestedWatcher() {
  yield takeEvery(stepRemoveRequested, requestStepDelete);
}

export const stepSagas = [
  stepAddRequestedWatcher,
  stepEditRequestedWatcher,
  stepSaveRequestedWatcher,
  stepRemoveRequestedWatcher,
];
