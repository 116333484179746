import { actionTermsSagas } from 'features/terms/actionsSlice';
import { applianceTermsSagas } from 'features/terms/appliancesSlice';
import { authorTermsSagas } from 'features/terms/authorsSlice';
import { containerTermsSagas } from 'features/terms/containersSlice';
import { ingredientTermsSagas } from 'features/terms/ingredientsSlice';
import { preparationTermsSagas } from 'features/terms/preparationsSlice';
import { tagTermsSagas } from 'features/terms/tagsSlice';
import { yieldTermsSagas } from 'features/terms/yieldSlice';

export const termsSagas = [
  ...applianceTermsSagas,
  ...actionTermsSagas,
  ...containerTermsSagas,
  ...ingredientTermsSagas,
  ...yieldTermsSagas,
  ...preparationTermsSagas,
  ...tagTermsSagas,
  ...authorTermsSagas,
];
