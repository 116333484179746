import { Box, Container, Grid, makeStyles, Paper } from '@material-ui/core';
import times from 'lodash/times';
import type { FC } from 'react';
import { memo, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import { appTheme } from 'app/theme';
import {
  layoutHeaderTitleSet,
  layoutHeaderRecipeStatusSet,
} from 'features/layout/layoutSlice';
import { selectRecipeSmartSuggestionsStatus } from 'features/recipes/recipesSlice';
import { snackbarOpen } from 'features/snackbar/snackbarSlice';
import { LocaleSwitcher } from 'features/translation/localeSwitcher/LocaleSwitcher';
import { selectCurrentLocale } from 'features/translation/localeSwitcher/localeSlice';
import { RecipeDetails } from 'features/translation/recipe/RecipeDetails';
import { RecipeStatusSelector } from 'features/translation/recipe/RecipeStatusSelector';
import { Step } from 'features/translation/recipe/Step';
import {
  recipeFetchRequested,
  selectRecipeFetching,
  selectRecipeSteps,
  recipeSaveRequested,
  selectRecipeSaving,
  selectRecipeNameTranslationsByLocaleAndType,
  recipeFetchTranslationsRequested,
  selectRecipeTranslationsFetching,
  selectRecipe,
  recipeStatusEditRequested,
} from 'features/translation/recipe/recipeSlice';
import { ButtonWithSpinner } from 'shared/components/ButtonWithSpinner';
import { SecondaryAppBar } from 'shared/components/SecondaryAppBar';
import { skeletonAriaLabel, StepSkeleton } from 'shared/components/Skeletons';
import { useGetEmptyTranslations } from 'shared/hooks/useGetEmptyTranslations';
import { ApiLocale, RecipeDetailsTranslationType } from 'shared/types/i18n';
import { SmartSuggestionsStatus } from 'shared/types/recipe';
import {
  areSuggestionsEnabled,
  getIsTranslatableLocale,
} from 'shared/utils/translation';

export const useStyles = makeStyles((theme) => ({
  top: {
    marginTop: theme.spacing(4),
  },
  actionButton: {
    marginLeft: theme.spacing(1),
  },
  actionsBar: {
    alignItems: 'center',
    justifyContent: 'end',
    padding: `${theme.spacing(1)}px 0`,
  },
  saveButton: {
    position: 'sticky',
    left: '90vw',
    bottom: '5vh',
    margin: theme.spacing(2),
  },
  secondaryBar: {
    backgroundColor: theme.palette.background.default,
  },
}));

export const translateRecipeTitle = 'Translate recipe';
export const textSuggestTranslations = 'Suggest translations';
export const textGetSmartTranslations = 'Get smart translations';
export const textNoSentences =
  'No sentences are untranslated. You can empty a sentence to get suggestions for it.';

export const RecipePage: FC = memo(function TranslateRecipePage() {
  const classes = useStyles();
  const { recipeId } = useParams<{
    recipeId: string;
  }>();
  const dispatch = useDispatch();

  const locale = useSelector(selectCurrentLocale);
  const isFetching = useSelector(selectRecipeFetching);
  const isFetchingTranslations = useSelector(selectRecipeTranslationsFetching);
  const isSaving = useSelector(selectRecipeSaving);
  const recipeSteps = useSelector(selectRecipeSteps(locale));
  const nameEnUs = useSelector(
    selectRecipeNameTranslationsByLocaleAndType(
      ApiLocale.EnUS,
      RecipeDetailsTranslationType.Name
    )
  );
  const recipeTranslationStatus = useSelector(
    selectRecipeSmartSuggestionsStatus(locale, recipeId)
  );
  const recipe = useSelector(selectRecipe(locale));

  const sentences = useGetEmptyTranslations(locale);

  useEffect(() => {
    dispatch(
      recipeFetchRequested({
        recipeId,
        locale,
      })
    );
    dispatch(layoutHeaderTitleSet(''));
    dispatch(layoutHeaderRecipeStatusSet());
  }, [locale, recipeId, dispatch]);

  useEffect(() => {
    dispatch(
      layoutHeaderTitleSet(`${translateRecipeTitle} - ${nameEnUs ?? ''}`)
    );
  }, [dispatch, nameEnUs]);

  const saveRecipeTranslations = () => {
    dispatch(
      recipeSaveRequested({
        recipeId,
        locale,
      })
    );
  };

  const handleSuggestTranslations = () => {
    if (sentences.length === 0) {
      dispatch(
        snackbarOpen({
          text: textNoSentences,
          severity: 'warning',
        })
      );
      return;
    }
    dispatch(
      recipeFetchTranslationsRequested({
        locale,
        sentences,
      })
    );
  };

  const handleStatusChange = (status: string) => {
    dispatch(
      recipeStatusEditRequested({
        locale,
        status: Number(status),
      })
    );
  };

  if (isFetching) {
    return (
      <Container maxWidth="md">
        <div aria-label={skeletonAriaLabel}>
          {times(5, (i) => (
            <Box my={2} key={i}>
              <StepSkeleton />
            </Box>
          ))}
        </div>
      </Container>
    );
  }

  return (
    <>
      <SecondaryAppBar className={classes.secondaryBar}>
        <Grid container className={classes.actionsBar}>
          {areSuggestionsEnabled(locale) && (
            <ButtonWithSpinner
              className={classes.actionButton}
              color="secondary"
              disabled={!getIsTranslatableLocale(locale)}
              loading={isFetchingTranslations}
              onClick={handleSuggestTranslations}
              variant="contained"
            >
              {recipeTranslationStatus === SmartSuggestionsStatus.Completed
                ? textGetSmartTranslations
                : textSuggestTranslations}
            </ButtonWithSpinner>
          )}
          <LocaleSwitcher displayInline />
        </Grid>
      </SecondaryAppBar>
      <Container maxWidth="md">
        {getIsTranslatableLocale(locale) && recipe && (
          <Paper>
            <Grid
              container
              alignItems="center"
              style={{
                padding: appTheme.spacing(2),
                marginTop: appTheme.spacing(),
              }}
            >
              <Grid item style={{ width: '200px' }}>
                <RecipeStatusSelector
                  currentStatus={recipe.status}
                  onChange={handleStatusChange}
                />
              </Grid>
            </Grid>
          </Paper>
        )}
        <RecipeDetails />
        {recipeSteps?.map((step, index) => (
          <Box key={`${step.id}-${index}`} my={2}>
            <Step stepId={step.id} stepIndex={index} />
          </Box>
        ))}
      </Container>
      <ButtonWithSpinner
        className={classes.saveButton}
        color="primary"
        disabled={!getIsTranslatableLocale(locale)}
        loading={isSaving}
        onClick={saveRecipeTranslations}
        variant="contained"
      >
        Save
      </ButtonWithSpinner>
    </>
  );
});
