import { Container } from '@material-ui/core';
import type { GridValueGetterParams, GridColDef } from '@mui/x-data-grid';
import type { FC } from 'react';
import { memo, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { translateFeatureRoutePath } from 'app/routes/constants';
import {
  selectFeaturesPageSize,
  selectFeatures,
  selectFeaturesPage,
  selectFeaturesTotalPages,
  featuresPageSizeSet,
  featuresPageSet,
  featuresFetchRequested,
  selectFeaturesApiPending,
} from 'features/features/featuresSlice';
import { layoutHeaderTitleSet } from 'features/layout/layoutSlice';
import { DataGrid } from 'shared/components/dataGrid/DataGrid';
import type { DataGridAction } from 'shared/components/dataGrid/DataGridActionsMenu';
import { useGetTranslateAction } from 'shared/components/dataGrid/DataGridActionsMenu';
import { DataGridImageColumn } from 'shared/components/dataGrid/DataGridImageColumn';
import { DataGridTagsColumn } from 'shared/components/dataGrid/DataGridTagsColumn';
import { DataGridTextColumn } from 'shared/components/dataGrid/DataGridTextColumn';
import { useShouldDisplayTranslatePage } from 'shared/hooks/useShouldDisplayPage';

export const FeaturesPage: FC = memo(function FeaturesPage() {
  const dispatch = useDispatch();
  const displayPage = useShouldDisplayTranslatePage();
  const translateAction = useGetTranslateAction(translateFeatureRoutePath);

  useEffect(() => {
    dispatch(layoutHeaderTitleSet('Features'));
  }, [dispatch]);

  const actions: DataGridAction[] = [translateAction];

  const columns: GridColDef[] = [
    {
      field: 'image',
      headerName: 'Image',
      width: 150,
      renderCell: (params: GridValueGetterParams) => (
        <DataGridImageColumn image={params.row.image} label={params.row.name} />
      ),
      sortable: false,
    },
    {
      field: 'name',
      headerName: 'Name',
      renderCell: (params: GridValueGetterParams) => (
        <DataGridTextColumn text={params.row.name} />
      ),
      width: 240,
    },
    {
      field: 'heading',
      headerName: 'Heading',
      renderCell: (params: GridValueGetterParams) => (
        <DataGridTextColumn text={params.row.heading} />
      ),
      width: 250,
    },
    {
      field: 'tags',
      headerName: 'Tags',
      renderCell: (params: GridValueGetterParams) => (
        <DataGridTagsColumn tags={params.row.tags} />
      ),
      sortable: false,
      width: 200,
    },
  ];

  if (!displayPage) return null;

  return (
    <Container maxWidth="md">
      <DataGrid
        actions={actions}
        columns={columns}
        isPendingSelector={selectFeaturesApiPending}
        dataSelector={selectFeatures}
        pageSelector={selectFeaturesPage}
        pageSizeSelector={selectFeaturesPageSize}
        totalPagesSelector={selectFeaturesTotalPages}
        dataFetchAction={featuresFetchRequested}
        pageSetAction={featuresPageSet}
        pageSizeSetAction={featuresPageSizeSet}
        viewItemPath={translateFeatureRoutePath}
      />
    </Container>
  );
});
