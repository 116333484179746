import { CloseIcon } from '@dropkitchen/icons-react';
import {
  Paper,
  Box,
  Grid,
  TextField,
  Button,
  IconButton,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Autocomplete from '@material-ui/lab/Autocomplete';
import debounce from 'lodash/debounce';
import type React from 'react';
import { useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import type { AppDispatch } from 'app/store';
import {
  selectTermsTagsEntities,
  selectTermsTagsApiPending,
  tagTermsFetchRequested,
} from 'features/terms/tagsSlice';
import { selectCurrentLocale } from 'features/translation/localeSwitcher/localeSlice';
import { RecipeStatusSelector } from 'features/translation/recipe/RecipeStatusSelector';

import {
  recipesFetchRequested,
  recipesFiltersSetSearch,
  recipesFiltersSetStatus,
  recipesFiltersSetTags,
  selectRecipesFiltersSearch,
  selectRecipesFiltersTags,
  selectRecipesFiltersStatus,
  recipesFiltersReset,
} from './recipesSlice';

const useStyles = makeStyles((theme) => ({
  filtersHeader: {
    marginTop: theme.spacing(1),
  },
  searchContainer: {
    marginBottom: theme.spacing(1),
  },
  statusContainer: {
    marginBottom: theme.spacing(1),
  },
  clearStatus: {
    color: theme.palette.background.paper,
    backgroundColor: theme.palette.primary.main,
    borderRadius: theme.spacing(2),
    padding: theme.spacing(0.5),
  },
  // container: {
  //   height: `${theme.spacing(recipeFiltersHeight)}px`,
  // },
}));

export const RecipesFilters: React.FC = () => {
  const classes = useStyles();
  const dispatch: AppDispatch = useDispatch();

  const tags = useSelector(selectTermsTagsEntities);
  const tagsPending = useSelector(selectTermsTagsApiPending);
  const searchTerm = useSelector(selectRecipesFiltersSearch);
  const filterByTags = useSelector(selectRecipesFiltersTags);
  const filterByStatus = useSelector(selectRecipesFiltersStatus);
  const currentLocale = useSelector(selectCurrentLocale);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceFilters = useCallback(
    debounce(() => dispatch(recipesFetchRequested()), 500),
    [dispatch]
  );

  useEffect(() => {
    if (!tags.length && !tagsPending) {
      dispatch(tagTermsFetchRequested());
    }
  }, [dispatch, tags, tagsPending]);

  useEffect(() => {
    debounceFilters();
  }, [
    debounceFilters,
    searchTerm,
    filterByTags,
    filterByStatus,
    currentLocale,
  ]);

  return (
    <Box pt={2}>
      <Paper>
        <Box px={2} py={2}>
          <Grid container className={classes.searchContainer}>
            <TextField
              fullWidth
              type="text"
              variant="outlined"
              value={searchTerm}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                dispatch(recipesFiltersSetSearch(event.target.value))
              }
              label="Search Recipes"
              id="search"
            />
          </Grid>
          <Grid
            container
            spacing={3}
            alignItems="center"
            className={classes.statusContainer}
          >
            <Grid item xs={12} sm={4}>
              <Grid container alignItems="center">
                <Grid item xs={10}>
                  <RecipeStatusSelector
                    currentStatus={filterByStatus}
                    id="filter-status"
                    onChange={(newStatus) => {
                      dispatch(recipesFiltersSetStatus(Number(newStatus)));
                    }}
                  />
                </Grid>
                <Grid item xs={2}>
                  <IconButton
                    aria-label="clearStatus"
                    onClick={() => {
                      dispatch(recipesFiltersSetStatus());
                    }}
                  >
                    <CloseIcon className={classes.clearStatus} />
                  </IconButton>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Autocomplete
                multiple
                fullWidth
                id="tags"
                onChange={(_event, newValue) => {
                  dispatch(recipesFiltersSetTags(newValue));
                }}
                options={tags}
                getOptionLabel={(tag) => tag.name}
                getOptionSelected={(option, value) => option.uri === value.uri}
                value={filterByTags || []}
                renderInput={(params) => (
                  <TextField {...params} variant="outlined" label="Tags" />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={2}>
              <Button
                fullWidth
                variant="contained"
                color="primary"
                onClick={() => {
                  dispatch(recipesFiltersReset());
                }}
              >
                Reset
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Paper>
    </Box>
  );
};
