import { Tooltip, Typography } from '@material-ui/core';
import type { FC } from 'react';
import { memo } from 'react';

export interface DataGridTextColumnProps {
  text: string | null;
}

export const DataGridTextColumn: FC<DataGridTextColumnProps> = memo(
  function DataGridTagsColumn({ text }) {
    if (!text) return null;

    return (
      <Tooltip title={text}>
        <Typography variant="body2">{text}</Typography>
      </Tooltip>
    );
  }
);
