import { DeleteIcon, EditIcon, MoreMenuIcon } from '@dropkitchen/icons-react';
import {
  Paper,
  makeStyles,
  Typography,
  Box,
  Grid,
  IconButton,
  Menu,
  useTheme,
  MenuItem,
  ListItemIcon,
  CircularProgress,
  ListItemText,
} from '@material-ui/core';
import type { EntityId } from '@reduxjs/toolkit';
import classNames from 'classnames';
import { TableRowPlusBefore, TableRowPlusAfter } from 'mdi-material-ui';
import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import {
  selectRecipeEditMode,
  RecipeEditMode,
  RecipeEditEntityType,
  recipeCreateEntityRequested,
  recipeEditEntityRequested,
  selectRecipeEditEntityId,
} from 'features/recipe/edit/editSlice';
import { StepAction } from 'features/recipe/steps/stepViewEntities/StepAction';
import { StepAppliance } from 'features/recipe/steps/stepViewEntities/StepAppliance';
import { StepContainers } from 'features/recipe/steps/stepViewEntities/StepContainers';
import { StepIngredients } from 'features/recipe/steps/stepViewEntities/StepIngredients';
import { StepTimer } from 'features/recipe/steps/stepViewEntities/StepTimer';
import { StepTips } from 'features/recipe/steps/stepViewEntities/StepTips';
import {
  selectStepById,
  selectStepsTotal,
  selectStepIsSavingById,
  stepRemoveRequested,
} from 'features/recipe/steps/stepsSlice';
import { AlertDialog } from 'shared/components/AlertDialog';

export const useStyles = makeStyles((theme) => ({
  header: {
    padding: theme.spacing(2),
  },
  content: {
    backgroundColor: 'rgb(230, 230, 230, 0.3)',
    padding: theme.spacing(2),
    paddingBottom: theme.spacing(3),
  },
  subheader: {
    ...theme.typography.overline,
  },
  text: {
    ...theme.typography.h6,
    color: '#645C5C',
    lineHeight: '24px',
    fontSize: 20,
    fontWeight: 'unset',
    marginBottom: theme.spacing(1),
  },
  previewText: {
    ...theme.typography.h6,
    fontWeight: 500,
    color: '#212121',
    marginBottom: theme.spacing(1),
  },
  menuItem: {
    display: 'flex',
  },
  menuIcon: {
    justifyContent: 'flex-end',
  },
  active: {
    border: `2px solid ${theme.palette.primary.main}`,
  },
}));

interface StepProps {
  stepId: EntityId;
  index: number;
}

export const Step: React.FC<StepProps> = React.memo(function Step({
  stepId,
  index,
}) {
  const classes = useStyles();
  const theme = useTheme();
  const dispatch = useDispatch();
  const step = useSelector(selectStepById(stepId));
  const stepsCount = useSelector(selectStepsTotal);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);

  const isSaving = useSelector(selectStepIsSavingById(stepId));
  const isEditMode = useSelector(selectRecipeEditMode) === RecipeEditMode.Edit;
  const editEntityId = useSelector(selectRecipeEditEntityId);

  if (!step) {
    return null;
  }

  const handleOpenEditDelete = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleAddClick = (addBefore: boolean) => {
    const position = addBefore ? index : index + 1;
    dispatch(
      recipeCreateEntityRequested({
        editEntityType: RecipeEditEntityType.Step,
        position,
      })
    );
    handleCloseEditDelete();
  };

  const handleEditClick = () => {
    dispatch(
      recipeEditEntityRequested({
        editEntityType: RecipeEditEntityType.Step,
        editEntityId: stepId,
      })
    );
    handleCloseEditDelete();
  };

  const handleDeleteClick = () => {
    dispatch(
      stepRemoveRequested({
        id: stepId,
      })
    );
    handleCloseEditDelete();
  };

  const handleCloseEditDelete = () => {
    setAnchorEl(null);
    setIsDeleteDialogOpen(false);
  };

  const isBeingEdited = editEntityId === stepId;

  return (
    <Paper
      className={classNames({
        [classes.active]: isBeingEdited,
      })}
      onDoubleClick={handleEditClick}
    >
      <AlertDialog
        isOpen={isDeleteDialogOpen}
        handleCloseDialog={handleCloseEditDelete}
        content={`Are you sure you want to delete Step ${index + 1}`}
        handleAgreeDialog={handleDeleteClick}
      />
      <div className={classes.header}>
        <Grid container justify="space-between">
          <Typography variant="body2">
            Step {index + 1}/{stepsCount}
          </Typography>
          {!isSaving ? (
            <>
              <IconButton
                aria-label="Step Menu"
                size="small"
                color="inherit"
                edge="start"
                disabled={isEditMode}
                onClick={handleOpenEditDelete}
              >
                <MoreMenuIcon />
              </IconButton>
              <Menu
                disableEnforceFocus
                disableRestoreFocus
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleCloseEditDelete}
              >
                <MenuItem
                  className={classes.menuItem}
                  onClick={handleEditClick}
                >
                  <ListItemText primary="Edit Step" />
                  <ListItemIcon className={classes.menuIcon}>
                    <EditIcon />
                  </ListItemIcon>
                </MenuItem>
                <MenuItem
                  className={classes.menuItem}
                  onClick={() => handleAddClick(true)}
                >
                  <ListItemText primary="Add Step Before" />
                  <ListItemIcon className={classes.menuIcon}>
                    <TableRowPlusBefore />
                  </ListItemIcon>
                </MenuItem>
                <MenuItem
                  className={classes.menuItem}
                  onClick={() => handleAddClick(false)}
                >
                  <ListItemText primary="Add Step After" />
                  <ListItemIcon className={classes.menuIcon}>
                    <TableRowPlusAfter />
                  </ListItemIcon>
                </MenuItem>
                <MenuItem
                  className={classes.menuItem}
                  onClick={() => setIsDeleteDialogOpen(true)}
                >
                  <ListItemText primary="Delete Step" />
                  <ListItemIcon className={classes.menuIcon}>
                    <DeleteIcon />
                  </ListItemIcon>
                </MenuItem>
              </Menu>
            </>
          ) : (
            <CircularProgress
              aria-label="Saving indicator"
              size={theme.spacing(4)}
              style={{ verticalAlign: 'middle' }}
            />
          )}
        </Grid>
        {step.sourceText && (
          <Box mt={1}>
            <div className={classes.subheader}>Source text</div>
            <div className={classes.text}>{step.sourceText}</div>
          </Box>
        )}
      </div>
      <div className={classes.content}>
        <div className={classes.subheader}>Preview</div>
        <div className={classes.previewText}>{step.sentence}</div>
        <Grid container spacing={2}>
          <StepAction action={step?.action1} />
          <StepContainers toContainer={step.toContainer} />
          <StepTimer time={step?.time} />
          <StepIngredients ingredientGroupUri={step?.ingredientGroup?.uri} />
          <StepAppliance appliance={step?.appliance} stepId={step.id} />
        </Grid>
        <StepTips tips={step?.tips} />
      </div>
    </Paper>
  );
});
