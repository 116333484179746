/**
 * new Date().getTime() wrapper for easier mocking in tests
 */
export const getTime = () => new Date().getTime();

const pad = (num: number, numDigits: number) =>
  num.toString().padStart(numDigits, '0');

export const toHHHMMSS = (secondsTotal: number): string => {
  const hours = Math.floor(secondsTotal / 3600);
  const minutes = Math.floor((secondsTotal - hours * 3600) / 60);
  const seconds = secondsTotal - hours * 3600 - minutes * 60;

  return `${pad(hours, 3)}:${pad(minutes, 2)}:${pad(seconds, 2)}`;
};

export const toHHHMM = (secondsTotal: number): string => {
  const minutesTotal = secondsTotal / 60;
  const hours = Math.floor(minutesTotal / 60);
  const minutes = minutesTotal - hours * 60;

  return `${pad(hours, 3)}:${pad(minutes, 2)}`;
};

export const toSecondsHHMMSS = (hhhmmss: string): number => {
  const [hhh, mm, ss] = hhhmmss.split(':').map((x) => parseInt(x));
  return hhh * 3600 + mm * 60 + ss;
};

export const toSecondsHHMM = (hhhmm: string): number => {
  const [hhh, mm] = hhhmm.split(':').map((x) => parseInt(x));
  return hhh * 3600 + mm * 60;
};
