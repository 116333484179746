import type { SvgIconProps } from '@material-ui/core/SvgIcon';
import SvgIcon from '@material-ui/core/SvgIcon';
import type { FC } from 'react';

const KenwoodLogo: FC<SvgIconProps> = (props) => (
  <SvgIcon viewBox="0 0 96 24" {...props}>
    <g id="Group-14" transform="translate(2, 7)">
      <path
        d="M10.9384971,11.3512317 L19.6295731,11.3525374 L19.6295731,9.70039605 L12.8817093,9.70039605 L12.8817093,6.49481891 C13.2840253,6.49481891 18.9890231,6.49638566 18.9890231,6.49638566 L18.9890231,4.81969856 L12.8817093,4.81969856 L12.8817093,1.94731761 C13.2866548,1.94731761 19.4541844,1.94914549 19.4541844,1.94914549 L19.4541844,0.293870684 L10.9384971,0.288648173 L10.9384971,11.3512317 Z"
        fill="current"
      />
      <path
        d="M28.5236478,8.316039 C27.8333999,7.294777 23.0944863,0.292695619 23.0944863,0.292695619 L21.0623964,0.29086774 L21.0623964,11.3544958 L23.0071864,11.3544958 L23.0071864,3.39826168 C23.6950677,4.41612904 28.3905943,11.3560625 28.3905943,11.3560625 L30.4671231,11.3560625 L30.4671231,0.295306874 L28.5236478,0.295306874 L28.5236478,8.316039 Z"
        fill="current"
      />
      <path
        d="M31.5717301,0.296612502 L34.7736917,11.3558014 L36.7200594,11.3558014 C36.7200594,11.3558014 38.7476791,4.52292939 39.0984566,3.33663605 C39.4502858,4.52292939 41.4713317,11.3563237 41.4713317,11.3563237 L43.4271657,11.3563237 L46.6157167,0.298962632 L44.4813386,0.298962632 C44.4813386,0.298962632 42.7592686,6.90230532 42.4332087,8.15727467 C42.0642876,6.91066133 40.1081907,0.298962632 40.1081907,0.298962632 L38.0939815,0.298962632 C38.0939815,0.298962632 36.1349921,6.89760506 35.766071,8.13873475 C35.4389592,6.88820454 33.7174151,0.296612502 33.7174151,0.296612502 L31.5717301,0.296612502 Z"
        fill="current"
      />
      <path
        d="M61.9558657,2.67288104 C62.6071968,2.03834597 63.4759889,1.72264519 64.5385762,1.73439584 C67.9364367,1.75606926 68.1838742,4.91542719 68.1778263,5.88394182 C68.1725673,6.84201143 67.891472,9.97003429 64.5101775,9.97003429 C63.4070955,9.97003429 62.5188451,9.64127723 61.8719841,8.99525264 C61.1583335,8.28342442 60.7489179,7.16189022 60.7489179,5.91501576 L60.7489179,5.88446407 C60.7602248,4.58353662 61.1975133,3.41108294 61.9558657,2.67288104 M58.7493763,5.83693922 L58.7493763,5.83798372 C58.7425972,7.5031813 59.3442305,9.06183965 60.3984034,10.114959 C61.4257553,11.1398767 62.8136138,11.6595165 64.5204326,11.6595165 C68.6708605,11.6595165 70.1552223,8.52627117 70.1663202,5.84294511 C70.17284,4.12029991 69.5780435,2.53657351 68.5349146,1.4967716 C67.5414834,0.505277922 66.191227,0.00365575758 64.5204326,0.00365575758 C60.5693219,0.00313350649 58.776255,2.93243983 58.7493763,5.83693922"
        fill="current"
      />
      <polygon
        fill="current"
        points="0 11.3480982 1.94478999 11.3480982 1.94478999 0.288648173 0 0.288648173"
      />
      <polygon
        fill="current"
        points="10.0316559 0.285645229 7.61329009 0.285645229 1.92827663 5.73115726 7.5904133 11.340395 9.99720924 11.340395 4.35269032 5.7345519"
      />
      <path
        d="M49.6995867,2.67288104 C50.3511808,2.03625697 51.2212876,1.71977281 52.2880822,1.72995671 C55.6888352,1.75737489 55.9310136,4.91542719 55.9244398,5.88394182 C55.9199696,6.84305593 55.6380855,9.96872866 52.2560021,9.96872866 C51.1566015,9.96794528 50.2696658,9.6399716 49.6230678,8.99525264 C48.9033693,8.27532952 48.4931648,7.14178355 48.4991467,5.88394182 C48.5052606,4.58353662 48.9441268,3.4136942 49.6995867,2.67288104 M46.5110357,5.83693922 C46.5110357,5.85521801 46.509458,5.87480242 46.509458,5.89308121 C46.509458,7.53086061 47.1034656,9.06392866 48.1423874,10.1058196 C49.1726317,11.1351765 50.5628568,11.6582109 52.278353,11.6595165 C56.4158963,11.6574275 57.9013099,8.52287654 57.9157722,5.83928935 C57.9270791,4.1252613 57.3367528,2.54127377 56.2920461,1.4967716 C55.2999298,0.503188918 53.9499363,0 52.278353,0 C48.3125171,0 46.5236574,2.93139532 46.5110357,5.83693922"
        fill="current"
      />
      <path
        d="M73.3403287,2.0325751 C73.7142458,2.0325751 76.1544366,2.03309735 76.1544366,2.03309735 C77.9440852,2.03309735 79.0132463,3.45884281 79.0132463,5.8460525 C79.0132463,8.21759467 77.9440852,9.63158947 76.1544366,9.63158947 L73.3403287,9.63158947 L73.3403287,2.0325751 Z M71.3958016,11.3625907 L76.1544366,11.3625907 C78.4939169,11.3625907 80.9890646,9.91256055 80.9890646,5.8460525 C80.9890646,3.99127778 80.4912972,2.55769856 79.5083842,1.58056679 C78.6658873,0.744965056 77.5062708,0.303662892 76.1544366,0.305229645 L71.3958016,0.30261839 L71.3958016,11.3625907 Z"
        fill="current"
      />
    </g>
  </SvgIcon>
);

export default KenwoodLogo;
