import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

import type { RootState } from 'app/rootReducer';
import { logoutSuccess } from 'features/login/loginSlice';
import {
  recipeFetchFinished,
  recipeFetchRequested,
  recipeFetchFailed,
} from 'features/recipe/recipeSlice/recipeActions';
import type { FrescoId } from 'shared/types/entity';
import type { FrescoMedia } from 'shared/types/media';
import type {
  FrescoTag,
  FrescoRecipeStatus,
  RecipeCreatedWith,
} from 'shared/types/recipe';
import { FrescoTimeType } from 'shared/types/recipe';
import type { FrescoUserClient } from 'shared/types/user';

export interface Details {
  id?: FrescoId;
  name: string;
  difficulty: number;
  introduction?: string;
  sourceUrl?: string;
  preparationTime?: number;
  cookTime?: number;
  otherTime?: number;
  servings?: number;
  yield?: number;
  yieldTermUri?: string;
  isExternal?: boolean;
  isPremium?: boolean;
  isCommunity?: boolean;
  image?: FrescoMedia;
  images?: FrescoMedia[];
  tags?: FrescoTag[];
  status?: FrescoRecipeStatus;
  sourceTextIngredients?: string;
  sourceTextDirections?: string;
  sourceTextKeywords?: string;
  author?: FrescoUserClient;
  createdWith?: RecipeCreatedWith;
}

export interface DetailsState extends Details {
  isSaving: boolean;
  isSaved: boolean;
  saveError?: string;
}

export const initialState: DetailsState = {
  name: '',
  difficulty: 1,
  isSaving: false,
  isSaved: true,
};

const detailsSlice = createSlice({
  name: 'recipe/detailsSlice',
  initialState,
  reducers: {
    detailsUpdated(state, { payload }: PayloadAction<Partial<Details>>) {
      return {
        ...state,
        ...payload,
        isSaved: false,
      };
    },
    detailsAuthorUpdated(state, { payload }: PayloadAction<FrescoUserClient>) {
      state.author = payload;
    },
    detailsSaveRequested(state) {
      state.isSaved = false;
      state.isSaving = true;
      state.saveError = undefined;
    },
    detailsSaveFinished(state) {
      state.isSaved = true;
      state.isSaving = false;
      state.saveError = undefined;
    },
    detailsSaveFailed(state, { payload }: PayloadAction<string>) {
      state.isSaved = false;
      state.isSaving = false;
      state.saveError = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(recipeFetchRequested, () => initialState);
    builder.addCase(recipeFetchFinished, (state, { payload: recipe }) => {
      state.id = recipe.id;
      state.name = recipe.name;
      state.difficulty = recipe.difficulty;
      state.introduction = recipe.introduction;
      state.sourceUrl = recipe.sourceUrl || undefined;
      state.preparationTime = recipe.times.find(
        (x) => x.type === FrescoTimeType.Preparation
      )?.time;
      state.cookTime = recipe.times.find(
        (x) =>
          x.type === FrescoTimeType.Baking || x.type === FrescoTimeType.Cook
      )?.time;
      state.otherTime = recipe.times.find(
        (x) => x.type === FrescoTimeType.Other
      )?.time;
      state.servings = recipe.nutrition.servings;
      state.yield = recipe.nutrition.yield;
      state.yieldTermUri = recipe.yieldTerm?.uri || undefined;
      state.isExternal = recipe.flags.isExternal;
      state.isPremium = recipe.flags.isPremium;
      state.isCommunity = recipe.flags.isCommunity;
      state.image = recipe.image || undefined;
      state.images = recipe.images;
      state.tags = recipe?.tags;
      state.sourceTextIngredients = recipe.sourceText?.ingredients;
      state.status = recipe.status;
      state.sourceTextDirections = recipe.sourceText?.directions;
      state.sourceTextKeywords = recipe.sourceText?.keywords;
      state.author = recipe.user;
      state.isSaving = false;
      state.isSaved = true;
      state.createdWith = recipe.createdWith;
    });
    builder.addCase(recipeFetchFailed, () => initialState);
    builder.addCase(logoutSuccess, () => initialState);
  },
});

export const {
  reducer: detailsReducer,
  actions: {
    detailsUpdated,
    detailsAuthorUpdated,
    detailsSaveRequested,
    detailsSaveFinished,
    detailsSaveFailed,
  },
} = detailsSlice;

export const selectDetails = (state: RootState): DetailsState =>
  state.recipe.details;

export const selectDetailsSaving = (state: RootState): boolean =>
  state.recipe.details.isSaving;

export const selectRecipeName = (state: RootState): string =>
  state.recipe.details.name;

export const selectRecipeStatus = (
  state: RootState
): FrescoRecipeStatus | undefined => state.recipe.details.status;

export const selectRecipeId = (state: RootState): FrescoId | undefined =>
  state.recipe.details.id;

export const selectRecipeCreatedWith = (
  state: RootState
): RecipeCreatedWith | undefined => state.recipe.details.createdWith;
