import { makeStyles } from '@material-ui/core';
import type { CSSProperties } from '@material-ui/core/styles/withStyles';
import type React from 'react';

const useStyles = makeStyles((theme) => ({
  footer: {
    display: 'flex',
    padding: `${theme.spacing(3)}px 0`,
    flexShrink: 0,
    justifyContent: 'center',
    '& button': {
      margin: `0 ${theme.spacing(2)}px`,
    } as CSSProperties,
  },
}));

export const EditSidebarActions: React.FC = ({ children }) => {
  const classes = useStyles();
  return <div className={classes.footer}>{children}</div>;
};
