import type { EntityId } from '@reduxjs/toolkit';
import snakecaseKeys from 'snakecase-keys';

import {
  translationsSmartSuggestionsApiPath,
  translationsSuggestionsApiPath,
} from 'api/constants';
import { fetchJson } from 'api/fetchJson';
import type {
  AckResponse,
  ApiRequestFn,
  JobResponse,
  LocationResponse,
} from 'api/types';
import { ApiResponseRequestVariant, HttpMethod } from 'api/types';
import type { FrescoRecipeLocaleStatus } from 'features/recipe/publish/publishStatusSlice';
import { appConfig } from 'shared/config';
import type {
  FrescoId,
  FrescoEntityUri,
  FrescoTerm,
} from 'shared/types/entity';
import type { ApiRecipeI18n } from 'shared/types/i18n';
import { apiLocaleToAcceptLanguageMap, ApiLocale } from 'shared/types/i18n';
import type { ApiLintReportResponse } from 'shared/types/lintReport';
import type { FrescoMedia } from 'shared/types/media';
import type {
  FrescoRecipesGetResponse,
  ApiRecipeWeb,
  FrescoNutrition,
  FrescoTime,
  FrescoRecipeStatus,
  RecipeSmartSuggestionsStatus,
} from 'shared/types/recipe';
import { getIdFromUri } from 'shared/utils/common';

interface RecipeSourceText {
  ingredients?: string;
  directions?: string;
}

interface StepPositionPatch extends FrescoEntityUri {
  position: number;
}
export interface ApiRecipePost {
  name: string;
  introduction?: string;
  difficulty?: number;
  sourceUrl?: string | null;
  times?: FrescoTime[];
  nutrition?: FrescoNutrition;
  yieldTerm?: FrescoEntityUri;
  flags?: {
    isExternal?: boolean;
    isPremium?: boolean;
    isCommunity?: boolean;
  };
  tags?: FrescoEntityUri[];
  image?: FrescoMedia | null;
  images?: FrescoMedia[];
  sourceText?: RecipeSourceText;
  steps?: StepPositionPatch[];
  user?: FrescoEntityUri;
}

export const apiPostRecipe =
  ({ recipe }: { recipe: ApiRecipePost }): ApiRequestFn<ApiRecipeWeb> =>
  (apiContext) =>
    fetchJson<ApiRecipeWeb>({
      apiContext,
      responseVariant: ApiResponseRequestVariant.Web,
      httpMethod: HttpMethod.Post,
      url: `${appConfig.apiV3PrefixRecipes()}`,
      body: snakecaseKeys(recipe),
    });

export interface ApiRecipePostByUrl {
  sourceUrl: string;
}

export const apiPostRecipeByUrl =
  ({ sourceUrl }: ApiRecipePostByUrl): ApiRequestFn<ApiRecipeWeb> =>
  (apiContext) => {
    const params = new URLSearchParams();
    if (sourceUrl) {
      params.append('source_url', sourceUrl);
    }
    return fetchJson<ApiRecipeWeb>({
      apiContext,
      responseVariant: ApiResponseRequestVariant.Web,
      httpMethod: HttpMethod.Post,
      url: `${appConfig.apiV3PrefixRecipes()}?${params.toString()}`,
    });
  };

export const apiPatchRecipe =
  ({
    recipeId,
    recipe,
  }: {
    recipeId: FrescoId;
    recipe: Partial<ApiRecipePost>;
  }): ApiRequestFn<ApiRecipeWeb> =>
  (apiContext) =>
    fetchJson<ApiRecipeWeb>({
      apiContext,
      responseVariant: ApiResponseRequestVariant.Web,
      httpMethod: HttpMethod.Patch,
      // TODO: Change to apiV3PrefixRecipes when API is done
      url: `${appConfig.apiRecipesUrl()}/${recipeId}`,
      body: snakecaseKeys(recipe),
    });

// Gets recipe from Creator 2 backend, which includes added source text
export const apiGetCreatorRecipe =
  ({ recipeId }: { recipeId: EntityId }): ApiRequestFn<ApiRecipeWeb> =>
  (apiContext) =>
    fetchJson<ApiRecipeWeb>({
      apiContext,
      responseVariant: ApiResponseRequestVariant.Web,
      httpMethod: HttpMethod.Get,
      url: `${appConfig.apiV3PrefixRecipes()}/${recipeId}`,
    });

export interface GetRecipeRequest {
  recipeId: EntityId;
  locale: ApiLocale;
}

export const apiGetRecipeTranslation =
  ({ recipeId, locale }: GetRecipeRequest): ApiRequestFn<ApiRecipeI18n> =>
  (apiContext) =>
    fetchJson<ApiRecipeI18n>({
      locale: apiLocaleToAcceptLanguageMap[locale],
      apiContext,
      responseVariant: ApiResponseRequestVariant.I18n,
      httpMethod: HttpMethod.Get,
      url: `${appConfig.apiRecipesUrl()}/${recipeId}`,
    });

export interface TipTranslations extends FrescoEntityUri {
  caption: string;
}
export interface StepTranslations extends FrescoEntityUri {
  directObjectType: string;
  directObjectId: string;
  sentenceRaw: string;
  tips: TipTranslations[];
}
export interface RecipeTranslations {
  name: string;
  introduction: string;
  completionCaption: string;
  steps: StepTranslations[];
}
export interface PatchRecipeRequest {
  recipeId: EntityId;
  recipeTranslations: RecipeTranslations;
  locale: ApiLocale;
}

export const apiPatchRecipeTranslation =
  ({
    recipeId,
    recipeTranslations,
    locale,
  }: PatchRecipeRequest): ApiRequestFn<ApiRecipeI18n> =>
  (apiContext) =>
    fetchJson<ApiRecipeI18n>({
      locale: apiLocaleToAcceptLanguageMap[locale],
      apiContext,
      responseVariant: ApiResponseRequestVariant.I18n,
      httpMethod: HttpMethod.Patch,
      url: `${appConfig.apiRecipesUrl()}/${recipeId}`,
      body: snakecaseKeys(recipeTranslations),
    });

export const apiGetRecipeLintReport =
  ({ recipeId }: { recipeId: EntityId }): ApiRequestFn<ApiLintReportResponse> =>
  (apiContext) =>
    fetchJson<ApiLintReportResponse>({
      apiContext,
      responseVariant: ApiResponseRequestVariant.Web,
      httpMethod: HttpMethod.Get,
      url: `${appConfig.apiRecipesUrl()}/${recipeId}/lint`,
    });

export interface GetRecipesRequest {
  locale: ApiLocale;
  page: number;
  pageSize: number;
  searchTerm?: string;
  status?: FrescoRecipeStatus;
  tags?: FrescoTerm[];
}

export const apiGetRecipes =
  ({
    locale,
    page,
    pageSize,
    searchTerm,
    status,
    tags,
  }: GetRecipesRequest): ApiRequestFn<FrescoRecipesGetResponse> =>
  (apiContext) => {
    const params = new URLSearchParams({
      page: page.toString(),
      // eslint-disable-next-line @typescript-eslint/naming-convention
      per_page: pageSize.toString(),
      // eslint-disable-next-line @typescript-eslint/naming-convention
      order_by: '-created_at',
      op: 'and',
    });

    if (searchTerm) {
      params.append('q', searchTerm);
    }
    if (tags?.length) {
      params.append('tags', tags.map(({ uri }) => getIdFromUri(uri)).join());
    }
    if (status !== undefined) {
      params.append('status', status.toString());
    }

    return fetchJson<FrescoRecipesGetResponse>({
      apiContext,
      httpMethod: HttpMethod.Get,
      locale: apiLocaleToAcceptLanguageMap[locale],
      responseVariant: ApiResponseRequestVariant.Lean,
      url: `${appConfig.apiV3PrefixRecipes()}?${params.toString()}`,
    });
  };

export const apiDeleteRecipe =
  ({ recipeId }: { recipeId: EntityId }): ApiRequestFn<void> =>
  (apiContext) =>
    fetchJson<void>({
      apiContext,
      responseVariant: ApiResponseRequestVariant.Web,
      httpMethod: HttpMethod.Delete,
      url: `${appConfig.apiRecipesUrl()}/${recipeId}`,
    });

export interface RecipePublishResponse {
  locales: FrescoRecipeLocaleStatus[];
}

export const apiGetRecipePublishState =
  ({ recipeId }: { recipeId: EntityId }): ApiRequestFn<RecipePublishResponse> =>
  (apiContext) =>
    fetchJson<RecipePublishResponse>({
      apiContext,
      responseVariant: ApiResponseRequestVariant.Web,
      httpMethod: HttpMethod.Get,
      url: `${appConfig.apiRecipesUrl()}/${recipeId}/publish`,
    });

export const apiPostRecipePublishState =
  ({
    recipeId,
    locales,
  }: {
    locales: { locales: FrescoRecipeLocaleStatus[] };
    recipeId: EntityId;
  }): ApiRequestFn<LocationResponse> =>
  (apiContext) =>
    fetchJson<LocationResponse>({
      apiContext,
      responseVariant: ApiResponseRequestVariant.Web,
      httpMethod: HttpMethod.Post,
      url: `${appConfig.apiRecipesUrl()}/${recipeId}/publish`,
      body: snakecaseKeys(locales),
    });

export interface PostRecipesPublishRequest {
  recipes: {
    id: FrescoId;
    locales: FrescoRecipeLocaleStatus[];
  }[];
}

export const apiPostRecipesPublish =
  (recipes: PostRecipesPublishRequest): ApiRequestFn<AckResponse> =>
  (apiContext) =>
    fetchJson<AckResponse>({
      apiContext,
      responseVariant: ApiResponseRequestVariant.Web,
      httpMethod: HttpMethod.Post,
      url: `${appConfig.apiRecipesUrl()}/publish`,
      body: snakecaseKeys(recipes),
    });

export interface PostSuggestTranslationsRequest {
  locale: ApiLocale;
  sentences: string[];
}

export const apiPostRecipeSuggestTranslations =
  ({
    locale,
    sentences,
  }: PostSuggestTranslationsRequest): ApiRequestFn<JobResponse> =>
  (apiContext) =>
    fetchJson<JobResponse>({
      apiContext,
      responseVariant: ApiResponseRequestVariant.Web,
      httpMethod: HttpMethod.Post,
      url: `${appConfig.apiUrl()}${translationsSuggestionsApiPath}`,
      body: { main: ApiLocale.EnUS, target: locale, sentences },
    });

export interface SmartTranslationsRequest {
  locale: ApiLocale;
  recipeIds: FrescoId[];
}

export const apiPostRecipesSmartTranslations =
  ({
    locale,
    recipeIds,
  }: SmartTranslationsRequest): ApiRequestFn<JobResponse> =>
  (apiContext) => {
    const body = {
      main: ApiLocale.EnUS,
      target: locale,
      recipeIds,
    };
    return fetchJson<JobResponse>({
      apiContext,
      responseVariant: ApiResponseRequestVariant.Web,
      httpMethod: HttpMethod.Post,
      url: `${appConfig.apiUrl()}${translationsSmartSuggestionsApiPath}`,
      body: snakecaseKeys(body),
    });
  };

export const apiGetRecipesSmartTranslations =
  ({
    locale,
    recipeIds,
  }: SmartTranslationsRequest): ApiRequestFn<RecipeSmartSuggestionsStatus[]> =>
  (apiContext) => {
    const queryParams = snakecaseKeys({
      locale,
      recipeIds: recipeIds.join(','),
    });
    const params = new URLSearchParams(queryParams);
    return fetchJson<RecipeSmartSuggestionsStatus[]>({
      apiContext,
      httpMethod: HttpMethod.Get,
      url: `${appConfig.apiUrl()}${translationsSmartSuggestionsApiPath}?${params.toString()}`,
    });
  };
