import {
  RecipesIcon,
  // ApplianceIcon,
  // TermsIcon,
  // IngredientsIcon,
  // ContainersIcon,
  CollectionsIcon,
  CategoriesIcon,
  // FeaturedItemIcon,
} from '@dropkitchen/icons-react';
// import SettingsIcon from '@material-ui/icons/Settings';
import type { FC } from 'react';

import {
  translateCategoryRoutePath,
  translateCollectionRoutePath,
  // translateFeatureRoutePath,
  translateRecipeRoutePath,
} from 'app/routes/constants';
import { SubMenuName } from 'features/layout/layoutSlice';
import { CollapsibleMenu } from 'features/layout/menus/CollapsibleMenu';
import type { LinkItem } from 'features/layout/menus/CollapsibleMenuItem';
import { appConfig } from 'shared/config';
import { useSelectUserHasAccessGroupRights } from 'shared/hooks/useSelectUserHasAccessGroupRights';
import { translationAccessGroups } from 'shared/permissions';

export const babelBaseUrl = appConfig.babelBaseUrl();

export const translationItems: LinkItem[] = [
  {
    title: 'Recipes',
    // eslint-disable-next-line react/display-name
    icon: () => <RecipesIcon />,
    url: translateRecipeRoutePath,
    accessGroups: translationAccessGroups,
    isInternal: true,
  },
  {
    title: 'Categories',
    // eslint-disable-next-line react/display-name
    icon: () => <CategoriesIcon />,
    url: translateCategoryRoutePath,
    accessGroups: translationAccessGroups,
    isInternal: true,
  },
  {
    title: 'Collections',
    // eslint-disable-next-line react/display-name
    icon: () => <CollectionsIcon />,
    url: translateCollectionRoutePath,
    accessGroups: translationAccessGroups,
    isInternal: true,
  },
  // {
  //   title: 'Appliances',
  //   // eslint-disable-next-line react/display-name
  //   icon: () => <ApplianceIcon />,
  //   url: `${babelBaseUrl}/appliances`,
  //   accessGroups: translationAccessGroups,
  // },
  // {
  //   title: 'Attachments',
  //   // eslint-disable-next-line react/display-name
  //   icon: () => <ApplianceIcon />,
  //   url: `${babelBaseUrl}/attachments`,
  //   accessGroups: translationAccessGroups,
  // },
  // {
  //   title: 'Containers',
  //   // eslint-disable-next-line react/display-name
  //   icon: () => <ContainersIcon />,
  //   url: `${babelBaseUrl}/containers`,
  //   accessGroups: translationAccessGroups,
  // },
  // {
  //   title: 'Features',
  //   // eslint-disable-next-line react/display-name
  //   icon: () => <FeaturedItemIcon />,
  //   url: translateFeatureRoutePath,
  //   accessGroups: translationAccessGroups,
  //   isInternal: true,
  // },
  // {
  //   title: 'Ingredients',
  //   // eslint-disable-next-line react/display-name
  //   icon: () => <IngredientsIcon />,
  //   url: `${babelBaseUrl}/ingredients`,
  //   accessGroups: translationAccessGroups,
  // },
  // {
  //   title: 'Settings',
  //   // eslint-disable-next-line react/display-name
  //   icon: () => <SettingsIcon />,
  //   url: `${babelBaseUrl}/settings`,
  //   accessGroups: translationAccessGroups,
  // },
  // {
  //   title: 'Terms',
  //   // eslint-disable-next-line react/display-name
  //   icon: () => <TermsIcon />,
  //   url: `${babelBaseUrl}/terms`,
  //   accessGroups: translationAccessGroups,
  // },
];

export const TranslationMenu: FC = () => {
  const userHasAccess = useSelectUserHasAccessGroupRights(
    translationAccessGroups
  );

  if (!userHasAccess) {
    return null;
  }

  return (
    <CollapsibleMenu
      subMenuName={SubMenuName.TranslationsOpen}
      listTitle="Translations"
      mainIcon={() => <RecipesIcon />}
      listItems={translationItems}
    />
  );
};
