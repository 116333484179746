import { CircularProgress, TextField } from '@material-ui/core';
import type { AutocompleteRenderInputParams } from '@material-ui/lab';
import type { FC } from 'react';

interface TermDropDownLoadingProps {
  label: string;
  loading: boolean;
  params: AutocompleteRenderInputParams;
  required?: boolean;
}

export const TermDropDownLoading: FC<TermDropDownLoadingProps> = ({
  label,
  loading,
  params,
  required,
}) => (
  <TextField
    variant="outlined"
    {...params}
    label={label}
    id={label}
    fullWidth
    required={required}
    InputProps={{
      ...params.InputProps,
      endAdornment: (
        <>
          {loading ? <CircularProgress color="inherit" size={20} /> : null}
          {params.InputProps.endAdornment}
        </>
      ),
    }}
  />
);
