import {
  Box,
  Drawer,
  Divider,
  makeStyles,
  Typography,
  Grid,
} from '@material-ui/core';
import type { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  selectDrawerOpen,
  drawerOpenUpdated,
} from 'features/layout/layoutSlice';
import { RecipesMenu } from 'features/layout/menus/Recipes';
import { TranslationMenu } from 'features/layout/menus/Translation';
import {
  selectLoginAuthUserEmail,
  selectLoginAuthUserFullName,
} from 'features/login/loginSlice';
import { appConfig } from 'shared/config';

const useStyles = makeStyles((theme) => ({
  header: {
    color: 'rgba(0, 0, 0, 0.87)',
    fontSize: '12px',
  },
  drawerPaper: {
    width: 350,
  },
  userInfo: {
    padding: theme.spacing(3),
  },
}));

export const adminBaseUrl = appConfig.adminBaseUrl();

export const NavigationDrawer: FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const isDrawerOpen = useSelector(selectDrawerOpen);

  const userName = useSelector(selectLoginAuthUserFullName);
  const userEmail = useSelector(selectLoginAuthUserEmail);

  const toggleDrawer = (value: boolean) => {
    dispatch(drawerOpenUpdated(value));
  };

  return (
    <Drawer
      classes={{
        paper: classes.drawerPaper,
      }}
      anchor="left"
      open={isDrawerOpen}
      onClose={() => toggleDrawer(false)}
    >
      <Box role="presentation">
        <Grid
          className={classes.userInfo}
          direction="column"
          container
          alignItems="flex-start"
          justify="center"
        >
          <Typography variant="h6">{userName}</Typography>
          <Typography variant="subtitle1">{userEmail}</Typography>
        </Grid>
        <Divider />
        <RecipesMenu />
        <Divider />
        <TranslationMenu />
      </Box>
    </Drawer>
  );
};
