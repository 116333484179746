import type { EntityId, Update } from '@reduxjs/toolkit';
import { createEntityAdapter } from '@reduxjs/toolkit';

export const tempIdPrefix = 'created-';

export interface SaveableState {
  isSaving: Record<EntityId, boolean | undefined>;
  isSaved: Record<EntityId, boolean | undefined>;
  saveError: Record<EntityId, string | undefined>;
}

export interface HasId {
  id: EntityId;
}

export declare type UpdateWithId<T> = {
  id: EntityId;
  entity: T;
};
/**
 * Wrapper around `createEntityAdapter` that adds additional state to keep track of
 * entities saving status and functions to update this state
 */
export const createSaveableEntityAdapter = <TEntity extends HasId>() => {
  const entityAdapter = createEntityAdapter<TEntity>();
  type SaveableEntitiesState = SaveableState &
    ReturnType<typeof entityAdapter['getInitialState']>;

  const getInitialState = (): SaveableEntitiesState => ({
    ...entityAdapter.getInitialState(),
    isSaved: {},
    isSaving: {},
    saveError: {},
  });

  const saveRequest = (
    state: SaveableEntitiesState,
    { id }: { id: EntityId }
  ) => {
    state.isSaving[id] = true;
    state.isSaved[id] = false;
    state.saveError[id] = undefined;
  };

  const saveFinish = (
    state: SaveableEntitiesState,
    { id }: { id: EntityId }
  ) => {
    state.isSaving[id] = false;
    state.isSaved[id] = true;
    state.saveError[id] = undefined;
  };

  const saveFail = (
    state: SaveableEntitiesState,
    { id, error }: { id: EntityId; error: string }
  ) => {
    state.isSaving[id] = false;
    state.isSaved[id] = false;
    state.saveError[id] = error;
  };

  const addOne = (
    state: SaveableEntitiesState,
    { entity }: { entity: TEntity }
  ) => {
    if (state.entities[entity.id]) {
      return;
    }
    state.isSaving[entity.id] = false;
    state.isSaved[entity.id] = false;
    state.saveError[entity.id] = undefined;
    entityAdapter.addOne(state, entity);
  };

  const updateOne = (
    state: SaveableEntitiesState,
    { update }: { update: Update<TEntity> }
  ) => {
    const { id } = update;
    state.isSaving[id] = false;
    state.isSaved[id] = false;
    state.saveError[id] = undefined;
    entityAdapter.updateOne(state, update);
  };

  const removeOne = (
    state: SaveableEntitiesState,
    { id }: { id: EntityId }
  ) => {
    delete state.isSaving[id];
    delete state.isSaved[id];
    delete state.saveError[id];
    entityAdapter.removeOne(state, id);
  };

  const updateWithNewId = (
    state: SaveableEntitiesState,
    { update: { id, entity } }: { update: UpdateWithId<TEntity> }
  ) => {
    state.isSaving[entity.id] = false;
    state.isSaved[entity.id] = true;
    state.saveError[entity.id] = undefined;
    entityAdapter.addOne(state, entity);
    removeOne(state, { id });
  };

  const loadAll = (
    state: SaveableEntitiesState,
    { entities }: { entities: TEntity[] }
  ) => {
    state.isSaving = {};
    state.isSaved = {};
    state.saveError = {};
    entityAdapter.setAll(state, entities);
    for (const id of state.ids) {
      state.isSaved[id] = true;
    }
  };

  const moveOne = (
    state: SaveableEntitiesState,
    { id, toIndex }: { id: EntityId; toIndex: number }
  ) => {
    const newIds = state.ids.filter((x) => x !== id);
    newIds.splice(toIndex, 0, id);
    state.ids = newIds;
  };

  let tempIdInc = 0;
  const testHelpergetLastTempIdInc = () => tempIdInc;
  const generateNewTempId = () => `${tempIdPrefix}${++tempIdInc}`;
  const getLastTempId = () => `${tempIdPrefix}${tempIdInc}`;
  const existsOnPlatform = (id: EntityId) =>
    typeof id === 'string' ? !id.startsWith(tempIdPrefix) : true;

  const getSelectors = <TRootState>(
    selectState: (state: TRootState) => SaveableEntitiesState
  ) => {
    const entitySelectors = entityAdapter.getSelectors(selectState);
    return {
      selectAll: entitySelectors.selectAll,
      selectIds: entitySelectors.selectIds,
      selectTotal: entitySelectors.selectTotal,
      selectById: (id: EntityId) => (state: TRootState) =>
        entitySelectors.selectById(state, id),
      selectIsSavedById: (id: EntityId) => (state: TRootState) =>
        selectState(state).isSaved[id],
      selectIsSavingById: (id: EntityId) => (state: TRootState) =>
        selectState(state).isSaving[id],
      selectSaveErrorById: (id: EntityId) => (state: TRootState) =>
        selectState(state).saveError[id],
    };
  };

  return {
    getInitialState,
    getSelectors,
    saveRequest,
    saveFinish,
    saveFail,
    addOne,
    updateOne,
    removeOne,
    /**
     * We need to replace an entity by old id in state
     * We need to set isSaved to true, and remove the old entity by id
     */
    updateWithNewId,
    loadAll,
    moveOne,
    /**
     * We want to give user instant visual feedback when adding new entity
     * React needs uniqe keys to render lists
     * This function generates unique temporary ids for that
     */
    generateNewTempId,
    getLastTempId,
    /**
     * Function is checking id, if id starts with temporary id prefix - it means
     * that entity is not created on platform yet
     */
    existsOnPlatform,
    /**
     * Helper for tests only. Returns current tempIdInc to be used for dynamically expecting the correct id
     */
    testHelpergetLastTempIdInc,
  };
};
