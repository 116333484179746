import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice, createAction } from '@reduxjs/toolkit';
import { put, call, select, takeLatest } from 'redux-saga/effects';

import { createRequestApiSaga } from 'api/createRequestApiSaga';
import { apiGetFeatures } from 'api/feature';
import type { ApiResponse } from 'api/types';
import type { RootState } from 'app/rootReducer';
import { logoutSuccess } from 'features/login/loginSlice';
import type {
  FrescoFeaturesGetResponse,
  FrescoFeature,
} from 'shared/types/feature';
import { getErrorString } from 'shared/utils/common';

export interface FeaturesState {
  apiError?: string;
  apiPending: boolean;
  page: number;
  pageSize: number;
  totalPages: number;
  entites: FrescoFeature[];
}

export const initialState: FeaturesState = {
  apiPending: false,
  page: 1,
  pageSize: 25,
  entites: [],
  totalPages: 0,
};

const featuresSlice = createSlice({
  name: 'featuresSlice',
  initialState,
  reducers: {
    featuresApiSuccess(
      state,
      { payload }: PayloadAction<FrescoFeaturesGetResponse>
    ) {
      state.apiPending = false;
      state.apiError = undefined;
      state.totalPages = payload.meta.total;
      state.entites = payload.results;
    },
    featuresApiPending(state) {
      state.apiPending = true;
      state.apiError = undefined;
    },
    featuresApiError(state, { payload }: PayloadAction<string>) {
      state.apiPending = false;
      state.apiError = payload;
      state.entites = [];
    },
    featuresPageSet(state, { payload }: PayloadAction<number>) {
      state.page = payload;
    },
    featuresPageSizeSet(state, { payload }: PayloadAction<number>) {
      state.pageSize = payload;
      state.page = 1;
    },
    featuresTotalPagesSet(state, { payload }: PayloadAction<number>) {
      state.totalPages = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(logoutSuccess, () => initialState);
  },
});

export const {
  reducer: featuresReducer,
  actions: {
    featuresApiSuccess,
    featuresApiPending,
    featuresApiError,
    featuresPageSet,
    featuresPageSizeSet,
    featuresTotalPagesSet,
  },
} = featuresSlice;

export const selectFeatures = (state: RootState): FrescoFeature[] =>
  state.features.entites;

export const selectFeaturesPage = (state: RootState): number =>
  state.features.page;

export const selectFeaturesPageSize = (state: RootState): number =>
  state.features.pageSize;

export const selectFeaturesTotalPages = (state: RootState): number =>
  state.features.totalPages;

export const selectFeaturesApiError = (state: RootState): string | undefined =>
  state.features.apiError;

export const selectFeaturesApiPending = (state: RootState): boolean =>
  state.features.apiPending;

export const apiGetFeaturesSaga = createRequestApiSaga(
  apiGetFeatures,
  'Features loading'
);

export function* requestFeatures() {
  yield put(featuresApiPending());

  const page: ReturnType<typeof selectFeaturesPage> = yield select(
    selectFeaturesPage
  );
  const pageSize: ReturnType<typeof selectFeaturesPageSize> = yield select(
    selectFeaturesPageSize
  );

  try {
    const response: ApiResponse<FrescoFeaturesGetResponse> = yield call(
      apiGetFeaturesSaga,
      { page, pageSize }
    );
    if (!response.ok) {
      yield put(featuresApiError(response.details.message));
      return;
    }
    yield put(featuresApiSuccess(response.data));
    return;
  } catch (e) {
    yield put(featuresApiError(getErrorString(e)));
  }
}

export const featuresFetchRequested = createAction(
  'featuresSlice/fetchRequested'
);

function* requestFeaturesFetchWatcher() {
  yield takeLatest(featuresFetchRequested, requestFeatures);
}
export const featuresSagas = [requestFeaturesFetchWatcher];
