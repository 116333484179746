import { Select, MenuItem, makeStyles, Grid } from '@material-ui/core';
import classNames from 'classnames';
import type { FC } from 'react';
import { useEffect, memo } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import {
  selectCurrentLocale,
  localeEditRequested,
  selectUserLocales,
} from 'features/translation/localeSwitcher/localeSlice';
import { localeLanguage, ApiLocale } from 'shared/types/i18n';

const useStyles = makeStyles((theme) => ({
  container: {
    position: 'absolute',
  },
  localeSwitcher: {
    margin: theme.spacing(2),
    backgroundColor: theme.palette.background.paper,
  },
  localeSwitcherInline: {
    marginBottom: 0,
    marginTop: 0,
  },
}));

export const selectLanguageText = 'Select Language';

export interface LocaleSwitcherProps {
  displayInline?: boolean;
}

export const LocaleSwitcher: FC<LocaleSwitcherProps> = memo(
  function LocaleSwitcher({ displayInline = false }) {
    const classes = useStyles();

    return (
      <>
        {displayInline ? (
          <LocaleDropDown displayInline />
        ) : (
          <Grid className={classes.container} container justify="flex-end">
            <LocaleDropDown />
          </Grid>
        )}
      </>
    );
  }
);

const LocaleDropDown: FC<LocaleSwitcherProps> = memo(function LocaleDropDown({
  displayInline = false,
}) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const locale = useSelector(selectCurrentLocale);
  const usableLocales = useSelector(selectUserLocales());

  useEffect(() => {
    if (usableLocales?.length === 1) {
      dispatch(localeEditRequested(usableLocales[0]));
    }
  }, [dispatch, usableLocales]);

  return (
    <Select
      inputProps={{ 'data-testid': 'localeSwitcher' }}
      variant="outlined"
      className={classNames(classes.localeSwitcher, {
        [classes.localeSwitcherInline]: displayInline,
      })}
      id="locale-switcher"
      value={locale}
      onChange={(event) => {
        dispatch(localeEditRequested(event.target.value as ApiLocale));
      }}
    >
      <MenuItem value={ApiLocale.EnUS}>{selectLanguageText}</MenuItem>
      {usableLocales?.map((userlocale) => (
        <MenuItem key={userlocale} value={userlocale}>
          {localeLanguage[userlocale]}
        </MenuItem>
      ))}
    </Select>
  );
});
