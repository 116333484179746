import { RecipesIcon } from '@dropkitchen/icons-react';
import AddIcon from '@material-ui/icons/Add';
import type { FC } from 'react';

import {
  recipesRoutesPathPrefix,
  createRecipeRoutesPath,
} from 'app/routes/constants';
import { SubMenuName } from 'features/layout/layoutSlice';
import { CollapsibleMenu } from 'features/layout/menus/CollapsibleMenu';
import type { LinkItem } from 'features/layout/menus/CollapsibleMenuItem';
import { useSelectUserHasAccessGroupRights } from 'shared/hooks/useSelectUserHasAccessGroupRights';
import { recipeAccessGroups } from 'shared/permissions';

export const recipeCreatorItems: LinkItem[] = [
  {
    title: 'List Recipes',
    // eslint-disable-next-line react/display-name
    icon: () => <RecipesIcon />,
    url: recipesRoutesPathPrefix,
    isInternal: true,
    accessGroups: recipeAccessGroups,
  },
  {
    title: 'Create Recipes',
    // eslint-disable-next-line react/display-name
    icon: () => <AddIcon />,
    url: createRecipeRoutesPath,
    isInternal: true,
    accessGroups: recipeAccessGroups,
  },
];

export const RecipesMenu: FC = () => {
  const userHasAccess = useSelectUserHasAccessGroupRights(recipeAccessGroups);

  if (!userHasAccess) {
    return null;
  }

  return (
    <CollapsibleMenu
      subMenuName={SubMenuName.RecipesOpen}
      listTitle="Recipe Management"
      mainIcon={() => <RecipesIcon />}
      listItems={recipeCreatorItems}
    />
  );
};
