import { appConfig } from 'shared/config';
import { noTranslationSentence } from 'shared/constants';
import { ApiLocale } from 'shared/types/i18n';

export const getNoTranslationPlaceholderText = (locale: string) => {
  return `${noTranslationSentence} ${locale}`;
};

export const getNoEnUsPlaceholderText = () => `Not set`;

export const getIsTranslatableLocale = (locale?: ApiLocale): boolean => {
  if (!locale) {
    return false;
  }
  return locale !== ApiLocale.EnUS;
};

export const areSuggestionsEnabled = (locale: ApiLocale): boolean => {
  const forbidden = appConfig.suggestionsForbiddenLocales();
  const allowed = appConfig.suggestionsAllowedLocales();
  return (
    !forbidden.includes(locale) &&
    (allowed.length === 0 || allowed.includes(locale))
  );
};
