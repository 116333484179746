import type { FrescoEntity, FrescoEntityUri } from 'shared/types/entity';

export interface FrescoMedia extends FrescoEntity {
  data: FrescoMediaData;
}

export interface FrescoMediaDataCopies {
  [size: string]: FrescoEntityUri;
}

export enum FrescoMediaMimetype {
  Jpeg = 'image/jpeg',
  Png = 'image/png',
  Mp4 = 'video/mp4',
}

export enum FrescoMediaClass {
  GenericIcon = 'generic-icon',
  RecipeImage = 'recipe-image',
  RecipeImages = 'recipe-images',
  StepTips = 'step-tips',
  UserIcon = 'user-icon',
}

export interface FrescoMediaData {
  class?: FrescoMediaClass;
  color?: string;
  copies?: FrescoMediaDataCopies;
  height?: number;
  key?: string;
  mimetype?: FrescoMediaMimetype;
  name?: string;
  uri: string;
  web?: string;
  width?: number;
}

export interface FrescoVideo extends FrescoEntity {
  data: FrescoMediaData;
  image?: FrescoMedia;
}

export interface AppImage {
  /**
   * Image URI in drop API
   */
  uri: string;
  previewUrl: string;
}
