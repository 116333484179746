import { Grid, makeStyles, Typography } from '@material-ui/core';
import classNames from 'classnames';
import type { FC } from 'react';
import { memo } from 'react';

import { FrescoIcon } from 'shared/components/FrescoIcon';
import type { FrescoVesselFlag } from 'shared/types/appliance';
import type { FrescoMedia } from 'shared/types/media';
import { toHHHMMSS } from 'shared/utils/getTimes';

interface StepApplianceProps {
  showHeader: boolean;
  applianceName: string;
  applianceImage: FrescoMedia | null;
  presetName?: string;
  settingName?: string;
  settingHeader?: string;
  setting2Name?: string;
  setting2Header?: string;
  setting3Name?: string;
  setting3Header?: string;
  setting4Name?: string;
  setting4Header?: string;
  applianceVesselFlagsWithStepValue?: FrescoVesselFlagStepValue[];
  attachmentName?: string;
  temperatureString?: string | null;
  color?: string;
  timeSetting?: number | null;
}

interface FrescoVesselFlagStepValue extends FrescoVesselFlag {
  value?: boolean;
}

const useStyles = makeStyles((theme) => ({
  header: {
    ...theme.typography.overline,
    transform: 'uppercase',
  },
  subheader: {
    fontSize: 16,
    lineHeight: '24px',
    fontWeight: 'bold',
  },
  icon: {
    paddingRight: theme.spacing(1),
  },
  stepEntityBackground: {
    backgroundColor: '#ffffff',
    padding: theme.spacing(1),
    minHeight: 58,
    alignItems: 'center',
  },
  contentExtraData: {
    paddingRight: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingTop: theme.spacing(1),
    backgroundColor: '#ffffff',
  },
  settingHeader: {
    fontSize: 14,
    lineHeight: '20px',
    color: `rgba(0, 0, 0, 0.6)`,
  },
  settingValue: {
    fontSize: 14,
    lineHeight: '20px',
    color: `rgba(0, 0, 0, 0.87)`,
  },
}));

const defaultSettingHeader = 'Setting';

export const StepAppliance: FC<StepApplianceProps> = memo(
  function StepAppliance({
    showHeader,
    applianceName,
    applianceImage,
    presetName,
    settingName,
    settingHeader = defaultSettingHeader,
    setting2Name,
    setting2Header = defaultSettingHeader,
    setting3Name,
    setting3Header = defaultSettingHeader,
    setting4Name,
    setting4Header = defaultSettingHeader,
    applianceVesselFlagsWithStepValue,
    attachmentName,
    temperatureString,
    color,
    timeSetting,
  }) {
    const classes = useStyles();

    const hasExtraData =
      settingName ||
      setting2Name ||
      setting3Name ||
      setting4Name ||
      presetName ||
      attachmentName ||
      temperatureString ||
      applianceVesselFlagsWithStepValue ||
      timeSetting;

    return (
      <Grid item xs={hasExtraData ? 12 : 4}>
        {showHeader && (
          <Grid item>
            <Typography className={classes.header}>Appliance</Typography>
          </Grid>
        )}
        <Grid
          container
          className={classNames({
            [classes.stepEntityBackground]: !hasExtraData,
          })}
          direction="row"
        >
          <Grid
            item
            className={classNames(classes.icon, {
              [classes.stepEntityBackground]: hasExtraData,
            })}
          >
            <FrescoIcon
              image={applianceImage}
              name={applianceName}
              color={color}
            />
          </Grid>
          <Grid
            item
            className={classNames({
              [classes.contentExtraData]: hasExtraData,
            })}
          >
            <Grid container alignItems="center" direction="row">
              <Typography variant="body2" className={classes.subheader}>
                {applianceName}
              </Typography>
              {hasExtraData && (
                <Grid container direction="row" spacing={2}>
                  {presetName && (
                    <Grid item>
                      <Typography
                        className={classes.settingHeader}
                        variant="body2"
                        color="textSecondary"
                      >
                        Preset
                      </Typography>
                      <Typography className={classes.settingValue}>
                        {presetName}
                      </Typography>
                    </Grid>
                  )}
                  {settingName && (
                    <Grid item>
                      <Typography className={classes.settingHeader}>
                        {settingHeader}
                      </Typography>
                      <Typography className={classes.settingValue}>
                        {settingName}
                      </Typography>
                    </Grid>
                  )}
                  {setting2Name && (
                    <Grid item>
                      <Typography className={classes.settingHeader}>
                        {setting2Header}
                      </Typography>
                      <Typography className={classes.settingValue}>
                        {setting2Name}
                      </Typography>
                    </Grid>
                  )}

                  {setting3Name && (
                    <Grid item>
                      <Typography className={classes.settingHeader}>
                        {setting3Header}
                      </Typography>
                      <Typography className={classes.settingValue}>
                        {setting3Name}
                      </Typography>
                    </Grid>
                  )}

                  {setting4Name && (
                    <Grid item>
                      <Typography className={classes.settingHeader}>
                        {setting4Header}
                      </Typography>
                      <Typography className={classes.settingValue}>
                        {setting4Name}
                      </Typography>
                    </Grid>
                  )}

                  {attachmentName && (
                    <Grid item>
                      <Typography className={classes.settingHeader}>
                        Attachment
                      </Typography>
                      <Typography className={classes.settingValue}>
                        {attachmentName}
                      </Typography>
                    </Grid>
                  )}
                  {temperatureString && (
                    <Grid item>
                      <Typography className={classes.settingHeader}>
                        Temperature
                      </Typography>
                      <Typography className={classes.settingValue}>
                        {temperatureString}
                      </Typography>
                    </Grid>
                  )}
                  {applianceVesselFlagsWithStepValue?.map(
                    (flag: FrescoVesselFlagStepValue) => (
                      <Grid item key={flag.key}>
                        <Typography className={classes.settingHeader}>
                          {flag.name}
                        </Typography>
                        <Typography className={classes.settingValue}>
                          {flag.value ? 'Yes' : 'No'}
                        </Typography>
                      </Grid>
                    )
                  )}
                  {timeSetting && (
                    <Grid item>
                      <Typography className={classes.settingHeader}>
                        Natural venting time
                      </Typography>
                      <Typography className={classes.settingValue}>
                        {toHHHMMSS(timeSetting)}
                      </Typography>
                    </Grid>
                  )}
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }
);
