import { datadogLogs } from '@datadog/browser-logs';
import * as Sentry from '@sentry/react';
import camelcaseKeys from 'camelcase-keys';

import type {
  ApiResponseRequestVariant,
  ApiContext,
  ErrorResponse,
  ErrorResponseDetails,
  ErrorPayload,
  ApiResponse,
} from 'api/types';
import { HttpMethod, ErrorLevel } from 'api/types';
import { appConfig } from 'shared/config';
import { acceptHeader, defaultLocale } from 'shared/constants';
import type { AcceptLanguageLocale } from 'shared/types/i18n';
import { getErrorString } from 'shared/utils/common';

const tryParseJson = <T>(responseText: string): T | undefined => {
  try {
    return JSON.parse(responseText);
  } catch {
    return undefined;
  }
};

type MergeDataFromHeadersMap<T> = {
  [propertyName in keyof T]: string;
};

export interface FetchJsonParams<T> {
  locale?: AcceptLanguageLocale;
  url: string;
  body?: URLSearchParams | unknown;
  httpMethod?: HttpMethod;
  responseVariant?: ApiResponseRequestVariant;
  requestVariant?: ApiResponseRequestVariant;
  mergeDataFromHeadersMap?: MergeDataFromHeadersMap<T>;
  apiContext: ApiContext;
  signal?: AbortSignal;
}

/**
 * Sends HTTP request with `Accept: application/json`
 *
 * - if `body` is not passed - sends GET HTTP
 * - if `body` is `URLSearchParams` - sends POST with `Content-Type: application/x-www-form-urlencoded`
 * - if `body` is `FormData` - sends POST with `Content-Type: multipart/form-data`
 * - otherwise sends POST as `JSON.stringify(body)` with `Content-Type: application/json`
 */
export const fetchJson = async <TResponse>({
  locale,
  url,
  body,
  httpMethod,
  responseVariant,
  requestVariant,
  mergeDataFromHeadersMap,
  apiContext,
  signal,
}: FetchJsonParams<TResponse>): Promise<ApiResponse<TResponse>> => {
  let response: Response | undefined;
  const method = httpMethod || (body ? HttpMethod.Post : HttpMethod.Get);
  const requestContentType = requestVariant
    ? `${acceptHeader}.${requestVariant}+json`
    : getContentType(body);

  const handleError = (
    errorDetails: ErrorResponseDetails,
    responseText?: string
  ): ErrorResponse => {
    const sentryExtraDetails = {
      errorDetails,
      url,
      method,
      responseStatusText: response?.statusText,
      responseStatus: response?.status,
      responseText,
    };
    const dataDogDetails = {
      ...sentryExtraDetails,
      env: appConfig.appEnv(),
      oid: apiContext.authData?.userOrganisationId || null,
      uid: apiContext.authData?.userId,
      requestBody: body,
    };

    if (errorDetails.details.level === ErrorLevel.Warning) {
      datadogLogs.logger.warn(`Request error ${url}`, {
        ...dataDogDetails,
      });

      Sentry.captureMessage(`HTTP request error ${url}`, {
        extra: sentryExtraDetails,
        level: Sentry.Severity.Warning,
      });

      return {
        ...errorDetails,
        ok: false,
      };
    }

    datadogLogs.logger.error(`Request error ${url}`, {
      ...dataDogDetails,
    });

    Sentry.captureMessage(`HTTP request error ${url}`, {
      extra: sentryExtraDetails,
      level: Sentry.Severity.Error,
    });

    return {
      ...errorDetails,
      ok: false,
    };
  };

  try {
    response = await fetch(url, {
      method,
      body: getBody(body),
      headers: {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        Accept: responseVariant
          ? `${acceptHeader}.${responseVariant}+json`
          : 'application/json',
        'Accept-Language': locale || defaultLocale,
        ...(apiContext.authData && {
          // eslint-disable-next-line @typescript-eslint/naming-convention
          Authorization: `Bearer ${apiContext.authData.token}`,
        }),
        ...(requestContentType && {
          'Content-Type': requestContentType,
        }),
      },
      signal,
    });
  } catch (e) {
    return handleError({
      details: {
        message: getErrorString(e),
        level: ErrorLevel.Error,
      },
    });
  }

  const responseText = await response.text();

  if (!response.ok) {
    const responseJson = tryParseJson<ErrorPayload>(responseText);
    const errorData = responseJson
      ? camelcaseKeys(responseJson)
      : ({} as ErrorPayload);
    return handleError(
      {
        httpStatus: response.status,
        details: {
          code: response.status,
          ...errorData,
          message:
            errorData?.message ||
            errorData?.errorDescription ||
            errorData?.error ||
            'Something went wrong',
          level: getErrorLogLevel(errorData?.code),
        },
      },
      responseText
    );
  }

  const responseJson = tryParseJson<TResponse>(responseText);
  let data = responseJson
    ? camelcaseKeys(responseJson, {
        deep: true,
        // Presets have GUID properties names + locales have xx_XX format
        exclude: [/.{8}-.{4}-.{4}-.{4}-.{12}/, /[a-z][a-z]_[A-Z][A-Z]/],
      })
    : ({} as TResponse);
  if (mergeDataFromHeadersMap) {
    data = {
      ...data,
      ...getDataFromHeaders(response.headers, mergeDataFromHeadersMap),
    };
  }
  datadogLogs.logger.info(`Request success ${url}`, {
    url,
    method,
    responseStatusText: response?.statusText,
    responseStatus: response?.status,
    env: appConfig.appEnv(),
    oid: apiContext.authData?.userOrganisationId || null,
    uid: apiContext.authData?.userId,
  });

  return {
    data,
    ok: true,
  };
};

const getErrorLogLevel = (code: number | undefined) =>
  // https://docs.kitchenos.com/docs/api-concepts#errors
  // http status, code, reason
  // 401, 601, 'Missing token'
  // 401, 602, 'Invalid token'
  // 401, 603, 'Expired token'
  // 401, 604, 'Revoked token'
  code && code > 600 && code < 605 ? ErrorLevel.Warning : ErrorLevel.Error;

function getBody(body: unknown): string | URLSearchParams | FormData {
  return body instanceof URLSearchParams || body instanceof FormData
    ? body
    : JSON.stringify(body);
}

function getDataFromHeaders<T extends { [key: string]: unknown }>(
  headers: Headers,
  mergeDataFromHeadersMap: MergeDataFromHeadersMap<T>
): { [key in keyof T]: string | null } {
  const data = {} as { [key in keyof T]: string | null };
  for (const key of Object.keys(mergeDataFromHeadersMap)) {
    data[key as keyof T] = headers.get(mergeDataFromHeadersMap[key]);
  }
  return data;
}

function getContentType(body: unknown): string | undefined {
  if (
    body &&
    !(body instanceof URLSearchParams) &&
    !(body instanceof FormData)
  ) {
    return 'application/json';
  }
  return undefined;
}
