import { makeStyles, Dialog, Button } from '@material-ui/core';
import type { CSSProperties } from '@material-ui/core/styles/withStyles';
import PlayCircleFilledIcon from '@material-ui/icons/PlayCircleFilled';
import React, { useState, useCallback } from 'react';

import type { FrescoVideo, FrescoMedia } from 'shared/types/media';

export const tipMediaLargeViewAriaLabel = 'Tip media large view';
export const tipMediaThumbnailAriaLabel = 'Tip media thumbnail';
export const tipVideoThumbnailPlayIconTitle = 'Play tip video';

const useStyles = makeStyles((theme) => ({
  mediaContainer: {
    cursor: 'pointer',
    height: theme.spacing(14),
    width: theme.spacing(14),
    padding: 0,
    flexShrink: 0,
    '& img': {
      height: '100%',
      width: '100%',
    } as CSSProperties,
    position: 'relative',
    transition: 'transform 0.1s',
    '&:hover': {
      transform: 'scale(1.05)',
    } as CSSProperties,
  },
  playButtonContainer: {
    display: 'flex',
    position: 'absolute',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    width: '100%',
    '& svg': {
      color: 'white',
      opacity: 0.2,
      transition: 'transform 0.1s, opacity 0.1s',
      height: theme.spacing(5),
      width: theme.spacing(5),
    } as CSSProperties,
    '&:hover svg': {
      opacity: 0.6,
      transform: 'scale(1.05)',
    } as CSSProperties,
  },
  viewDialog: {
    '& video': {
      outline: 'none',
    } as CSSProperties,
    '& img': {
      maxWidth: '100%',
      height: 'auto',
    } as CSSProperties,
  },
}));

interface StepTipMediaProps {
  image?: FrescoMedia | null;
  video?: FrescoVideo | null;
}

export const StepTipMedia: React.FC<StepTipMediaProps> = React.memo(
  function StepTipMedia({ image, video }) {
    const classes = useStyles();
    const [isViewOpen, setIsViewOpen] = useState(false);
    const handleClick = useCallback(() => {
      setIsViewOpen(true);
    }, []);
    const handleClose = useCallback(() => {
      setIsViewOpen(false);
    }, []);

    const previewImage = video?.image || video || image;
    if (!previewImage) {
      return null;
    }

    return (
      <>
        <Button className={classes.mediaContainer} onClick={handleClick}>
          {video && (
            <div className={classes.playButtonContainer}>
              <PlayCircleFilledIcon
                aria-label={tipVideoThumbnailPlayIconTitle}
              />
            </div>
          )}
          <img src={previewImage.data.uri} alt={tipMediaThumbnailAriaLabel} />
        </Button>
        <Dialog
          open={isViewOpen}
          onClose={handleClose}
          className={classes.viewDialog}
        >
          {video && (
            // eslint-disable-next-line jsx-a11y/media-has-caption
            <video
              src={video.data.uri}
              datatype={video.data.mimetype}
              aria-label={tipMediaLargeViewAriaLabel}
              controls
            />
          )}
          {image && (
            <img
              src={image.data.uri}
              alt={tipMediaLargeViewAriaLabel}
              style={{ width: image.data.width }}
            />
          )}
        </Dialog>
      </>
    );
  }
);
