import { Box, Container, Grid } from '@material-ui/core';
import { useEffect, memo } from 'react';
import type { FC } from 'react';
import { useDispatch } from 'react-redux';

import { AreaCard } from 'features/homepage/AreaCard';
import { layoutHeaderTitleSet } from 'features/layout/layoutSlice';
import { appConfig } from 'shared/config';
import type { PermissionsCheckRuleSet } from 'shared/permissions';
import {
  adminAccessGroups,
  translationAccessGroups,
  recipeAccessGroups,
  machinaAccessGroups,
} from 'shared/permissions';

export interface LinkArea {
  name: string;
  description: string;
  button: string;
  link: string;
  accessGroups: PermissionsCheckRuleSet;
}

const isProduction = appConfig.appEnv() === 'production';
const enviromentUrl =
  appConfig.appEnv() === 'development' ? '' : `-${appConfig.appEnv()}`;

export const areas: LinkArea[] = [
  {
    name: 'Admin',
    description: 'Fresco Tool for admin Management',
    button: 'Open Admin',
    link: isProduction
      ? 'https://admin.getdrop.io/'
      : `https://admin${enviromentUrl}.getdrop.io/`,
    accessGroups: adminAccessGroups,
  },
  {
    name: 'Creator',
    description: 'Fresco Tool for recipe entry',
    button: 'Open Creator',
    link: '/recipes',
    accessGroups: recipeAccessGroups,
  },
  {
    name: 'Babel',
    description: 'Fresco Tool for recipe translation',
    button: 'Open Babel',
    link: 'translate/recipes',
    accessGroups: translationAccessGroups,
  },
  {
    name: 'Machina',
    description: 'Fresco Tool for appliance management',
    button: 'Open Machina',
    link: isProduction
      ? 'https://machina.getdrop.io/'
      : `https://machina${enviromentUrl}.getdrop.io/`,
    accessGroups: machinaAccessGroups,
  },
];

export const HomePage: FC = memo(function HomePage() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(layoutHeaderTitleSet(''));
  }, [dispatch]);

  return (
    <Box pb={2} mt={6}>
      <Container maxWidth="md">
        <Grid spacing={4} container direction="row">
          {areas.map((area) => (
            <AreaCard key={area.link} area={area} />
          ))}
        </Grid>
      </Container>
    </Box>
  );
});
