import { Grid } from '@material-ui/core';
import { memo } from 'react';
import type { FC } from 'react';

import { StepAction as StepActionLayout } from 'shared/components/stepViewEntities/StepAction';
import type { FrescoStepAction } from 'shared/types/step';

interface StepActionProps {
  action: FrescoStepAction | null;
}

export const StepAction: FC<StepActionProps> = memo(function StepAction({
  action,
}) {
  if (!action) {
    return null;
  }

  return (
    <Grid item xs={4}>
      <StepActionLayout showHeader actionName={action.name} />
    </Grid>
  );
});
