import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

import type { RootState } from 'app/rootReducer';
import { logoutSuccess } from 'features/login/loginSlice';
import type {
  ApiJob,
  ApiTranslationsJobResult,
  GetJobRequest,
} from 'shared/types/job';

interface JobState {
  isFetched: boolean;
  isFetching: boolean;
  isFinished: boolean;
  error?: string;
  job?: ApiJob;
}

const initialState: JobState = {
  isFetched: false,
  isFetching: false,
  isFinished: false,
};

const jobSlice = createSlice({
  name: 'jobSlice',
  initialState,
  reducers: {
    jobFetchFailed(state, { payload }: PayloadAction<string>) {
      state.error = payload;
      state.isFetched = false;
      state.isFetching = false;
      state.job = undefined;
    },
    jobFetchFinished(state, { payload }: PayloadAction<ApiJob>) {
      state.job = payload;
      state.isFetched = true;
      state.isFetching = false;
    },
    jobFetchRequested(state, _: PayloadAction<GetJobRequest>) {
      state.isFetched = false;
      state.isFetching = true;
      state.job = undefined;
      state.error = undefined;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(logoutSuccess, () => initialState);
  },
});

export const {
  reducer: jobReducer,
  actions: { jobFetchFailed, jobFetchFinished, jobFetchRequested },
} = jobSlice;

export const selectJobResult = (
  state: RootState
): ApiTranslationsJobResult | undefined => state.job.job?.result;
