import type { EntityId, PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

import type { RootState } from 'app/rootReducer';
import { logoutSuccess } from 'features/login/loginSlice';
import { recipeFetchRequested } from 'features/recipe/recipeSlice/recipeActions';

export enum RecipeEditEntityType {
  Container = 'container',
  Ingredient = 'ingredient',
  Step = 'Step',
  Recipe = 'Recipe',
  Author = 'Author',
}

export enum RecipeSplitEntityType {
  Ingredient = 'ingredient',
}

export enum RecipeEditMode {
  Create,
  Edit,
  Closed,
}

export enum RecipeSplitMode {
  Closed,
  Split,
}
export interface EditState {
  editEntityId?: EntityId;
  splitEntityId?: EntityId;
  editEntityType?: RecipeEditEntityType;
  splitEntityType?: RecipeSplitEntityType;
  createAtPosition?: number;
  isCreating?: boolean;
  isSplitPending?: boolean;
}

export const initialState: EditState = {};

const editSlice = createSlice({
  name: 'recipe/editSlice',
  initialState,
  reducers: {
    recipeEditEntityRequested: (
      state,
      {
        payload: { editEntityId, editEntityType },
      }: PayloadAction<{
        editEntityId: EntityId;
        editEntityType: RecipeEditEntityType;
      }>
    ) => ({
      ...state,
      editEntityId,
      editEntityType,
      createAtPosition: undefined,
    }),
    recipeEditEntityFinished: (state) => ({
      ...state,
      editEntityId: undefined,
      editEntityType: undefined,
      createAtPosition: undefined,
    }),
    recipeSplitEntityRequested: (
      state,
      {
        payload: { splitEntityId, splitEntityType },
      }: PayloadAction<{
        splitEntityId: EntityId;
        splitEntityType: RecipeSplitEntityType;
      }>
    ) => ({
      ...state,
      splitEntityId,
      splitEntityType,
    }),
    recipeSplitEntity: (state) => ({
      ...state,
      isSplitPending: true,
    }),
    recipeSplitEntityFinished: (state) => ({
      ...state,
      splitEntityId: undefined,
      splitEntityType: undefined,
      isSplitPending: false,
    }),
    recipeSplitEntityFailed: (state) => ({
      ...state,
      isSplitPending: false,
    }),
    recipeCreateEntityRequested: (
      state,
      {
        payload: { editEntityType, position },
      }: PayloadAction<{
        editEntityType: RecipeEditEntityType;
        position?: number;
      }>
    ) => ({
      editEntityId: undefined,
      editEntityType,
      createAtPosition: position,
    }),
    recipeCreateRequested: (
      _state,
      {
        payload: { editEntityType },
      }: PayloadAction<{ editEntityType: RecipeEditEntityType }>
    ) => ({
      editEntityType,
      isCreating: true,
    }),
    recipeCreateFailed: (
      _state,
      {
        payload: { editEntityType },
      }: PayloadAction<{ editEntityType: RecipeEditEntityType }>
    ) => ({
      editEntityType,
      isCreating: false,
    }),
    recipeCreateSuccess: (
      _state,
      {
        payload: { editEntityType },
      }: PayloadAction<{ editEntityType: RecipeEditEntityType }>
    ) => ({
      editEntityType,
      isCreating: false,
    }),
  },
  extraReducers: (builder) => {
    builder.addCase(recipeFetchRequested, () => initialState);
    builder.addCase(logoutSuccess, () => initialState);
  },
});

export const {
  reducer: editReducer,
  actions: {
    recipeCreateEntityRequested,
    recipeEditEntityRequested,
    recipeSplitEntityRequested,
    recipeSplitEntity,
    recipeSplitEntityFinished,
    recipeSplitEntityFailed,
    recipeEditEntityFinished,
    recipeCreateRequested,
    recipeCreateFailed,
    recipeCreateSuccess,
  },
} = editSlice;

export const selectIsRecipeCreating = (state: RootState): boolean | undefined =>
  state.recipe.edit.isCreating;

export const isSplitPending = (state: RootState): boolean | undefined =>
  state.recipe.edit.isSplitPending;

export const selectRecipeEditEntityType = (state: RootState) =>
  state.recipe.edit.editEntityType;

export const selectRecipeSplitEntityType = (state: RootState) =>
  state.recipe.edit.splitEntityType;

export const selectRecipeEditEntityId = (state: RootState) =>
  state.recipe.edit.editEntityId;

export const selectRecipeSplitEntityId = (state: RootState) =>
  state.recipe.edit.splitEntityId;

export const selectRecipeCreateEntityPosition = (state: RootState) =>
  state.recipe.edit.createAtPosition;

export const selectIsSideBarOpen = (state: RootState): boolean => {
  if (
    selectRecipeEditMode(state) !== RecipeEditMode.Closed ||
    selectRecipeSplitMode(state) !== RecipeSplitMode.Closed
  ) {
    return true;
  }

  return false;
};

export const selectRecipeEditMode = (state: RootState): RecipeEditMode => {
  if (selectRecipeEditEntityId(state)) {
    return RecipeEditMode.Edit;
  }

  if (selectRecipeEditEntityType(state)) {
    return RecipeEditMode.Create;
  }

  return RecipeEditMode.Closed;
};

export const selectRecipeSplitMode = (state: RootState): RecipeSplitMode => {
  if (selectRecipeSplitEntityId(state)) {
    return RecipeSplitMode.Split;
  }

  return RecipeSplitMode.Closed;
};
