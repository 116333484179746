import { Grid, makeStyles, Typography } from '@material-ui/core';
import TimerIcon from '@material-ui/icons/Timer';
import React from 'react';

import { toHHHMMSS } from 'shared/utils/getTimes';

interface StepApplianceProps {
  time: number | null;
}

const useStyles = makeStyles((theme) => ({
  icon: {
    paddingRight: theme.spacing(1),
  },
  subheader: {
    ...theme.typography.overline,
    transform: 'uppercase',
  },
  stepEntityBackground: {
    backgroundColor: '#ffffff',
    padding: theme.spacing(1),
    minHeight: 58,
  },
  bold: {
    fontSize: 16,
    fontWeight: 'bold',
  },
}));

export const StepTimer: React.FC<StepApplianceProps> = React.memo(
  function StepTimer({ time }) {
    const classes = useStyles();

    if (!time) {
      return null;
    }

    return (
      <Grid item xs={4}>
        <Typography className={classes.subheader}>Timer</Typography>
        <Grid
          className={classes.stepEntityBackground}
          container
          alignItems="center"
          direction="row"
        >
          <Grid className={classes.icon} item>
            <TimerIcon color="primary" />
          </Grid>
          <Grid item>
            <Typography className={classes.bold} variant="body2">
              {toHHHMMSS(time)}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    );
  }
);
