import { fetchJson } from 'api/fetchJson';
import type { ApiRequestFn } from 'api/types';
import { appConfig } from 'shared/config';
import type { OrganisationId } from 'shared/constants';
import {
  getCurrentOrganizationClientId,
  getCurrentOrganizationId,
} from 'shared/constants';
import type { FrescoUser } from 'shared/types/user';

const getAuthApiUrl = (organisation: OrganisationId): string =>
  `${appConfig.dropApi()}${appConfig.dropApiPrefix()}/organizations/${organisation}/auth`;
export const getAuthApiTokenEndpoint = (organisation: OrganisationId): string =>
  `${getAuthApiUrl(organisation)}/token`;
export const getAuthApiRevokeEndpoint = (
  organisation: OrganisationId
): string => `${getAuthApiUrl(organisation)}/revoke`;
export const authApiGrantType = 'password';
export const authApiRefreshGrantType = 'refresh_token';

export interface ApiLoginRequest {
  email: string;
  password: string;
  clientId: string;
}

export interface ApiLoginResponse {
  expiresIn: number;
  refreshToken: string;
  accessToken: string;
  user: FrescoUser;
}

export const apiLogin =
  ({
    email,
    password,
    clientId,
  }: ApiLoginRequest): ApiRequestFn<ApiLoginResponse> =>
  (apiContext) => {
    const body = new URLSearchParams();
    body.append('username', email);
    body.append('password', password);
    body.append('grant_type', authApiGrantType);
    body.append('client_id', clientId);

    return fetchJson<ApiLoginResponse>({
      apiContext,
      url: getAuthApiTokenEndpoint(getCurrentOrganizationId()),
      body,
    });
  };

export interface ApiRefreshTokenRequest {
  refreshToken: string;
  orgId: OrganisationId;
}

export const apiRefreshToken =
  ({
    refreshToken,
    orgId,
  }: ApiRefreshTokenRequest): ApiRequestFn<ApiLoginResponse> =>
  (apiContext) => {
    const body = new URLSearchParams();
    body.append('refresh_token', refreshToken);
    body.append('grant_type', authApiRefreshGrantType);
    body.append('client_id', getCurrentOrganizationClientId());

    return fetchJson<ApiLoginResponse>({
      apiContext,
      url: getAuthApiTokenEndpoint(orgId),
      body,
    });
  };

interface ApiRevokeTokenRequest {
  token: string;
  orgId: OrganisationId;
}

export const apiRevokeToken =
  ({ token, orgId }: ApiRevokeTokenRequest): ApiRequestFn<void> =>
  (apiContext) => {
    const body = new URLSearchParams();
    body.append('token', token);
    body.append('token_type_hint', 'access_token');
    body.append('client_id', getCurrentOrganizationClientId());

    return fetchJson({
      apiContext,
      url: getAuthApiRevokeEndpoint(orgId),
      body,
    });
  };
