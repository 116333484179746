import { useTheme, IconButton, makeStyles } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import React from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

import { featureFlagsDeveloperMenuOpenUpdated } from 'features/developerMenu/featureFlagsSlice';
import { drawerOpenUpdated } from 'features/layout/layoutSlice';
import { ReactComponent as FrescoLogo } from 'logos/fresco.svg';
import { appConfig } from 'shared/config';

const useStyles = makeStyles((theme) => ({
  devMenu: {
    width: 50,
    height: 50,
  },
  appNameLink: {
    color: theme.palette.primary.contrastText,
    textDecoration: 'none',
  },
  logo: {
    marginLeft: theme.spacing(2),
  },
}));

export const LogoTitleBar: React.FC = React.memo(function LogoTitleBar() {
  const theme = useTheme();
  const classes = useStyles();
  const dispatch = useDispatch();

  return (
    <>
      {appConfig.appBackstage() && (
        <IconButton
          color="inherit"
          onClick={() => dispatch(drawerOpenUpdated(true))}
        >
          <MenuIcon />
        </IconButton>
      )}
      <Link className={classes.appNameLink} to="/">
        <FrescoLogo
          className={classes.logo}
          width="75px"
          fill={theme.palette.background.default}
        />
      </Link>
      <div
        onDoubleClick={(event) => {
          if (event.altKey) {
            dispatch(featureFlagsDeveloperMenuOpenUpdated(true));
          }
        }}
        className={classes.devMenu}
      />
    </>
  );
});
