import {
  Grid,
  TextField,
  Fab,
  Typography,
  makeStyles,
  CircularProgress,
} from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';
import Big from 'big.js';
import isNull from 'lodash/isNull';
import round from 'lodash/round';
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { EditSidebarActions } from 'features/recipe/edit/EditSidebarActions';
import { EditSidebarContent } from 'features/recipe/edit/EditSidebarContent';
import { EditSidebarForm } from 'features/recipe/edit/EditSidebarForm';
import { EditSidebarTitle } from 'features/recipe/edit/EditSidebarTitle';
import {
  recipeSplitEntityFinished,
  selectRecipeSplitEntityId,
  isSplitPending,
} from 'features/recipe/edit/editSlice';
import {
  selectIngredientById,
  ingredientSplitRequested,
} from 'features/recipe/ingredients/ingredientsSlice';

const useStyles = makeStyles((theme) => ({
  sentence: {
    paddingBottom: theme.spacing(3),
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
}));

export const IngredientSplitForm: React.FC = React.memo(
  function IngredientSplitForm() {
    const classes = useStyles();
    const dispatch = useDispatch();

    const editingEnityId = useSelector(selectRecipeSplitEntityId);
    const editEntity = useSelector(selectIngredientById(editingEnityId ?? ''));

    const isSplitting = useSelector(isSplitPending);

    const [currentIngredientAmount, setCurrentIngredientAmount] = useState<
      number | null
    >();
    const [newIngredientAmount, setNewIngredientAmount] = useState<number>(0);

    useEffect(() => {
      if (editEntity?.amount) {
        setCurrentIngredientAmount(editEntity?.amount);
      }
    }, [editEntity]);

    if (!editEntity?.amount) {
      return null;
    }

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      if (!editingEnityId || !newIngredientAmount || !currentIngredientAmount) {
        return;
      }

      dispatch(
        ingredientSplitRequested({
          id: editEntity.id,
          newAmount: currentIngredientAmount,
        })
      );
    };

    return (
      <EditSidebarForm aria-label="Ingredient form" onSubmit={handleSubmit}>
        <EditSidebarTitle
          text="Split Ingredient"
          onClosePayloadAction={recipeSplitEntityFinished()}
        />
        <EditSidebarContent>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField
                label="Ingredient"
                id="Ingredient"
                variant="outlined"
                fullWidth
                disabled
                value={editEntity?.ingredient.name || ''}
              />
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={1} direction="row">
                <Grid item xs={6}>
                  <Typography className={classes.sentence}>to</Typography>
                  <TextField
                    autoFocus
                    type="number"
                    label="Quantity"
                    id="quantity"
                    variant="outlined"
                    fullWidth
                    required
                    value={
                      !isNull(currentIngredientAmount)
                        ? currentIngredientAmount
                        : ''
                    }
                    onChange={(event) => {
                      if (
                        !Number.isNaN(parseFloat(event.target.value)) &&
                        editEntity.amount
                      ) {
                        const value = round(parseFloat(event.target.value), 2);
                        setCurrentIngredientAmount(value);
                        setNewIngredientAmount(
                          new Big(editEntity.amount).minus(value).toNumber()
                        );
                        return;
                      }
                      setCurrentIngredientAmount(null);
                      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                      setNewIngredientAmount(editEntity.amount!);
                    }}
                    InputProps={{
                      endAdornment: (
                        <TextField
                          inputProps={{ 'data-testid': 'unit' }}
                          disabled
                          value={editEntity?.units || ''}
                        />
                      ),
                      inputProps: {
                        min: 0,
                        max: editEntity.amount,
                        step: 'any',
                      },
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Typography className={classes.sentence}>and</Typography>
                  <TextField
                    type="number"
                    label="Quantity"
                    id="splitquantity"
                    variant="outlined"
                    fullWidth
                    disabled
                    value={newIngredientAmount}
                    InputProps={{
                      endAdornment: (
                        <TextField
                          disabled
                          inputProps={{ 'data-testid': 'unit' }}
                          value={editEntity?.units || ''}
                        />
                      ),
                      inputProps: { min: 0, step: 'any' },
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </EditSidebarContent>
        <EditSidebarActions>
          <Fab type="submit" color="primary" variant="extended">
            {isSplitting ? (
              <CircularProgress color="secondary" />
            ) : (
              <SaveIcon className={classes.extendedIcon} />
            )}
            Split
          </Fab>
        </EditSidebarActions>
      </EditSidebarForm>
    );
  }
);
