import type { Color } from '@material-ui/lab/Alert';
import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

import type { RootState } from 'app/rootReducer';

interface SnackbarNotificationKey {
  key: string;
}

interface SnackbarState extends SnackbarNotificationKey {
  dismissed?: boolean;
  severity: Color;
  text: string;
}

let snackbarKey = 0;
export const initialState: SnackbarState[] = [];

const snackbarSlice = createSlice({
  name: 'snackbarSlice',
  initialState,
  reducers: {
    snackbarOpen(
      state,
      {
        payload,
      }: PayloadAction<
        Omit<SnackbarState, 'key'> & Partial<SnackbarNotificationKey>
      >
    ) {
      state.push({
        ...payload,
        key: payload.key || (snackbarKey++).toString(),
      });
    },
    snackbarDismissed(
      state,
      { payload }: PayloadAction<SnackbarNotificationKey>
    ) {
      const notification = state.find((x) => x.key === payload.key);
      if (notification) {
        notification.dismissed = true;
      }
    },
    snackbarRemoved(
      state,
      { payload }: PayloadAction<SnackbarNotificationKey>
    ) {
      const idx = state.findIndex((x) => x.key === payload.key);
      state.splice(idx, 1);
    },
  },
});

export const {
  reducer: snackbarReducer,
  actions: { snackbarOpen, snackbarDismissed, snackbarRemoved },
} = snackbarSlice;

export const selectSnackbar = (state: RootState): SnackbarState[] =>
  state.snackbar;
