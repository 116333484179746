import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice, createSelector } from '@reduxjs/toolkit';

import type { RootState } from 'app/rootReducer';
import {
  logoutSuccess,
  selectLoginAuthDataUserGroups,
} from 'features/login/loginSlice';
import type { UserLocaleRoles } from 'shared/types/i18n';
import { userAccessLocales, ApiLocale } from 'shared/types/i18n';

export interface LocaleState {
  currentLocale: ApiLocale;
}

export const initialState: LocaleState = {
  currentLocale: ApiLocale.EnUS,
};

const localeSlice = createSlice({
  name: 'translation/localeSlice',
  initialState,
  reducers: {
    localeEditRequested(state, { payload }: PayloadAction<ApiLocale>) {
      state.currentLocale = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(logoutSuccess, () => initialState);
  },
});

export const {
  reducer: localeReducer,
  actions: { localeEditRequested },
} = localeSlice;

export const selectCurrentLocale = (state: RootState): ApiLocale =>
  state.translation.locale.currentLocale;

export const selectUserLocales = () =>
  createSelector(selectLoginAuthDataUserGroups, (userGroups) =>
    userGroups
      ?.filter((group) => userAccessLocales[group.name as UserLocaleRoles])
      .map((userRole) => userAccessLocales[userRole.name as UserLocaleRoles])
  );
