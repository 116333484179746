import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice, createAction } from '@reduxjs/toolkit';
import { put, call, takeLatest } from 'redux-saga/effects';

import { createRequestApiSaga } from 'api/createRequestApiSaga';
import { apiActionTermsGet } from 'api/terms';
import type { FrescoPaginatedResponse, ApiResponse } from 'api/types';
import type { RootState } from 'app/rootReducer';
import { logoutSuccess } from 'features/login/loginSlice';
import type { FrescoStepAction } from 'shared/types/step';
import { getErrorString } from 'shared/utils/common';

interface TermsActionsState {
  apiError?: string;
  apiPending: boolean;
  entities: FrescoStepAction[];
}

export const initialState: TermsActionsState = {
  apiPending: false,
  entities: [],
};

const termsActionsSlice = createSlice({
  name: 'termsActionsSlice',
  initialState,
  reducers: {
    termsActionsSuccess(state, { payload }: PayloadAction<FrescoStepAction[]>) {
      state.entities = payload;
      state.apiPending = false;
      state.apiError = undefined;
    },
    termsActionsApiPending(state) {
      state.apiPending = true;
      state.apiError = undefined;
    },
    termsActionsApiError(state, { payload }: PayloadAction<string>) {
      state.apiPending = false;
      state.apiError = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(logoutSuccess, () => initialState);
  },
});

export const {
  reducer: termsActionsReducer,
  actions: {
    termsActionsSuccess,
    termsActionsApiPending,
    termsActionsApiError,
  },
} = termsActionsSlice;

const selectTermsActionsState = (state: RootState): TermsActionsState =>
  state.terms.actions;

export const selectTermsActionsEntities = (
  state: RootState
): FrescoStepAction[] => selectTermsActionsState(state).entities;

export const selectTermsActionsApiPending = (state: RootState): boolean =>
  selectTermsActionsState(state).apiPending;

export const selectTermsActionsApiError = (
  state: RootState
): string | undefined => selectTermsActionsState(state).apiError;

export const apiGetActionTermsSaga = createRequestApiSaga(
  apiActionTermsGet,
  'Actions loading'
);

export function* requestActionTerms() {
  try {
    yield put(termsActionsApiPending());
    const response: ApiResponse<FrescoPaginatedResponse<FrescoStepAction>> =
      yield call(apiGetActionTermsSaga);
    if (!response.ok) {
      yield put(termsActionsApiError(response.details.message));
      return;
    }
    yield put(termsActionsSuccess(response.data.results));
    return;
  } catch (e) {
    yield put(termsActionsApiError(getErrorString(e)));
  }
}

export const actionTermsFetchRequested = createAction(
  'terms/actionTermsFetchRequested'
);

export function* requestActionTermsFetchWatcher() {
  yield takeLatest(actionTermsFetchRequested, requestActionTerms);
}

export const actionTermsSagas = [requestActionTermsFetchWatcher];
