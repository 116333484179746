import { SignOutIcon } from '@dropkitchen/icons-react';
import {
  AppBar,
  Toolbar,
  IconButton,
  Tooltip,
  Container,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import type { FC } from 'react';
import { memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { DeveloperMenu } from 'features/developerMenu/DeveloperMenu';
import { selectDeveloperMenuOpen } from 'features/developerMenu/featureFlagsSlice';
import { NavigationDrawer } from 'features/layout/NavigationDrawer';
import { HeaderTitle } from 'features/layout/headerTitle/HeaderTitle';
import { selectOrgLogo } from 'features/layout/layoutSlice';
import { LogoTitleBar } from 'features/layout/logoTitleBar/LogoTitleBar';
import { logoutRequested } from 'features/login/loginSlice';
import { primaryAppBarHeaderHeight } from 'shared/constants';

const useStyles = makeStyles((theme) => ({
  header: {
    height: `${theme.spacing(primaryAppBarHeaderHeight)}px`,
  },
  logo: {
    width: 75,
  },
}));

export const PageLayout: FC = memo(function PageLayout({ children }) {
  const classes = useStyles();
  const dispatch = useDispatch();

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const LogoSvg = useSelector(selectOrgLogo)!;
  const showDeveloperMenu = useSelector(selectDeveloperMenuOpen);

  const handleLogoutClick = () => {
    dispatch(logoutRequested());
  };

  return (
    <>
      <AppBar color="secondary" position="sticky">
        <Toolbar className={classes.header}>
          <LogoTitleBar />
          <Container maxWidth="md">
            <HeaderTitle />
          </Container>

          <LogoSvg className={classes.logo} color="inherit" />
          <Tooltip title="Log out">
            <IconButton edge="end" color="inherit" onClick={handleLogoutClick}>
              <SignOutIcon />
            </IconButton>
          </Tooltip>
        </Toolbar>
      </AppBar>
      {showDeveloperMenu && <DeveloperMenu />}
      <NavigationDrawer />
      {children}
    </>
  );
});
