import values from 'lodash/values';
import type { FC } from 'react';
import { memo } from 'react';

import { StepSetting } from 'features/recipe/steps/stepFormEntities/StepSetting';
import type {
  FrescoAppliance,
  FrescoApplianceProfilePreset,
} from 'shared/types/appliance';
import type { FrescoStepSetting } from 'shared/types/step';

interface StepSettingsProps {
  stepTimeSetting: number | null;
  currentAppliance?: FrescoAppliance;
  currentPreset?: FrescoApplianceProfilePreset;
  stepSettings: (FrescoStepSetting | null)[];
  onStepSettingsChange: (
    action: FrescoStepSetting | null,
    index: number
  ) => void;
  onStepTimeSettingChange: (time: number | null) => void;
}

export const StepSettings: FC<StepSettingsProps> = memo(function StepSettings({
  stepTimeSetting,
  currentAppliance,
  currentPreset,
  stepSettings,
  onStepSettingsChange,
  onStepTimeSettingChange,
}) {
  const currentProfile = currentAppliance?.profile;
  const presets = values(currentProfile?.presets);
  const settings = currentPreset
    ? [
        currentPreset?.setting1 || null,
        currentPreset?.setting2 || null,
        currentPreset?.setting3 || null,
        currentPreset?.setting4 || null,
      ]
    : [
        currentProfile?.settings?.setting1 || null,
        currentProfile?.settings?.setting2 || null,
        currentProfile?.settings?.setting3 || null,
        currentProfile?.settings?.setting4 || null,
      ];

  return (
    <>
      {settings.map((setting, index) => {
        if (!setting) {
          return null;
        }
        return (
          <StepSetting
            key={`${setting.name}-${index}`}
            stepTimeSetting={stepTimeSetting}
            fullWidth={!!presets?.length}
            setting={setting}
            settingIndex={index}
            currentAppliance={currentAppliance}
            stepSettings={stepSettings}
            onStepSettingsChange={onStepSettingsChange}
            onStepTimeSettingChange={onStepTimeSettingChange}
          />
        );
      })}
    </>
  );
});
