import { makeStyles } from '@material-ui/core';
import type React from 'react';

const useStyles = makeStyles(() => ({
  form: { display: 'flex', flexDirection: 'column', height: '100%' },
}));

type EditSidebarFormProps = React.DetailedHTMLProps<
  React.FormHTMLAttributes<HTMLFormElement>,
  HTMLFormElement
>;

export const EditSidebarForm: React.FC<EditSidebarFormProps> = ({
  children,
  ...restProps
}) => {
  const classes = useStyles();
  return (
    <form {...restProps} className={classes.form} autoComplete="off">
      {children}
    </form>
  );
};
