import { Grid } from '@material-ui/core';
import { memo } from 'react';
import type { FC } from 'react';
import { useSelector } from 'react-redux';

import { selectCurrentLocale } from 'features/translation/localeSwitcher/localeSlice';
import { StepAction as StepActionLayout } from 'shared/components/stepViewEntities/StepAction';
import { noTranslationSentence } from 'shared/constants';
import type { FrescoStepActionI18n } from 'shared/types/i18n';
import { ApiLocale } from 'shared/types/i18n';
import { getIsTranslatableLocale } from 'shared/utils/translation';

interface StepActionProps {
  action: FrescoStepActionI18n | null;
}

export const StepAction: FC<StepActionProps> = memo(function StepAction({
  action,
}) {
  const locale = useSelector(selectCurrentLocale);

  if (!action) {
    return null;
  }

  return (
    <Grid item xs={4}>
      <StepActionLayout
        showHeader
        actionName={action.nameTranslations?.[ApiLocale.EnUS] || ''}
      />
      {getIsTranslatableLocale(locale) && (
        <StepActionLayout
          showHeader={false}
          actionName={
            action.nameTranslations?.[locale] ||
            `${noTranslationSentence} ${locale}`
          }
        />
      )}
    </Grid>
  );
});
