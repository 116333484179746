import { Grid, Paper, Typography, Button, makeStyles } from '@material-ui/core';
import { push } from 'connected-react-router';
import React from 'react';
import { useDispatch } from 'react-redux';

import type { LinkArea } from 'features/homepage/HomePage';
import { useSelectUserHasAccessGroupRights } from 'shared/hooks/useSelectUserHasAccessGroupRights';

const useStyles = makeStyles((theme) => ({
  row: {
    marginTop: theme.spacing(2),
  },
  card: {
    padding: theme.spacing(6),
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-around',
  },
  item: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(2),
  },
  description: {
    fontSize: '20px',
    color: '#645c5c',
    fontWeight: 'normal',
  },
}));

interface AreaCardProps {
  area: LinkArea;
}

export const AreaCard: React.FC<AreaCardProps> = React.memo(function AreaCard({
  area,
}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const userHasAccess = useSelectUserHasAccessGroupRights(area.accessGroups);

  const gotToLink = () => {
    if (area.link.includes('https://')) {
      window.open(area.link, '_blank');
      return;
    }
    dispatch(push(area.link));
  };

  if (!userHasAccess) {
    return null;
  }

  return (
    <Grid item sm={12} md={6}>
      <Paper className={classes.card}>
        <Grid className={classes.container}>
          <Grid item className={classes.item}>
            <Typography variant="h4">{area.name}</Typography>
          </Grid>
          <Grid item className={classes.item}>
            <Typography variant="body1">{area.description}</Typography>
          </Grid>
          <Button color="primary" variant="contained" onClick={gotToLink}>
            {area.button}
          </Button>
        </Grid>
      </Paper>
    </Grid>
  );
});
