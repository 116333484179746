import { Tooltip } from '@material-ui/core';
import type { TextFieldProps } from '@material-ui/core/TextField';
import TextField from '@material-ui/core/TextField';
import MaskedInput from 'react-text-mask';

import {
  toHHHMMSS,
  toHHHMM,
  toSecondsHHMMSS,
  toSecondsHHMM,
} from 'shared/utils/getTimes';

interface TimeInputProps extends Omit<TextFieldProps, 'onChange' | 'value'> {
  value?: number;
  onChange: (value: number) => void;
  includeSeconds?: boolean;
}

const hhmmMask = [/\d/, /\d/, /\d/, ':', /\d/, /\d/];
const hhmmssMask = [/\d/, /\d/, /\d/, ':', /\d/, /\d/, ':', /\d/, /\d/];

export const TimeInput = ({
  onChange,
  value,
  includeSeconds,
  ...props
}: TimeInputProps) => (
  <Tooltip title={includeSeconds ? 'HHH:MM:SS format' : 'HHH:MM format'}>
    <MaskedInput
      inputMode="text"
      onChange={(e) => {
        onChange(
          includeSeconds
            ? toSecondsHHMMSS(e.target.value)
            : toSecondsHHMM(e.target.value)
        );
      }}
      value={includeSeconds ? toHHHMMSS(value || 0) : toHHHMM(value || 0)}
      render={(ref, { ...maskProps }) => (
        <TextField
          {...maskProps}
          {...props}
          inputRef={ref}
          InputLabelProps={{ shrink: true }}
          fullWidth
          variant="outlined"
        />
      )}
      mask={includeSeconds ? hhmmssMask : hhmmMask}
      placeholderChar="0"
      guide
      showMask
    />
  </Tooltip>
);
