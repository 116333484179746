import { ActionsIcon } from '@dropkitchen/icons-react';
import { Grid, Typography, makeStyles } from '@material-ui/core';
import { memo } from 'react';
import type { FC } from 'react';

interface StepActionProps {
  actionName: string;
  showHeader: boolean;
}

const useStyles = makeStyles((theme) => ({
  icon: {
    paddingRight: theme.spacing(1),
    fontSize: theme.spacing(4),
  },
  subheader: {
    ...theme.typography.overline,
    transform: 'uppercase',
  },
  stepEntityBackground: {
    backgroundColor: '#ffffff',
    padding: theme.spacing(1),
    minHeight: 58,
  },
  bold: {
    fontSize: 16,
    fontWeight: 'bold',
  },
}));

export const StepAction: FC<StepActionProps> = memo(function StepAction({
  actionName,
  showHeader,
}) {
  const classes = useStyles();

  if (!actionName) {
    return null;
  }

  return (
    <>
      {showHeader && (
        <Typography className={classes.subheader}>Action</Typography>
      )}
      <Grid
        className={classes.stepEntityBackground}
        container
        alignItems="center"
        direction="row"
      >
        <ActionsIcon className={classes.icon} />
        <Grid item>
          <Typography className={classes.bold} variant="body2">
            {actionName}
          </Typography>
        </Grid>
      </Grid>
    </>
  );
});
