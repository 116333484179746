import { ConnectedRouter } from 'connected-react-router';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import { browserHistory, store } from 'app/store';
import 'index.css';

import * as serviceWorker from './serviceWorker';

const render = () => {
  // eslint-disable-next-line global-require, @typescript-eslint/no-var-requires, @typescript-eslint/naming-convention
  const { App } = require('./app/App');

  ReactDOM.render(
    <Provider store={store}>
      <ConnectedRouter history={browserHistory}>
        <App />
      </ConnectedRouter>
    </Provider>,
    document.getElementById('root')
  );
};

render();

if (process.env.NODE_ENV === 'development' && module.hot) {
  module.hot.accept('./app/App', render);
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
