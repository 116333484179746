import { Grid, makeStyles, Typography } from '@material-ui/core';
import type { FC } from 'react';
import { memo } from 'react';

import { FrescoIcon } from 'shared/components/FrescoIcon';
import type { FrescoMedia } from 'shared/types/media';

interface StepContainersProps {
  showHeader: boolean;
  containerName: string;
  containerImage: FrescoMedia | null;
  color?: string;
}

const useStyles = makeStyles((theme) => ({
  icon: {
    paddingRight: theme.spacing(1),
  },
  subheader: {
    ...theme.typography.overline,
    transform: 'uppercase',
  },
  stepEntityBackground: {
    backgroundColor: '#ffffff',
    padding: theme.spacing(1),
    minHeight: 58,
  },
  bold: {
    fontSize: 16,
    fontWeight: 'bold',
  },
}));

export const StepContainers: FC<StepContainersProps> = memo(
  function StepContainers({
    showHeader,
    containerName,
    containerImage,
    color,
  }) {
    const classes = useStyles();

    if (!containerName) {
      return null;
    }

    return (
      <>
        {showHeader && (
          <Typography className={classes.subheader}>Containers</Typography>
        )}
        {containerName && (
          <Grid
            className={classes.stepEntityBackground}
            container
            alignItems="center"
            direction="row"
          >
            <Grid className={classes.icon} item>
              <FrescoIcon
                image={containerImage}
                name={containerName}
                color={color}
              />
            </Grid>
            <Grid item>
              <Typography className={classes.bold} variant="body2">
                {containerName}
              </Typography>
            </Grid>
          </Grid>
        )}
      </>
    );
  }
);
