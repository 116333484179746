import {
  AddIcon,
  DeleteIcon,
  MoreMenuIcon,
  EditIcon,
} from '@dropkitchen/icons-react';
import {
  IconButton,
  Container,
  Fab,
  Tooltip,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Grid,
  CircularProgress,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { DataGrid } from '@mui/x-data-grid';
import type {
  GridValueGetterParams,
  GridRowParams,
  GridRowId,
  GridColDef,
  GridSelectionModel,
} from '@mui/x-data-grid';
import type { EntityId } from '@reduxjs/toolkit';
import type { History } from 'history';
import type { FC } from 'react';
import React, { memo, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import {
  recipesRoutesPathPrefix,
  createRecipeRoutesPath,
  translateRecipeRoutePath,
} from 'app/routes/constants';
import type { AppDispatch } from 'app/store';
import {
  selectCreateFromUrlEnabled,
  selectExternalRecipesEnabled,
} from 'features/developerMenu/featureFlagsSlice';
import { layoutHeaderTitleSet } from 'features/layout/layoutSlice';
import { recipeDeleteRequested } from 'features/recipe/recipeSlice/recipeActions';
import { RecipesFilters } from 'features/recipes/RecipesFilters';
import {
  selectRecipesPageSize,
  selectRecipes,
  selectRecipesPage,
  selectRecipesTotalPages,
  recipesPageSizeSet,
  recipesPageSet,
  recipesFetchRequested,
  selectRecipesApiPending,
  selectRecipesSmartSuggestionsPending,
  recipesSmartTranslationsRequested,
  selectRecipesSmartSuggestionsStatus,
  selectRecipesSmartSuggestionsStatusPending,
  recipesStatusChangeRequested,
  selectRecipesStatusChangePending,
  selectRecipesStatusChangeFinished,
} from 'features/recipes/recipesSlice';
import { snackbarOpen } from 'features/snackbar/snackbarSlice';
import { LocaleSwitcher } from 'features/translation/localeSwitcher/LocaleSwitcher';
import { selectCurrentLocale } from 'features/translation/localeSwitcher/localeSlice';
import { RecipeStatusSelector } from 'features/translation/recipe/RecipeStatusSelector';
import { AlertDialog } from 'shared/components/AlertDialog';
import { ButtonWithSpinner } from 'shared/components/ButtonWithSpinner';
import { SecondaryAppBar } from 'shared/components/SecondaryAppBar';
import { DataGridImageColumn } from 'shared/components/dataGrid/DataGridImageColumn';
import { DataGridTextColumn } from 'shared/components/dataGrid/DataGridTextColumn';
import { appConfig } from 'shared/config';
import {
  primaryAppBarHeaderHeight,
  recipeFiltersHeight,
  TabNameCreate,
  appActionsBarHeight,
} from 'shared/constants';
import { useSelectUserHasAccessGroupRights } from 'shared/hooks/useSelectUserHasAccessGroupRights';
import { recipesContentAdmin, translationAdmin } from 'shared/permissions';
import type { FrescoRecipeStatus } from 'shared/types/recipe';
import {
  SmartSuggestionsStatus,
  dropRecipeStatusNamesMap,
} from 'shared/types/recipe';
import {
  areSuggestionsEnabled,
  getIsTranslatableLocale,
} from 'shared/utils/translation';

// Issue with DataGrid, doesn't render in js-dom without autoHeight flag
// https://github.com/mui-org/material-ui-x/issues/1151
const useAutoHeight = appConfig.isTestEnv();

const useStyles = makeStyles((theme) => ({
  gridContainer: {
    height: `calc(100vh - ${theme.spacing(
      recipeFiltersHeight
    )}px - ${theme.spacing(
      primaryAppBarHeaderHeight + appActionsBarHeight
    )}px)`,
  },
  gridHeight: {
    display: 'flex',
    height: '100%',
  },
  grid: {
    flexGrow: 1,
    paddingTop: theme.spacing(1),
  },
  createButton: {
    position: 'absolute',
    bottom: theme.spacing(4),
    right: theme.spacing(4),
  },
  table: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.shape.borderRadiusTable,
  },
  menuItem: {
    display: 'flex',
  },
  menuIcon: {
    justifyContent: 'flex-end',
  },
  actionButton: {
    marginLeft: theme.spacing(1),
  },
  actionsBar: {
    alignItems: 'center',
    justifyContent: 'end',
    padding: `${theme.spacing(1)}px 0`,
  },
  secondaryBar: {
    backgroundColor: theme.palette.background.default,
  },
  statusDropdown: {
    backgroundColor: theme.palette.background.paper,
    marginLeft: theme.spacing(2),
    width: '200px',
  },
  statusChangeProgress: {
    marginLeft: theme.spacing(1),
  },
}));

export const textRequestTranslations = 'Request Smart Translations';
const textNoRecipes =
  'No recipes are selected. Select some recipes before requesting Smart Translations.';
export const textUpdateStatusLabel = 'Update status';

const apiStatusToHumanText = (
  status: SmartSuggestionsStatus | undefined
): string => {
  switch (status) {
    case SmartSuggestionsStatus.Completed:
      return 'Available';
    case SmartSuggestionsStatus.Progress:
    case SmartSuggestionsStatus.Submitted:
      return 'In progress';
    default:
      return '';
  }
};

export const RecipesPage: FC = memo(function RecipesPage() {
  const classes = useStyles();
  const dispatch: AppDispatch = useDispatch();
  const isBackStageApplication = appConfig.appBackstage();
  const userHasRecipeEditAccess = useSelectUserHasAccessGroupRights({
    anyOf: [recipesContentAdmin],
  });

  const userHasRecipeTranslateAccess = useSelectUserHasAccessGroupRights({
    anyOf: [translationAdmin],
  });

  const userHasAllRecipeAccess =
    userHasRecipeEditAccess && userHasRecipeTranslateAccess;

  const isCreateFromUrlEnabled = useSelector(selectCreateFromUrlEnabled);
  const isExternalRecipesAvailable = useSelector(selectExternalRecipesEnabled);

  const history: History = useHistory();
  const page = useSelector(selectRecipesPage);
  const pageSize = useSelector(selectRecipesPageSize);
  const recipes = useSelector(selectRecipes);
  const totalPages = useSelector(selectRecipesTotalPages);
  const isPending = useSelector(selectRecipesApiPending);
  const isFetchingTranslations = useSelector(
    selectRecipesSmartSuggestionsPending
  );
  const locale = useSelector(selectCurrentLocale);
  const isStatusChangePending = useSelector(selectRecipesStatusChangePending);
  const isStatusChangeSuccess = useSelector(selectRecipesStatusChangeFinished);

  const [recipeSelectedId, setRecipeSelectedId] = useState<EntityId | null>();
  const [deleteSelectedRecipe, setDeleteSelectedRecipe] = useState(false);
  const [selectedRecipes, setSelectedRecipes] = useState<GridSelectionModel>(
    []
  );
  const recipesTranslationsStatus = useSelector(
    selectRecipesSmartSuggestionsStatus(locale)
  );
  const isRecipesTranslationsStatusPending = useSelector(
    selectRecipesSmartSuggestionsStatusPending
  );

  const [bulkStatus, setBulkStatus] = useState<FrescoRecipeStatus>();

  useEffect(() => {
    dispatch(layoutHeaderTitleSet(''));
  }, [dispatch]);

  useEffect(() => {
    dispatch(recipesFetchRequested());
  }, [dispatch, page, pageSize]);

  useEffect(() => {
    if (isStatusChangeSuccess) {
      setBulkStatus(undefined);
    }
  }, [isStatusChangeSuccess]);

  const handlePageChange = (pageNumber: number) => {
    dispatch(recipesPageSet(pageNumber + 1));
  };

  const handlePageSizeChange = (pageSizeNumber: number) => {
    dispatch(recipesPageSizeSet(pageSizeNumber));
  };

  const handleRecipeOnClick = (params: GridRowParams) => {
    if (
      isBackStageApplication &&
      !userHasRecipeEditAccess &&
      userHasRecipeTranslateAccess
    ) {
      history.push(`${translateRecipeRoutePath}/${params.row.id}`);
      return;
    }

    history.push(`${recipesRoutesPathPrefix}/${params.row.id}`);
  };

  const handleCreateOnClick = () => {
    if (isCreateFromUrlEnabled) {
      history.push(`${createRecipeRoutesPath}/${TabNameCreate.FromUrl}`);
      return;
    }
    history.push(`${createRecipeRoutesPath}/${TabNameCreate.FromText}`);
  };

  const handleTranslateClick = () => {
    history.push(`${translateRecipeRoutePath}/${recipeSelectedId}`);
  };

  const handleDeleteClick = () => {
    if (recipeSelectedId) {
      dispatch(recipeDeleteRequested(recipeSelectedId));
      setDeleteSelectedRecipe(false);
      setRecipeSelectedId(null);
    }
    setAnchorEl(null);
  };

  const handleCloseDelete = () => {
    setRecipeSelectedId(null);

    setDeleteSelectedRecipe(false);
  };

  const handleCloseEditDelete = () => {
    setAnchorEl(null);
    setRecipeSelectedId(null);
  };

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const handleOpenEditDelete = (
    event: React.MouseEvent<HTMLButtonElement>,
    id: GridRowId
  ) => {
    setRecipeSelectedId(id);
    setAnchorEl(event.currentTarget);
  };

  const handleSuggestSmartTranslations = () => {
    if (selectedRecipes.length === 0) {
      dispatch(
        snackbarOpen({
          text: textNoRecipes,
          severity: 'warning',
        })
      );
    }
    dispatch(
      recipesSmartTranslationsRequested({
        locale,
        recipeIds: selectedRecipes,
      })
    );
  };

  const handleStatusChange = (status: string) => {
    const newStatus = Number(status);
    setBulkStatus(newStatus);
    dispatch(
      recipesStatusChangeRequested({
        locale,
        status: newStatus,
        recipeIds: selectedRecipes,
      })
    );
  };

  const disableSmartSuggestionsButton =
    selectedRecipes.length === 0 || !getIsTranslatableLocale(locale);

  const getRecipeActionsColumn = (params: GridValueGetterParams) => {
    if (
      !isBackStageApplication ||
      (userHasRecipeEditAccess && !userHasRecipeTranslateAccess)
    ) {
      return (
        <IconButton
          onClick={(event) => {
            event.stopPropagation();
            setRecipeSelectedId(params.id);
            setDeleteSelectedRecipe(true);
          }}
          aria-label="delete-recipe"
          color="inherit"
        >
          <DeleteIcon />
        </IconButton>
      );
    }

    if (isBackStageApplication && userHasAllRecipeAccess) {
      return (
        <IconButton
          aria-label="Recipe Menu"
          size="small"
          edge="start"
          color="inherit"
          onClick={(event) => {
            event.stopPropagation();
            handleOpenEditDelete(event, params.id);
          }}
        >
          <MoreMenuIcon />
        </IconButton>
      );
    }

    return <></>;
  };

  const showSmartTranslationsStatus =
    areSuggestionsEnabled(locale) && getIsTranslatableLocale(locale);
  const extraFields =
    (showSmartTranslationsStatus ? 1 : 0) +
    (isExternalRecipesAvailable ? 1 : 0);
  const columns: GridColDef[] = [
    {
      field: 'image',
      headerName: 'Image',
      width: 140,
      renderCell: (params: GridValueGetterParams) => (
        <DataGridImageColumn image={params.row.image} label={params.row.name} />
      ),
      sortable: false,
    },
    {
      field: 'name',
      headerName: 'Name',
      renderCell: (params: GridValueGetterParams) => (
        <div style={{ flexWrap: 'wrap' }}>
          <DataGridTextColumn text={params.row.name} />
          <DataGridTextColumn text={params.row.id} />
        </div>
      ),
      width: 260 - extraFields * 60,
    },
    {
      field: 'user',
      headerName: 'Owner',
      valueGetter: (params: GridValueGetterParams) => params.row.user.fullName,
      width: 240 - extraFields * 60,
    },
    {
      field: 'status',
      valueGetter: (params: GridValueGetterParams) =>
        dropRecipeStatusNamesMap[params.row.status as FrescoRecipeStatus],
      headerName: 'Status',
      width: 120,
    },
  ];

  if (isExternalRecipesAvailable) {
    columns.splice(columns.length, 0, {
      field: 'flags',
      width: 120,
      headerName: 'External',
      valueGetter: (params: GridValueGetterParams) =>
        params.row.flags.isExternal,
    } as GridColDef);
  }

  if (showSmartTranslationsStatus) {
    columns.splice(columns.length, 0, {
      field: 'translations',
      width: 120,
      headerName: 'Translations',
      renderCell: (params: GridValueGetterParams) =>
        isRecipesTranslationsStatusPending ? (
          <CircularProgress size={32} />
        ) : (
          apiStatusToHumanText(recipesTranslationsStatus?.[params.row.id])
        ),
    } as GridColDef);
  }

  if (userHasAllRecipeAccess) {
    columns.splice(columns.length, 0, {
      field: isBackStageApplication ? 'edit' : 'delete',
      width: 100,
      headerName: isBackStageApplication ? 'Edit' : 'Delete',
      sortable: false,
      disableClickEventBubbling: true,
      renderCell: (params: GridValueGetterParams) =>
        getRecipeActionsColumn(params),
    } as GridColDef);
  }

  return (
    <>
      <AlertDialog
        isOpen={deleteSelectedRecipe}
        handleCloseDialog={handleCloseDelete}
        content={`Are you sure you want to delete recipe ${recipeSelectedId}?`}
        handleAgreeDialog={handleDeleteClick}
      />
      {isBackStageApplication && userHasRecipeTranslateAccess && (
        <SecondaryAppBar className={classes.secondaryBar}>
          <Grid container className={classes.actionsBar}>
            {areSuggestionsEnabled(locale) && (
              <ButtonWithSpinner
                className={classes.actionButton}
                color="secondary"
                disabled={disableSmartSuggestionsButton}
                loading={isFetchingTranslations}
                onClick={handleSuggestSmartTranslations}
                variant="contained"
              >
                {textRequestTranslations}
              </ButtonWithSpinner>
            )}
            <Grid
              className={classes.statusDropdown}
              container
              justify="flex-end"
            >
              <RecipeStatusSelector
                currentStatus={bulkStatus}
                disabled={selectedRecipes.length === 0 || isStatusChangePending}
                id="update-status"
                label={textUpdateStatusLabel}
                onChange={handleStatusChange}
              />
            </Grid>
            {isStatusChangePending && (
              <CircularProgress className={classes.statusChangeProgress} />
            )}

            <LocaleSwitcher displayInline />
          </Grid>
        </SecondaryAppBar>
      )}
      <Container maxWidth="md">
        <RecipesFilters />
        <Menu
          disableEnforceFocus
          disableRestoreFocus
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleCloseEditDelete}
        >
          <MenuItem className={classes.menuItem} onClick={handleTranslateClick}>
            <ListItemText primary="Translate Recipe" />
            <ListItemIcon className={classes.menuIcon}>
              <EditIcon />
            </ListItemIcon>
          </MenuItem>
          {userHasRecipeEditAccess && (
            <MenuItem
              className={classes.menuItem}
              onClick={() => {
                setAnchorEl(null);
                setDeleteSelectedRecipe(true);
              }}
            >
              <ListItemText primary="Delete recipe" />
              <ListItemIcon className={classes.menuIcon}>
                <DeleteIcon />
              </ListItemIcon>
            </MenuItem>
          )}
        </Menu>
        <div className={classes.gridContainer}>
          <div className={classes.gridHeight}>
            <div className={classes.grid}>
              <DataGrid
                className={classes.table}
                disableSelectionOnClick
                autoHeight={useAutoHeight}
                rows={recipes}
                columnBuffer={columns.length}
                columns={columns}
                density="comfortable"
                rowHeight={100}
                paginationMode="server"
                rowCount={totalPages}
                loading={isPending}
                pageSize={pageSize}
                page={page - 1}
                onPageChange={handlePageChange}
                onPageSizeChange={handlePageSizeChange}
                onRowClick={handleRecipeOnClick}
                checkboxSelection
                onSelectionModelChange={(newSelectionModel) =>
                  setSelectedRecipes(newSelectionModel)
                }
                selectionModel={selectedRecipes}
              />
            </div>
          </div>
        </div>
      </Container>
      {userHasRecipeEditAccess && (
        <Tooltip title="Create new recipe">
          <Fab
            onClick={handleCreateOnClick}
            className={classes.createButton}
            color="primary"
            aria-label="create"
          >
            <AddIcon />
          </Fab>
        </Tooltip>
      )}
    </>
  );
});
