import { makeStyles } from '@material-ui/core';
import WallpaperIcon from '@material-ui/icons/Wallpaper';
import React from 'react';

import type { FrescoMedia } from 'shared/types/media';

const useStyles = makeStyles((theme) => ({
  icon: {
    height: theme.spacing(4),
    width: theme.spacing(4),
    // Chrome has weird artifacts on edges of images with background color, that is a workaround
    '-webkit-backface-visibility': 'hidden',
    outline: 'white 2px solid',
  },
}));

interface FrescoIconProps {
  image: FrescoMedia | null;
  color?: string;
  name: string;
}

export const FrescoIcon: React.FC<FrescoIconProps> = React.memo(
  function FrescoIcon({ image, color, name }) {
    const classes = useStyles();

    return image ? (
      <img
        src={image.data.uri}
        alt={`${name} icon`}
        className={classes.icon}
        style={{
          background: color ?? 'transparent',
        }}
      />
    ) : (
      <WallpaperIcon aria-label="Icon placeholder" className={classes.icon} />
    );
  }
);
