import { makeStyles, Typography, Grid } from '@material-ui/core';
import type { FC } from 'react';

import { StepTip } from 'shared/components/stepViewEntities/StepTip';
import type { FrescoTip } from 'shared/types/step';

const useStyles = makeStyles((theme) => ({
  subheader: {
    ...theme.typography.overline,
    transform: 'uppercase',
  },
}));

interface StepTipsProps {
  tips: FrescoTip[] | undefined;
}

export const StepTips: FC<StepTipsProps> = ({ tips }) => {
  const classes = useStyles();

  if (!tips?.length) {
    return null;
  }

  return (
    <Grid item>
      <Typography className={classes.subheader}>Tips</Typography>
      <Grid container direction="row" spacing={2}>
        {tips.map(({ id, caption, video, image, uri }) => (
          <Grid item key={id} xs={tips.length > 1 ? 6 : 12}>
            <StepTip caption={caption} video={video} image={image} uri={uri} />
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
};
