import {
  Box,
  TextField,
  Button,
  Paper,
  Grid,
  makeStyles,
  Typography,
} from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { selectIsRecipeCreating } from 'features/recipe/edit/editSlice';
import { recipePostByUrlRequested } from 'features/recipe/recipeSlice/recipeActions';
import { isValidUrl } from 'shared/utils/common';

import RecipeIllustration from './pulling-details-loading.gif';

const useStyles = makeStyles((theme) => ({
  marginTop: {
    marginTop: theme.spacing(1),
  },
  headerMargins: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(2),
  },
}));

export const urlErrorText =
  'Please enter a valid url, starting with http or https';

export const CreateFromUrl: React.FC = React.memo(function CreateFromUrl() {
  const classes = useStyles();
  const dispatch = useDispatch();

  const posting = useSelector(selectIsRecipeCreating);
  const [sourceUrl, setSourceUrl] = useState<string | undefined>();
  const [urlError, setUrlError] = useState<string | undefined>();

  useEffect(() => {
    if (sourceUrl && !isValidUrl.test(sourceUrl)) {
      setUrlError(urlErrorText);
      return;
    }
    setUrlError(undefined);
  }, [sourceUrl]);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (!sourceUrl) {
      return;
    }
    dispatch(recipePostByUrlRequested({ sourceUrl }));
  };

  return (
    <Box p={3}>
      <Paper>
        <Box p={2} mt={1}>
          <form autoComplete="off" onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              {posting ? (
                <Grid
                  direction="column"
                  justify="center"
                  alignItems="center"
                  container
                >
                  <img
                    src={RecipeIllustration}
                    alt="loading..."
                    className={classes.marginTop}
                    width="240px"
                    height="156px"
                  />
                  <Typography variant="h6" className={classes.headerMargins}>
                    Pulling recipe details from the web
                  </Typography>
                </Grid>
              ) : (
                <>
                  <Grid item xs={12}>
                    <TextField
                      error={!!urlError}
                      helperText={urlError}
                      autoFocus
                      variant="outlined"
                      fullWidth
                      value={sourceUrl || ''}
                      onChange={(event) => {
                        setSourceUrl(event?.target.value);
                      }}
                      label="Source Url"
                      id="Source Url"
                      required
                    />
                  </Grid>
                  <Grid container justify="flex-end">
                    <Button
                      variant="text"
                      color="primary"
                      type="submit"
                      disabled={!!urlError}
                    >
                      Create Recipe
                    </Button>
                  </Grid>
                </>
              )}
            </Grid>
          </form>
        </Box>
      </Paper>
    </Box>
  );
});
