import type { SagaIterator } from 'redux-saga';
import { select, call, put, spawn, take } from 'redux-saga/effects';

import type { ApiContext, ApiRequestFn, PromiseReturnType } from 'api/types';
import { requestTokenRefresh } from 'features/login/loginSagas';
import { selectApiContext, loginSuccess } from 'features/login/loginSlice';
import { snackbarOpen } from 'features/snackbar/snackbarSlice';

/**
 * Creates saga that queries for auth data from store, refreshes token if needed,
 * performs request, handles errors and returns response
 */
export function createRequestApiSaga<TRequestFnArgs, TResponse>(
  /**
   * Factory function that accepts request arguments and returns request function of type `ApiRequestFn`
   */
  apiRequestFnFactory:
    | ((args: TRequestFnArgs) => ApiRequestFn<TResponse>)
    | (() => ApiRequestFn<TResponse>),
  /**
   * Request description to show error message to user like "{requestDescription} error: ..."
   */
  requestDescription = 'Server request'
) {
  type ApiRequestFnType = ReturnType<typeof apiRequestFnFactory>;
  type ApiResponseType = PromiseReturnType<ApiRequestFnType>;

  function* requestApiSaga(
    args?: TRequestFnArgs
  ): SagaIterator<ApiResponseType> {
    let apiContext: ApiContext = yield select(selectApiContext);

    const apiRequestFn: ApiRequestFnType =
      args === undefined
        ? yield call(apiRequestFnFactory)
        : yield call(apiRequestFnFactory, args);

    let response: ApiResponseType = yield call(apiRequestFn, apiContext);
    if (!response.ok) {
      if (response.httpStatus === 401 && !!apiContext.authData) {
        // Token looks not valid, let's refresh it
        yield spawn(requestTokenRefresh);
        yield take(loginSuccess);
        // Call failed API again
        apiContext = yield select(selectApiContext);
        response = yield call(apiRequestFn, apiContext);
      } else {
        yield put(
          snackbarOpen({
            text: `${requestDescription} error: ${response.details.message}`,
            severity: 'error',
          })
        );
      }
    }
    return response;
  }

  // eslint-disable-next-line @typescript-eslint/ban-types
  return requestApiSaga as TRequestFnArgs extends {}
    ? (args: TRequestFnArgs) => ReturnType<typeof requestApiSaga>
    : () => ReturnType<typeof requestApiSaga>;
}
