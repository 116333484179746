import type { RouteProps } from 'react-router-dom';
import { Redirect } from 'react-router-dom';

import {
  recipesRoutesPathPrefix,
  createRecipeRoutesPath,
  translateRecipeRoutePath,
  translateCollectionRoutePath,
  translateCategoryRoutePath,
  translateFeatureRoutePath,
} from 'app/routes/constants';
import { CategoriesPage } from 'features/categories/CategoriesPage';
import { CollectionsPage } from 'features/collections/CollectionsPage';
import { FeaturesPage } from 'features/features/FeaturesPage';
import { HomePage } from 'features/homepage/HomePage';
import { RecipePage } from 'features/recipe/RecipePage';
import { CreatePage } from 'features/recipe/create/CreatePage';
import { RecipesPage } from 'features/recipes/RecipesPage';
import { CategoryPage as TranslateCategoryPage } from 'features/translation/category/CategoryPage';
import { CollectionPage as TranslateCollectionPage } from 'features/translation/collection/CollectionPage';
import { RecipePage as TranslateRecipePage } from 'features/translation/recipe/RecipePage';
import { appConfig } from 'shared/config';
import { TabName, TabNameCreate } from 'shared/constants';

const isBackStageApplication = appConfig.appBackstage();

export const createRecipeRoute: RouteProps = {
  path: `${createRecipeRoutesPath}/:tab(${Object.keys(TabNameCreate).join(
    '|'
  )})?`,
  exact: true,
  children: <CreatePage />,
};

export const recipesRoute: RouteProps = {
  path: `/recipes`,
  exact: true,
  children: <RecipesPage />,
};

export const homePageRoute: RouteProps = {
  path: `/`,
  exact: true,
  children: <HomePage />,
};

export const recipeRoute: RouteProps = {
  path: `${recipesRoutesPathPrefix}/:recipeId/:tab(${Object.keys(TabName).join(
    '|'
  )})?`,
  exact: true,
  children: <RecipePage />,
};

export const translateRecipesRoute: RouteProps = {
  path: translateRecipeRoutePath,
  exact: true,
  children: <RecipesPage />,
};

export const translateRecipeRoute: RouteProps = {
  path: `${translateRecipeRoutePath}/:recipeId`,
  exact: true,
  children: <TranslateRecipePage />,
};

export const translateCollectionsRoute: RouteProps = {
  path: translateCollectionRoutePath,
  exact: true,
  children: <CollectionsPage />,
};

export const translateCollectionRoute: RouteProps = {
  path: `${translateCollectionRoutePath}/:collectionId`,
  exact: true,
  children: <TranslateCollectionPage />,
};

export const translateCategoriesRoute: RouteProps = {
  path: translateCategoryRoutePath,
  exact: true,
  children: <CategoriesPage />,
};

export const translateCategoryRoute: RouteProps = {
  path: `${translateCategoryRoutePath}/:categoryId`,
  exact: true,
  children: <TranslateCategoryPage />,
};

export const translateFeaturesRoute: RouteProps = {
  path: translateFeatureRoutePath,
  exact: true,
  children: <FeaturesPage />,
};

const catchAllRedirect: RouteProps = {
  path: '*',
  children: isBackStageApplication ? (
    <Redirect to={homePageRoute.path as string} />
  ) : (
    <Redirect to={recipesRoute.path as string} />
  ),
};

const routes: RouteProps[] = [
  createRecipeRoute,
  recipeRoute,
  recipesRoute,
  translateCategoriesRoute,
  translateCategoryRoute,
  translateCollectionRoute,
  translateCollectionsRoute,
  translateFeaturesRoute,
  translateRecipeRoute,
  translateRecipesRoute,
  catchAllRedirect,
];

if (isBackStageApplication) {
  routes.unshift(homePageRoute);
}

export const privateRoutes: RouteProps[] = routes;
