import { combineReducers } from '@reduxjs/toolkit';

import { categoryReducer } from 'features/translation/category/categorySlice';
import { collectionReducer } from 'features/translation/collection/collectionSlice';
import { localeReducer } from 'features/translation/localeSwitcher/localeSlice';
import { recipeReducer } from 'features/translation/recipe/recipeSlice';

export const translationReducer = combineReducers({
  category: categoryReducer,
  collection: collectionReducer,
  locale: localeReducer,
  recipe: recipeReducer,
});
