import { Grid } from '@material-ui/core';
import type { FC } from 'react';
import { memo } from 'react';
import { useSelector } from 'react-redux';

import { selectCurrentLocale } from 'features/translation/localeSwitcher/localeSlice';
import { StepAppliance as StepApplianceLayout } from 'shared/components/stepViewEntities/StepAppliance';
import { noTranslationSentence } from 'shared/constants';
import type { FrescoStepApplianceI18n, ApiStepI18n } from 'shared/types/i18n';
import { ApiLocale } from 'shared/types/i18n';
import { getIsTranslatableLocale } from 'shared/utils/translation';

interface StepApplianceProps {
  appliance: FrescoStepApplianceI18n | null;
  step: ApiStepI18n;
}

export const StepAppliance: FC<StepApplianceProps> = memo(
  function StepAppliance({ appliance, step }) {
    const locale = useSelector(selectCurrentLocale);

    if (!appliance || !step) {
      return null;
    }
    const { setting, setting2, setting3, setting4, attachment } = step;

    return (
      <Grid item xs={12}>
        <Grid item xs={12}>
          <StepApplianceLayout
            showHeader
            applianceName={appliance.nameTranslations?.[ApiLocale.EnUS] || ''}
            applianceImage={appliance.image}
            settingName={setting?.nameTranslations?.[ApiLocale.EnUS]}
            setting2Name={setting2?.nameTranslations?.[ApiLocale.EnUS]}
            setting3Name={setting3?.nameTranslations?.[ApiLocale.EnUS]}
            setting4Name={setting4?.nameTranslations?.[ApiLocale.EnUS]}
            attachmentName={attachment?.nameTranslations?.[ApiLocale.EnUS]}
            color="#000000"
          />
        </Grid>
        {getIsTranslatableLocale(locale) && (
          <Grid item xs={12}>
            <StepApplianceLayout
              showHeader={false}
              applianceName={
                appliance.nameTranslations?.[locale] ||
                `${noTranslationSentence} ${locale}`
              }
              applianceImage={appliance.image}
              settingName={
                setting
                  ? setting?.nameTranslations?.[locale] ||
                    `${noTranslationSentence} ${locale}`
                  : undefined
              }
              setting2Name={
                setting2
                  ? setting2?.nameTranslations?.[locale] ||
                    `${noTranslationSentence} ${locale}`
                  : undefined
              }
              setting3Name={
                setting3
                  ? setting3?.nameTranslations?.[locale] ||
                    `${noTranslationSentence} ${locale}`
                  : undefined
              }
              setting4Name={
                setting4
                  ? setting4?.nameTranslations?.[locale] ||
                    `${noTranslationSentence} ${locale}`
                  : undefined
              }
              attachmentName={
                attachment
                  ? attachment?.nameTranslations?.[locale] ||
                    `${noTranslationSentence} ${locale}`
                  : undefined
              }
              color="#000000"
            />
          </Grid>
        )}
      </Grid>
    );
  }
);
