import snakecaseKeys from 'snakecase-keys';

import { fetchJson } from 'api/fetchJson';
import type { ApiRequestFn, LocationResponse } from 'api/types';
import { HttpMethod } from 'api/types';
import { appConfig } from 'shared/config';
import type { FrescoId, FrescoEntityUri } from 'shared/types/entity';
import type { FrescoRecipeContainer } from 'shared/types/recipe';

export type ApiRecipeContainerGetResponse = FrescoRecipeContainer;

export type ApiRecipeContainerLocationResponse = LocationResponse;

export interface ApiCreateRecipeContainerRequest {
  recipeId: FrescoId;
  container: FrescoEntityUri;
  quantity: number;
}

export const apiRecipeContainerPost =
  ({
    recipeId,
    container,
    quantity,
  }: ApiCreateRecipeContainerRequest): ApiRequestFn<ApiRecipeContainerGetResponse> =>
  (apiContext) =>
    fetchJson<ApiRecipeContainerGetResponse>({
      apiContext,
      url: `${appConfig.apiV3PrefixRecipes()}/${recipeId}/containers`,
      body: snakecaseKeys({
        container,
        quantity,
      }),
    });

export interface ApiPatchRecipeContainerRequest {
  recipeId: FrescoId;
  container: FrescoRecipeContainer;
}

export const apiRecipeContainerPatch =
  ({
    recipeId,
    container,
  }: ApiPatchRecipeContainerRequest): ApiRequestFn<ApiRecipeContainerGetResponse> =>
  (apiContext) =>
    fetchJson<ApiRecipeContainerGetResponse>({
      apiContext,
      httpMethod: HttpMethod.Patch,
      url: `${appConfig.apiV3PrefixRecipes()}/${recipeId}/containers/${
        container.id
      }`,
      body: snakecaseKeys({
        ...container,
      }),
    });

export interface ApiDeleteRecipeContainerRequest {
  recipeId: FrescoId;
  containerId: FrescoId;
}

export const apiRecipeContainerDelete =
  ({
    recipeId,
    containerId,
  }: ApiDeleteRecipeContainerRequest): ApiRequestFn<ApiRecipeContainerLocationResponse> =>
  (apiContext) =>
    fetchJson<ApiRecipeContainerLocationResponse>({
      apiContext,
      httpMethod: HttpMethod.Delete,
      url: `${appConfig.apiV3PrefixRecipes()}/${recipeId}/containers/${containerId}`,
    });
