import type { PayloadAction } from '@reduxjs/toolkit';
import { createAction, createSlice } from '@reduxjs/toolkit';

import type { GetCategoryRequest } from 'api/category';
import type { RootState } from 'app/rootReducer';
import { logoutSuccess } from 'features/login/loginSlice';
import type { PatchCategorySaga } from 'features/translation/category/categorySagas';
import type { FrescoCategoryI18n } from 'shared/types/category';
import type { FrescoId } from 'shared/types/entity';
import type { ApiLocale, TranslationEditPayload } from 'shared/types/i18n';

export interface TranslationCategoryState {
  isFetched: boolean;
  isFetching: boolean;
  isSaving: boolean;
  isSaved: boolean;
  error?: string;
  category?: FrescoCategoryI18n;
}

export const initialState: TranslationCategoryState = {
  isFetching: false,
  isFetched: false,
  isSaving: false,
  isSaved: false,
};

export const categoryFetchRequested = createAction<GetCategoryRequest>(
  'translation/categorySlice/categoryFetchRequested'
);

export const categorySaveRequested = createAction<PatchCategorySaga>(
  'translation/categorySlice/categorySaveRequested'
);

const categorySlice = createSlice({
  name: 'translation/categorySlice',
  initialState,
  reducers: {
    categoryFetchFinished(
      state,
      { payload }: PayloadAction<FrescoCategoryI18n>
    ) {
      state.category = payload;
      state.isFetched = true;
      state.isFetching = false;
    },
    categoryFetchFailed(state, { payload }: PayloadAction<string>) {
      state.error = payload;
      state.isFetched = false;
      state.isFetching = false;
      state.category = undefined;
    },
    categorySaveFinished(state) {
      state.isSaved = true;
      state.isSaving = false;
      state.error = undefined;
    },
    categorySaveFailed(state, { payload }: PayloadAction<string>) {
      state.error = payload;
      state.isSaved = false;
      state.isSaving = false;
    },
    categoryTranslationEditRequested(
      state,
      {
        payload: { property, translation, locale },
      }: PayloadAction<TranslationEditPayload>
    ) {
      if (state.category?.[property]) {
        state.category[property][locale] = translation;
        state.isSaved = false;
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(categoryFetchRequested, () => ({
      ...initialState,
      isFetched: false,
      isFetching: true,
      category: undefined,
      error: undefined,
    }));
    builder.addCase(categorySaveRequested, (state) => ({
      ...state,
      isSaved: false,
      isSaving: true,
    }));
    builder.addCase(logoutSuccess, () => initialState);
  },
});

export const {
  reducer: categoryReducer,
  actions: {
    categoryFetchFinished,
    categoryFetchFailed,
    categorySaveFinished,
    categorySaveFailed,
    categoryTranslationEditRequested,
  },
} = categorySlice;

export const selectCategoryFetching = (state: RootState): boolean =>
  state.translation.category.isFetching;

export const selectCategoryFetched = (state: RootState): boolean =>
  state.translation.category.isFetched;

export const selectCategorySaving = (state: RootState): boolean =>
  state.translation.category.isSaving;

export const selectCategorySaved = (state: RootState): boolean =>
  state.translation.category.isSaved;

export const selectCategoryError = (state: RootState): string | undefined =>
  state.translation.category.error;

export const selectCategory = (
  state: RootState
): FrescoCategoryI18n | undefined => state.translation.category.category;

export const selectCategoryId = (state: RootState): FrescoId | undefined =>
  state.translation.category.category?.id;

export const selectCategoryName =
  (locale: ApiLocale) =>
  (state: RootState): string | undefined =>
    state.translation.category.category?.nameTranslations[locale];

export const selectCategoryDescription =
  (locale: ApiLocale) =>
  (state: RootState): string | undefined =>
    state.translation.category.category?.descriptionTranslations[locale];
