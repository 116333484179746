import { TextField, Grid } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import React from 'react';
import { useSelector } from 'react-redux';

import { selectTermsApplianceAttachments } from 'features/terms/appliancesSlice';
import type { FrescoAttachment } from 'shared/types/appliance';
import type { FrescoId } from 'shared/types/entity';

interface StepAttachmentProps {
  stepAttachment: FrescoAttachment | null;
  applianceId: FrescoId | null;
  onStepAttachmentChange: (action: FrescoAttachment | null) => void;
}

export const StepAttachment: React.FC<StepAttachmentProps> = React.memo(
  function StepAttachment({
    stepAttachment,
    applianceId,
    onStepAttachmentChange,
  }) {
    const attachments = useSelector(
      selectTermsApplianceAttachments(applianceId)
    );

    return (
      <>
        {!!attachments?.length && (
          <Grid item xs={12}>
            <Autocomplete
              value={stepAttachment || null}
              options={attachments}
              getOptionLabel={(value) => value.name}
              getOptionSelected={(option, value) => option.uri === value.uri}
              onChange={(_e, value) => {
                onStepAttachmentChange(
                  value ? (value as FrescoAttachment) : null
                );
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Attachment"
                  variant="outlined"
                  fullWidth
                />
              )}
            />
          </Grid>
        )}
      </>
    );
  }
);
