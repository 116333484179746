import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice, createAction, createSelector } from '@reduxjs/toolkit';
import { put, call, takeLatest } from 'redux-saga/effects';

import { createRequestApiSaga } from 'api/createRequestApiSaga';
import { apiContainerTermsGet, apiContainerTermGet } from 'api/terms';
import type { FrescoPaginatedResponse, ApiResponse } from 'api/types';
import type { RootState } from 'app/rootReducer';
import { logoutSuccess } from 'features/login/loginSlice';
import type { FrescoContainer } from 'shared/types/container';
import type { FrescoId } from 'shared/types/entity';
import { getErrorString } from 'shared/utils/common';

interface TermsContainersState {
  apiError?: string;
  apiPending: boolean;
  entities: FrescoContainer[];
}

export const initialState: TermsContainersState = {
  apiPending: false,
  entities: [],
};

const termsContainersSlice = createSlice({
  name: 'termsContainersSlice',
  initialState,
  reducers: {
    termsContainersSuccess(
      state,
      { payload }: PayloadAction<FrescoContainer[]>
    ) {
      state.entities = payload;
      state.apiPending = false;
      state.apiError = undefined;
    },
    termsContainersApiPending(state) {
      state.apiPending = true;
      state.apiError = undefined;
    },
    termsContainersApiError(state, { payload }: PayloadAction<string>) {
      state.apiPending = false;
      state.apiError = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(logoutSuccess, () => initialState);
  },
});

export const {
  reducer: termsContainersReducer,
  actions: {
    termsContainersSuccess,
    termsContainersApiPending,
    termsContainersApiError,
  },
} = termsContainersSlice;

const selectTermsContainersState = (state: RootState): TermsContainersState =>
  state.terms.containers;

export const selectTermsContainersEntities = (
  state: RootState
): FrescoContainer[] => selectTermsContainersState(state).entities;

export const selectTermsContainerById = (id: FrescoId | null) =>
  createSelector(selectTermsContainersState, (containers) =>
    containers.entities.find((x) => x.id === id)
  );

export const selectTermsContainersApiPending = (state: RootState): boolean =>
  selectTermsContainersState(state).apiPending;

export const selectTermsContainersApiError = (
  state: RootState
): string | undefined => selectTermsContainersState(state).apiError;

export const apiGetContainerTermsSaga = createRequestApiSaga(
  apiContainerTermsGet,
  'Containers loading'
);

export const apiGetContainerByIdSaga = createRequestApiSaga(
  apiContainerTermGet,
  'Container loading'
);

export function* requestContainerTerms() {
  try {
    yield put(termsContainersApiPending());
    const response: ApiResponse<FrescoPaginatedResponse<FrescoContainer>> =
      yield call(apiGetContainerTermsSaga);
    if (!response.ok) {
      yield put(termsContainersApiError(response.details.message));
      return;
    }
    yield put(termsContainersSuccess(response.data.results));
    return;
  } catch (e) {
    yield put(termsContainersApiError(getErrorString(e)));
  }
}

export const containerTermsFetchRequested = createAction(
  'terms/containerTermsFetchRequested'
);

function* requestContainerTermsFetchWatcher() {
  yield takeLatest(containerTermsFetchRequested, requestContainerTerms);
}

export const containerTermsSagas = [requestContainerTermsFetchWatcher];
