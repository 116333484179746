import { appConfig } from 'shared/config';
import { useSelectUserHasAccessGroupRights } from 'shared/hooks/useSelectUserHasAccessGroupRights';
import type { PermissionsCheckRuleSet } from 'shared/permissions';
import { translationAdmin } from 'shared/permissions';

export const useShouldDisplayPage = ({
  backstage,
  groups,
}: {
  backstage?: boolean;
  groups: PermissionsCheckRuleSet;
}) => {
  const isBackStageApplication = appConfig.appBackstage();
  const userHasAccess = useSelectUserHasAccessGroupRights(groups);

  return (
    userHasAccess &&
    (backstage === undefined || isBackStageApplication === backstage)
  );
};

export const useShouldDisplayTranslatePage = () => {
  return useShouldDisplayPage({
    backstage: true,
    groups: {
      anyOf: [translationAdmin],
    },
  });
};
