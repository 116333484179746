import { TextField, Grid } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import {
  selectTermsActionsEntities,
  actionTermsFetchRequested,
  selectTermsActionsApiPending,
} from 'features/terms/actionsSlice';
import type { FrescoStepAction } from 'shared/types/step';

interface StepActionProps {
  stepAction: FrescoStepAction | null;
  /**
   * Array created from selected Appliance, Attachment and Container
   * otherwise it is returned from the API.
   */
  limitedActions?: FrescoStepAction[];
  onStepActionChange: (action: FrescoStepAction | null) => void;
}

export const StepAction: React.FC<StepActionProps> = React.memo(
  function StepAction({ stepAction, limitedActions, onStepActionChange }) {
    const dispatch = useDispatch();
    const actionTerms = useSelector(selectTermsActionsEntities);
    const actionTermsPending = useSelector(selectTermsActionsApiPending);

    useEffect(() => {
      if (
        !limitedActions?.length &&
        !actionTerms.length &&
        !actionTermsPending
      ) {
        dispatch(actionTermsFetchRequested());
      }
    }, [dispatch, limitedActions, actionTerms, actionTermsPending]);

    return (
      <Grid item xs={12}>
        <Autocomplete
          value={stepAction}
          options={limitedActions?.length ? limitedActions : actionTerms}
          getOptionLabel={(value) => value.name}
          getOptionSelected={(option, value) => option.uri === value.uri}
          onChange={(_e, value) => {
            onStepActionChange(value);
          }}
          renderInput={(params) => (
            <TextField
              required={!!limitedActions?.length}
              label="Action"
              {...params}
              variant="outlined"
            />
          )}
        />
      </Grid>
    );
  }
);
