import type { PayloadAction } from '@reduxjs/toolkit';
import { createAction, createSlice } from '@reduxjs/toolkit';

import type { GetCollectionRequest } from 'api/collection';
import type { RootState } from 'app/rootReducer';
import { logoutSuccess } from 'features/login/loginSlice';
import type { PatchCollectionSaga } from 'features/translation/collection/collectionSagas';
import type { FrescoCollectionI18n } from 'shared/types/collection';
import type { FrescoId } from 'shared/types/entity';
import type { ApiLocale, TranslationEditPayload } from 'shared/types/i18n';

export interface TranslationCollectionState {
  isFetched: boolean;
  isFetching: boolean;
  isSaving: boolean;
  isSaved: boolean;
  error?: string;
  collection?: FrescoCollectionI18n;
}

export const initialState: TranslationCollectionState = {
  isFetching: false,
  isFetched: false,
  isSaving: false,
  isSaved: false,
};

export const collectionFetchRequested = createAction<GetCollectionRequest>(
  'translation/collectionSlice/collectionFetchRequested'
);

export const collectionSaveRequested = createAction<PatchCollectionSaga>(
  'translation/collectionSlice/collectionSaveRequested'
);

const collectionSlice = createSlice({
  name: 'translation/collectionSlice',
  initialState,
  reducers: {
    collectionFetchFinished(
      state,
      { payload }: PayloadAction<FrescoCollectionI18n>
    ) {
      state.collection = payload;
      state.isFetched = true;
      state.isFetching = false;
    },
    collectionFetchFailed(state, { payload }: PayloadAction<string>) {
      state.error = payload;
      state.isFetched = false;
      state.isFetching = false;
      state.collection = undefined;
    },
    collectionSaveFinished(state) {
      state.isSaved = true;
      state.isSaving = false;
      state.error = undefined;
    },
    collectionSaveFailed(state, { payload }: PayloadAction<string>) {
      state.error = payload;
      state.isSaved = false;
      state.isSaving = false;
    },
    collectionTranslationEditRequested(
      state,
      {
        payload: { property, translation, locale },
      }: PayloadAction<TranslationEditPayload>
    ) {
      if (state.collection?.[property]) {
        state.collection[property][locale] = translation;
        state.isSaved = false;
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(collectionFetchRequested, () => ({
      ...initialState,
      isFetched: false,
      isFetching: true,
      collection: undefined,
      error: undefined,
    }));
    builder.addCase(collectionSaveRequested, (state) => ({
      ...state,
      isSaved: false,
      isSaving: true,
    }));
    builder.addCase(logoutSuccess, () => initialState);
  },
});

export const {
  reducer: collectionReducer,
  actions: {
    collectionFetchFinished,
    collectionFetchFailed,
    collectionSaveFinished,
    collectionSaveFailed,
    collectionTranslationEditRequested,
  },
} = collectionSlice;

export const selectCollectionFetching = (state: RootState): boolean =>
  state.translation.collection.isFetching;

export const selectCollectionFetched = (state: RootState): boolean =>
  state.translation.collection.isFetched;

export const selectCollectionSaving = (state: RootState): boolean =>
  state.translation.collection.isSaving;

export const selectCollectionSaved = (state: RootState): boolean =>
  state.translation.collection.isSaved;

export const selectCollectionError = (state: RootState): string | undefined =>
  state.translation.collection.error;

export const selectCollection = (
  state: RootState
): FrescoCollectionI18n | undefined => state.translation.collection.collection;

export const selectCollectionId = (state: RootState): FrescoId | undefined =>
  state.translation.collection.collection?.id;

export const selectCollectionName =
  (locale: ApiLocale) =>
  (state: RootState): string | undefined =>
    state.translation.collection.collection?.nameTranslations[locale];

export const selectCollectionDescription =
  (locale: ApiLocale) =>
  (state: RootState): string | undefined =>
    state.translation.collection.collection?.descriptionTranslations[locale];
