import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import WallpaperIcon from '@material-ui/icons/Wallpaper';
import type { FC } from 'react';
import { memo } from 'react';

import type { FrescoMedia } from 'shared/types/media';

const useStyles = makeStyles((theme) => ({
  tableImage: {
    width: theme.spacing(15),
    height: theme.spacing(15),
    backgroundSize: 'cover',
  },
  missingTableImage: {
    width: theme.spacing(15),
    height: theme.spacing(15),
    marginTop: theme.spacing(7),
  },
}));

export interface DataGridImageColumnProps {
  image: FrescoMedia | null;
  label: string;
}

export const DataGridImageColumn: FC<DataGridImageColumnProps> = memo(
  function DataGridImageColumn({ image, label }) {
    const classes = useStyles();

    return image?.data?.uri ? (
      <div
        role="img"
        aria-label={label}
        className={classes.tableImage}
        style={{
          backgroundImage: `url(${image?.data?.uri})`,
        }}
      />
    ) : (
      <Box color="text.secondary">
        <WallpaperIcon
          className={classes.missingTableImage}
          color="inherit"
          aria-label="no image"
        />
      </Box>
    );
  }
);
