import { combineReducers } from '@reduxjs/toolkit';
import { connectRouter } from 'connected-react-router';
import type { History } from 'history';

import { categoriesReducer } from 'features/categories/categoriesSlice';
import { collectionsReducer } from 'features/collections/collectionsSlice';
import { featureFlagsReducer } from 'features/developerMenu/featureFlagsSlice';
import { featuresReducer } from 'features/features/featuresSlice';
import { jobReducer } from 'features/jobs/jobSlice';
import { layoutSliceReducer } from 'features/layout/layoutSlice';
import { loginReducer } from 'features/login/loginSlice';
import { mediaUploadReducer } from 'features/mediaUpload/mediaUploadSlice';
import { recipeReducer } from 'features/recipe/recipeSlice/recipeReducer';
import { recipesSliceReducer } from 'features/recipes/recipesSlice';
import { snackbarReducer } from 'features/snackbar/snackbarSlice';
import { termsReducer } from 'features/terms/termsReducer';
import { translationReducer } from 'features/translation/translationReducer';

export const createRootReducer = (history: History) =>
  combineReducers({
    router: connectRouter(history),
    login: loginReducer,
    recipe: recipeReducer,
    recipes: recipesSliceReducer,
    terms: termsReducer,
    snackbar: snackbarReducer,
    layout: layoutSliceReducer,
    featureFlags: featureFlagsReducer,
    mediaUpload: mediaUploadReducer,
    translation: translationReducer,
    collections: collectionsReducer,
    categories: categoriesReducer,
    features: featuresReducer,
    job: jobReducer,
  });

export type RootState = ReturnType<ReturnType<typeof createRootReducer>>;
