import { Box } from '@material-ui/core';
import React from 'react';

import { Containers } from 'features/recipe/containers/Containers';
import { Ingredients } from 'features/recipe/ingredients/Ingredients';
import { Appliances } from 'features/recipe/preps/Appliances';

export const RecipePrepPage: React.FC = React.memo(function RecipePrepsPage() {
  return (
    <>
      <Box my={2}>
        <Ingredients />
      </Box>
      <Box mb={2}>
        <Containers />
      </Box>
      <Appliances />
    </>
  );
});
