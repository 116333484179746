import { Grid } from '@material-ui/core';
import type { FC } from 'react';
import { memo } from 'react';
import { useSelector } from 'react-redux';

import { selectContainerById } from 'features/recipe/containers/containersSlice';
import { StepContainers as StepContainersLayout } from 'shared/components/stepViewEntities/StepContainers';
import type { FrescoEntityUri } from 'shared/types/entity';
import { getIdFromUri } from 'shared/utils/common';

interface StepContainersProps {
  toContainer: FrescoEntityUri | null;
}

export const StepContainers: FC<StepContainersProps> = memo(
  function StepContainers({ toContainer }) {
    const toRecipeContainer = useSelector(
      selectContainerById(getIdFromUri(toContainer?.uri || ''))
    );

    if (!toRecipeContainer) {
      return null;
    }

    return (
      <Grid item xs={4}>
        <StepContainersLayout
          showHeader
          containerImage={toRecipeContainer?.container.image}
          containerName={toRecipeContainer?.container.name}
        />
      </Grid>
    );
  }
);
