import { Chip } from '@material-ui/core';
import type { FC } from 'react';
import { memo } from 'react';

import type { FrescoTag } from 'shared/types/recipe';

export interface DataGridTagsColumnProps {
  tags: FrescoTag[];
}

export const DataGridTagsColumn: FC<DataGridTagsColumnProps> = memo(
  function DataGridTagsColumn({ tags }) {
    if (!tags) return null;

    return (
      <>
        {tags.map((tag: FrescoTag) => (
          <Chip label={tag.name} style={{ marginRight: '5px' }} key={tag.id} />
        ))}
      </>
    );
  }
);
