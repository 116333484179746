import { useMemo } from 'react';
import { useSelector } from 'react-redux';

// eslint-disable-next-line import/namespace
import type { TranslationSuggestion } from 'features/translation/recipe/recipeSlice';
import {
  TranslationSuggestionType,
  selectRecipeNameTranslationsByLocaleAndType,
  selectRecipeSteps,
} from 'features/translation/recipe/recipeSlice';
import { tier1Locales } from 'shared/constants';
import { ApiLocale, RecipeDetailsTranslationType } from 'shared/types/i18n';
import { isVirtualStep } from 'shared/utils/common';

const isEmptyTranslation = (
  original: string | undefined,
  translated: string | undefined
): boolean => (!translated || !translated.trim()) && !!original;

export const useGetEmptyTranslations = (
  locale: ApiLocale
): TranslationSuggestion[] => {
  const nameLocale = useSelector(
    selectRecipeNameTranslationsByLocaleAndType(
      locale,
      RecipeDetailsTranslationType.Name
    )
  );
  const nameEnUs = useSelector(
    selectRecipeNameTranslationsByLocaleAndType(
      ApiLocale.EnUS,
      RecipeDetailsTranslationType.Name
    )
  );
  const introLocale = useSelector(
    selectRecipeNameTranslationsByLocaleAndType(
      locale,
      RecipeDetailsTranslationType.Introduction
    )
  );
  const introEnUs = useSelector(
    selectRecipeNameTranslationsByLocaleAndType(
      ApiLocale.EnUS,
      RecipeDetailsTranslationType.Introduction
    )
  );
  const completionLocale = useSelector(
    selectRecipeNameTranslationsByLocaleAndType(
      locale,
      RecipeDetailsTranslationType.CompletionCaption
    )
  );
  const completionEnUs = useSelector(
    selectRecipeNameTranslationsByLocaleAndType(
      ApiLocale.EnUS,
      RecipeDetailsTranslationType.CompletionCaption
    )
  );
  const stepsLocale = useSelector(selectRecipeSteps(locale));
  const stepsEnUs = useSelector(selectRecipeSteps(ApiLocale.EnUS));

  /* eslint-disable  @typescript-eslint/no-non-null-assertion */
  const emptyTranslations: TranslationSuggestion[] = [];
  if (isEmptyTranslation(nameEnUs, nameLocale)) {
    emptyTranslations.push({
      type: TranslationSuggestionType.Name,
      original: nameEnUs!,
    });
  }
  if (isEmptyTranslation(introEnUs, introLocale)) {
    emptyTranslations.push({
      type: TranslationSuggestionType.Introduction,
      original: introEnUs!,
    });
  }
  if (isEmptyTranslation(completionEnUs, completionLocale)) {
    emptyTranslations.push({
      type: TranslationSuggestionType.CompletionCaption,
      original: completionEnUs!,
    });
  }
  const emptySteps = useMemo(() => {
    const steps: TranslationSuggestion[] = [];
    stepsLocale?.forEach((step, index) => {
      if (isVirtualStep(step.uri)) return;
      const stepEnUs = stepsEnUs?.[index];
      if (!stepEnUs) return;

      const enUsSentence =
        stepEnUs.sentenceRawTranslations?.[ApiLocale.EnUS] || stepEnUs.sentence;
      if (tier1Locales.includes(locale)) {
        // step.sentenceRawTranslations[locale] === '' will be if we remove a translation
        if (
          enUsSentence &&
          (step.sentenceRawTranslations[locale] === '' ||
            (!step.sentenceRawTranslations[locale] &&
              step.sentence === step.sentenceRawTranslations[ApiLocale.EnUS]))
        ) {
          steps.push({
            type: TranslationSuggestionType.Step,
            original: enUsSentence,
            id: step.id,
          });
        }
      } else if (
        isEmptyTranslation(enUsSentence, step.sentenceRawTranslations[locale])
      ) {
        steps.push({
          type: TranslationSuggestionType.Step,
          original: enUsSentence!,
          id: step.id,
        });
      }

      const tips = step.tips
        .filter((tip) =>
          isEmptyTranslation(
            tip.captionTranslations[ApiLocale.EnUS],
            tip.captionTranslations[locale]
          )
        )
        .map((tip) => ({
          type: TranslationSuggestionType.Tip,
          original: tip.captionTranslations[ApiLocale.EnUS]!,
          id: tip.id,
        }));
      steps.push(...tips);
    });
    return steps;
  }, [locale, stepsLocale, stepsEnUs]);
  emptyTranslations.push(...emptySteps);
  /* eslint-enable  @typescript-eslint/no-non-null-assertion */

  return emptyTranslations;
};
