import type { FrescoId } from 'shared/types/entity';

export enum JobStatus {
  Finished = 'finished',
  Started = 'started',
}

export enum JobTranslationStatus {
  Error = 'Status.error',
  Success = 'Status.success',
}

export interface ApiTranslationsJobResult {
  status: JobTranslationStatus;
  message: string;
  details: {
    suggestions: string[];
  };
}

export interface ApiJob {
  status: JobStatus;
  description: string;
  startedAt: string;
  endedAt: string;
  result: ApiTranslationsJobResult;
}

export interface GetJobRequest {
  jobId: FrescoId;
}
