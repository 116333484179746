import type { AppAuthData } from 'features/login/loginSlice';
import type { FrescoId } from 'shared/types/entity';

/**
 * Unwraps Promise return value
 * e.g. `Promise<{ a: number; }>` => `{ a: number; }`
 */
export type PromiseReturnType<T> = T extends Promise<infer U>
  ? U
  : T extends (...args: never) => Promise<infer U>
  ? U
  : T extends (...args: never) => infer U
  ? U
  : T;

export interface LocationResponse {
  location: string;
}

export interface JobResponse {
  jobId: FrescoId;
}

export interface AckResponse {
  acknowledged: boolean;
}

export enum HttpMethod {
  Get = 'GET',
  Patch = 'PATCH',
  Post = 'POST',
  Put = 'PUT',
  Delete = 'DELETE',
}

export enum ApiResponseRequestVariant {
  Lean = 'lean',
  Default = 'default',
  Web = 'web',
  Full = 'full',
  I18n = 'i18n',
}

export interface SuccessResponse<T> {
  data: T;
  ok: true;
}

export enum ErrorLevel {
  Warning = 'warning',
  Error = 'error',
}

export interface ErrorPayload {
  code?: number;
  name?: string;
  message?: string;
  error?: string;
  type?: string;
  errorDescription?: string;
}

export interface ApiMeta {
  page: number;
  perPage: number;
  numResults: number;
  total: number;
}

export interface FrescoPaginatedResponse<TEntity> {
  query: string | null;
  meta: ApiMeta;
  results: TEntity[];
}

export type ApiResponse<TResponse> = SuccessResponse<TResponse> | ErrorResponse;

export interface ErrorResponseDetails {
  details: {
    code?: number;
    name?: string;
    level?: ErrorLevel;
    message: string;
  };
  httpStatus?: number;
}

export interface ErrorResponse extends ErrorResponseDetails {
  ok: false;
}
export interface ApiContext {
  authData: AppAuthData | null;
}

export type ApiRequestFn<TResponse> = (
  apiContext: ApiContext
) => Promise<ApiResponse<TResponse>>;

export type ApiRequestFnResponseType<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  TApiRequestFn extends (...args: any) => any
> = PromiseReturnType<ReturnType<TApiRequestFn>>;
