import { datadogLogs } from '@datadog/browser-logs';
import type { PayloadAction } from '@reduxjs/toolkit';
import { createAction } from '@reduxjs/toolkit';
import { takeEvery, select, call } from 'redux-saga/effects';

import type { AppAuthData } from 'features/login/loginSlice';
import { selectLoginAuthData } from 'features/login/loginSlice';
import { appConfig } from 'shared/config';

export const ingredientSplitRequest = 'Ingredient Split Requested';
export const ingredientSplitEvent = 'Ingredient Split';
export const ingredientSplitTableSplit = 'Requested from Ingredient Table';
export const ingredientSplitStepSplit = 'Requested from Step';
export const ingredientSplitSuccess = 'Ingredient Split Saga success';

interface DataDogLogInfo {
  event: string;
  name: string;
  params?: unknown;
}

export function* dataDogLogInfo({
  payload: { event, name, ...params },
}: PayloadAction<DataDogLogInfo>) {
  const authData: AppAuthData = yield select(selectLoginAuthData);

  yield call([datadogLogs.logger, datadogLogs.logger.info], event, {
    name,
    env: appConfig.appEnv(),
    oid: authData?.userOrganisationId || null,
    uid: authData?.userId,
    ...params,
  });
}

export const requestDataDogLogInfo = createAction<DataDogLogInfo>(
  'dataDog/requestDataDogLogInfo'
);

function* requestDataDogLogIntoWatcher() {
  yield takeEvery(requestDataDogLogInfo, dataDogLogInfo);
}

export const dataDogSagas = [requestDataDogLogIntoWatcher];
