import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice, createAction } from '@reduxjs/toolkit';
import { put, call, takeLatest } from 'redux-saga/effects';

import { createRequestApiSaga } from 'api/createRequestApiSaga';
import { apiPreparationsTermsGet } from 'api/terms';
import type { ApiResponse, FrescoPaginatedResponse } from 'api/types';
import type { RootState } from 'app/rootReducer';
import { logoutSuccess } from 'features/login/loginSlice';
import type { FrescoTerm } from 'shared/types/entity';
import { getErrorString } from 'shared/utils/common';

interface TermsPreparationsState {
  apiError?: string;
  apiPending: boolean;
  entities: FrescoTerm[];
}

export const initialState: TermsPreparationsState = {
  apiPending: false,
  entities: [],
};

const termsPreparationsSlice = createSlice({
  name: 'termsPreparationsSlice',
  initialState,
  reducers: {
    termsPreparationsSuccess(state, { payload }: PayloadAction<FrescoTerm[]>) {
      state.entities = payload;
      state.apiPending = false;
      state.apiError = undefined;
    },
    termsPreparationsApiPending(state) {
      state.apiPending = true;
      state.apiError = undefined;
    },
    termsPreparationsApiError(state, { payload }: PayloadAction<string>) {
      state.apiPending = false;
      state.apiError = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(logoutSuccess, () => initialState);
  },
});

export const {
  reducer: termsPreparationsReducer,
  actions: {
    termsPreparationsSuccess,
    termsPreparationsApiPending,
    termsPreparationsApiError,
  },
} = termsPreparationsSlice;

const selectTermsPreparationsState = (
  state: RootState
): TermsPreparationsState => state.terms.preparations;

export const selectTermsPreparationsEntities = (
  state: RootState
): FrescoTerm[] => selectTermsPreparationsState(state).entities;

export const selectTermsPreparationsApiPending = (state: RootState): boolean =>
  selectTermsPreparationsState(state).apiPending;

export const selectTermsPreparationsApiError = (
  state: RootState
): string | undefined => selectTermsPreparationsState(state).apiError;

export const apiGetPreparationTermsSaga = createRequestApiSaga(
  apiPreparationsTermsGet,
  'Preparations loading'
);

export function* requestPreparationTerms() {
  try {
    yield put(termsPreparationsApiPending());
    const response: ApiResponse<FrescoPaginatedResponse<FrescoTerm>> =
      yield call(apiGetPreparationTermsSaga);
    if (!response.ok) {
      yield put(termsPreparationsApiError(response.details.message));
      return;
    }
    yield put(termsPreparationsSuccess(response.data.results));
    return;
  } catch (e) {
    yield put(termsPreparationsApiError(getErrorString(e)));
  }
}

export const preparationTermsFetchRequested = createAction(
  'terms/preparationTermsFetchRequested'
);

function* requestPreparationTermsFetchWatcher() {
  yield takeLatest(preparationTermsFetchRequested, requestPreparationTerms);
}

export const preparationTermsSagas = [requestPreparationTermsFetchWatcher];
