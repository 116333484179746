import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { getSelectUserHasAccessGroupRights } from 'features/login/loginSlice';
import type { PermissionsCheckRuleSet } from 'shared/permissions';

export const useSelectUserHasAccessGroupRights = (
  ruleSet: PermissionsCheckRuleSet
) => {
  const memoizedUserHasAccessGroupRights = useMemo(
    () => getSelectUserHasAccessGroupRights(ruleSet),
    [ruleSet]
  );

  return useSelector(memoizedUserHasAccessGroupRights);
};
