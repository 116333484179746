import {
  DeleteIcon,
  EditIcon,
  MoreMenuIcon,
  CopyIcon,
} from '@dropkitchen/icons-react';
import {
  Paper,
  Box,
  Typography,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  makeStyles,
  useTheme,
  IconButton,
  CircularProgress,
  Button,
  Grid,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Tooltip,
} from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import type { EntityId } from '@reduxjs/toolkit';
import classNames from 'classnames';
import isNil from 'lodash/isNil';
import partition from 'lodash/partition';
import React, { useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { selectRecipeFetched } from 'features/recipe/api/apiSlice';
import {
  recipeCreateEntityRequested,
  RecipeEditEntityType,
  recipeEditEntityRequested,
  selectRecipeEditMode,
  RecipeEditMode,
  RecipeSplitMode,
  selectRecipeEditEntityType,
  selectRecipeEditEntityId,
  recipeSplitEntityRequested,
  RecipeSplitEntityType,
  selectRecipeSplitMode,
  selectIsSideBarOpen,
} from 'features/recipe/edit/editSlice';
import {
  selectIngredientsAll,
  selectIngredientById,
  selectIngredientIsSavingById,
  ingredientRemoveRequested,
} from 'features/recipe/ingredients/ingredientsSlice';
import {
  selectDoesIngredientGroupExistInStep,
  selectStepsAll,
} from 'features/recipe/steps/stepsSlice';
import { FrescoIcon } from 'shared/components/FrescoIcon';
import { TableSkeleton } from 'shared/components/Skeletons';
import type { ApiIngredientGroupIngredient } from 'shared/types/ingredient';
import {
  requestDataDogLogInfo,
  ingredientSplitRequest,
  ingredientSplitTableSplit,
} from 'shared/utils/dataDogSagas';

import { ReactComponent as IngredientsIllustraion } from './ingredients-empty-state.svg';

const useStyles = makeStyles((theme) => ({
  cell: {
    padding: theme.spacing(1),
  },
  iconCell: {
    width: theme.spacing(5),
  },
  editCell: {
    width: theme.spacing(3),
  },
  headerContainer: {
    display: 'flex',
  },
  headerText: {
    flexGrow: 1,
  },
  menuItem: {
    display: 'flex',
  },
  menuIcon: {
    justifyContent: 'flex-end',
  },
  marginTop: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  addButton: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
  active: {
    border: `2px solid ${theme.palette.primary.main}`,
  },
  ingredientTableHeader: {
    ...theme.typography.overline,
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(2),
  },
  tableMargin: {
    marginBottom: theme.spacing(2),
  },
}));

interface RowProps {
  recipeIngredientId: EntityId;
}

const Row: React.FC<RowProps> = React.memo(function Row({
  recipeIngredientId,
}) {
  const classes = useStyles();
  const theme = useTheme();
  const dispatch = useDispatch();
  const editMode = useSelector(selectRecipeEditMode);
  const splitMode = useSelector(selectRecipeSplitMode);
  const editingEntityId = useSelector(selectRecipeEditEntityId);
  const isSideBarOpen = useSelector(selectIsSideBarOpen);

  const isEditing = editMode === RecipeEditMode.Edit;
  const isSplitting = splitMode === RecipeSplitMode.Split;
  const isCreating = editMode === RecipeEditMode.Create;

  const ingredientGroupIngredient = useSelector(
    selectIngredientById(recipeIngredientId)
  );
  const isSaving = useSelector(
    selectIngredientIsSavingById(recipeIngredientId)
  );
  const isIngredientUsedInStep = useSelector(
    selectDoesIngredientGroupExistInStep(
      ingredientGroupIngredient?.uri?.split('/ingredients/')[0]
    )
  );
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const handleOpenEditDelete = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseEditDelete = () => {
    setAnchorEl(null);
  };

  const handleEditClick = () => {
    dispatch(
      recipeEditEntityRequested({
        editEntityType: RecipeEditEntityType.Ingredient,
        editEntityId: recipeIngredientId,
      })
    );
    setAnchorEl(null);
  };

  const handleSplitClick = () => {
    dispatch(
      requestDataDogLogInfo({
        event: ingredientSplitRequest,
        name: ingredientSplitTableSplit,
        params: {
          ingredientId: recipeIngredientId,
        },
      })
    );

    dispatch(
      recipeSplitEntityRequested({
        splitEntityType: RecipeSplitEntityType.Ingredient,
        splitEntityId: recipeIngredientId,
      })
    );
  };

  const handleDeleteClick = () => {
    dispatch(ingredientRemoveRequested({ id: recipeIngredientId }));
    setAnchorEl(null);
  };

  if (!ingredientGroupIngredient) {
    return null;
  }

  const isBeingEdited = isEditing && editingEntityId === recipeIngredientId;
  const isOptional = !isNil(ingredientGroupIngredient?.flags?.required)
    ? !ingredientGroupIngredient?.flags?.required
    : false;

  return (
    <TableRow
      className={classNames({
        [classes.active]: isBeingEdited,
      })}
      key={ingredientGroupIngredient.id}
    >
      <TableCell align="left" className={classes.cell}>
        <FrescoIcon
          image={ingredientGroupIngredient.ingredient.image}
          name={ingredientGroupIngredient.ingredient.name}
          color={ingredientGroupIngredient.ingredient.color}
        />
      </TableCell>
      <TableCell align="left" className={classes.cell}>
        {ingredientGroupIngredient?.sourceText}
      </TableCell>
      <TableCell align="left" width="100px" className={classes.cell}>
        {ingredientGroupIngredient.ingredient.name}
      </TableCell>
      <TableCell align="left" className={classes.cell}>
        {ingredientGroupIngredient?.amount
          ? `${ingredientGroupIngredient.amount} ${ingredientGroupIngredient?.units}`
          : 'as needed'}
      </TableCell>
      <TableCell align="left" className={classes.cell}>
        {[
          ingredientGroupIngredient?.preparation1?.name,
          ingredientGroupIngredient?.preparation2?.name,
          ingredientGroupIngredient?.preparation3?.name,
        ]
          .filter(Boolean)
          .join(', ')}
      </TableCell>
      <TableCell align="left" className={classes.cell}>
        {isOptional && <CheckIcon aria-label="optional" />}
      </TableCell>
      <TableCell align="left" className={classes.cell}>
        {!isSaving && (
          <IconButton
            aria-label="Split Ingredient"
            size="small"
            edge="start"
            color="inherit"
            disabled={
              !ingredientGroupIngredient.amount || isSplitting || isCreating
            }
            onClick={handleSplitClick}
          >
            <CopyIcon />
          </IconButton>
        )}
      </TableCell>
      <TableCell align="left" className={classes.cell}>
        {!isSaving && !isIngredientUsedInStep && (
          <>
            <IconButton
              aria-label="Ingredient Menu"
              size="small"
              edge="start"
              color="inherit"
              disabled={isSideBarOpen}
              onClick={handleOpenEditDelete}
            >
              <MoreMenuIcon />
            </IconButton>
            <Menu
              disableEnforceFocus
              disableRestoreFocus
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleCloseEditDelete}
            >
              <MenuItem className={classes.menuItem} onClick={handleEditClick}>
                <ListItemText primary="Edit Ingredient" />
                <ListItemIcon className={classes.menuIcon}>
                  <EditIcon />
                </ListItemIcon>
              </MenuItem>
              <Tooltip
                aria-label="Ingredient Warning"
                placement="left-start"
                disableHoverListener={!isIngredientUsedInStep}
                title="You cannot delete an ingredient that is currently used in a step"
              >
                <span>
                  <MenuItem
                    className={classes.menuItem}
                    onClick={handleDeleteClick}
                    disabled={isIngredientUsedInStep}
                  >
                    <ListItemText primary="Delete Ingredient" />
                    <ListItemIcon className={classes.menuIcon}>
                      <DeleteIcon />
                    </ListItemIcon>
                  </MenuItem>
                </span>
              </Tooltip>
            </Menu>
          </>
        )}
        {!isSaving && isIngredientUsedInStep && (
          <IconButton
            aria-label="Edit Ingredient"
            size="small"
            edge="start"
            color="inherit"
            disabled={isSideBarOpen}
            onClick={handleEditClick}
          >
            <EditIcon />
          </IconButton>
        )}
        {isSaving && (
          <CircularProgress
            aria-label="Saving indicator"
            size={theme.spacing(4)}
            style={{ verticalAlign: 'middle' }}
          />
        )}
      </TableCell>
    </TableRow>
  );
});

interface TableProps {
  header: string;
  ingredients: ApiIngredientGroupIngredient[];
}

const IngredientTable: React.FC<TableProps> = React.memo(
  function IngredientTable({ header, ingredients }) {
    const classes = useStyles();

    if (!ingredients) {
      return null;
    }

    return (
      <>
        <Typography className={classes.ingredientTableHeader}>
          {header}
        </Typography>
        <Table className={classes.tableMargin}>
          <TableHead>
            <TableRow>
              <TableCell
                align="left"
                className={classNames(classes.cell, classes.iconCell)}
              >
                <Typography variant="caption">Icon</Typography>
              </TableCell>
              <TableCell align="left" className={classes.cell}>
                <Typography variant="caption">Source Text</Typography>
              </TableCell>
              <TableCell align="left" className={classes.cell}>
                <Typography variant="caption">Ingredient name</Typography>
              </TableCell>
              <TableCell align="left" className={classes.cell}>
                <Typography variant="caption">Quantity</Typography>
              </TableCell>
              <TableCell align="left" className={classes.cell}>
                <Typography variant="caption">Preparations</Typography>
              </TableCell>
              <TableCell align="left" className={classes.cell}>
                <Typography variant="caption">Optional</Typography>
              </TableCell>
              <TableCell align="left" className={classes.cell}>
                <Typography variant="caption">Split</Typography>
              </TableCell>
              <TableCell
                align="left"
                className={classNames(classes.cell, classes.editCell)}
              >
                <Typography variant="caption">Edit</Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          {!!ingredients?.length && (
            <TableBody>
              {ingredients.map((ingredientGroupIngredient) => (
                <Row
                  key={ingredientGroupIngredient.id}
                  recipeIngredientId={ingredientGroupIngredient.id}
                />
              ))}
            </TableBody>
          )}
        </Table>
      </>
    );
  }
);

export const Ingredients: React.FC = React.memo(function Ingredients() {
  const classes = useStyles();
  const theme = useTheme();
  const ingredients = useSelector(selectIngredientsAll);
  const steps = useSelector(selectStepsAll);
  const isFetched = useSelector(selectRecipeFetched);
  const editMode = useSelector(selectRecipeEditMode);

  const editEntityType = useSelector(selectRecipeEditEntityType);
  const showCreatingText =
    editMode === RecipeEditMode.Create &&
    editEntityType === RecipeEditEntityType.Ingredient;

  const dispatch = useDispatch();

  const handleAddClick = () => {
    dispatch(
      recipeCreateEntityRequested({
        editEntityType: RecipeEditEntityType.Ingredient,
      })
    );
  };

  const [usedIngredients, unUsedIngredients] = useMemo(
    () =>
      partition(ingredients, (ingredient) =>
        steps.some(
          (step) =>
            step?.ingredientGroup?.uri &&
            ingredient.uri.startsWith(step.ingredientGroup.uri)
        )
      ),
    [ingredients, steps]
  );

  return (
    <Paper>
      <Box p={2}>
        <div className={classes.headerContainer}>
          <Typography variant="h6" className={classes.headerText}>
            Ingredients
          </Typography>
        </div>
        {isFetched && !ingredients?.length && (
          <Grid
            direction="column"
            justify="center"
            alignItems="center"
            container
          >
            <IngredientsIllustraion className={classes.marginTop} />
            {showCreatingText ? (
              <Typography variant="h6" className={classes.marginTop}>
                Once added, ingredients will show here
              </Typography>
            ) : (
              <>
                <Typography variant="h6" className={classes.marginTop}>
                  Begin by adding an ingredient
                </Typography>
                <Button
                  className={classes.addButton}
                  color="primary"
                  variant="contained"
                  onClick={handleAddClick}
                >
                  Add Ingredient
                </Button>
              </>
            )}
          </Grid>
        )}
        {!isFetched && (
          <TableSkeleton
            columnsCount={5}
            rowsCount={5}
            cellClassName={classes.cell}
            skeletonItemHeight={theme.spacing(5)}
          />
        )}
        {!!usedIngredients?.length && (
          <IngredientTable
            ingredients={usedIngredients}
            header="Assigned to recipe steps"
          />
        )}
        {!!unUsedIngredients?.length && (
          <IngredientTable
            ingredients={unUsedIngredients}
            header="Unassigned"
          />
        )}
        {!!ingredients?.length && (
          <Button
            className={classes.addButton}
            variant="text"
            aria-label="Add ingredient"
            color="primary"
            onClick={handleAddClick}
          >
            Add Ingredient
          </Button>
        )}
      </Box>
    </Paper>
  );
});
