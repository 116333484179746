import { CopyIcon } from '@dropkitchen/icons-react';
import {
  TextField,
  Grid,
  IconButton,
  Typography,
  makeStyles,
  Chip,
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import classNames from 'classnames';
import React, { useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import {
  selectRecipeEditEntityId,
  recipeSplitEntityRequested,
  RecipeSplitEntityType,
} from 'features/recipe/edit/editSlice';
import { selectIngredientsAll } from 'features/recipe/ingredients/ingredientsSlice';
import {
  selectStepById,
  selectStepsAll,
} from 'features/recipe/steps/stepsSlice';
import type { FrescoId } from 'shared/types/entity';
import type { ApiIngredientGroupIngredient } from 'shared/types/ingredient';
import { getIdFromUri, getIngredientGroupId } from 'shared/utils/common';
import {
  requestDataDogLogInfo,
  ingredientSplitRequest,
  ingredientSplitStepSplit,
} from 'shared/utils/dataDogSagas';

const useStyles = makeStyles((theme) => ({
  option: {
    '&[data-focus="true"]': {
      cursor: 'default',
      backgroundColor: '#FFF',
    },
    padding: 0,
    margin: 0,
  },
  ingredientAutocompleteRow: {
    marginLeft: -25,
    padding: `${theme.spacing(1)}px ${theme.spacing(3)}px ${theme.spacing(
      1
    )}px ${theme.spacing(3)}px `,
    cursor: 'pointer',
    width: '110%',
    heigt: '100%',
    '&:hover': {
      backgroundColor: '#cccccc',
    },
  },
  ingredientText: {
    fontWeight: 'bold',
    fontFamily: 'DMSans-Regular',
  },
  disabled: {
    color: '#9E9E9E',
    cursor: 'default',
    background: 'transparent',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  ingredientSplitIcon: {
    color: '#333333',
  },
}));
export interface IngredientsAutoCompleteHelper {
  usedInStep?: string;
  disabled?: boolean;
  ingredient: ApiIngredientGroupIngredient;
}

interface StepIngredientsProps {
  stepIngredients: IngredientsAutoCompleteHelper[];
  onStepIngredientsChange: (
    stepIngredients: IngredientsAutoCompleteHelper[]
  ) => void;
}

export const getIngredientPrepSentence = (
  option: IngredientsAutoCompleteHelper
) =>
  [
    option?.ingredient.preparation1?.name,
    option?.ingredient.preparation2?.name,
    option?.ingredient.preparation3?.name,
  ]
    .filter(Boolean)
    .join(', ');

export const getIngredientLabel = (option: IngredientsAutoCompleteHelper) => {
  const preparations = getIngredientPrepSentence(option);
  const preparationLabel = preparations ? ` ${preparations}` : '';
  return option.ingredient.amount
    ? `${option.ingredient.ingredient.name} (${option.ingredient.amount} ${option.ingredient.units})${preparationLabel}`
    : `${option.ingredient.ingredient.name} (as needed)${preparationLabel}`;
};

export const StepIngredients: React.FC<StepIngredientsProps> = React.memo(
  function StepIngredients({ stepIngredients, onStepIngredientsChange }) {
    const classes = useStyles();
    const dispatch = useDispatch();

    const [open, setOpen] = React.useState<boolean>(false);
    const editingEnityId = useSelector(selectRecipeEditEntityId);
    const editEntity = useSelector(selectStepById(editingEnityId ?? ''));
    const ingredients = useSelector(selectIngredientsAll);
    const steps = useSelector(selectStepsAll);

    const stepsWithIngredientGroups = useMemo(
      () =>
        steps.reduce((map, step, i) => {
          if (step?.ingredientGroup?.uri) {
            map[getIdFromUri(step.ingredientGroup.uri)] = i;
          }
          return map;
        }, {} as Record<string, number>),
      [steps]
    );

    const ingredientsAutocomplete: IngredientsAutoCompleteHelper[] = useMemo(
      () =>
        ingredients.map((ingredient) => {
          const stepIndex =
            stepsWithIngredientGroups[getIngredientGroupId(ingredient.uri)];
          return editEntity?.ingredientGroup?.uri &&
            ingredient.uri.startsWith(editEntity.ingredientGroup.uri)
            ? {
                ingredient,
                usedInStep: stepIndex > -1 ? `Step ${stepIndex + 1}` : '',
                disabled: false,
              }
            : {
                ingredient,
                usedInStep:
                  stepIndex > -1 ? `Step ${stepIndex + 1}` : 'Not Used',
                disabled: stepIndex > -1,
              };
        }),
      [ingredients, stepsWithIngredientGroups, editEntity]
    );

    const sortedIngredientsAutocomplete = useMemo(
      () =>
        ingredientsAutocomplete.sort((a, b) =>
          b.usedInStep && a.usedInStep
            ? -b.usedInStep.localeCompare(a.usedInStep, undefined, {
                numeric: true,
                sensitivity: 'base',
              })
            : -1
        ),
      [ingredientsAutocomplete]
    );

    const ingredientTagRecord = useMemo(
      () =>
        sortedIngredientsAutocomplete.reduce((map, ingredient) => {
          map[ingredient.ingredient.id] = getIngredientLabel(ingredient);
          return map;
        }, {} as Record<string, string>),
      [sortedIngredientsAutocomplete]
    );

    const handleSplitClick = (ingredientId: FrescoId) => {
      dispatch(
        requestDataDogLogInfo({
          event: ingredientSplitRequest,
          name: ingredientSplitStepSplit,
          params: {
            ingredientId,
          },
        })
      );

      dispatch(
        recipeSplitEntityRequested({
          splitEntityType: RecipeSplitEntityType.Ingredient,
          splitEntityId: ingredientId,
        })
      );
    };

    return (
      <Grid item xs={12}>
        <Autocomplete
          multiple
          classes={{
            option: classes.option,
          }}
          disableCloseOnSelect
          open={open}
          onOpen={() => setOpen(true)}
          onClose={() => setOpen(false)}
          data-testid="ingredient"
          value={stepIngredients}
          options={sortedIngredientsAutocomplete}
          groupBy={(option) => option?.usedInStep || 'Not Used'}
          getOptionLabel={(option) => ingredientTagRecord[option.ingredient.id]}
          getOptionSelected={(option, value) =>
            option.ingredient.uri === value.ingredient.uri
          }
          renderTags={(tagValue, getTagProps) =>
            tagValue.map((option, index) => (
              <Chip
                key={index}
                label={ingredientTagRecord[option.ingredient.id]}
                {...getTagProps({ index })}
              />
            ))
          }
          renderOption={(option) => (
            <Grid
              className={classNames(classes.ingredientAutocompleteRow, {
                [classes.disabled]: option.disabled,
              })}
              container
            >
              <Grid item xs={11}>
                <Typography className={classes.ingredientText}>
                  {ingredientTagRecord[option.ingredient.id] || ''}
                </Typography>
              </Grid>
              <Grid item xs={1}>
                <IconButton
                  aria-label="Split Ingredient"
                  size="small"
                  edge="start"
                  className={classNames(classes.ingredientSplitIcon, {
                    [classes.disabled]: !option.ingredient.amount,
                  })}
                  disabled={!option.ingredient.amount}
                  onClick={(event) => {
                    event.stopPropagation();
                    setOpen(false);
                    handleSplitClick(option.ingredient.id);
                  }}
                >
                  <CopyIcon />
                </IconButton>
              </Grid>
            </Grid>
          )}
          onChange={(_e, value) => {
            // Have to disable item click like this to allow splitting
            if (value.some((values) => values.disabled)) {
              return;
            }
            onStepIngredientsChange(value);
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Ingredients"
              variant="outlined"
              fullWidth
            />
          )}
        />
      </Grid>
    );
  }
);
