import { makeStyles, Typography, Grid } from '@material-ui/core';
import type { FC } from 'react';

import { StepTipMedia } from 'shared/components/stepViewEntities/StepTipMedia';
import type { FrescoMedia, FrescoVideo } from 'shared/types/media';
import { isVirtualTip } from 'shared/utils/common';

const useStyles = makeStyles((theme) => ({
  tipContainer: {
    backgroundColor: '#ffffff',
  },
  caption: {
    padding: theme.spacing(1.5),
    fontSize: 14,
  },
}));

interface StepTipsProps {
  caption: string;
  video: FrescoVideo | null;
  image: FrescoMedia | null;
  uri: string;
}

export const StepTip: FC<StepTipsProps> = ({ caption, video, image, uri }) => {
  const classes = useStyles();

  if (!uri) {
    return null;
  }

  return (
    <Grid container direction="row" className={classes.tipContainer}>
      <Grid item>
        <StepTipMedia image={image} video={video} />
      </Grid>
      <Grid item xs>
        <Typography className={classes.caption}>
          {isVirtualTip(uri) ? `Virtual tip: ${caption}` : caption}
        </Typography>
      </Grid>
    </Grid>
  );
};
