import { makeStyles, Grid, Typography } from '@material-ui/core';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { selectIngredientsByGroupUri } from 'features/recipe/ingredients/ingredientsSlice';
import { StepIngredient } from 'shared/components/stepViewEntities/StepIngredient';

interface StepIngredientsProps {
  ingredientGroupUri: string | undefined;
}

const useStyles = makeStyles((theme) => ({
  subheader: {
    ...theme.typography.overline,
    transform: 'uppercase',
  },
}));

export const StepIngredients: React.FC<StepIngredientsProps> = React.memo(
  function StepIngredients({ ingredientGroupUri }) {
    const classes = useStyles();
    const ingredientsMemoizedSelector = useMemo(
      () =>
        ingredientGroupUri
          ? selectIngredientsByGroupUri(ingredientGroupUri)
          : () => null,
      [ingredientGroupUri]
    );
    const ingredients = useSelector(ingredientsMemoizedSelector);

    if (!ingredients) {
      return null;
    }

    return (
      <Grid item xs={ingredients.length > 1 ? 12 : 4}>
        <Typography className={classes.subheader}>Ingredients</Typography>
        <Grid container direction="row" spacing={2}>
          {ingredients.map(({ id, ingredient, quantity }) => (
            <Grid item xs={ingredients.length > 1 ? 4 : 12} key={id}>
              <StepIngredient
                key={id}
                ingredientImage={ingredient.image}
                ingredientName={ingredient.name}
                ingredientColor={ingredient.color}
                quantity={quantity}
              />
            </Grid>
          ))}
        </Grid>
      </Grid>
    );
  }
);
