import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice, createAction } from '@reduxjs/toolkit';
import { put, call, select, takeLatest } from 'redux-saga/effects';

import { apiGetCategories } from 'api/category';
import { createRequestApiSaga } from 'api/createRequestApiSaga';
import type { ApiResponse } from 'api/types';
import type { RootState } from 'app/rootReducer';
import { logoutSuccess } from 'features/login/loginSlice';
import { selectCurrentLocale } from 'features/translation/localeSwitcher/localeSlice';
import type {
  FrescoCategoriesGetResponse,
  FrescoCategory,
} from 'shared/types/category';
import { TranslationStatus } from 'shared/types/i18n';
import { getErrorString } from 'shared/utils/common';

export interface Filters {
  translationStatus?: TranslationStatus;
}

export interface CategoriesState {
  apiError?: string;
  apiPending: boolean;
  filters: Filters;
  page: number;
  pageSize: number;
  totalPages: number;
  entites: FrescoCategory[];
}

export const initialState: CategoriesState = {
  apiPending: false,
  entites: [],
  filters: {
    translationStatus: TranslationStatus.All,
  },
  page: 1,
  pageSize: 25,
  totalPages: 0,
};

const categoriesSlice = createSlice({
  name: 'categoriesSlice',
  initialState,
  reducers: {
    categoriesApiSuccess(
      state,
      { payload }: PayloadAction<FrescoCategoriesGetResponse>
    ) {
      state.apiPending = false;
      state.apiError = undefined;
      state.totalPages = payload.meta.total;
      state.entites = payload.results;
    },
    categoriesApiPending(state) {
      state.apiPending = true;
      state.apiError = undefined;
    },
    categoriesApiError(state, { payload }: PayloadAction<string>) {
      state.apiPending = false;
      state.apiError = payload;
      state.entites = [];
    },
    categoriesPageSet(state, { payload }: PayloadAction<number>) {
      state.page = payload;
    },
    categoriesPageSizeSet(state, { payload }: PayloadAction<number>) {
      state.pageSize = payload;
      state.page = 1;
    },
    categoriesTotalPagesSet(state, { payload }: PayloadAction<number>) {
      state.totalPages = payload;
    },
    categoriesFiltersSetTranslationStatus(
      state,
      { payload }: PayloadAction<TranslationStatus>
    ) {
      state.filters.translationStatus = payload;
      state.page = 1;
    },
    categoriesFiltersReset(state) {
      state.filters = initialState.filters;
      state.page = 1;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(logoutSuccess, () => initialState);
  },
});

export const {
  reducer: categoriesReducer,
  actions: {
    categoriesApiSuccess,
    categoriesApiPending,
    categoriesApiError,
    categoriesPageSet,
    categoriesPageSizeSet,
    categoriesTotalPagesSet,
    categoriesFiltersSetTranslationStatus,
    categoriesFiltersReset,
  },
} = categoriesSlice;

export const selectCategories = (state: RootState): FrescoCategory[] =>
  state.categories.entites;

export const selectCategoriesPage = (state: RootState): number =>
  state.categories.page;

export const selectCategoriesPageSize = (state: RootState): number =>
  state.categories.pageSize;

export const selectCategoriesTotalPages = (state: RootState): number =>
  state.categories.totalPages;

export const selectCategoriesFilters = (
  state: RootState
): Filters | undefined => state.categories.filters;

export const selectCategoriesFiltersTranslationStatus = (
  state: RootState
): TranslationStatus | undefined => state.categories.filters?.translationStatus;

export const selectCategoriesApiError = (
  state: RootState
): string | undefined => state.categories.apiError;

export const selectCategoriesApiPending = (state: RootState): boolean =>
  state.categories.apiPending;

export const apiGetCategoriesSaga = createRequestApiSaga(
  apiGetCategories,
  'Categories loading'
);

export function* requestCategories() {
  yield put(categoriesApiPending());

  const page: ReturnType<typeof selectCategoriesPage> = yield select(
    selectCategoriesPage
  );
  const pageSize: ReturnType<typeof selectCategoriesPageSize> = yield select(
    selectCategoriesPageSize
  );
  const translationStatus: ReturnType<
    typeof selectCategoriesFiltersTranslationStatus
  > = yield select(selectCategoriesFiltersTranslationStatus);
  const locale: ReturnType<typeof selectCurrentLocale> = yield select(
    selectCurrentLocale
  );

  try {
    const response: ApiResponse<FrescoCategoriesGetResponse> = yield call(
      apiGetCategoriesSaga,
      { page, pageSize, translationStatus, locale }
    );
    if (!response.ok) {
      yield put(categoriesApiError(response.details.message));
      return;
    }
    yield put(categoriesApiSuccess(response.data));
    return;
  } catch (e) {
    yield put(categoriesApiError(getErrorString(e)));
  }
}

export const categoriesFetchRequested = createAction(
  'categoriesSlice/fetchRequested'
);

function* requestCategoriesFetchWatcher() {
  yield takeLatest(categoriesFetchRequested, requestCategories);
}
export const categoriesSagas = [requestCategoriesFetchWatcher];
