import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice, createAction, createSelector } from '@reduxjs/toolkit';

import type { GetRecipeRequest } from 'api/recipe';
import type { RootState } from 'app/rootReducer';
import { logoutSuccess } from 'features/login/loginSlice';
import type { FrescoId } from 'shared/types/entity';
import type {
  ApiRecipeI18n,
  ApiStepI18n,
  FrescoStepTipI18n,
  RecipeDetailsTranslationType,
} from 'shared/types/i18n';
import { ApiLocale } from 'shared/types/i18n';
import type { FrescoRecipeStatus } from 'shared/types/recipe';
import { isVirtualStep } from 'shared/utils/common';

export interface PatchRecipeRequest {
  recipeId: FrescoId;
  locale: ApiLocale;
}

export interface RecipeDetailsSentencePayload {
  localeSentence: string | undefined;
  locale: ApiLocale;
  type: RecipeDetailsTranslationType;
}

export interface RecipeStatusPayload {
  locale: ApiLocale;
  status: FrescoRecipeStatus;
}

export enum TranslationSuggestionType {
  Name = 'name',
  Introduction = 'introduction',
  CompletionCaption = 'completionCaption',
  Step = 'step',
  Tip = 'tip',
}

export interface TranslationSuggestion {
  type: TranslationSuggestionType;
  original: string;
  id?: FrescoId;
  suggestion?: string;
}

export interface TranslationRecipeState {
  isFetched: boolean;
  isFetching: boolean;
  isFetchedTranslations: boolean;
  isFetchingTranslations: boolean;
  isSaving: boolean;
  isSaved: boolean;
  isStatusSaved: boolean;
  error?: string;
  recipes: Partial<Record<ApiLocale, ApiRecipeI18n>>;
  suggestions: Partial<Record<ApiLocale, TranslationSuggestion[]>>;
}

export interface FetchTranslationsPayload {
  locale: ApiLocale;
  sentences: TranslationSuggestion[];
}

export const initialState: TranslationRecipeState = {
  isFetching: false,
  isFetched: false,
  isFetchingTranslations: false,
  isFetchedTranslations: false,
  isSaving: false,
  isSaved: false,
  isStatusSaved: true,
  recipes: {},
  suggestions: {},
};

export const recipeFetchRequested = createAction<GetRecipeRequest>(
  'translation/recipeSlice/recipeFetchRequested'
);

export const recipeSaveRequested = createAction<PatchRecipeRequest>(
  'translation/recipeSlice/recipeSaveRequested'
);

const recipeSlice = createSlice({
  name: 'translation/recipeSlice',
  initialState,
  reducers: {
    recipeFetchFinished(
      state,
      {
        payload: { recipeLocale, recipeEnUs, locale },
      }: PayloadAction<{
        recipeLocale?: ApiRecipeI18n;
        recipeEnUs: ApiRecipeI18n;
        locale: ApiLocale;
      }>
    ) {
      if (recipeLocale) {
        state.recipes[locale] = recipeLocale;
      }
      state.recipes[ApiLocale.EnUS] = recipeEnUs;
      state.isFetched = true;
      state.isFetching = false;
      state.error = undefined;
    },
    recipeFetchFailed(state, { payload }: PayloadAction<string>) {
      state.error = payload;
      state.isFetched = false;
      state.isFetching = false;
    },
    recipeFetchTranslationsFinished(
      state,
      {
        payload: { locale, sentences, suggestions },
      }: PayloadAction<{
        locale: ApiLocale;
        sentences: TranslationSuggestion[];
        suggestions: string[];
      }>
    ) {
      state.isFetchedTranslations = true;
      state.isFetchingTranslations = false;
      state.error = undefined;
      const sentencesWithSuggestions: TranslationSuggestion[] = sentences.map(
        (sentence, index) => ({ ...sentence, suggestion: suggestions[index] })
      );
      state.suggestions[locale] = sentencesWithSuggestions;
    },
    recipeFetchTranslationsFailed(state, { payload }: PayloadAction<string>) {
      state.error = payload;
      state.isFetchedTranslations = false;
      state.isFetchingTranslations = false;
    },
    recipeSaveFinished(state) {
      state.isSaved = true;
      state.isSaving = false;
      state.error = undefined;
      state.isStatusSaved = true;
    },
    recipeSaveFailed(state, { payload }: PayloadAction<string>) {
      state.error = payload;
      state.isSaved = false;
      state.isSaving = false;
    },
    recipeStepEditRequested(
      state,
      {
        payload: { step, stepIndex, locale },
      }: PayloadAction<{
        step: ApiStepI18n;
        stepIndex: number;
        locale: ApiLocale;
      }>
    ) {
      const localeRecipe = state.recipes[locale];
      if (localeRecipe && localeRecipe?.steps?.[stepIndex]) {
        localeRecipe.steps[stepIndex].sentenceRawTranslations =
          step.sentenceRawTranslations;
        state.recipes[locale] = localeRecipe;
        state.isSaved = false;
      }
    },
    recipeStepTipEditRequested(
      state,
      {
        payload: { tip, tipIndex, stepIndex, locale },
      }: PayloadAction<{
        tip: FrescoStepTipI18n;
        tipIndex: number;
        stepIndex: number;
        locale: ApiLocale;
      }>
    ) {
      const localeRecipe = state.recipes[locale];
      if (localeRecipe && localeRecipe?.steps?.[stepIndex]?.tips?.[tipIndex]) {
        localeRecipe.steps[stepIndex].tips[tipIndex] = tip;
        state.recipes[locale] = localeRecipe;
        state.isSaved = false;
      }
    },
    recipeDetailsTranslationEditRequested(
      state,
      {
        payload: { localeSentence, locale, type },
      }: PayloadAction<RecipeDetailsSentencePayload>
    ) {
      const recipe = state.recipes[locale];
      if (recipe) {
        recipe[type][locale] = localeSentence;
        state.isSaved = false;
      }
    },
    recipeStatusEditRequested(
      state,
      { payload: { locale, status } }: PayloadAction<RecipeStatusPayload>
    ) {
      const recipe = state.recipes[locale];
      if (recipe) {
        recipe.status = status;
        state.isSaved = false;
        state.isStatusSaved = false;
      }
    },
    recipeFetchTranslationsRequested(
      state,
      _: PayloadAction<FetchTranslationsPayload>
    ) {
      state.isFetchedTranslations = false;
      state.isFetchingTranslations = true;
      state.suggestions = {};
    },
  },
  extraReducers: (builder) => {
    builder.addCase(recipeFetchRequested, () => ({
      ...initialState,
      isFetched: false,
      isFetching: true,
    }));
    builder.addCase(recipeSaveRequested, (state) => ({
      ...state,
      isSaved: false,
      isSaving: true,
    }));
    builder.addCase(logoutSuccess, () => initialState);
  },
});

export const {
  reducer: recipeReducer,
  actions: {
    recipeFetchFinished,
    recipeFetchFailed,
    recipeFetchTranslationsFinished,
    recipeFetchTranslationsFailed,
    recipeFetchTranslationsRequested,
    recipeSaveFinished,
    recipeSaveFailed,
    recipeStepEditRequested,
    recipeStepTipEditRequested,
    recipeDetailsTranslationEditRequested,
    recipeStatusEditRequested,
  },
} = recipeSlice;

export const selectRecipeFetching = (state: RootState): boolean =>
  state.translation.recipe.isFetching;

export const selectRecipeSaving = (state: RootState): boolean =>
  state.translation.recipe.isSaving;

export const selectRecipeSaved = (state: RootState): boolean =>
  state.translation.recipe.isSaved;

export const selectRecipeFetched = (state: RootState): boolean =>
  state.translation.recipe.isFetched;

export const selectRecipeError = (state: RootState): string | undefined =>
  state.translation.recipe.error;

export const selectRecipe =
  (locale: ApiLocale) =>
  (state: RootState): ApiRecipeI18n | undefined =>
    state.translation.recipe.recipes[locale];

export const selectRecipeNameTranslationsByLocaleAndType = (
  locale: ApiLocale,
  type: RecipeDetailsTranslationType
) => createSelector(selectRecipe(locale), (recipe) => recipe?.[type][locale]);

export const selectRecipeSteps =
  (locale: ApiLocale) =>
  (state: RootState): ApiStepI18n[] | undefined =>
    state.translation.recipe.recipes?.[locale]?.steps;

export const selectRecipeStepsTotal =
  (locale: ApiLocale) =>
  (state: RootState): number | undefined =>
    state.translation.recipe.recipes?.[locale]?.steps.length;

export const selectRecipeStepById = (stepId: FrescoId, locale: ApiLocale) =>
  createSelector(selectRecipeSteps(locale), (steps) =>
    steps?.find((step) => step.id === stepId)
  );

export const selectRecipeStepTipByIndex = (
  stepIndex: number,
  tipIndex: number,
  locale: ApiLocale
) =>
  createSelector(
    selectRecipeSteps(locale),
    (steps) => steps?.[stepIndex]?.tips?.[tipIndex]
  );

export const mapRecipeToPatchRecipe = (
  recipe: ApiRecipeI18n | undefined,
  locale: ApiLocale
) => {
  const recipeSteps = recipe?.steps
    .filter((steps) => !isVirtualStep(steps.uri))
    .map((step) => {
      const stepTips = step.tips.map((tip) => ({
        caption: tip.captionTranslations[locale],
        uri: tip.uri,
      }));
      return {
        directObjectType: step.directObjectType,
        directObjectId: step.directObjectId,
        sentenceRaw: step.sentenceRawTranslations[locale],
        uri: step.uri,
        tips: stepTips,
      };
    });
  return {
    name: recipe?.nameTranslations[locale],
    introduction: recipe?.introductionTranslations[locale],
    completionCaption: recipe?.completionCaptionTranslations[locale],
    steps: recipeSteps,
  };
};

export const selectPatchRecipeTranslation = (locale: ApiLocale) =>
  createSelector(selectRecipe(locale), (recipe) =>
    mapRecipeToPatchRecipe(recipe, locale)
  );

export const selectRecipeTranslationsFetching = (state: RootState): boolean =>
  state.translation.recipe.isFetchingTranslations;

export const selectRecipeTranslationsFetched = (state: RootState): boolean =>
  state.translation.recipe.isFetchedTranslations;

export const selectSuggestions =
  (locale: ApiLocale) =>
  (state: RootState): TranslationSuggestion[] | undefined =>
    state.translation.recipe.suggestions[locale];

export const selectRecipeTranslationSuggestion = (
  locale: ApiLocale,
  type: string,
  id?: FrescoId
) =>
  createSelector(selectSuggestions(locale), (suggestions) => {
    return suggestions?.find(
      (suggestion) => suggestion.type === type && (!id || suggestion.id === id)
    )?.suggestion;
  });

export const selectRecipeStatusSaved = (state: RootState): boolean =>
  state.translation.recipe.isStatusSaved;
