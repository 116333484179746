import type { PayloadAction, Update, EntityId } from '@reduxjs/toolkit';
import { createSlice, createSelector, createAction } from '@reduxjs/toolkit';

import type { ApiResponse } from 'api/types';
import type { RootState } from 'app/rootReducer';
import { logoutSuccess } from 'features/login/loginSlice';
import {
  recipeFetchFinished,
  recipeFetchRequested,
  recipeFetchFailed,
} from 'features/recipe/recipeSlice/recipeActions';
import { createSaveableEntityAdapter } from 'shared/redux/saveableEntityAdapter';
import type { FrescoId, FrescoTerm } from 'shared/types/entity';
import type {
  ApiIngredientGroupIngredient,
  FrescoIngredient,
  FrescoIngredientGroupIngredientFlags,
  ApiIngredientGroup,
} from 'shared/types/ingredient';
import { getIngredientGroupId } from 'shared/utils/common';

export interface IngredientRequestPayload {
  ingredient: FrescoIngredient;
  amount: number | null;
  units: string | null;
  preparation1: FrescoTerm | null;
  preparation2: FrescoTerm | null;
  preparation3: FrescoTerm | null;
  flags: FrescoIngredientGroupIngredientFlags;
}

export const ingredientsAdapter =
  createSaveableEntityAdapter<ApiIngredientGroupIngredient>();

export const initialState = ingredientsAdapter.getInitialState();

const ingredientsSlice = createSlice({
  name: 'recipe/ingredientsSlice',
  initialState,
  reducers: {
    ingredientAdded: (
      state,
      { payload }: PayloadAction<{ entity: ApiIngredientGroupIngredient }>
    ) => {
      ingredientsAdapter.addOne(state, payload);
    },
    ingredientUpdated: (
      state,
      {
        payload,
      }: PayloadAction<{ update: Update<ApiIngredientGroupIngredient> }>
    ) => {
      ingredientsAdapter.updateOne(state, payload);
    },
    ingredientRemoved: (
      state,
      { payload }: PayloadAction<{ id: EntityId }>
    ) => {
      ingredientsAdapter.removeOne(state, payload);
    },
    ingredientRemoveRequested: (
      state,
      { payload }: PayloadAction<{ id: EntityId }>
    ) => {
      ingredientsAdapter.saveRequest(state, payload);
    },
    ingredientMoved: (
      state,
      { payload }: PayloadAction<{ id: EntityId; toIndex: number }>
    ) => {
      ingredientsAdapter.moveOne(state, payload);
    },
    ingredientSaveRequested: (
      state,
      { payload }: PayloadAction<{ id: EntityId }>
    ) => {
      ingredientsAdapter.saveRequest(state, payload);
    },
    ingredientSaveFinished: (
      state,
      { payload }: PayloadAction<{ id: EntityId }>
    ) => {
      ingredientsAdapter.saveFinish(state, payload);
    },
    ingredientSaveFailed: (
      state,
      { payload }: PayloadAction<{ id: EntityId; error: string }>
    ) => {
      ingredientsAdapter.saveFail(state, payload);
    },
    ingredientGroupFetched: (
      state,
      {
        payload: { response, previousIngredients },
      }: PayloadAction<IngredientGroupFetchPayload>
    ) => {
      if (!response.ok) {
        previousIngredients?.forEach((ingredientGroupIngredient) =>
          ingredientsAdapter.saveFail(state, {
            id: ingredientGroupIngredient.id,
            error: response.details.message,
          })
        );
        return;
      }
      previousIngredients?.forEach((ingredientGroupIngredient, index) =>
        ingredientsAdapter.updateWithNewId(state, {
          update: {
            id: ingredientGroupIngredient.id,
            entity: response.data.ingredients[index],
          },
        })
      );
    },
  },
  extraReducers: (builder) => {
    builder.addCase(recipeFetchRequested, () => initialState);
    builder.addCase(recipeFetchFinished, (state, { payload: recipe }) =>
      ingredientsAdapter.loadAll(state, {
        entities: recipe.ingredientGroups.flatMap((x) => x.ingredients),
      })
    );
    builder.addCase(recipeFetchFailed, () => initialState);
    builder.addCase(logoutSuccess, () => initialState);
  },
});

export const {
  reducer: ingredientsReducer,
  actions: {
    ingredientAdded,
    ingredientUpdated,
    ingredientRemoved,
    ingredientRemoveRequested,
    ingredientMoved,
    ingredientSaveRequested,
    ingredientSaveFinished,
    ingredientSaveFailed,
    ingredientGroupFetched,
  },
} = ingredientsSlice;

export const {
  selectAll: selectIngredientsAll,
  selectById: selectIngredientById,
  selectIsSavedById: selectIngredientIsSavedById,
  selectIsSavingById: selectIngredientIsSavingById,
  selectSaveErrorById: selectIngredientSaveErrorById,
} = ingredientsAdapter.getSelectors<RootState>(
  (state) => state.recipe.ingredients
);

export const selectIngredientsByGroupUri = (uri: string) =>
  createSelector(selectIngredientsAll, (ingredients) =>
    ingredients.filter((i) => i.uri.startsWith(uri))
  );

export const selectIngredientsByGroupId = (groupId: FrescoId) =>
  createSelector(selectIngredientsAll, (ingredients) =>
    ingredients.filter((i) => getIngredientGroupId(i.uri) === groupId)
  );

export const selectOtherIngredientsInGroup = (
  groupId: FrescoId,
  ingredientId: FrescoId
) =>
  createSelector(selectIngredientsAll, (ingredients) =>
    ingredients.filter(
      (i) => getIngredientGroupId(i.uri) === groupId && ingredientId !== i.id
    )
  );

export interface IngredientAddRequestPayload {
  groupId?: FrescoId;
  ingredient: IngredientRequestPayload;
  ingredients: ApiIngredientGroupIngredient[];
}

export interface IngredientGroupFetchPayload {
  response: ApiResponse<ApiIngredientGroup>;
  previousIngredients?: ApiIngredientGroupIngredient[];
}

export interface IngredientSplitPayload {
  id: FrescoId;
  newAmount: number;
}

export interface IngredientGroupSaveRequestPayload {
  ingredients: ApiIngredientGroupIngredient[];
  groupId?: FrescoId;
}

export const ingredientAddRequested = createAction<IngredientAddRequestPayload>(
  'recipe/ingredientsSlice/ingredientAddRequested'
);

export const ingredientEditRequested =
  createAction<ApiIngredientGroupIngredient>(
    'recipe/ingredientsSlice/ingredientEditRequested'
  );

export const ingredientGroupSaveRequested =
  createAction<IngredientGroupSaveRequestPayload>(
    'recipe/ingredientsSlice/ingredientGroupSaveRequested'
  );

export const ingredientPatchRequested = createAction<{ id: FrescoId }>(
  'recipe/ingredientsSlice/ingredientPatchRequested'
);

export const ingredientSplitRequested = createAction<IngredientSplitPayload>(
  'recipe/ingredientsSlice/ingredientSplitRequested'
);
