import { TextField } from '@material-ui/core';
import type { FC } from 'react';
import { memo } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import type { RootState } from 'app/rootReducer';
import { selectCurrentLocale } from 'features/translation/localeSwitcher/localeSlice';
import type {
  ApiLocale,
  TranslatableProperty,
  TranslationEditPayload,
} from 'shared/types/i18n';
import {
  getIsTranslatableLocale,
  getNoTranslationPlaceholderText,
} from 'shared/utils/translation';

interface TranslationFieldProps {
  property: TranslatableProperty;
  label: string;
  maxRows?: number;
  localeSentenceSelector: (
    locale: ApiLocale
  ) => (state: RootState) => string | undefined;
  localeSentenceEditAction: (payload: TranslationEditPayload) => void;
}

export const TranslationField: FC<TranslationFieldProps> = memo(
  function TranslationField({
    property,
    label,
    maxRows,
    localeSentenceSelector,
    localeSentenceEditAction,
  }) {
    const dispatch = useDispatch();
    const locale = useSelector(selectCurrentLocale);
    const localeSentence = useSelector(localeSentenceSelector(locale));

    const handleTranslationChange = (translation: string | undefined) => {
      dispatch(localeSentenceEditAction({ property, translation, locale }));
    };

    if (!getIsTranslatableLocale(locale)) {
      return null;
    }
    return (
      <TextField
        multiline={!!maxRows}
        rows={maxRows}
        variant="outlined"
        fullWidth
        value={localeSentence || ''}
        onChange={(event) => handleTranslationChange(event?.target.value)}
        placeholder={getNoTranslationPlaceholderText(locale)}
        id={`${locale} ${label}`}
        label={`${locale} ${label}`}
        InputLabelProps={{ shrink: true }}
      />
    );
  }
);
