import type { AppAuthData } from 'features/login/loginSlice';

export enum LocalStorageKey {
  TokenIsRefreshing = 'token-refreshing',
  User = 'user',
}

interface LocalStorage<T> {
  getValue: () => T | null;
  setValue(body: T | null): void;
  removeValue(): void;
  subscribe(onChange: (value: T | null) => void): () => void;
}

export const createJsonLocalStorage = <T>(
  key: LocalStorageKey
): LocalStorage<T> => ({
  getValue: () => {
    const value = localStorage.getItem(key);
    if (!value) {
      return null;
    }
    return JSON.parse(value);
  },
  setValue: (body) => {
    if (body) {
      localStorage.setItem(key, JSON.stringify(body));
    } else {
      localStorage.removeItem(key);
    }
  },
  removeValue: () => {
    localStorage.removeItem(key);
  },
  subscribe: (onChange) => {
    const eventListener = (e: StorageEvent) => {
      if (e.key === key) {
        onChange(e.newValue ? JSON.parse(e.newValue) : null);
      }
    };

    window.addEventListener('storage', eventListener);

    return () => {
      window.removeEventListener('storage', eventListener);
    };
  },
});

export const localStorageUser = createJsonLocalStorage<AppAuthData>(
  LocalStorageKey.User
);

/**
 * Value in storage that is used to let other tabs know that token refresh is running
 */
export const localStorageTokenIsRefreshing = createJsonLocalStorage<boolean>(
  LocalStorageKey.TokenIsRefreshing
);
