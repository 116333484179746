import { Box, Container, Tab, Drawer, makeStyles } from '@material-ui/core';
import { Alert, TabContext, TabList, TabPanel } from '@material-ui/lab';
import classNames from 'classnames';
import type React from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, Link } from 'react-router-dom';

import { recipesRoutesPathPrefix } from 'app/routes/constants';
import type { AppDispatch } from 'app/store';
import {
  layoutHeaderTitleSet,
  layoutHeaderRecipeStatusSet,
} from 'features/layout/layoutSlice';
import { RecipeDetailsPage } from 'features/recipe/details/RecipeDetailsPage';
import {
  selectRecipeCreatedWith,
  selectRecipeName,
  selectRecipeStatus,
} from 'features/recipe/details/detailsSlice';
import { EditSidebar } from 'features/recipe/edit/EditSidebar';
import { selectIsSideBarOpen } from 'features/recipe/edit/editSlice';
import { RecipePrepPage } from 'features/recipe/preps/RecipePrepPage';
import { recipeFetchRequested } from 'features/recipe/recipeSlice/recipeActions';
import { RecipeReviewPage } from 'features/recipe/review/RecipeReviewPage';
import { DirectionsPage } from 'features/recipe/steps/DirectionsPage';
import { SecondaryAppBar } from 'shared/components/SecondaryAppBar';
import {
  TabName,
  sidebarWidth,
  primaryAppBarHeaderHeight,
  secondaryAppBarHeaderHeight,
  OrganisationId,
  getCurrentOrganizationId,
} from 'shared/constants';
import { usePreserveScrollPosition } from 'shared/hooks/usePreserveScrollPosition';
import { RecipeCreatedWith } from 'shared/types/recipe';

export const creator1Message =
  "This recipe can't be edited here because it was originally created using Creator 1. Go to Creator 1 to edit this recipe.";

const useStyles = makeStyles((theme) => ({
  sideBar: {
    width: `${theme.spacing(sidebarWidth)}px`,
    height: 'unset',
    top: `${theme.spacing(
      primaryAppBarHeaderHeight + secondaryAppBarHeaderHeight
    )}px`,
    bottom: 0,
    zIndex: theme.zIndex.appBar - 1,
  },
  sidebarOffset: {
    paddingLeft: `${theme.spacing(sidebarWidth)}px`,
  },
  content: {
    transition: theme.transitions.create('padding-left', {
      duration: theme.transitions.duration.standard,
    }),
  },
  tabIndicator: {
    backgroundColor: theme.palette.primary.light,
    bottom: 0,
  },
}));

export const RecipePage: React.FC = () => {
  const classes = useStyles();
  const { recipeId, ...restRouteParams } = useParams<{
    recipeId: string;
    tab: TabName;
  }>();
  const tab = (restRouteParams.tab as TabName) || TabName.Details;

  const dispatch: AppDispatch = useDispatch();

  usePreserveScrollPosition(tab);
  useEffect(() => {
    dispatch(recipeFetchRequested(recipeId));
  }, [dispatch, recipeId]);

  const recipeName = useSelector(selectRecipeName);
  const recipeStatus = useSelector(selectRecipeStatus);
  const recipeCreatedWith = useSelector(selectRecipeCreatedWith);
  useEffect(() => {
    dispatch(layoutHeaderTitleSet(recipeName));
    dispatch(layoutHeaderRecipeStatusSet(recipeStatus));
  }, [recipeName, recipeStatus, dispatch]);

  const isSideBarOpen = useSelector(selectIsSideBarOpen);

  return (
    <TabContext value={tab}>
      <SecondaryAppBar>
        <Container maxWidth="md">
          <TabList
            variant="fullWidth"
            TabIndicatorProps={{
              className: classes.tabIndicator,
            }}
          >
            <Tab
              label="Details"
              value={TabName.Details}
              component={Link}
              to={`${recipesRoutesPathPrefix}/${recipeId}/${TabName.Details}`}
            />
            <Tab
              label="Prep"
              value={TabName.Prep}
              component={Link}
              to={`${recipesRoutesPathPrefix}/${recipeId}/${TabName.Prep}`}
            />
            <Tab
              label="Directions"
              value={TabName.Directions}
              component={Link}
              to={`${recipesRoutesPathPrefix}/${recipeId}/${TabName.Directions}`}
            />
            <Tab
              label="Review"
              value={TabName.Review}
              component={Link}
              to={`${recipesRoutesPathPrefix}/${recipeId}/${TabName.Review}`}
            />
          </TabList>
        </Container>
      </SecondaryAppBar>
      <Drawer
        variant="persistent"
        anchor="left"
        open={isSideBarOpen}
        PaperProps={{
          className: classes.sideBar,
        }}
      >
        <EditSidebar />
      </Drawer>
      <Box
        pb={2}
        className={classNames(classes.content, {
          [classes.sidebarOffset]: isSideBarOpen,
        })}
      >
        <Container maxWidth="md">
          {recipeCreatedWith === RecipeCreatedWith.Creator1 &&
          getCurrentOrganizationId() === OrganisationId.Kenwood ? (
            <Box marginTop={2}>
              <Alert severity="error">{creator1Message}</Alert>
            </Box>
          ) : (
            <>
              <TabPanel value={TabName.Details}>
                <RecipeDetailsPage />
              </TabPanel>
              <TabPanel value={TabName.Prep}>
                <RecipePrepPage />
              </TabPanel>
              <TabPanel value={TabName.Directions}>
                <DirectionsPage />
              </TabPanel>
              <TabPanel value={TabName.Review}>
                <RecipeReviewPage />
              </TabPanel>
            </>
          )}
        </Container>
      </Box>
    </TabContext>
  );
};
