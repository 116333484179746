import type { PayloadAction, EntityId } from '@reduxjs/toolkit';
import { createSlice, createAction, createSelector } from '@reduxjs/toolkit';
import { put, call, takeLatest } from 'redux-saga/effects';

import { createRequestApiSaga } from 'api/createRequestApiSaga';
import {
  apiAppliancesTermsGet,
  apiAppliancesPresetsGet,
  apiAppliancesSettingsGet,
  apiAppliancesAttachmentsGet,
  apiAppliancesContainersGet,
} from 'api/terms';
import type { FrescoPaginatedResponse, ApiResponse } from 'api/types';
import type { RootState } from 'app/rootReducer';
import { logoutSuccess } from 'features/login/loginSlice';
import type {
  FrescoAppliance,
  FrescoAppliancePresets,
  FrescoApplianceProfileSettingEnumValue,
  FrescoApplianceAttachment,
} from 'shared/types/appliance';
import type { FrescoContainer } from 'shared/types/container';
import type { FrescoId } from 'shared/types/entity';
import type { FrescoStepPreset } from 'shared/types/step';
import { getErrorString } from 'shared/utils/common';

interface TermsAppliancesState {
  apiError?: string;
  apiPending: boolean;
  entities: FrescoAppliance[];
}
interface TermsAppliancesSettingsPayload {
  settings: FrescoApplianceProfileSettingEnumValue[];
  id: FrescoId;
}

interface TermsAppliancesAttachmentsPayload {
  attachments: FrescoApplianceAttachment[];
  id: FrescoId;
}
interface TermsAppliancesPresetsPayload {
  presets: FrescoStepPreset[];
  id: FrescoId;
}

interface TermsAppliancesContainersPayload {
  containers: FrescoContainer[];
  id: FrescoId;
}

export const initialState: TermsAppliancesState = {
  apiPending: false,
  entities: [],
};

const termsAppliancesSlice = createSlice({
  name: 'termsAppliancesSlice',
  initialState,
  reducers: {
    termsAppliancesSuccess(
      state,
      { payload }: PayloadAction<FrescoAppliance[]>
    ) {
      state.entities = payload.sort((a, b) =>
        a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1
      );
      state.apiPending = false;
      state.apiError = undefined;
    },
    termsAppliancesSettingsSuccess(
      state,
      {
        payload: { settings, id },
      }: PayloadAction<TermsAppliancesSettingsPayload>
    ) {
      const entity = state.entities.find((x) => x.id === id);
      if (entity) {
        entity.profile = entity.profile || {};
        entity.profile.settings = {
          setting1: {
            name: 'Setting',
            type: 'enum',
            values: settings,
          },
        };
      }
      state.apiPending = false;
      state.apiError = undefined;
    },
    termsAppliancesAttachmentsSuccess(
      state,
      {
        payload: { attachments, id },
      }: PayloadAction<TermsAppliancesAttachmentsPayload>
    ) {
      const entity = state.entities.find((x) => x.id === id);
      if (entity) {
        entity.profile = entity.profile || {};
        entity.profile.attachments = attachments;
      }
      state.apiPending = false;
      state.apiError = undefined;
    },
    termsAppliancesContainersSuccess(
      state,
      {
        payload: { containers, id },
      }: PayloadAction<TermsAppliancesContainersPayload>
    ) {
      const entity = state.entities.find((x) => x.id === id);
      if (entity) {
        entity.containers = containers;
      }
      state.apiPending = false;
      state.apiError = undefined;
    },
    termsAppliancesPresetSuccess(
      state,
      { payload: { presets, id } }: PayloadAction<TermsAppliancesPresetsPayload>
    ) {
      const entity = state.entities.find((x) => x.id === id);
      if (entity) {
        entity.profile = entity.profile || {};
        entity.profile.presets = presets.reduce(
          (acc, preset) => ({
            ...acc,
            [preset.id]: preset,
          }),
          {} as FrescoAppliancePresets
        );
      }
      state.apiPending = false;
      state.apiError = undefined;
    },
    termsAppliancesApiPending(state) {
      state.apiPending = true;
      state.apiError = undefined;
    },
    termsAppliancesApiError(state, { payload }: PayloadAction<string>) {
      state.apiPending = false;
      state.apiError = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(logoutSuccess, () => initialState);
  },
});

export const {
  reducer: termsAppliancesReducer,
  actions: {
    termsAppliancesSuccess,
    termsAppliancesSettingsSuccess,
    termsAppliancesPresetSuccess,
    termsAppliancesAttachmentsSuccess,
    termsAppliancesContainersSuccess,
    termsAppliancesApiPending,
    termsAppliancesApiError,
  },
} = termsAppliancesSlice;

const selectTermsAppliancesState = (state: RootState): TermsAppliancesState =>
  state.terms.appliances;

export const selectTermsAppliancesEntities = (
  state: RootState
): FrescoAppliance[] => selectTermsAppliancesState(state).entities;

export const selectTermsApplianceAttachments = (id: FrescoId | null) =>
  createSelector(
    selectTermsAppliancesState,
    (appliances) =>
      appliances.entities.find((x) => x.id === id)?.profile?.attachments
  );

export const selectTermsApplianceContainers = (id: FrescoId | null) =>
  createSelector(
    selectTermsAppliancesState,
    (appliances) => appliances.entities.find((x) => x.id === id)?.containers
  );

export const selectTermsApplianceVesselFlags = (id: FrescoId | null) =>
  createSelector(
    selectTermsAppliancesState,
    (appliances) =>
      appliances.entities.find((x) => x.id === id)?.profile?.vessels
  );

export const selectTermsAppliancesApiPending = (state: RootState): boolean =>
  selectTermsAppliancesState(state).apiPending;

export const selectTermsAppliancesApiError = (
  state: RootState
): string | undefined => selectTermsAppliancesState(state).apiError;

export const apiGetApplianceTermsSaga = createRequestApiSaga(
  apiAppliancesTermsGet,
  'Appliances loading'
);

export const apiGetAppliancePresetsSaga = createRequestApiSaga(
  apiAppliancesPresetsGet,
  'Appliance presets loading'
);

export const apiGetApplianceSettingsSaga = createRequestApiSaga(
  apiAppliancesSettingsGet,
  'Appliance settings loading'
);

export const apiGetApplianceAttachmentsSaga = createRequestApiSaga(
  apiAppliancesAttachmentsGet,
  'Appliance attachments loading'
);

export const apiGetApplianceContainersSaga = createRequestApiSaga(
  apiAppliancesContainersGet,
  'Appliance containers loading'
);

export function* requestApplianceTerms() {
  try {
    yield put(termsAppliancesApiPending());
    const response: ApiResponse<FrescoPaginatedResponse<FrescoAppliance>> =
      yield call(apiGetApplianceTermsSaga);
    if (!response.ok) {
      yield put(termsAppliancesApiError(response.details.message));
      return;
    }
    yield put(termsAppliancesSuccess(response.data.results));
    return;
  } catch (e) {
    yield put(termsAppliancesApiError(getErrorString(e)));
  }
}

export function* requestAppliancePresets({ payload }: PayloadAction<FrescoId>) {
  try {
    yield put(termsAppliancesApiPending());
    const response: ApiResponse<FrescoPaginatedResponse<FrescoStepPreset>> =
      yield call(apiGetAppliancePresetsSaga, { id: payload });
    if (!response.ok) {
      yield put(termsAppliancesApiError(response.details.message));
      return;
    }
    yield put(
      termsAppliancesPresetSuccess({
        id: payload,
        presets: response.data.results,
      })
    );
    return;
  } catch (e) {
    yield put(termsAppliancesApiError(getErrorString(e)));
  }
}

export function* requestApplianceSettings({
  payload,
}: PayloadAction<FrescoId>) {
  try {
    yield put(termsAppliancesApiPending());
    const response: ApiResponse<
      FrescoPaginatedResponse<FrescoApplianceProfileSettingEnumValue>
    > = yield call(apiGetApplianceSettingsSaga, { id: payload });
    if (!response.ok) {
      yield put(termsAppliancesApiError(response.details.message));
      return;
    }
    yield put(
      termsAppliancesSettingsSuccess({
        id: payload,
        settings: response.data.results,
      })
    );
    return;
  } catch (e) {
    yield put(termsAppliancesApiError(getErrorString(e)));
  }
}

export function* requestApplianceAttachments({
  payload,
}: PayloadAction<FrescoId>) {
  try {
    yield put(termsAppliancesApiPending());
    const response: ApiResponse<
      FrescoPaginatedResponse<FrescoApplianceAttachment>
    > = yield call(apiGetApplianceAttachmentsSaga, { id: payload });
    if (!response.ok) {
      yield put(termsAppliancesApiError(response.details.message));
      return;
    }
    yield put(
      termsAppliancesAttachmentsSuccess({
        id: payload,
        attachments: response.data.results,
      })
    );
    return;
  } catch (e) {
    yield put(termsAppliancesApiError(getErrorString(e)));
  }
}

export function* requestApplianceContainers({
  payload,
}: PayloadAction<FrescoId>) {
  try {
    yield put(termsAppliancesApiPending());
    const response: ApiResponse<FrescoPaginatedResponse<FrescoContainer>> =
      yield call(apiGetApplianceContainersSaga, { id: payload });
    if (!response.ok) {
      yield put(termsAppliancesApiError(response.details.message));
      return;
    }
    yield put(
      termsAppliancesContainersSuccess({
        id: payload,
        containers: response.data.results,
      })
    );
    return;
  } catch (e) {
    yield put(termsAppliancesApiError(getErrorString(e)));
  }
}

export const applianceTermsFetchRequested = createAction(
  'terms/applianceTermsFetchRequested'
);

export const appliancePresetsFetchRequested = createAction<EntityId>(
  'terms/appliancePresetsFetchRequested'
);

export const applianceSettingsFetchRequested = createAction<EntityId>(
  'terms/applianceSettingsFetchRequested'
);

export const applianceAttachmentsFetchRequested = createAction<EntityId>(
  'terms/applianceAttachmentsFetchRequested'
);

export const applianceContainersFetchRequested = createAction<EntityId>(
  'terms/applianceContainersFetchRequested'
);

function* requestApplianceTermsFetchWatcher() {
  yield takeLatest(applianceTermsFetchRequested, requestApplianceTerms);
}

function* requestAppliancePresetsFetchWatcher() {
  yield takeLatest(appliancePresetsFetchRequested, requestAppliancePresets);
}

function* requestApplianceSettingsFetchWatcher() {
  yield takeLatest(applianceSettingsFetchRequested, requestApplianceSettings);
}

function* requestApplianceAttachmentsFetchWatcher() {
  yield takeLatest(
    applianceAttachmentsFetchRequested,
    requestApplianceAttachments
  );
}

function* requestApplianceContainersFetchWatcher() {
  yield takeLatest(
    applianceContainersFetchRequested,
    requestApplianceContainers
  );
}

export const applianceTermsSagas = [
  requestApplianceTermsFetchWatcher,
  requestAppliancePresetsFetchWatcher,
  requestApplianceSettingsFetchWatcher,
  requestApplianceAttachmentsFetchWatcher,
  requestApplianceContainersFetchWatcher,
];
