import { useRef, useEffect } from 'react';

/**
 * Helps to store user scroll position under `key` value
 * and restores scroll back when `key` value changes back to stored one
 */
export function usePreserveScrollPosition(key: string) {
  const scrollPositions = useRef({} as Record<string, number>);

  useEffect(() => {
    // Delay scroll until next tick so all childred could render
    ((y: number) => {
      setTimeout(() => {
        window.scrollTo({ top: y, behavior: 'smooth' });
      });
    })(scrollPositions.current[key] || 0);

    const scrollHandler = () => {
      scrollPositions.current = {
        ...scrollPositions.current,
        [key]: window.scrollY,
      };
    };

    window.addEventListener('scroll', scrollHandler);
    return () => {
      window.removeEventListener('scroll', scrollHandler);
    };
  }, [key]);
}
