import { Grid, FormControlLabel, Switch } from '@material-ui/core';
import camelCase from 'lodash/camelCase';
import React from 'react';
import { useSelector } from 'react-redux';

import { selectTermsApplianceVesselFlags } from 'features/terms/appliancesSlice';
import type { FrescoId } from 'shared/types/entity';
import type { FrescoStepVesselFlags } from 'shared/types/step';

interface StepVesselFlagsProps {
  stepVesselFlags: FrescoStepVesselFlags | null;
  applianceId: FrescoId | null;
  onStepVesselFlagsChange: (
    vesselFlagKey: string,
    flagKey: string,
    value: boolean
  ) => void;
}

export const StepVesselFlags: React.FC<StepVesselFlagsProps> = React.memo(
  function StepVesselFlags({
    stepVesselFlags,
    applianceId,
    onStepVesselFlagsChange,
  }) {
    const vesselFlags = useSelector(
      selectTermsApplianceVesselFlags(applianceId)
    );

    return (
      <>
        {vesselFlags && (
          <Grid item xs={12}>
            {Object.keys(vesselFlags).map((key) =>
              vesselFlags?.[key]?.flags?.map((flag) => (
                <FormControlLabel
                  key={flag.name}
                  labelPlacement="start"
                  control={
                    <Switch
                      checked={stepVesselFlags?.[key]?.[camelCase(flag.key)]}
                      onChange={(_e, value) => {
                        onStepVesselFlagsChange(
                          key,
                          camelCase(flag.key),
                          !!value
                        );
                      }}
                      name={flag.name}
                      color="primary"
                    />
                  }
                  label={flag.name}
                />
              ))
            )}
          </Grid>
        )}
      </>
    );
  }
);
