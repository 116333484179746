import type { PayloadAction } from '@reduxjs/toolkit';
import { call, put, select, takeLatest } from 'redux-saga/effects';

import type { GetCategoryRequest } from 'api/category';
import {
  apiPatchCategoryTranslation,
  apiGetCategoryTranslation,
} from 'api/category';
import { createRequestApiSaga } from 'api/createRequestApiSaga';
import type { ApiResponse } from 'api/types';
import {
  categoryFetchFailed,
  categoryFetchFinished,
  categoryFetchRequested,
  categorySaveFailed,
  categorySaveFinished,
  categorySaveRequested,
  selectCategoryDescription,
  selectCategoryName,
} from 'features/translation/category/categorySlice';
import type { FrescoCategoryI18n } from 'shared/types/category';
import type { FrescoId } from 'shared/types/entity';
import type { ApiLocale } from 'shared/types/i18n';
import { getErrorString } from 'shared/utils/common';

export const apiGetCategorySaga = createRequestApiSaga(
  apiGetCategoryTranslation,
  'Category loading'
);

export const apiPatchCategorySaga = createRequestApiSaga(
  apiPatchCategoryTranslation,
  'Category saving'
);

export function* requestCategory({
  payload: { categoryId, locale },
}: PayloadAction<GetCategoryRequest>) {
  try {
    const response: ApiResponse<FrescoCategoryI18n> = yield call(
      apiGetCategorySaga,
      {
        categoryId,
        locale,
      }
    );

    if (!response.ok) {
      yield put(categoryFetchFailed(response.details.message));
      return;
    }
    yield put(categoryFetchFinished(response.data));
  } catch (e) {
    yield put(categoryFetchFailed(getErrorString(e)));
  }
}

export interface PatchCategorySaga {
  categoryId: FrescoId;
  locale: ApiLocale;
}

export function* patchCategory({
  payload: { categoryId, locale },
}: PayloadAction<PatchCategorySaga>) {
  const name: string = yield select(selectCategoryName(locale));
  const description: string = yield select(selectCategoryDescription(locale));

  const response: ApiResponse<FrescoCategoryI18n> = yield call(
    apiPatchCategorySaga,
    {
      categoryId,
      category: { name, description },
      locale,
    }
  );

  if (!response.ok) {
    yield put(categorySaveFailed(response.details.message));
  }

  yield put(categorySaveFinished());
}

function* requestCategoryFetchWatcher() {
  yield takeLatest(categoryFetchRequested, requestCategory);
}

function* requestCategoryPatchWatcher() {
  yield takeLatest(categorySaveRequested, patchCategory);
}

export const categoryTranslationSagas = [
  requestCategoryFetchWatcher,
  requestCategoryPatchWatcher,
];
