import type { PayloadAction, EntityId } from '@reduxjs/toolkit';
import { call, select, put, takeEvery } from 'redux-saga/effects';

import type {
  ApiRecipeContainerLocationResponse,
  ApiRecipeContainerGetResponse,
} from 'api/containers';
import {
  apiRecipeContainerPost,
  apiRecipeContainerPatch,
  apiRecipeContainerDelete,
} from 'api/containers';
import { createRequestApiSaga } from 'api/createRequestApiSaga';
import type { ApiResponse } from 'api/types';
import type { ContainerRequestPayload } from 'features/recipe/containers/containersSlice';
import {
  containersAdapter,
  containerAdded,
  containerUpdated,
  containerUpdatedWithNewId,
  selectContainerById,
  containerSaveFailed,
  containerSaveRequested,
  containerRemoved,
  containerRemoveRequested,
  containerAddRequested,
  containerEditRequested,
  containerSaveFinished,
} from 'features/recipe/containers/containersSlice';
import { selectRecipeId } from 'features/recipe/details/detailsSlice';
import { recipeEditEntityFinished } from 'features/recipe/edit/editSlice';
import type { FrescoContainer } from 'shared/types/container';
import type { FrescoEntityUri, FrescoId } from 'shared/types/entity';
import type { FrescoRecipeContainer } from 'shared/types/recipe';
import { getErrorString } from 'shared/utils/common';

export const apiRecipeContainerPostSaga = createRequestApiSaga(
  apiRecipeContainerPost,
  'Adding container'
);

export const apiRecipeContainerPatchSaga = createRequestApiSaga(
  apiRecipeContainerPatch,
  'Editing container'
);

export const apiRecipeContainerDeleteSaga = createRequestApiSaga(
  apiRecipeContainerDelete,
  'Deleting container'
);

export function* containerAddRequestedWatcher() {
  yield takeEvery(containerAddRequested, requestContainerAdd);
}

export function* containerEditRequestedWatcher() {
  yield takeEvery(containerEditRequested, requestContainerEdit);
}

export function* containerSaveRequestedWatcher() {
  yield takeEvery(containerSaveRequested, requestContainerSave);
}

export function* containerRemoveRequestedWatcher() {
  yield takeEvery(containerRemoveRequested, requestContainerDelete);
}
interface ContainerPostRequestPayload extends FrescoEntityUri {
  container: FrescoContainer;
  quantity: number;
  id: EntityId;
}

export function* requestContainerAdd({
  payload: { quantity, container },
}: PayloadAction<ContainerRequestPayload>) {
  const tempId = containersAdapter.generateNewTempId();
  yield put(
    containerAdded({
      entity: {
        container,
        quantity,
        id: tempId,
        uri: tempId,
        handle: null,
      },
    })
  );
  yield put(
    containerSaveRequested({
      id: tempId,
    })
  );
  yield put(recipeEditEntityFinished());
}

export function* requestContainerEdit({
  payload: { quantity, container, id },
}: PayloadAction<ContainerPostRequestPayload>) {
  yield put(
    containerUpdated({
      update: {
        id,
        changes: {
          container,
          quantity,
        },
      },
    })
  );
  yield put(
    containerSaveRequested({
      id,
    })
  );
  yield put(recipeEditEntityFinished());
}

function* requestContainerSave({
  payload: { id },
}: PayloadAction<{ id: EntityId }>) {
  if (!containersAdapter.existsOnPlatform(id)) {
    yield call(requestContainerPost, id);
  } else {
    yield call(requestContainerPatch, id);
  }
}

export function* requestContainerPost(id: EntityId) {
  const recipeId: FrescoId = yield select(selectRecipeId);
  const recipeContainer: FrescoRecipeContainer = yield select(
    selectContainerById(id)
  );

  try {
    const response: ApiResponse<ApiRecipeContainerGetResponse> = yield call(
      apiRecipeContainerPostSaga,
      {
        container: {
          uri: recipeContainer.container.uri,
        },
        quantity: recipeContainer.quantity,
        recipeId,
      }
    );
    if (!response.ok) {
      yield put(
        containerSaveFailed({
          id,
          error: response.details.message,
        })
      );
      return;
    }
    yield put(
      containerUpdatedWithNewId({
        update: {
          id,
          entity: response.data,
        },
      })
    );
  } catch (e) {
    yield put(
      containerSaveFailed({
        id,
        error: getErrorString(e),
      })
    );
  }
}

export function* requestContainerPatch(id: EntityId) {
  const recipeId: FrescoId = yield select(selectRecipeId);
  const recipeContainer: FrescoRecipeContainer = yield select(
    selectContainerById(id)
  );

  try {
    const response: ApiResponse<ApiRecipeContainerGetResponse> = yield call(
      apiRecipeContainerPatchSaga,
      {
        container: recipeContainer,
        recipeId,
      }
    );
    if (!response.ok) {
      yield put(
        containerSaveFailed({
          id,
          error: response.details.message,
        })
      );
      return;
    }
    yield put(
      containerUpdated({
        update: {
          id,
          changes: response.data,
        },
      })
    );
    yield put(
      containerSaveFinished({
        id,
      })
    );
  } catch (e) {
    yield put(
      containerSaveFailed({
        id,
        error: getErrorString(e),
      })
    );
  }
}

export function* requestContainerDelete({
  payload: { id },
}: PayloadAction<{ id: EntityId }>) {
  const recipeId: FrescoId = yield select(selectRecipeId);
  try {
    const response: ApiResponse<ApiRecipeContainerLocationResponse> =
      yield call(apiRecipeContainerDeleteSaga, {
        containerId: id,
        recipeId,
      });
    if (!response.ok) {
      yield put(
        containerSaveFailed({
          id,
          error: response.details.message,
        })
      );
      return;
    }
    yield put(containerRemoved({ id }));
  } catch (e) {
    yield put(
      containerSaveFailed({
        id,
        error: getErrorString(e),
      })
    );
  }
}

export const containersSagas = [
  containerAddRequestedWatcher,
  containerSaveRequestedWatcher,
  containerEditRequestedWatcher,
  containerRemoveRequestedWatcher,
];
