import { Container } from '@material-ui/core';
import type { GridValueGetterParams, GridColDef } from '@mui/x-data-grid';
import type { FC } from 'react';
import { memo, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { translateCollectionRoutePath } from 'app/routes/constants';
import { CollectionsFilters } from 'features/collections/CollectionsFilters';
import {
  selectCollectionsPageSize,
  selectCollections,
  selectCollectionsPage,
  selectCollectionsTotalPages,
  collectionsPageSizeSet,
  collectionsPageSet,
  collectionsFetchRequested,
  selectCollectionsApiPending,
} from 'features/collections/collectionsSlice';
import { layoutHeaderTitleSet } from 'features/layout/layoutSlice';
import { LocaleSwitcher } from 'features/translation/localeSwitcher/LocaleSwitcher';
import { DataGrid } from 'shared/components/dataGrid/DataGrid';
import type { DataGridAction } from 'shared/components/dataGrid/DataGridActionsMenu';
import { useGetTranslateAction } from 'shared/components/dataGrid/DataGridActionsMenu';
import { DataGridImageColumn } from 'shared/components/dataGrid/DataGridImageColumn';
import { DataGridTagsColumn } from 'shared/components/dataGrid/DataGridTagsColumn';
import { DataGridTextColumn } from 'shared/components/dataGrid/DataGridTextColumn';
import { entityFiltersHeight } from 'shared/constants';
import { useShouldDisplayTranslatePage } from 'shared/hooks/useShouldDisplayPage';

export const CollectionsPage: FC = memo(function CollectionsPage() {
  const dispatch = useDispatch();
  const displayPage = useShouldDisplayTranslatePage();
  const translateAction = useGetTranslateAction(translateCollectionRoutePath);

  useEffect(() => {
    dispatch(layoutHeaderTitleSet('Collections'));
  }, [dispatch]);

  const actions: DataGridAction[] = [translateAction];

  const columns: GridColDef[] = [
    { field: 'id', headerName: 'ID', width: 100 },
    {
      field: 'image',
      headerName: 'Image',
      width: 150,
      renderCell: (params: GridValueGetterParams) => (
        <DataGridImageColumn image={params.row.image} label={params.row.name} />
      ),
      sortable: false,
    },
    {
      field: 'name',
      headerName: 'Name',
      renderCell: (params: GridValueGetterParams) => (
        <DataGridTextColumn text={params.row.name} />
      ),
      width: 300,
    },
    {
      field: 'tags',
      headerName: 'Tags',
      renderCell: (params: GridValueGetterParams) => (
        <DataGridTagsColumn tags={params.row.tags} />
      ),
      sortable: false,
      width: 300,
    },
  ];

  if (!displayPage) return null;

  return (
    <>
      <LocaleSwitcher />
      <Container maxWidth="md">
        <CollectionsFilters />
        <DataGrid
          actions={actions}
          columns={columns}
          isPendingSelector={selectCollectionsApiPending}
          dataSelector={selectCollections}
          filtersHeight={entityFiltersHeight}
          pageSelector={selectCollectionsPage}
          pageSizeSelector={selectCollectionsPageSize}
          totalPagesSelector={selectCollectionsTotalPages}
          dataFetchAction={collectionsFetchRequested}
          pageSetAction={collectionsPageSet}
          pageSizeSetAction={collectionsPageSizeSet}
          viewItemPath={translateCollectionRoutePath}
        />
      </Container>
    </>
  );
});
