import {
  Box,
  Container,
  Grid,
  makeStyles,
  Paper,
  TextField,
} from '@material-ui/core';
import type { FC, FormEvent } from 'react';
import { useEffect, memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { layoutHeaderTitleSet } from 'features/layout/layoutSlice';
import {
  categoryFetchRequested,
  categorySaveRequested,
  categoryTranslationEditRequested,
  selectCategoryDescription,
  selectCategoryFetching,
  selectCategoryName,
  selectCategorySaving,
} from 'features/translation/category/categorySlice';
import { LocaleSwitcher } from 'features/translation/localeSwitcher/LocaleSwitcher';
import { selectCurrentLocale } from 'features/translation/localeSwitcher/localeSlice';
import { ButtonWithSpinner } from 'shared/components/ButtonWithSpinner';
import { ListSkeleton } from 'shared/components/Skeletons';
import { TranslationField } from 'shared/components/TranslationField';
import { useShouldDisplayTranslatePage } from 'shared/hooks/useShouldDisplayPage';
import { ApiLocale, TranslatableProperty } from 'shared/types/i18n';
import {
  getIsTranslatableLocale,
  getNoEnUsPlaceholderText,
} from 'shared/utils/translation';

const useStyles = makeStyles((theme) => ({
  submitButtonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    paddingTop: theme.spacing(2),
  },
  container: {
    marginTop: theme.spacing(4),
  },
}));

export const translateCategoryTitle = 'Translate category';

export const CategoryPage: FC = memo(function TranslateCategoryPage() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { categoryId } = useParams<{
    categoryId: string;
  }>();

  const displayPage = useShouldDisplayTranslatePage();

  const locale = useSelector(selectCurrentLocale);
  const nameEnUs = useSelector(selectCategoryName(ApiLocale.EnUS));
  const descriptionEnUs = useSelector(
    selectCategoryDescription(ApiLocale.EnUS)
  );
  const isFetching = useSelector(selectCategoryFetching);
  const isSaving = useSelector(selectCategorySaving);

  useEffect(() => {
    dispatch(
      categoryFetchRequested({
        categoryId,
        locale: locale || ApiLocale.EnUS,
      })
    );
  }, [categoryId, dispatch, locale]);

  useEffect(() => {
    dispatch(
      layoutHeaderTitleSet(`${translateCategoryTitle} - ${nameEnUs ?? ''}`)
    );
  }, [dispatch, nameEnUs]);

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (locale) {
      dispatch(categorySaveRequested({ categoryId, locale }));
    }
  };

  if (!displayPage) return null;

  if (isFetching) {
    return (
      <Container className={classes.container} maxWidth="md">
        <Paper elevation={2}>
          <Box p={2} mt={1}>
            <ListSkeleton rows={2} />
          </Box>
        </Paper>
      </Container>
    );
  }

  return (
    <>
      <LocaleSwitcher />
      <Container className={classes.container} maxWidth="md">
        <Paper elevation={2}>
          <Box p={2} mt={1}>
            <form onSubmit={handleSubmit}>
              <div>
                <Grid container spacing={2} direction="column">
                  <Grid item xs={12}>
                    <TextField
                      disabled
                      fullWidth
                      id="en-us-name"
                      InputLabelProps={{ shrink: true }}
                      label="en_US Name"
                      placeholder={getNoEnUsPlaceholderText()}
                      value={nameEnUs || ''}
                      variant="outlined"
                    />
                  </Grid>
                  {getIsTranslatableLocale(locale) && (
                    <Grid item xs={12}>
                      <TranslationField
                        property={TranslatableProperty.NameTranslations}
                        label="Name"
                        localeSentenceSelector={selectCategoryName}
                        localeSentenceEditAction={
                          categoryTranslationEditRequested
                        }
                      />
                    </Grid>
                  )}
                  <Grid item xs={12}>
                    <TextField
                      disabled
                      fullWidth
                      id="en-us-description"
                      InputLabelProps={{ shrink: true }}
                      label="en_US Description"
                      multiline
                      placeholder={getNoEnUsPlaceholderText()}
                      rows={5}
                      value={descriptionEnUs || ''}
                      variant="outlined"
                    />
                  </Grid>
                  {getIsTranslatableLocale(locale) && (
                    <Grid item xs={12}>
                      <TranslationField
                        property={TranslatableProperty.DescriptionTranslations}
                        label="Description"
                        localeSentenceSelector={selectCategoryDescription}
                        localeSentenceEditAction={
                          categoryTranslationEditRequested
                        }
                      />
                    </Grid>
                  )}
                </Grid>
              </div>
              <Grid className={classes.submitButtonContainer} item xs={12}>
                <Grid>
                  <ButtonWithSpinner
                    color="primary"
                    disabled={!getIsTranslatableLocale(locale)}
                    type="submit"
                    loading={isSaving}
                  >
                    SAVE
                  </ButtonWithSpinner>
                </Grid>
              </Grid>
            </form>
          </Box>
        </Paper>
      </Container>
    </>
  );
});
