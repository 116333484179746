import type { PayloadAction, EntityId } from '@reduxjs/toolkit';
import Big from 'big.js';
import pick from 'lodash/pick';
import {
  all,
  race,
  take,
  call,
  select,
  put,
  takeEvery,
} from 'redux-saga/effects';

import { createRequestApiSaga } from 'api/createRequestApiSaga';
import {
  apiIngredientGroupPost,
  apiIngredientGroupPut,
  apiIngredientPatch,
  apiIngredientGroupDelete,
  apiIngredientGroupGet,
} from 'api/ingredients';
import type { ApiResponse, LocationResponse } from 'api/types';
import { selectRecipeId } from 'features/recipe/details/detailsSlice';
import {
  recipeEditEntityFinished,
  recipeSplitEntityFinished,
  recipeSplitEntity,
  recipeSplitEntityFailed,
} from 'features/recipe/edit/editSlice';
import type {
  IngredientSplitPayload,
  IngredientAddRequestPayload,
  IngredientGroupSaveRequestPayload,
} from 'features/recipe/ingredients/ingredientsSlice';
import {
  ingredientAdded,
  ingredientUpdated,
  ingredientSaveRequested,
  ingredientSaveFailed,
  ingredientsAdapter,
  selectIngredientById,
  ingredientAddRequested,
  ingredientEditRequested,
  ingredientRemoved,
  ingredientRemoveRequested,
  selectOtherIngredientsInGroup,
  ingredientGroupSaveRequested,
  ingredientPatchRequested,
  ingredientGroupFetched,
  ingredientSplitRequested,
  selectIngredientsAll,
  selectIngredientsByGroupId,
} from 'features/recipe/ingredients/ingredientsSlice';
import type { StepIngredientGroupsUpdate } from 'features/recipe/steps/stepsSlice';
import {
  stepSaveFailed,
  selectTemporaryIngredientGroup,
} from 'features/recipe/steps/stepsSlice';
import type { FrescoId } from 'shared/types/entity';
import type {
  ApiIngredientGroupIngredient,
  ApiIngredientGroup,
} from 'shared/types/ingredient';
import { getIngredientGroupId, getErrorString } from 'shared/utils/common';
import {
  requestDataDogLogInfo,
  ingredientSplitEvent,
  ingredientSplitSuccess,
} from 'shared/utils/dataDogSagas';

export const apiRecipeIngredientGroupPostSaga = createRequestApiSaga(
  apiIngredientGroupPost,
  'Adding ingredient'
);

export const apiRecipeIngredientGroupPutSaga = createRequestApiSaga(
  apiIngredientGroupPut,
  'Adding ingredient'
);

export const apiIngredientGroupGetSaga = createRequestApiSaga(
  apiIngredientGroupGet,
  'Getting ingredient'
);

export const apiIngredientPatchSaga = createRequestApiSaga(
  apiIngredientPatch,
  'Editing ingredient'
);

export const apiIngredientGroupDeleteSaga = createRequestApiSaga(
  apiIngredientGroupDelete,
  'Deleteing ingredient'
);

export interface IngredientGroupGetRequest {
  ingredients: ApiIngredientGroupIngredient[];
  groupId: FrescoId;
}

// Called on action
export function* requestIngredientPatch({
  payload: { id },
}: PayloadAction<{ id: FrescoId }>) {
  const recipeId: FrescoId = yield select(selectRecipeId);
  const ingredient: ApiIngredientGroupIngredient = yield select(
    selectIngredientById(id)
  );

  try {
    const response: ApiResponse<ApiIngredientGroupIngredient> = yield call(
      apiIngredientPatchSaga,
      {
        groupId: getIngredientGroupId(ingredient.uri),
        recipeId,
        ingredient,
      }
    );
    if (!response.ok) {
      yield put(
        ingredientSaveFailed({
          id,
          error: response.details.message,
        })
      );
      return;
    }
    yield put(
      ingredientUpdated({
        update: {
          id,
          changes: response.data,
        },
      })
    );
  } catch (e) {
    yield put(
      ingredientSaveFailed({
        id,
        error: getErrorString(e),
      })
    );
  }
}

// Called on action
export function* requestIngredientSplit({
  payload: { id, newAmount },
}: PayloadAction<IngredientSplitPayload>) {
  yield put(recipeSplitEntity());

  const currentIngredient: ApiIngredientGroupIngredient = yield select(
    selectIngredientById(id)
  );

  const recipeIngredients: ApiIngredientGroupIngredient[] = yield select(
    selectIngredientsAll
  );
  const ingredientGroupIds = recipeIngredients
    .map((ingredient) => getIngredientGroupId(ingredient.uri))
    .filter((groupdId) => groupdId);
  const temporaryGroupIds: string[] = yield select(
    selectTemporaryIngredientGroup(ingredientGroupIds)
  );
  const ingredientsInTemporaryGroup: ApiIngredientGroupIngredient[] =
    yield select(selectIngredientsByGroupId(temporaryGroupIds[0]));

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const newIngredientAmount = new Big(currentIngredient.amount!)
    .minus(newAmount)
    .toNumber();

  // TODO: Add ingredient sourceText when API is made on server
  const newIngredient = {
    ingredient: currentIngredient.ingredient,
    amount: newIngredientAmount,
    units: currentIngredient.units,
    preparation1: currentIngredient.preparation1 ?? null,
    preparation2: currentIngredient.preparation2 ?? null,
    preparation3: currentIngredient.preparation3 ?? null,
    flags: {
      advancePrep: currentIngredient.flags.advancePrep,
      required: currentIngredient.flags.required,
    },
  };

  const isCurrentIngredientUnassigned = ingredientsInTemporaryGroup.some(
    (ingredient) => ingredient.id === id
  );

  // If current ingredient is assigned patch single ingredient
  if (!isCurrentIngredientUnassigned) {
    yield call(ingredientEdit, { ...currentIngredient, amount: newAmount });
    const { fail } = yield race({
      success: take(ingredientUpdated),
      fail: take(ingredientSaveFailed),
    });
    if (fail) {
      yield put(
        ingredientUpdated({
          update: {
            id: currentIngredient.id,
            changes: currentIngredient,
          },
        })
      );
      yield put(recipeSplitEntityFailed());
      return;
    }
  }

  yield call(ingredientAdd, {
    groupId: temporaryGroupIds[0],
    ingredient: newIngredient,
    ingredients: isCurrentIngredientUnassigned
      ? // If ingredient is unassigned temporary group is overwritten with new array of ingredients
        ingredientsInTemporaryGroup.map((ingredient) => {
          if (ingredient.id === id) {
            return {
              ...ingredient,
              amount: newAmount,
            };
          }
          return ingredient;
        })
      : ingredientsInTemporaryGroup,
  });

  const {
    payload: { response },
  }: PayloadAction<{
    response: ApiResponse<ApiIngredientGroup>;
    ingredients?: ApiIngredientGroupIngredient[];
  }> = yield take(ingredientGroupFetched);
  if (!response.ok) {
    yield put(ingredientRemoved({ id: ingredientsAdapter.getLastTempId() }));
    yield call(ingredientEdit, currentIngredient);
    yield put(recipeSplitEntityFailed());
    return;
  }

  yield put(recipeSplitEntityFinished());
  yield put(
    requestDataDogLogInfo({
      event: ingredientSplitEvent,
      name: ingredientSplitSuccess,
      params: { ingredientId: id },
    })
  );
}

// Called on action
export function* requestIngredientAdd({
  payload: { groupId, ingredient, ingredients },
}: PayloadAction<IngredientAddRequestPayload>) {
  yield call(ingredientAdd, { groupId, ingredient, ingredients });

  yield put(recipeEditEntityFinished());
}

// Called by other sagas within this file
export function* ingredientAdd({
  groupId,
  ingredient,
  ingredients,
}: IngredientAddRequestPayload) {
  const tempId = ingredientsAdapter.generateNewTempId();
  const newIngredient = {
    ...ingredient,
    id: tempId,
    uri: tempId,
    position: 0,
  };
  yield put(
    ingredientAdded({
      entity: newIngredient,
    })
  );
  yield put(
    ingredientSaveRequested({
      id: tempId,
    })
  );
  yield put(
    ingredientGroupSaveRequested({
      groupId,
      ingredients: [newIngredient, ...ingredients],
    })
  );
}

// Called on action
export function* requestIngredientEdit({
  payload,
}: PayloadAction<ApiIngredientGroupIngredient>) {
  yield call(ingredientEdit, payload);
  yield put(recipeEditEntityFinished());
}

// Called by other sagas within this file
export function* ingredientEdit(payload: ApiIngredientGroupIngredient) {
  yield put(
    ingredientUpdated({
      update: {
        id: payload.id,
        changes: payload,
      },
    })
  );
  yield put(
    ingredientSaveRequested({
      id: payload.id,
    })
  );
  yield put(
    ingredientPatchRequested({
      id: payload.id,
    })
  );
}

// Called on action
export function* requestIngredientGroupSave({
  payload: { groupId, ingredients },
}: PayloadAction<IngredientGroupSaveRequestPayload>) {
  if (groupId) {
    yield call(requestIngredientGroupPut, { groupId, ingredients });
  } else {
    yield call(requestIngredientGroupPost, { ingredients });
  }
}

// Called by other sagas within this file
export function* requestIngredientGroupPut({
  groupId,
  ingredients,
}: IngredientGroupGetRequest) {
  const recipeId: FrescoId = yield select(selectRecipeId);

  try {
    const response: ApiResponse<ApiIngredientGroup> = yield call(
      apiRecipeIngredientGroupPutSaga,
      {
        recipeId,
        groupId,
        ingredients: ingredients.map((ingredient) =>
          pick(ingredient, [
            'uri',
            'ingredient',
            'amount',
            'units',
            'preparation1',
            'preparation2',
            'preparation3',
            'flags',
          ])
        ),
      }
    );

    yield put(
      ingredientGroupFetched({
        response,
        previousIngredients: ingredients,
      })
    );
  } catch (e) {
    yield all(
      ingredients.map((ingredientGroupIngredient) =>
        put(
          ingredientSaveFailed({
            id: ingredientGroupIngredient.id,
            error: getErrorString(e),
          })
        )
      )
    );
  }
}

// Called by other sagas within this file
export function* requestIngredientGroupPost({
  ingredients,
}: {
  ingredients: ApiIngredientGroupIngredient[];
}) {
  const recipeId: FrescoId = yield select(selectRecipeId);

  try {
    const response: ApiResponse<ApiIngredientGroup> = yield call(
      apiRecipeIngredientGroupPostSaga,
      {
        recipeId,
        ingredients: ingredients.map((ingredient) =>
          pick(ingredient, [
            'uri',
            'ingredient',
            'amount',
            'units',
            'preparation1',
            'preparation2',
            'preparation3',
            'flags',
          ])
        ),
      }
    );

    yield put(
      ingredientGroupFetched({
        response,
        previousIngredients: ingredients,
      })
    );
  } catch (e) {
    yield all(
      ingredients.map((ingredientGroupIngredient) =>
        put(
          ingredientSaveFailed({
            id: ingredientGroupIngredient.id,
            error: getErrorString(e),
          })
        )
      )
    );
  }
}

// Called on action
export function* requestIngredientDelete({
  payload: { id },
}: PayloadAction<{ id: EntityId }>) {
  const recipeId: FrescoId = yield select(selectRecipeId);
  const ingredient: ApiIngredientGroupIngredient = yield select(
    selectIngredientById(id)
  );

  const ingredientsInGroup: ApiIngredientGroupIngredient[] = yield select(
    selectOtherIngredientsInGroup(getIngredientGroupId(ingredient.uri), id)
  );

  try {
    const response: ApiResponse<LocationResponse> = yield call(
      apiIngredientGroupDeleteSaga,
      // If no ingredientId is sent into API call the entire group is deleted.
      // Don't send ingredientId in if this is a single ingredient in an ingredient group
      // If it's a single ingredient in group the whole group should be removed
      {
        recipeId,
        groupId: getIngredientGroupId(ingredient.uri),
        ingredientId: ingredientsInGroup.length ? ingredient.id : undefined,
      }
    );
    if (!response.ok) {
      yield put(
        ingredientSaveFailed({
          id,
          error: response.details.message,
        })
      );
      return;
    }
    yield put(ingredientRemoved({ id }));
  } catch (e) {
    yield put(
      ingredientSaveFailed({
        id,
        error: getErrorString(e),
      })
    );
  }
}

// Called by other sagas within this file
export function* ingredientGroupDelete({
  stepId,
  groupId,
}: {
  stepId: EntityId;
  groupId: FrescoId;
}) {
  const recipeId: FrescoId = yield select(selectRecipeId);

  try {
    const response: ApiResponse<LocationResponse> = yield call(
      apiIngredientGroupDeleteSaga,
      {
        recipeId,
        groupId,
      }
    );
    if (!response.ok) {
      yield put(
        stepSaveFailed({
          id: stepId,
          error: response.details.message,
        })
      );
      return;
    }
  } catch (e) {
    yield put(
      stepSaveFailed({
        id: stepId,
        error: getErrorString(e),
      })
    );
  }
}

// Called by step sagas
export function* requestIngredientsGroupDelete({
  ingredientsUpdate,
  stepId,
}: {
  ingredientsUpdate?: StepIngredientGroupsUpdate;
  stepId: EntityId;
}) {
  if (
    !ingredientsUpdate?.stepIngredientGroup?.ingredients.length &&
    ingredientsUpdate?.stepIngredientGroup?.groupId
  ) {
    yield call(ingredientGroupDelete, {
      stepId,
      groupId: ingredientsUpdate?.stepIngredientGroup?.groupId,
    });
  }
  if (
    !ingredientsUpdate?.temporaryIngredientGroup?.ingredients.length &&
    ingredientsUpdate?.temporaryIngredientGroup?.groupId
  ) {
    yield call(ingredientGroupDelete, {
      stepId,
      groupId: ingredientsUpdate?.temporaryIngredientGroup?.groupId,
    });
  }
}

function* ingredientAddRequestedWatcher() {
  yield takeEvery(ingredientAddRequested, requestIngredientAdd);
}

export function* ingredientEditRequestedWatcher() {
  yield takeEvery(ingredientEditRequested, requestIngredientEdit);
}

function* ingredientGroupSaveRequestedWatcher() {
  yield takeEvery(ingredientGroupSaveRequested, requestIngredientGroupSave);
}

function* ingredientPatchRequestedWatcher() {
  yield takeEvery(ingredientPatchRequested, requestIngredientPatch);
}

export function* ingredientsRemoveRequestedWatcher() {
  yield takeEvery(ingredientRemoveRequested, requestIngredientDelete);
}

export function* ingredientSplitRequestedWatcher() {
  yield takeEvery(ingredientSplitRequested, requestIngredientSplit);
}

export const ingredientSagas = [
  ingredientAddRequestedWatcher,
  ingredientEditRequestedWatcher,
  ingredientsRemoveRequestedWatcher,
  ingredientGroupSaveRequestedWatcher,
  ingredientPatchRequestedWatcher,
  ingredientSplitRequestedWatcher,
];
