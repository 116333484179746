import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { put, call, takeLatest, select } from 'redux-saga/effects';

import { createRequestApiSaga } from 'api/createRequestApiSaga';
import { apiGetRecipeLintReport } from 'api/recipe';
import type { ApiResponse } from 'api/types';
import type { RootState } from 'app/rootReducer';
import { logoutSuccess } from 'features/login/loginSlice';
import { selectRecipeId } from 'features/recipe/details/detailsSlice';
import {
  recipeFetchFinished,
  recipeFetchRequested,
  recipeFetchFailed,
} from 'features/recipe/recipeSlice/recipeActions';
import type { FrescoId } from 'shared/types/entity';
import type {
  ApiLintReportResponse,
  FormattedLintReport,
} from 'shared/types/lintReport';
import { severityOrderMap } from 'shared/types/lintReport';
import { getErrorString } from 'shared/utils/common';

interface LintReportState {
  apiError?: string;
  apiPending: boolean;
  report?: FormattedLintReport;
}

export const apiLintRecipeSaga = createRequestApiSaga(
  apiGetRecipeLintReport,
  'Linting recipe'
);

export const initialState: LintReportState = {
  apiPending: false,
};

const lintReportSlice = createSlice({
  name: 'lintReportSlice',
  initialState,
  reducers: {
    setLintReportSuccess(
      state,
      { payload }: PayloadAction<FormattedLintReport>
    ) {
      state.report = payload;
      state.apiPending = false;
      state.apiError = undefined;
    },
    lintReportRequested(state) {
      state.report = undefined;
      state.apiPending = true;
      state.apiError = undefined;
    },
    lintReportRequestFailed(
      state,
      { payload: { error } }: PayloadAction<{ error: string }>
    ) {
      state.report = undefined;
      state.apiPending = false;
      state.apiError = error;
    },
    lintReportReset(state) {
      state.apiPending = false;
      state.report = undefined;
      state.apiError = undefined;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(recipeFetchRequested, () => initialState);
    builder.addCase(recipeFetchFinished, () => initialState);
    builder.addCase(recipeFetchFailed, () => initialState);
    builder.addCase(logoutSuccess, () => initialState);
  },
});

export const { reducer: lintReportReducer } = lintReportSlice;

export const {
  actions: {
    setLintReportSuccess,
    lintReportRequested,
    lintReportRequestFailed,
    lintReportReset,
  },
} = lintReportSlice;

export const selectLintReport = (
  state: RootState
): FormattedLintReport | undefined => state.recipe.lintReport.report;

export const selectLintReportPending = (state: RootState): boolean =>
  state.recipe.lintReport.apiPending;

export const selectLintReportError = (state: RootState): string | undefined =>
  state.recipe.lintReport.apiError;

export const formatLintErrors = (
  lintErrors: ApiLintReportResponse
): FormattedLintReport => ({
  ...lintErrors,
  report: Object.keys(lintErrors.report)
    .flatMap((key) =>
      lintErrors.report[key].flatMap((errorBlock) =>
        errorBlock.errors.flatMap((error) => ({
          ...error,
          location: key,
        }))
      )
    )
    .sort(
      (a, b) => severityOrderMap[a.severity] - severityOrderMap[b.severity]
    ),
});

export function* requestLintReport() {
  const recipeId: FrescoId = yield select(selectRecipeId);

  try {
    const response: ApiResponse<ApiLintReportResponse> = yield call(
      apiLintRecipeSaga,
      {
        recipeId,
      }
    );

    if (!response.ok) {
      yield put(lintReportRequestFailed({ error: response.details.message }));
      return;
    }
    const formattedErrors = formatLintErrors(response.data);
    yield put(setLintReportSuccess(formattedErrors));
  } catch (e) {
    yield put(lintReportRequestFailed({ error: getErrorString(e) }));
  }
}

export function* lintReportRequestedWatcher() {
  yield takeLatest(lintReportRequested, requestLintReport);
}

export const lintReportSagas = [lintReportRequestedWatcher];
