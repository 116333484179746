import { CloseIcon } from '@dropkitchen/icons-react';
import { Typography, Box, Grid, IconButton } from '@material-ui/core';
import type { PayloadAction } from '@reduxjs/toolkit';
import type React from 'react';
import { useDispatch } from 'react-redux';

interface EditSidebarTitleProps {
  text: string;
  onClosePayloadAction: PayloadAction;
}

export const EditSidebarTitle: React.FC<EditSidebarTitleProps> = ({
  text,
  onClosePayloadAction,
}) => {
  const dispatch = useDispatch();

  const handleCancelClick = () => {
    dispatch(onClosePayloadAction);
  };

  return (
    <Box px={3} mt={3}>
      <Grid container direction="row" justify="space-between">
        <Typography variant="h6">{text}</Typography>
        <IconButton color="inherit" onClick={handleCancelClick}>
          <CloseIcon />
        </IconButton>
      </Grid>
    </Box>
  );
};
