import { categoriesSagas } from 'features/categories/categoriesSlice';
import { collectionsSagas } from 'features/collections/collectionsSlice';
import { featuresSagas } from 'features/features/featuresSlice';
import { jobSagas } from 'features/jobs/jobSagas';
import { layoutSagas } from 'features/layout/layoutSlice';
import { loginSagas } from 'features/login/loginSagas';
import { mediaUploadSagas } from 'features/mediaUpload/mediaUploadSlice';
import { recipeSagas } from 'features/recipe/recipeSlice/recipeSagas';
import { recipesSagas } from 'features/recipes/recipesSagas';
import { termsSagas } from 'features/terms/termsSagas';
import { categoryTranslationSagas } from 'features/translation/category/categorySagas';
import { collectionTranslationSagas } from 'features/translation/collection/collectionSagas';
import { recipeTranslationSagas } from 'features/translation/recipe/recipeSagas';
import { dataDogSagas } from 'shared/utils/dataDogSagas';

/**
 * Most of app sagas are using `takeEvery` so in case if they accidentally crash
 * it makes sense to report an error and restart them automatically so they could
 * continue handling other actions
 */
export const getRestartableSagas = (): ((...args: unknown[]) => unknown)[] => [
  ...loginSagas,
  ...recipesSagas,
  ...recipeSagas,
  ...termsSagas,
  ...layoutSagas,
  ...dataDogSagas,
  ...mediaUploadSagas,
  ...recipeTranslationSagas,
  ...collectionsSagas,
  ...collectionTranslationSagas,
  ...categoriesSagas,
  ...categoryTranslationSagas,
  ...featuresSagas,
  ...jobSagas,
];

/**
 * Some sagas may be designed to run only once, for example handling
 * some single action during startup. It doesn't make sense to restart them
 * even in case of unhandled error
 */
// Not currently used
// export const getOneTimeSagas = (): ((...args: unknown[]) => unknown)[] => [];
