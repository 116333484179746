import { AccessGroup } from 'shared/permissions';
import type { FrescoEntity } from 'shared/types/entity';
import type { FrescoMedia, FrescoVideo } from 'shared/types/media';

export enum AcceptLanguageLocale {
  CsCz = 'cs-CZ',
  DaDK = 'da-DK',
  DeDE = 'de-DE',
  EsES = 'es-ES',
  FiFI = 'fi-FI',
  FrFR = 'fr-FR',
  ItIT = 'it-IT',
  NbNO = 'nb-NO',
  NlNL = 'nl-NL',
  PlPL = 'pl-PL',
  PtPT = 'pt-PT',
  RuRU = 'ru-RU',
  SvSE = 'sv-SE',
  EnAU = 'en-AU',
  EnGB = 'en-GB',
  EnUS = 'en-US',
}

export enum ApiLocale {
  CsCz = 'cs_CZ',
  DaDK = 'da_DK',
  DeDE = 'de_DE',
  EsES = 'es_ES',
  FiFI = 'fi_FI',
  FrFR = 'fr_FR',
  ItIT = 'it_IT',
  NbNO = 'nb_NO',
  NlNL = 'nl_NL',
  PlPL = 'pl_PL',
  PtPT = 'pt_PT',
  RuRU = 'ru_RU',
  SvSE = 'sv_SE',
  EnAU = 'en_AU',
  EnGB = 'en_GB',
  EnUS = 'en_US',
}

export const apiLocaleToAcceptLanguageMap: Record<
  ApiLocale,
  AcceptLanguageLocale
> = {
  [ApiLocale.CsCz]: AcceptLanguageLocale.CsCz,
  [ApiLocale.DaDK]: AcceptLanguageLocale.DaDK,
  [ApiLocale.DeDE]: AcceptLanguageLocale.DeDE,
  [ApiLocale.EsES]: AcceptLanguageLocale.EsES,
  [ApiLocale.FiFI]: AcceptLanguageLocale.FiFI,
  [ApiLocale.FrFR]: AcceptLanguageLocale.FrFR,
  [ApiLocale.ItIT]: AcceptLanguageLocale.ItIT,
  [ApiLocale.NbNO]: AcceptLanguageLocale.NbNO,
  [ApiLocale.NlNL]: AcceptLanguageLocale.NlNL,
  [ApiLocale.PlPL]: AcceptLanguageLocale.PlPL,
  [ApiLocale.PtPT]: AcceptLanguageLocale.PtPT,
  [ApiLocale.RuRU]: AcceptLanguageLocale.RuRU,
  [ApiLocale.SvSE]: AcceptLanguageLocale.SvSE,
  [ApiLocale.EnAU]: AcceptLanguageLocale.EnAU,
  [ApiLocale.EnGB]: AcceptLanguageLocale.EnGB,
  [ApiLocale.EnUS]: AcceptLanguageLocale.EnUS,
};

export type UserLocaleRoles =
  | AccessGroup.AdminContentEnUS
  | AccessGroup.AdminContentEnGB
  | AccessGroup.AdminContentEnAU
  | AccessGroup.AdminContentCsCZ
  | AccessGroup.AdminContentDaDK
  | AccessGroup.AdminContentDeDE
  | AccessGroup.AdminContentEsES
  | AccessGroup.AdminContentFiFI
  | AccessGroup.AdminContentFrFR
  | AccessGroup.AdminContentItIT
  | AccessGroup.AdminContentNlNL
  | AccessGroup.AdminContentNbNO
  | AccessGroup.AdminContentPlPL
  | AccessGroup.AdminContentPtPT
  | AccessGroup.AdminContentRuRU
  | AccessGroup.AdminContentSvSE
  | AccessGroup.AdminContentRuRU;

export const userAccessLocales: Record<UserLocaleRoles, ApiLocale> = {
  [AccessGroup.AdminContentEnUS]: ApiLocale.EnUS,
  [AccessGroup.AdminContentEnGB]: ApiLocale.EnGB,
  [AccessGroup.AdminContentEnAU]: ApiLocale.EnAU,
  [AccessGroup.AdminContentCsCZ]: ApiLocale.CsCz,
  [AccessGroup.AdminContentDaDK]: ApiLocale.DaDK,
  [AccessGroup.AdminContentDeDE]: ApiLocale.DeDE,
  [AccessGroup.AdminContentEsES]: ApiLocale.EsES,
  [AccessGroup.AdminContentFiFI]: ApiLocale.FiFI,
  [AccessGroup.AdminContentFrFR]: ApiLocale.FrFR,
  [AccessGroup.AdminContentItIT]: ApiLocale.ItIT,
  [AccessGroup.AdminContentNbNO]: ApiLocale.NbNO,
  [AccessGroup.AdminContentNlNL]: ApiLocale.NlNL,
  [AccessGroup.AdminContentPlPL]: ApiLocale.PlPL,
  [AccessGroup.AdminContentPtPT]: ApiLocale.PtPT,
  [AccessGroup.AdminContentRuRU]: ApiLocale.RuRU,
  [AccessGroup.AdminContentSvSE]: ApiLocale.SvSE,
};

export const localeLanguage: Record<ApiLocale, string> = {
  [ApiLocale.EnUS]: 'American English',
  [ApiLocale.EnGB]: 'British English',
  [ApiLocale.EnAU]: 'Australian English',
  [ApiLocale.CsCz]: 'Czech',
  [ApiLocale.DaDK]: 'Danish',
  [ApiLocale.DeDE]: 'German',
  [ApiLocale.EsES]: 'Spanish',
  [ApiLocale.FiFI]: 'Finnish',
  [ApiLocale.FrFR]: 'French',
  [ApiLocale.ItIT]: 'Italian',
  [ApiLocale.NbNO]: 'Norwegian',
  [ApiLocale.NlNL]: 'Dutch',
  [ApiLocale.PlPL]: 'Polish',
  [ApiLocale.PtPT]: 'Portuguese',
  [ApiLocale.RuRU]: 'Russian',
  [ApiLocale.SvSE]: 'Swedish',
};

export type LocaleTranslation = Partial<Record<ApiLocale, string>>;

export type LocaleMetadataTranslationsPluralForm = Partial<
  Record<'one' | 'other', string>
>;

export type LocaleMetadataTranslations = Partial<
  Record<
    'gender' | 'number' | 'pluralForm',
    string | LocaleMetadataTranslationsPluralForm
  >
>;

export type LocaleMetaTranslation = Partial<
  Record<ApiLocale, LocaleMetadataTranslations>
>;

export enum TranslationStatus {
  All = '',
  Translated = '1',
  Untranslated = '0',
}

export enum RecipeDetailsTranslationType {
  Name = 'nameTranslations',
  Introduction = 'introductionTranslations',
  CompletionCaption = 'completionCaptionTranslations',
}

export enum TranslatableProperty {
  NameTranslations = 'nameTranslations',
  DescriptionTranslations = 'descriptionTranslations',
}

export interface TranslationEditPayload {
  property: TranslatableProperty;
  translation: string | undefined;
  locale: ApiLocale;
}

export interface ApiRecipeI18n extends FrescoEntity {
  locale: ApiLocale;
  [RecipeDetailsTranslationType.Name]: LocaleTranslation;
  status: number;
  [RecipeDetailsTranslationType.Introduction]: LocaleTranslation;
  [RecipeDetailsTranslationType.CompletionCaption]: LocaleTranslation;
  steps: ApiStepI18n[];
}

export interface FrescoRecipeEntityI18n extends FrescoEntity {
  nameTranslations?: LocaleTranslation;
  prepositionTranslations?: LocaleTranslation;
  localeMetadataTranslations?: LocaleMetaTranslation;
}
export type FrescoStepActionI18n = FrescoRecipeEntityI18n;

export interface FrescoStepTipI18n extends FrescoEntity {
  captionTranslations: LocaleTranslation;
  image: FrescoMedia | null;
  video: FrescoVideo | null;
}

export interface FrescoStepContainerI18n extends FrescoRecipeEntityI18n {
  image: FrescoMedia;
}

export interface FrescoStepToContainerI18n extends FrescoEntity {
  container: FrescoStepContainerI18n;
}

export type FrescoStepSettingI18n = FrescoRecipeEntityI18n;

export interface FrescoStepAttachmentI18n extends FrescoRecipeEntityI18n {
  image: FrescoMedia;
}

export interface FrescoStepIngredientI18n extends FrescoRecipeEntityI18n {
  image: FrescoMedia;
  color: string;
}
export interface FrescoStepIngredientGroupIngredientI18n extends FrescoEntity {
  ingredient: FrescoStepIngredientI18n;
  quantityString: string;
}
export interface FrescoStepIngredientGroupI18n extends FrescoEntity {
  ingredients: FrescoStepIngredientGroupIngredientI18n[];
}

export interface FrescoStepApplianceI18n extends FrescoRecipeEntityI18n {
  ingredients: FrescoStepIngredientGroupIngredientI18n[];
  aliasTranslations: LocaleTranslation;
  descriptionTranslations: LocaleTranslation;
  badgeTranslations: LocaleTranslation;
  image: FrescoMedia;
}

export interface ApiStepI18n extends FrescoEntity {
  sentence?: string;
  sentenceRawTranslations: LocaleTranslation;
  tips: FrescoStepTipI18n[];
  action1: FrescoStepActionI18n | null;
  isVirtual: boolean;
  toContainer: FrescoStepToContainerI18n | null;
  setting: FrescoStepSettingI18n | null;
  setting2: FrescoStepSettingI18n | null;
  setting3: FrescoStepSettingI18n | null;
  setting4: FrescoStepSettingI18n | null;
  ingredientGroup: FrescoStepIngredientGroupI18n | null;
  appliance: FrescoStepApplianceI18n | null;
  attachment: FrescoStepAttachmentI18n | null;
  directObjectId: string | null;
  directObjectType: string | null;
}
