import { makeStyles, Typography, Grid } from '@material-ui/core';
import type { FC } from 'react';
import { memo } from 'react';

import { StepTip } from 'features/translation/recipe/stepViewEntities/StepTip';
import type { FrescoStepTipI18n } from 'shared/types/i18n';

const useStyles = makeStyles((theme) => ({
  subheader: {
    ...theme.typography.overline,
    transform: 'uppercase',
  },
}));
interface StepTipsProps {
  stepIndex: number;
  tips: FrescoStepTipI18n[] | undefined;
}

export const StepTips: FC<StepTipsProps> = memo(function StepTips({
  stepIndex,
  tips,
}) {
  const classes = useStyles();

  if (!tips?.length) {
    return null;
  }

  return (
    <Grid item>
      <Typography className={classes.subheader}>Tips</Typography>
      <Grid container direction="row" spacing={2}>
        {tips.map((tip, index) => (
          <Grid item key={tip.id} xs={tips.length > 1 ? 6 : 12}>
            <StepTip stepIndex={stepIndex} tipIndex={index} />
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
});
