import snakecaseKeys from 'snakecase-keys';

import { fetchJson } from 'api/fetchJson';
import type { ApiRequestFn } from 'api/types';
import { ApiResponseRequestVariant, HttpMethod } from 'api/types';
import { collectionsRoutesPathPrefix } from 'app/routes/constants';
import { appConfig } from 'shared/config';
import type {
  FrescoCollectionI18n,
  FrescoCollectionsGetResponse,
} from 'shared/types/collection';
import type { FrescoId } from 'shared/types/entity';
import type { TranslationStatus } from 'shared/types/i18n';
import { apiLocaleToAcceptLanguageMap, ApiLocale } from 'shared/types/i18n';
import type { FrescoTag } from 'shared/types/recipe';
import { getIdFromUri } from 'shared/utils/common';

export interface GetCollectionsRequest {
  page: number;
  pageSize: number;
  searchTerm?: string;
  tags?: FrescoTag[];
  translationStatus?: TranslationStatus;
  locale: ApiLocale;
}

export const apiGetCollections =
  ({
    page,
    pageSize,
    searchTerm,
    tags,
    translationStatus,
    locale,
  }: GetCollectionsRequest): ApiRequestFn<FrescoCollectionsGetResponse> =>
  (apiContext) => {
    const params = new URLSearchParams({
      page: page.toString(),
      // eslint-disable-next-line @typescript-eslint/naming-convention
      per_page: pageSize.toString(),
      // eslint-disable-next-line @typescript-eslint/naming-convention
      order_by: '-created_at',
    });

    if (searchTerm) {
      params.append('q', searchTerm);
    }

    if (tags?.length) {
      params.append('tags', tags.map(({ uri }) => getIdFromUri(uri)).join());
      params.append('op', 'and');
    }

    let headerLocale = ApiLocale.EnUS;

    if (translationStatus) {
      params.append('translation_status', translationStatus);
      headerLocale = locale;
    }
    return fetchJson<FrescoCollectionsGetResponse>({
      apiContext,
      locale: apiLocaleToAcceptLanguageMap[headerLocale],
      responseVariant: ApiResponseRequestVariant.Web,
      httpMethod: HttpMethod.Get,
      url: `${appConfig.apiUrl()}${collectionsRoutesPathPrefix}?type=featured&${params.toString()}`,
    });
  };

export interface GetCollectionRequest {
  collectionId: FrescoId;
  locale: ApiLocale;
}

export const apiGetCollectionTranslation =
  ({
    collectionId,
    locale,
  }: GetCollectionRequest): ApiRequestFn<FrescoCollectionI18n> =>
  (apiContext) =>
    fetchJson<FrescoCollectionI18n>({
      locale: apiLocaleToAcceptLanguageMap[locale],
      apiContext,
      responseVariant: ApiResponseRequestVariant.I18n,
      httpMethod: HttpMethod.Get,
      url: `${appConfig.apiCollectionsUrl()}/${collectionId}`,
    });

interface PatchCollectionValues {
  name: string;
  description: string;
}

export interface PatchCollectionRequest {
  collectionId: FrescoId;
  collection: PatchCollectionValues;
  locale: ApiLocale;
}

export const apiPatchCollectionTranslation =
  ({
    collectionId,
    collection,
    locale,
  }: PatchCollectionRequest): ApiRequestFn<FrescoCollectionI18n> =>
  (apiContext) =>
    fetchJson<FrescoCollectionI18n>({
      apiContext,
      body: snakecaseKeys(collection),
      httpMethod: HttpMethod.Patch,
      locale: apiLocaleToAcceptLanguageMap[locale],
      responseVariant: ApiResponseRequestVariant.I18n,
      url: `${appConfig.apiCollectionsUrl()}/${collectionId}`,
    });
