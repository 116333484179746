import { datadogLogs } from '@datadog/browser-logs';
import { datadogRum } from '@datadog/browser-rum';
import { ThemeProvider } from '@material-ui/core';
import CssBaseline from '@material-ui/core/CssBaseline';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { SnackbarProvider } from 'notistack';
import type React from 'react';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Switch, Route } from 'react-router-dom';

import { PageLayout } from 'features/layout/PageLayout';
import { LoginPage } from 'features/login/LoginPage';
import {
  selectLoginAuthData,
  selectLoginIsAuthenticated,
} from 'features/login/loginSlice';
import { Snackbar } from 'features/snackbar/Snackbar';
import { appConfig, AppEnvironment } from 'shared/config';
import {
  datadogApplicationId,
  datadogRumClientToken,
  datadogLogsClientToken,
} from 'shared/constants';

import { privateRoutes } from './routes';
import { appTheme } from './theme';

if (appConfig.appEnv() !== AppEnvironment.Development) {
  datadogLogs.onReady(() => {
    datadogLogs.init({
      clientToken: datadogLogsClientToken,
      site: 'datadoghq.com',
      forwardErrorsToLogs: true,
      sampleRate: 100,
      service: appConfig.appBackstage() ? 'Backstage' : 'Creator2',
      silentMultipleInit: true,
      env: appConfig.appEnv(),
    });
  });

  datadogRum.init({
    applicationId: datadogApplicationId,
    clientToken: datadogRumClientToken,
    site: 'datadoghq.com',
    service: appConfig.appBackstage() ? 'Backstage' : 'Creator2',
    env: appConfig.appEnv(),
    sampleRate: 100,
    trackInteractions: true,
    defaultPrivacyLevel: 'allow',
    replaySampleRate: 100,
    premiumSampleRate: 100,
  });
  datadogRum.startSessionReplayRecording();
}

const sentryDns = appConfig.appBackstage()
  ? 'https://816380f5fb4f4b29915933d04a1179a0@o761960.ingest.sentry.io/6039633'
  : 'https://f8473df248354625968e16fc9af31e52@o761960.ingest.sentry.io/5797990';

Sentry.init({
  dsn: sentryDns,
  integrations: [new Integrations.BrowserTracing()],
  enabled: appConfig.appEnv() !== AppEnvironment.Development,
  environment: appConfig.appEnv(),
  tracesSampleRate: 0.7,
});

export const App: React.FC = () => {
  const authData = useSelector(selectLoginAuthData);
  const isAuthenticated = useSelector(selectLoginIsAuthenticated);

  useEffect(() => {
    if (authData) {
      Sentry.setUser({
        id: authData.userId,
        email: authData.userEmail,
      });
    }
  }, [authData]);

  return (
    <ThemeProvider theme={appTheme}>
      <CssBaseline />
      <SnackbarProvider maxSnack={5}>
        <Switch>
          {!isAuthenticated ? (
            <LoginPage />
          ) : (
            privateRoutes.map(({ children, ...route }) => (
              <Route
                key={Array.isArray(route.path) ? route.path[0] : route.path}
                render={() => <PageLayout>{children}</PageLayout>}
                {...route}
              />
            ))
          )}
        </Switch>
        <Snackbar />
      </SnackbarProvider>
    </ThemeProvider>
  );
};
