import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice, createAction } from '@reduxjs/toolkit';

import type { SmartTranslationsRequest } from 'api/recipe';
import type { RootState } from 'app/rootReducer';
import { logoutSuccess } from 'features/login/loginSlice';
import type { FrescoId } from 'shared/types/entity';
import type { ApiLocale } from 'shared/types/i18n';
import type {
  FrescoRecipeLean,
  FrescoRecipesGetResponse,
  FrescoRecipeStatus,
  FrescoTag,
  RecipeSmartSuggestionsStatus,
  SmartSuggestionsStatus,
} from 'shared/types/recipe';

export interface Filters {
  search?: string;
  tags?: FrescoTag[];
  status?: FrescoRecipeStatus | undefined;
}

export interface RecipesState {
  apiError?: string;
  apiPending: boolean;
  page: number;
  filters: Filters;
  pageSize: number;
  totalPages: number;
  entites: FrescoRecipeLean[];
  smartTranslationsPending: boolean;
  smartTranslationsError?: string;
  smartTranslationsStatus: Partial<
    Record<ApiLocale, Record<FrescoId, SmartSuggestionsStatus>>
  >;
  smartTranslationsStatusPending: boolean;
  smartTranslationsStatusError?: string;
  statusChangePending: boolean;
  statusChangeError?: string;
  statusChangeFinished: boolean;
}

export interface RecipesSmartTranslationsStatusFinishedPayload {
  locale: ApiLocale;
  recipesStatus: RecipeSmartSuggestionsStatus[];
}

export interface RecipesStatusChangePayload {
  locale: ApiLocale;
  status: FrescoRecipeStatus;
  recipeIds: FrescoId[];
}

export const initialState: RecipesState = {
  apiPending: false,
  page: 1,
  pageSize: 25,
  entites: [],
  totalPages: 0,
  filters: {
    search: '',
    tags: [],
    status: undefined,
  },
  smartTranslationsPending: false,
  smartTranslationsStatus: {},
  smartTranslationsStatusPending: false,
  statusChangePending: false,
  statusChangeFinished: false,
};

export const recipesFetchRequested = createAction(
  'recipesSlice/fetchRequested'
);

const recipesSlice = createSlice({
  name: 'recipesSlice',
  initialState,
  reducers: {
    recipesApiSuccess(
      state,
      { payload }: PayloadAction<FrescoRecipesGetResponse>
    ) {
      state.apiPending = false;
      state.apiError = undefined;
      state.totalPages = payload.meta.total;
      state.entites = payload.results;
      state.smartTranslationsStatus = {};
    },
    recipesApiPending(state) {
      state.apiPending = true;
      state.apiError = undefined;
    },
    recipesApiError(state, { payload }: PayloadAction<string>) {
      state.apiPending = false;
      state.apiError = payload;
      state.entites = [];
      state.smartTranslationsStatus = {};
    },
    recipesPageSet(state, { payload }: PayloadAction<number>) {
      state.page = payload;
    },
    recipesPageSizeSet(state, { payload }: PayloadAction<number>) {
      state.pageSize = payload;
      state.page = 1;
    },
    recipesTotalPagesSet(state, { payload }: PayloadAction<number>) {
      state.totalPages = payload;
    },
    recipesFiltersSetSearch(state, { payload }: PayloadAction<string>) {
      state.filters.search = payload;
      state.page = 1;
    },
    recipesFiltersSetStatus(
      state,
      { payload }: PayloadAction<number | undefined>
    ) {
      state.filters.status = payload;
      state.page = 1;
    },
    recipesFiltersSetTags(state, { payload }: PayloadAction<FrescoTag[]>) {
      state.filters.tags = payload;
      state.page = 1;
    },
    recipesFiltersReset(state) {
      state.filters = initialState.filters;
      state.page = 1;
    },
    recipesSmartTranslationsSuccess(state) {
      state.smartTranslationsPending = false;
      state.smartTranslationsError = undefined;
    },
    recipesSmartTranslationsError(state, { payload }: PayloadAction<string>) {
      state.smartTranslationsPending = false;
      state.smartTranslationsError = payload;
    },
    recipesSmartTranslationsRequested(
      state,
      _: PayloadAction<SmartTranslationsRequest>
    ) {
      state.smartTranslationsPending = true;
      state.smartTranslationsError = undefined;
    },
    recipesSmartTranslationsStatusRequested(
      state,
      _: PayloadAction<SmartTranslationsRequest>
    ) {
      state.smartTranslationsStatusPending = true;
      state.smartTranslationsStatusError = undefined;
    },
    recipesSmartTranslationsStatusFinished(
      state,
      {
        payload: { locale, recipesStatus },
      }: PayloadAction<RecipesSmartTranslationsStatusFinishedPayload>
    ) {
      const statuses: Record<FrescoId, SmartSuggestionsStatus> = {};
      recipesStatus.forEach((item) => {
        statuses[item.recipeId] = item.status;
      });
      state.smartTranslationsStatus[locale] = {
        ...state.smartTranslationsStatus[locale],
        ...statuses,
      };
      state.smartTranslationsStatusPending = false;
      state.smartTranslationsStatusError = undefined;
    },
    recipesSmartTranslationsStatusError(
      state,
      { payload }: PayloadAction<string>
    ) {
      state.smartTranslationsStatusPending = false;
      state.smartTranslationsStatusError = payload;
    },
    recipesStatusChangeRequested(
      state,
      _: PayloadAction<RecipesStatusChangePayload>
    ) {
      state.statusChangePending = true;
      state.statusChangeError = undefined;
      state.statusChangeFinished = false;
    },
    recipesStatusChangeError(state, { payload }: PayloadAction<string>) {
      state.statusChangePending = false;
      state.statusChangeError = payload;
      state.statusChangeFinished = true;
    },
    recipesStatusChangeFinished(state) {
      state.statusChangePending = false;
      state.statusChangeError = undefined;
      state.statusChangeFinished = true;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(logoutSuccess, () => initialState);
  },
});

export const {
  reducer: recipesSliceReducer,
  actions: {
    recipesApiSuccess,
    recipesApiPending,
    recipesApiError,
    recipesPageSet,
    recipesFiltersSetSearch,
    recipesFiltersSetStatus,
    recipesFiltersSetTags,
    recipesPageSizeSet,
    recipesTotalPagesSet,
    recipesFiltersReset,
    recipesSmartTranslationsError,
    recipesSmartTranslationsRequested,
    recipesSmartTranslationsSuccess,
    recipesSmartTranslationsStatusRequested,
    recipesSmartTranslationsStatusFinished,
    recipesSmartTranslationsStatusError,
    recipesStatusChangeRequested,
    recipesStatusChangeError,
    recipesStatusChangeFinished,
  },
} = recipesSlice;

export const selectRecipes = (state: RootState): FrescoRecipeLean[] =>
  state.recipes.entites;

export const selectRecipesPage = (state: RootState): number =>
  state.recipes.page;

export const selectRecipesPageSize = (state: RootState): number =>
  state.recipes.pageSize;

export const selectRecipesTotalPages = (state: RootState): number =>
  state.recipes.totalPages;

export const selectRecipesFilters = (state: RootState): Filters | undefined =>
  state.recipes.filters;

export const selectRecipesApiError = (state: RootState): string | undefined =>
  state.recipes.apiError;

export const selectRecipesApiPending = (state: RootState): boolean =>
  state.recipes.apiPending;

export const selectRecipesFiltersSearch = (
  state: RootState
): string | undefined => state.recipes.filters?.search;

export const selectRecipesFiltersTags = (
  state: RootState
): FrescoTag[] | undefined => state.recipes.filters?.tags;

export const selectRecipesFiltersStatus = (
  state: RootState
): number | undefined => state.recipes.filters?.status;

export const selectRecipesSmartSuggestionsPending = (
  state: RootState
): boolean => state.recipes.smartTranslationsPending;

export const selectRecipesSmartSuggestionsStatus =
  (locale: ApiLocale) =>
  (state: RootState): Record<FrescoId, SmartSuggestionsStatus> | undefined =>
    state.recipes.smartTranslationsStatus[locale];

export const selectRecipeSmartSuggestionsStatus =
  (locale: ApiLocale, recipeId: FrescoId) =>
  (state: RootState): SmartSuggestionsStatus | undefined =>
    state.recipes.smartTranslationsStatus[locale]?.[recipeId];

export const selectRecipesSmartSuggestionsStatusPending = (
  state: RootState
): boolean => state.recipes.smartTranslationsStatusPending;

export const selectRecipesStatusChangePending = (state: RootState): boolean =>
  state.recipes.statusChangePending;

export const selectRecipesStatusChangeFinished = (state: RootState): boolean =>
  state.recipes.statusChangeFinished;
