import type { PayloadAction } from '@reduxjs/toolkit';
import isEmpty from 'lodash/isEmpty';
import { call, put, select, take, takeLatest } from 'redux-saga/effects';

import { createRequestApiSaga } from 'api/createRequestApiSaga';
import type {
  PostRecipesPublishRequest,
  SmartTranslationsRequest,
} from 'api/recipe';
import {
  apiPostRecipesPublish,
  apiGetRecipesSmartTranslations,
  apiGetRecipes,
  apiPostRecipesSmartTranslations,
} from 'api/recipe';
import type { ApiResponse, JobResponse, LocationResponse } from 'api/types';
import {
  jobFetchFailed,
  jobFetchFinished,
  jobFetchRequested,
  selectJobResult,
} from 'features/jobs/jobSlice';
import type { RecipesStatusChangePayload } from 'features/recipes/recipesSlice';
import {
  recipesApiError,
  recipesApiPending,
  recipesApiSuccess,
  recipesFetchRequested,
  recipesSmartTranslationsRequested,
  selectRecipesFiltersSearch,
  selectRecipesFiltersStatus,
  selectRecipesFiltersTags,
  selectRecipesPage,
  selectRecipesPageSize,
  recipesSmartTranslationsError,
  recipesSmartTranslationsSuccess,
  recipesSmartTranslationsStatusRequested,
  recipesSmartTranslationsStatusFinished,
  recipesSmartTranslationsStatusError,
  recipesStatusChangeRequested,
  recipesStatusChangeError,
  recipesStatusChangeFinished,
} from 'features/recipes/recipesSlice';
import { snackbarOpen } from 'features/snackbar/snackbarSlice';
import { selectCurrentLocale } from 'features/translation/localeSwitcher/localeSlice';
import { JobTranslationStatus } from 'shared/types/job';
import type {
  FrescoRecipesGetResponse,
  RecipeSmartSuggestionsStatus,
} from 'shared/types/recipe';
import { getErrorString } from 'shared/utils/common';
import {
  areSuggestionsEnabled,
  getIsTranslatableLocale,
} from 'shared/utils/translation';

export const apiGetRecipesSaga = createRequestApiSaga(
  apiGetRecipes,
  'Recipes loading'
);

export const apiPostSmartTranslationsSaga = createRequestApiSaga(
  apiPostRecipesSmartTranslations,
  'Smart translations requested'
);

export const apiGetSmartTranslationsSaga = createRequestApiSaga(
  apiGetRecipesSmartTranslations,
  'Smart translations status requested'
);

export const apiPostRecipesPublishSaga = createRequestApiSaga(
  apiPostRecipesPublish,
  'Status change requested'
);

export function* requestRecipes() {
  yield put(recipesApiPending());

  const page: ReturnType<typeof selectRecipesPage> = yield select(
    selectRecipesPage
  );
  const pageSize: ReturnType<typeof selectRecipesPageSize> = yield select(
    selectRecipesPageSize
  );
  const searchTerm: ReturnType<typeof selectRecipesFiltersSearch> =
    yield select(selectRecipesFiltersSearch);
  const tags: ReturnType<typeof selectRecipesFiltersTags> = yield select(
    selectRecipesFiltersTags
  );
  const status: ReturnType<typeof selectRecipesFiltersStatus> = yield select(
    selectRecipesFiltersStatus
  );
  const locale: ReturnType<typeof selectCurrentLocale> = yield select(
    selectCurrentLocale
  );

  try {
    const response: ApiResponse<FrescoRecipesGetResponse> = yield call(
      apiGetRecipesSaga,
      { locale, page, pageSize, searchTerm, status, tags }
    );
    if (!response.ok) {
      yield put(recipesApiError(response.details.message));
      return;
    }
    yield put(recipesApiSuccess(response.data));
    if (areSuggestionsEnabled(locale) && getIsTranslatableLocale(locale)) {
      const recipeIds = response.data.results.map((recipe) => recipe.id);
      yield put(
        recipesSmartTranslationsStatusRequested({
          locale,
          recipeIds,
        })
      );
    }
  } catch (e) {
    yield put(recipesApiError(getErrorString(e)));
  }
}

export function* requestRecipesSmartTranslations({
  payload: { locale, recipeIds },
}: PayloadAction<SmartTranslationsRequest>) {
  try {
    const response: ApiResponse<JobResponse> = yield call(
      apiPostSmartTranslationsSaga,
      { locale, recipeIds }
    );
    if (!response.ok) {
      yield put(recipesSmartTranslationsError(response.details.message));
      return;
    }

    yield put(jobFetchRequested({ jobId: response.data.jobId }));
    yield take([jobFetchFinished, jobFetchFailed]);
    const jobResponse: ReturnType<typeof selectJobResult> = yield select(
      selectJobResult
    );
    if (jobResponse?.status === JobTranslationStatus.Error) {
      let errorMessage = jobResponse?.message;
      if (jobResponse?.message.includes('Parallel Data does not exist')) {
        errorMessage =
          'Request could not be completed. Not enough translated recipes to use smart suggestions';
      }
      yield put(
        snackbarOpen({
          text: errorMessage,
          severity: 'error',
        })
      );
      yield put(recipesSmartTranslationsError(jobResponse?.message));
      return;
    }
    yield put(
      snackbarOpen({
        text: 'Request successful. You can check the progress in this recipe list',
        severity: 'success',
      })
    );
    yield put(recipesSmartTranslationsSuccess());
    yield put(recipesSmartTranslationsStatusRequested({ locale, recipeIds }));
  } catch (e) {
    yield put(recipesSmartTranslationsError(getErrorString(e)));
  }
}

export function* requestRecipesSmartTranslationsStatus({
  payload: { locale, recipeIds },
}: PayloadAction<SmartTranslationsRequest>) {
  try {
    const response: ApiResponse<RecipeSmartSuggestionsStatus[]> = yield call(
      apiGetSmartTranslationsSaga,
      { locale, recipeIds }
    );
    if (!response.ok) {
      yield put(recipesSmartTranslationsStatusError(response.details.message));
      return;
    }
    yield put(
      recipesSmartTranslationsStatusFinished({
        locale,
        recipesStatus: response.data,
      })
    );
  } catch (e) {
    yield put(recipesSmartTranslationsStatusError(getErrorString(e)));
  }
}

export function* requestRecipesStatusChange({
  payload: { locale, recipeIds, status },
}: PayloadAction<RecipesStatusChangePayload>) {
  try {
    if (isEmpty(recipeIds)) {
      yield put(recipesStatusChangeFinished());
      return;
    }
    const payload: PostRecipesPublishRequest = {
      recipes: recipeIds.map((id) => ({
        id,
        locales: [{ locale, status }],
      })),
    };
    const response: ApiResponse<LocationResponse[]> = yield call(
      apiPostRecipesPublishSaga,
      payload
    );
    if (!response.ok) {
      yield put(recipesStatusChangeError(response.details.message));
      return;
    }
    yield put(recipesStatusChangeFinished());
    yield put(recipesFetchRequested());
  } catch (e) {
    yield put(recipesStatusChangeError(getErrorString(e)));
  }
}

function* requestRecipeFetchWatcher() {
  yield takeLatest(recipesFetchRequested, requestRecipes);
}

function* requestRecipeSmartTranslationsWatcher() {
  yield takeLatest(
    recipesSmartTranslationsRequested,
    requestRecipesSmartTranslations
  );
}

function* requestRecipeSmartTranslationsStatusWatcher() {
  yield takeLatest(
    recipesSmartTranslationsStatusRequested,
    requestRecipesSmartTranslationsStatus
  );
}

function* requestRecipesStatusChangeWatcher() {
  yield takeLatest(recipesStatusChangeRequested, requestRecipesStatusChange);
}

export const recipesSagas = [
  requestRecipeFetchWatcher,
  requestRecipeSmartTranslationsWatcher,
  requestRecipeSmartTranslationsStatusWatcher,
  requestRecipesStatusChangeWatcher,
];
