import type { EntityId } from '@reduxjs/toolkit';
import { createAction } from '@reduxjs/toolkit';

import type { ApiRecipePost, ApiRecipePostByUrl } from 'api/recipe';
import type { ApiRecipeWeb } from 'shared/types/recipe';

export const recipeFetchRequested = createAction<EntityId>(
  'recipe/fetchRequested'
);

export const recipeFetchFinished = createAction<ApiRecipeWeb>(
  'recipe/fetchFinished'
);

export const recipeDeleteRequested = createAction<EntityId>(
  'recipe/deleteRequested'
);

export const recipeFetchFailed = createAction<string>('recipe/fetchFailed');

export const recipePostRequested = createAction<ApiRecipePost>(
  'recipe/postRequested'
);

export const recipePostByUrlRequested = createAction<ApiRecipePostByUrl>(
  'recipe/postByUrlRequested'
);

export const recipePatchRequested = createAction<{
  recipe: Partial<ApiRecipePost>;
}>('recipe/patchRequested');
