import Big from 'big.js';
import isNil from 'lodash/isNil';

/**
 * Version of `range` function that can work with float numbers
 *
 * Float numbers have precision issues, so some library like Big.js should be used
 */
export const bigRange = (start?: number, end?: number, step?: number) => {
  const result: Big[] = [];
  if (isNil(start) || isNil(end)) {
    return result;
  }
  const bigStart = Big(start);
  const bigEnd = Big(end);
  if (!step) {
    return start === end ? [bigStart] : [bigStart, bigEnd];
  }
  const bigStep = Big(step);
  let current = bigStart;
  while (current.lte(bigEnd)) {
    result.push(current);
    current = current.plus(bigStep);
  }
  return result;
};
