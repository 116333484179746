import snakecaseKeys from 'snakecase-keys';

import { fetchJson } from 'api/fetchJson';
import type { ApiRequestFn, LocationResponse } from 'api/types';
import { ApiResponseRequestVariant, HttpMethod } from 'api/types';
import { appConfig } from 'shared/config';
import type { FrescoId, FrescoEntityUri } from 'shared/types/entity';
import type {
  ApiIngredientGroupIngredient,
  FrescoIngredientGroupIngredientFlags,
  ApiIngredientGroup,
} from 'shared/types/ingredient';

export interface ApiIngredientGroupIngredientRequest {
  ingredient: FrescoEntityUri;
  amount: number | null;
  units: string | null;
  preparation1: FrescoEntityUri | null;
  preparation2: FrescoEntityUri | null;
  preparation3: FrescoEntityUri | null;
  flags: FrescoIngredientGroupIngredientFlags;
}

export interface ApiPostIngredientGroupRequest {
  recipeId: FrescoId;
  name?: string;
  ingredients: ApiIngredientGroupIngredientRequest[];
}

export const apiIngredientGroupPost =
  ({
    recipeId,
    name,
    ingredients,
  }: ApiPostIngredientGroupRequest): ApiRequestFn<LocationResponse> =>
  (apiContext) =>
    fetchJson<LocationResponse>({
      apiContext,
      httpMethod: HttpMethod.Post,
      mergeDataFromHeadersMap: {
        location: 'Location',
      },
      url: `${appConfig.apiV3PrefixRecipes()}/${recipeId}/ingredient-groups`,
      body: snakecaseKeys({
        name,
        ingredients,
      }),
    });

export const apiIngredientGroupGet =
  ({
    recipeId,
    groupId,
  }: {
    recipeId: FrescoId;
    groupId: FrescoId;
  }): ApiRequestFn<ApiIngredientGroup> =>
  (apiContext) =>
    fetchJson<ApiIngredientGroup>({
      apiContext,
      httpMethod: HttpMethod.Get,
      responseVariant: ApiResponseRequestVariant.Web,
      url: `${appConfig.apiV3PrefixRecipes()}/${recipeId}/ingredient-groups/${groupId}`,
    });

export interface ApiPatchIngredientRequest {
  recipeId: FrescoId;
  groupId: FrescoId;
  ingredient: ApiIngredientGroupIngredient;
}

export const apiIngredientPatch =
  ({
    recipeId,
    groupId,
    ingredient,
  }: ApiPatchIngredientRequest): ApiRequestFn<
    Partial<ApiIngredientGroupIngredient>
  > =>
  (apiContext) =>
    fetchJson<ApiIngredientGroupIngredient>({
      apiContext,
      httpMethod: HttpMethod.Patch,
      url: `${appConfig.apiV3PrefixRecipes()}/${recipeId}/ingredient-groups/${groupId}/ingredients/${
        ingredient.id
      }`,
      body: snakecaseKeys(ingredient),
    });

export interface ApiPutIngredientGroupRequest {
  recipeId: FrescoId;
  groupId: FrescoId;
  ingredients: ApiIngredientGroupIngredientRequest[];
}

export const apiIngredientGroupPut =
  ({
    recipeId,
    groupId,
    ingredients,
  }: ApiPutIngredientGroupRequest): ApiRequestFn<LocationResponse> =>
  (apiContext) =>
    fetchJson<LocationResponse>({
      apiContext,
      httpMethod: HttpMethod.Put,
      mergeDataFromHeadersMap: {
        location: 'Location',
      },
      url: `${appConfig.apiV3PrefixRecipes()}/${recipeId}/ingredient-groups/${groupId}`,
      body: snakecaseKeys({
        ingredients: [...ingredients],
      }),
    });
export interface ApiDeleteIngredientGroupRequest {
  recipeId: FrescoId;
  groupId: FrescoId;
  ingredientId?: FrescoId;
}

export type ApiIngredientGroupDeleteResponse = LocationResponse;

export const apiIngredientGroupDelete =
  ({
    recipeId,
    groupId,
    ingredientId,
  }: ApiDeleteIngredientGroupRequest): ApiRequestFn<LocationResponse> =>
  (apiContext) => {
    let url = `${appConfig.apiV3PrefixRecipes()}/${recipeId}/ingredient-groups/${groupId}`;
    if (ingredientId) {
      url += `/ingredients/${ingredientId}`;
    }
    return fetchJson<LocationResponse>({
      apiContext,
      httpMethod: HttpMethod.Delete,
      url,
    });
  };
