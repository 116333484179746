import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';
import type React from 'react';

interface OwnProps {
  title?: string | React.ReactElement;
  content?: string | React.ReactElement;
  isOpen: boolean;
  handleAgreeDialog: () => void;
  handleCloseDialog: () => void;
}

export const AlertDialog: React.FC<OwnProps> = ({
  title,
  content,
  isOpen,
  handleCloseDialog,
  handleAgreeDialog,
}) => (
  <Dialog
    open={isOpen}
    onClose={handleCloseDialog}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
  >
    <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
    <DialogContent>
      <DialogContentText id="alert-dialog-description">
        {content}
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button onClick={handleCloseDialog} color="primary">
        Cancel
      </Button>
      <Button onClick={handleAgreeDialog} color="primary" autoFocus>
        Ok
      </Button>
    </DialogActions>
  </Dialog>
);
