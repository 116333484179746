import * as Sentry from '@sentry/react';
import { all, spawn, call } from 'redux-saga/effects';

import { getRestartableSagas } from 'app/sagas';
import { getErrorString } from 'shared/utils/common';

function logError(e: string) {
  // eslint-disable-next-line no-console
  console.error(e);
  Sentry.captureException(e, {
    level: Sentry.Severity.Error,
  });
}

/**
 * We don't want unhandled errors in any saga to bubble up and crash root saga canceling all child
 * sagas, so all sagas are wrapped in `try {} catch {}` and reporting to console and Sentry.
 * It's a last resort error handling and sagas should not rely on it and handle their exceptions
 */
export function* rootSaga() {
  yield all([
    ...getRestartableSagas().map((saga) =>
      spawn(function* safeRestartSaga() {
        while (true) {
          try {
            yield call(saga);
            break;
          } catch (e) {
            logError(getErrorString(e));
          }
        }
      })
    ),
    // ...getOneTimeSagas.map((saga) =>
    //   spawn(function* safeSaga() {
    //     try {
    //       yield call(saga);
    //     } catch (e) {
    //       logError(e);
    //     }
    //   })
    // ),
  ]);
}
