import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice, createAction } from '@reduxjs/toolkit';
import { put, call, debounce, takeLatest } from 'redux-saga/effects';

import type { AuthorCreate } from 'api/authors';
import { apiUsersGet, apiUsersCreate, apiUserGet } from 'api/authors';
import { createRequestApiSaga } from 'api/createRequestApiSaga';
import type {
  FrescoPaginatedResponse,
  ApiResponse,
  LocationResponse,
} from 'api/types';
import type { RootState } from 'app/rootReducer';
import { detailsAuthorUpdated } from 'features/recipe/details/detailsSlice';
import { recipeEditEntityFinished } from 'features/recipe/edit/editSlice';
import { appConfig } from 'shared/config';
import { getDebounceInputs } from 'shared/constants';
import type { FrescoUser, FrescoUserClient } from 'shared/types/user';
import { getErrorString, getIdFromUri } from 'shared/utils/common';

interface TermsAuthorsState {
  apiError?: string;
  apiPending: boolean;
  entities: FrescoUser[];
}

export const initialState: TermsAuthorsState = {
  apiPending: false,
  entities: [],
};

const termsAuthorsSlice = createSlice({
  name: 'termsAuthorsSlice',
  initialState,
  reducers: {
    termsAuthorsSuccess(state, { payload }: PayloadAction<FrescoUser[]>) {
      state.entities = payload;
      state.apiPending = false;
      state.apiError = undefined;
    },
    termsAuthorsApiPending(state) {
      state.apiPending = true;
      state.apiError = undefined;
    },
    termsAuthorsApiError(state, { payload }: PayloadAction<string>) {
      state.apiPending = false;
      state.apiError = payload;
    },
  },
});

export const {
  reducer: termsAuthorsReducer,
  actions: {
    termsAuthorsSuccess,
    termsAuthorsApiPending,
    termsAuthorsApiError,
  },
} = termsAuthorsSlice;

const selectTermsAuthorsState = (state: RootState): TermsAuthorsState =>
  state.terms.authors;

export const selectTermsAuthorsEntities = (state: RootState): FrescoUser[] =>
  selectTermsAuthorsState(state).entities;

export const selectTermsAuthorsApiPending = (state: RootState): boolean =>
  selectTermsAuthorsState(state).apiPending;

export const selectTermsAuthorsApiError = (
  state: RootState
): string | undefined => selectTermsAuthorsState(state).apiError;

export const apiGetAuthorTermsSaga = createRequestApiSaga(
  apiUsersGet,
  'Authors loading'
);

export const apiGetAuthorSaga = createRequestApiSaga(
  apiUserGet,
  'Authors loading'
);

export const apiCreateAuthorSaga = createRequestApiSaga(
  apiUsersCreate,
  'Create Author'
);

export function* requestAuthorTerms({ payload }: PayloadAction<string | null>) {
  try {
    yield put(termsAuthorsApiPending());
    const response: ApiResponse<FrescoPaginatedResponse<FrescoUser>> =
      yield call(apiGetAuthorTermsSaga, { searchTerm: payload });
    if (!response.ok) {
      yield put(termsAuthorsApiError(response.details.message));
      return;
    }
    yield put(termsAuthorsSuccess(response.data.results));
  } catch (e) {
    yield put(termsAuthorsApiError(getErrorString(e)));
  }
}

export const createPartnersEmail = (name: string) =>
  `${appConfig.authorEmailPrefix()}${name
    .replace(/\s/g, '')
    .toLowerCase()}${appConfig.authorEmailSuffix()}`;

export function* createAuthorTerms({
  payload,
}: PayloadAction<Partial<AuthorCreate>>) {
  const defaultPassword = appConfig.authorPassword();
  if (defaultPassword) {
    try {
      yield put(termsAuthorsApiPending());
      const response: ApiResponse<LocationResponse> = yield call(
        apiCreateAuthorSaga,
        {
          ...payload,
          password: appConfig.authorPassword(),
        } as AuthorCreate
      );
      if (!response.ok) {
        yield put(termsAuthorsApiError(response.details.message));
        return;
      }
      const id = getIdFromUri(response.data.location);
      const authorResponse: ApiResponse<FrescoUser> = yield call(
        apiGetAuthorSaga,
        id
      );
      if (!authorResponse.ok) {
        return;
      }

      yield put(
        detailsAuthorUpdated({
          id: authorResponse.data.id,
          uri: authorResponse.data.uri,
          fullName: authorResponse.data.fullName,
          groups: authorResponse.data.groups,
          image: authorResponse.data.image,
          isPartner: authorResponse.data.isPartner,
          profile: authorResponse.data.profile,
          email: authorResponse.data.email,
        } as FrescoUserClient)
      );
      yield put(recipeEditEntityFinished());
    } catch (e) {
      yield put(termsAuthorsApiError(getErrorString(e)));
    }
  }
}

export const authorTermsFetchRequested = createAction<string | null>(
  'terms/authorTermsFetchRequested'
);

export const authorTermsCreateRequested = createAction<Partial<AuthorCreate>>(
  'terms/authorTermsCreateRequested'
);

function* requestAuthorTermsFetchWatcher() {
  yield debounce(
    getDebounceInputs(),
    authorTermsFetchRequested,
    requestAuthorTerms
  );
}

function* requestAuthorTermsCreateWatcher() {
  yield takeLatest(authorTermsCreateRequested, createAuthorTerms);
}

export const authorTermsSagas = [
  requestAuthorTermsFetchWatcher,
  requestAuthorTermsCreateWatcher,
];
