import type { SvgIconProps } from '@material-ui/core/SvgIcon';
import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import type { FunctionComponent } from 'react';
import { takeEvery, call } from 'redux-saga/effects';

import type { RootState } from 'app/rootReducer';
import { logoutSuccess, selectLoginState } from 'features/login/loginSlice';
import { appConfig } from 'shared/config';
import { organisationLogos } from 'shared/constants';
import type { FrescoRecipeStatus } from 'shared/types/recipe';

export enum SubMenuName {
  'MarketingOpen',
  'EntitiesOpen',
  'RecipesOpen',
  'TranslationsOpen',
  'AppliancesOpen',
}
interface LayoutState {
  headerTitle: string;
  recipeStatus?: FrescoRecipeStatus;
  drawerOpen: boolean;
  [SubMenuName.MarketingOpen]: boolean;
  [SubMenuName.EntitiesOpen]: boolean;
  [SubMenuName.RecipesOpen]: boolean;
  [SubMenuName.TranslationsOpen]: boolean;
  [SubMenuName.AppliancesOpen]: boolean;
}

export const initialState: LayoutState = {
  headerTitle: appConfig.applicationName(),
  drawerOpen: false,
  [SubMenuName.MarketingOpen]: false,
  [SubMenuName.EntitiesOpen]: false,
  [SubMenuName.RecipesOpen]: false,
  [SubMenuName.TranslationsOpen]: false,
  [SubMenuName.AppliancesOpen]: false,
};

interface SubMenuPayload {
  subMenuName: SubMenuName;
  value: boolean;
}

const layoutSlice = createSlice({
  name: 'layoutSlice',
  initialState,
  reducers: {
    layoutHeaderTitleSet(state, { payload }: PayloadAction<string>) {
      state.headerTitle = payload;
      state.recipeStatus = undefined;
    },
    layoutHeaderRecipeStatusSet(
      state,
      { payload }: PayloadAction<FrescoRecipeStatus | undefined>
    ) {
      state.recipeStatus = payload;
    },
    drawerOpenUpdated(state, { payload }: PayloadAction<boolean>) {
      state.drawerOpen = payload;
    },
    drawerSubMenuOpenUpdated(
      state,
      { payload: { subMenuName, value } }: PayloadAction<SubMenuPayload>
    ) {
      state[subMenuName] = value;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(logoutSuccess, () => initialState);
  },
});

export const { reducer: layoutSliceReducer } = layoutSlice;

export const {
  actions: {
    layoutHeaderTitleSet,
    layoutHeaderRecipeStatusSet,
    drawerOpenUpdated,
    drawerSubMenuOpenUpdated,
  },
} = layoutSlice;

const selectLayoutState = (state: RootState): LayoutState => state.layout;

export const selectLayoutHeaderTitle = (state: RootState): string =>
  selectLayoutState(state).headerTitle;

export const selectDrawerOpen = (state: RootState): boolean =>
  state.layout.drawerOpen;

export const selectSubMenuOpenByName =
  (name: SubMenuName) =>
  (state: RootState): boolean =>
    state.layout[name];

export const selectLayoutHeaderRecipeStatus = (
  state: RootState
): FrescoRecipeStatus | undefined => selectLayoutState(state).recipeStatus;

export const selectOrgLogo = (
  state: RootState
): FunctionComponent<SvgIconProps> | null => {
  const orgId = selectLoginState(state).authData?.userOrganisationId;
  if (orgId) {
    return organisationLogos[orgId];
  }
  return null;
};

export const setDocumentTitle = (title: string) => {
  document.title = `${title && `${title} - `}${appConfig.applicationName()}`;
};

function* layoutHeaderTitleSetSaga({
  payload,
}: ReturnType<typeof layoutHeaderTitleSet>) {
  yield call(setDocumentTitle, payload);
}

export function* layoutHeaderTitleSetWatcher() {
  yield takeEvery(layoutHeaderTitleSet, layoutHeaderTitleSetSaga);
}

export const layoutSagas = [layoutHeaderTitleSetWatcher];
