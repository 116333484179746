import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice, createAction, createSelector } from '@reduxjs/toolkit';

import type { ApiLoginRequest } from 'api/auth';
import type { ApiContext } from 'api/types';
import type { RootState } from 'app/rootReducer';
import type { OrganisationName } from 'shared/constants';
import type { PermissionsCheckRuleSet } from 'shared/permissions';
import type { ApiLocale } from 'shared/types/i18n';
import type { FrescoUser, FrescoGroup } from 'shared/types/user';
import { localStorageUser } from 'shared/utils/localStorage';

export const tokenRefreshPeriod = 60 * 60 * 1000;

export interface AppAuthData {
  expiresAt: number;
  refreshToken: string;
  token: string;
  userId: string;
  userEmail: string | undefined;
  userOrganisationName: OrganisationName;
  userOrganisationId: number;
  user: FrescoUser;
}

export interface LoginState {
  authData: AppAuthData | null;
  apiError?: string;
  apiPending: boolean;
}

export const getInitialState = (): LoginState => ({
  apiPending: false,
  authData: localStorageUser.getValue(),
});

const loginSlice = createSlice({
  name: 'loginSlice',
  initialState: getInitialState(),
  reducers: {
    loginSuccess(state, { payload }: PayloadAction<AppAuthData>) {
      state.authData = { ...payload };
      state.apiPending = false;
      state.apiError = undefined;
    },
    loginApiPendingUpdated(state, { payload }: PayloadAction<boolean>) {
      state.apiPending = payload;
      state.apiError = undefined;
    },
    loginApiError(state, { payload }: PayloadAction<string>) {
      state.apiPending = false;
      state.apiError = payload;
    },
    logoutSuccess(state) {
      state.authData = null;
    },
  },
});

export const {
  reducer: loginReducer,
  actions: {
    loginSuccess,
    loginApiPendingUpdated,
    loginApiError,
    logoutSuccess,
  },
} = loginSlice;

export const selectLoginState = (state: RootState): LoginState => state.login;

export const selectLoginApiPending = (state: RootState): boolean =>
  selectLoginState(state).apiPending;

export const selectLoginApiError = (state: RootState): string | undefined =>
  selectLoginState(state).apiError;

export const selectLoginAuthUserFullName = (
  state: RootState
): string | undefined => state.login.authData?.user.fullName;

export const selectLoginAuthUserEmail = (
  state: RootState
): string | undefined => selectLoginState(state).authData?.userEmail;

export const selectLoginAuthOrgName = (state: RootState): string | undefined =>
  selectLoginState(state).authData?.userOrganisationName;

export const selectLoginAuthData = (state: RootState): AppAuthData | null =>
  selectLoginState(state).authData;

export const selectLoginAuthDataUserGroups = (
  state: RootState
): FrescoGroup[] | undefined => selectLoginState(state).authData?.user.groups;

export const getSelectUserHasAccessGroupRights = (
  ruleSet: PermissionsCheckRuleSet
) =>
  createSelector(selectLoginAuthDataUserGroups, (userGroups) =>
    userHasGroupRights(ruleSet, userGroups)
  );

export const userHasGroupRights = (
  ruleSet: PermissionsCheckRuleSet,
  userGroups?: FrescoGroup[]
): boolean =>
  ruleSet.anyOf.some((requiredPermissions) =>
    requiredPermissions.allOf.every((rule) =>
      rule instanceof RegExp
        ? userGroups?.some((userGroup) => rule.test(userGroup.name))
        : userGroups?.some((userGroup) => userGroup.name === rule)
    )
  );

export const selectLoginIsAuthenticated = (state: RootState): boolean =>
  !!selectLoginAuthData(state);

export const selectLoginUserOrgSupportedLocales = (
  state: RootState
): ApiLocale[] =>
  selectLoginState(state).authData?.user.organization.settings
    ?.supportedLocales || [];

export const selectApiContext = (state: RootState): ApiContext => ({
  authData: selectLoginAuthData(state),
});

export const loginRequested = createAction<ApiLoginRequest>(
  'loginSlice/loginRequested'
);

export const logoutRequested = createAction('loginSlice/logoutRequested');
