import type { PayloadAction } from '@reduxjs/toolkit';
import { call, put, select, takeLatest } from 'redux-saga/effects';

import type { GetCollectionRequest } from 'api/collection';
import {
  apiPatchCollectionTranslation,
  apiGetCollectionTranslation,
} from 'api/collection';
import { createRequestApiSaga } from 'api/createRequestApiSaga';
import type { ApiResponse } from 'api/types';
import {
  collectionFetchFailed,
  collectionFetchFinished,
  collectionFetchRequested,
  collectionSaveFailed,
  collectionSaveFinished,
  collectionSaveRequested,
  selectCollectionDescription,
  selectCollectionName,
} from 'features/translation/collection/collectionSlice';
import type { FrescoCollectionI18n } from 'shared/types/collection';
import type { FrescoId } from 'shared/types/entity';
import type { ApiLocale } from 'shared/types/i18n';
import { getErrorString } from 'shared/utils/common';

export const apiGetCollectionSaga = createRequestApiSaga(
  apiGetCollectionTranslation,
  'Collection loading'
);

export const apiPatchCollectionSaga = createRequestApiSaga(
  apiPatchCollectionTranslation,
  'Collection saving'
);

export function* requestCollection({
  payload: { collectionId, locale },
}: PayloadAction<GetCollectionRequest>) {
  try {
    const response: ApiResponse<FrescoCollectionI18n> = yield call(
      apiGetCollectionSaga,
      {
        collectionId,
        locale,
      }
    );

    if (!response.ok) {
      yield put(collectionFetchFailed(response.details.message));
      return;
    }
    yield put(collectionFetchFinished(response.data));
  } catch (e) {
    yield put(collectionFetchFailed(getErrorString(e)));
  }
}

export interface PatchCollectionSaga {
  collectionId: FrescoId;
  locale: ApiLocale;
}

export function* patchCollection({
  payload: { collectionId, locale },
}: PayloadAction<PatchCollectionSaga>) {
  const name: string = yield select(selectCollectionName(locale));
  const description: string = yield select(selectCollectionDescription(locale));

  const response: ApiResponse<FrescoCollectionI18n> = yield call(
    apiPatchCollectionSaga,
    {
      collectionId,
      collection: { name, description },
      locale,
    }
  );

  if (!response.ok) {
    yield put(collectionSaveFailed(response.details.message));
  }

  yield put(collectionSaveFinished());
}

function* requestCollectionFetchWatcher() {
  yield takeLatest(collectionFetchRequested, requestCollection);
}

function* requestCollectionPatchWatcher() {
  yield takeLatest(collectionSaveRequested, patchCollection);
}

export const collectionTranslationSagas = [
  requestCollectionFetchWatcher,
  requestCollectionPatchWatcher,
];
