import camelCase from 'lodash/camelCase';

import type {
  FrescoApplianceProfileVessels,
  FrescoVesselFlag,
} from 'shared/types/appliance';
import type { FrescoStepVesselFlags } from 'shared/types/step';

interface FrescoVesselFlagStepValue extends FrescoVesselFlag {
  value?: boolean;
}

/**
 * Takes applianceVesselFlags and also stepVesselFlags
 * Matches the applianceVesselFlags flag Key to the stepVesselFlags key
 * Returns the applianceVesselFlags with the additional stepVesselFlags value
 */
export const getApplianceVesselFlagsWithStepValue = (
  applianceVesselFlags: FrescoApplianceProfileVessels,
  stepVesselFlags: FrescoStepVesselFlags
) => {
  return Object.entries(applianceVesselFlags).reduce(
    (acc, [vesselId, vessel]) => {
      const existingStepVesselFlags = stepVesselFlags[vesselId];

      if (!existingStepVesselFlags) {
        return acc;
      }

      // Get appliance profile vessel with same id
      // and map step vessel flag value to it
      const applianceVesselFlagWithStepValue = vessel.flags?.map((flag) => ({
        ...flag,
        value: existingStepVesselFlags[camelCase(flag.key)],
      }));
      if (applianceVesselFlagWithStepValue) {
        acc.push(...applianceVesselFlagWithStepValue);
      }
      return acc;
    },
    [] as FrescoVesselFlagStepValue[]
  );
};
