import { Container } from '@material-ui/core';
import type { GridValueGetterParams, GridColDef } from '@mui/x-data-grid';
import type { FC } from 'react';
import { memo, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { translateCategoryRoutePath } from 'app/routes/constants';
import { CategoriesFilters } from 'features/categories/CategoriesFilters';
import {
  selectCategoriesPageSize,
  selectCategories,
  selectCategoriesPage,
  selectCategoriesTotalPages,
  categoriesPageSizeSet,
  categoriesPageSet,
  categoriesFetchRequested,
  selectCategoriesApiPending,
} from 'features/categories/categoriesSlice';
import { layoutHeaderTitleSet } from 'features/layout/layoutSlice';
import { LocaleSwitcher } from 'features/translation/localeSwitcher/LocaleSwitcher';
import { DataGrid } from 'shared/components/dataGrid/DataGrid';
import type { DataGridAction } from 'shared/components/dataGrid/DataGridActionsMenu';
import { useGetTranslateAction } from 'shared/components/dataGrid/DataGridActionsMenu';
import { DataGridImageColumn } from 'shared/components/dataGrid/DataGridImageColumn';
import { DataGridTagsColumn } from 'shared/components/dataGrid/DataGridTagsColumn';
import { DataGridTextColumn } from 'shared/components/dataGrid/DataGridTextColumn';
import { entityFiltersHeight } from 'shared/constants';
import { useShouldDisplayTranslatePage } from 'shared/hooks/useShouldDisplayPage';

export const CategoriesPage: FC = memo(function CategoriesPage() {
  const dispatch = useDispatch();
  const displayPage = useShouldDisplayTranslatePage();
  const translateAction = useGetTranslateAction(translateCategoryRoutePath);

  useEffect(() => {
    dispatch(layoutHeaderTitleSet('Categories'));
  }, [dispatch]);

  const actions: DataGridAction[] = [translateAction];

  const columns: GridColDef[] = [
    {
      field: 'icon',
      headerName: 'Icon',
      width: 150,
      renderCell: (params: GridValueGetterParams) => (
        <DataGridImageColumn image={params.row.icon} label={params.row.name} />
      ),
      sortable: false,
    },
    {
      field: 'name',
      headerName: 'Name',
      renderCell: (params: GridValueGetterParams) => (
        <DataGridTextColumn text={params.row.name} />
      ),
      width: 250,
    },
    {
      field: 'kind',
      headerName: 'Kind',
      renderCell: (params: GridValueGetterParams) => (
        <DataGridTextColumn text={params.row.kind.name} />
      ),
      // As kind is an object, we have to tell what is the value so it knows how to sort
      valueGetter: (params: GridValueGetterParams) => params.row.kind.name,
      width: 200,
    },
    {
      field: 'tags',
      headerName: 'Tags',
      renderCell: (params: GridValueGetterParams) => (
        <DataGridTagsColumn tags={params.row.tags} />
      ),
      sortable: false,
      width: 220,
    },
  ];

  if (!displayPage) return null;

  return (
    <>
      <LocaleSwitcher />
      <Container maxWidth="md">
        <CategoriesFilters />
        <DataGrid
          actions={actions}
          columns={columns}
          filtersHeight={entityFiltersHeight}
          isPendingSelector={selectCategoriesApiPending}
          dataSelector={selectCategories}
          pageSelector={selectCategoriesPage}
          pageSizeSelector={selectCategoriesPageSize}
          totalPagesSelector={selectCategoriesTotalPages}
          dataFetchAction={categoriesFetchRequested}
          pageSetAction={categoriesPageSet}
          pageSizeSetAction={categoriesPageSizeSet}
          viewItemPath={translateCategoryRoutePath}
        />
      </Container>
    </>
  );
});
