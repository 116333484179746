import { TextField, Grid, Button, makeStyles } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import isNull from 'lodash/isNull';
import type { FC } from 'react';
import { memo, useState, useEffect, useMemo } from 'react';

import { StepTimer } from 'features/recipe/steps/stepFormEntities/StepTimer';
import type {
  FrescoAppliance,
  FrescoApplianceProfileSettingEnumValue,
  FrescoApplianceProfileSettingEnum,
  FrescoPresetTimeWithPrompt,
} from 'shared/types/appliance';
import { isPresetSetting } from 'shared/types/appliance';
import type { FrescoStepSetting } from 'shared/types/step';

export const useStyles = makeStyles((theme) => ({
  settingTimeButton: {
    height: theme.spacing(7),
  },
}));

interface StepSettingsProps {
  stepTimeSetting: number | null;
  fullWidth?: boolean;
  currentAppliance?: FrescoAppliance;
  setting: FrescoApplianceProfileSettingEnum;
  settingIndex: number;
  stepSettings: (FrescoStepSetting | null)[];
  onStepSettingsChange: (
    action: FrescoStepSetting | null,
    index: number
  ) => void;
  onStepTimeSettingChange: (time: number | null) => void;
}

export const StepSetting: FC<StepSettingsProps> = memo(function StepSetting({
  stepTimeSetting,
  fullWidth,
  currentAppliance,
  setting,
  settingIndex,
  stepSettings,
  onStepSettingsChange,
  onStepTimeSettingChange,
}) {
  const classes = useStyles();

  const [settingOptions, setSettingOptions] = useState<
    FrescoPresetTimeWithPrompt | undefined
  >();

  const settingValueId = stepSettings?.[settingIndex]?.id;
  const hasSettingOptions = settingValueId
    ? currentAppliance?.profile?.settingsOptions?.[settingValueId]
    : false;

  const settingName = useMemo(() => {
    return (
      setting.values.find((value) =>
        isPresetSetting(value)
          ? value.value === settingValueId
          : value.id === settingValueId
      )?.name || ''
    );
  }, [setting, settingValueId]);

  useEffect(() => {
    setSettingOptions(
      // In future if more than one setting option exists, this would not be set to .timesetting
      // settingOptions would be an object with key values.
      // Object keys would be mapped below, switch display component on object key
      hasSettingOptions && settingValueId
        ? currentAppliance?.profile?.settingsOptions?.[settingValueId]
            .timeSetting
        : undefined
    );
  }, [setting, currentAppliance, settingValueId, hasSettingOptions]);

  // Checks for compatible setting value in appliance profile setting options.
  // If none reset timeSetting value to null
  useEffect(() => {
    const applianceSettingsOptions = currentAppliance?.profile?.settingsOptions;
    const hasSettingOptionValue = applianceSettingsOptions
      ? stepSettings.some((stepSetting) =>
          stepSetting?.id
            ? Object.keys(applianceSettingsOptions).includes(
                stepSetting?.id.toString()
              )
            : false
        )
      : false;

    if (!hasSettingOptionValue) {
      onStepTimeSettingChange(null);
    }
  }, [stepSettings, currentAppliance, onStepTimeSettingChange]);

  const handleSettingChange = (
    value: FrescoApplianceProfileSettingEnumValue | null,
    index: number
  ) => {
    if (!value) {
      onStepSettingsChange(null, index);
      return;
    }
    const isPresetSettingValue = isPresetSetting(value);
    if (
      (isPresetSettingValue && !value.value) ||
      (!isPresetSettingValue && !value.id)
    ) {
      onStepSettingsChange(null, index);
      return;
    }

    onStepSettingsChange(
      {
        id: isPresetSettingValue ? value.value : value.id,
        name: value.name,
        uri: isPresetSettingValue
          ? `api/v1/settings/${value.value}`
          : value.uri,
      },
      index
    );
  };

  const handleAddSettingTimer = () => {
    onStepTimeSettingChange(settingOptions ? settingOptions.default : 0);
  };

  return (
    <>
      <Grid key={settingValueId} item xs={fullWidth ? 6 : 12}>
        <Autocomplete
          value={{
            name: settingName,
            value: (settingValueId as number) || 0,
          }}
          options={setting.values}
          getOptionLabel={(value) => value.name}
          getOptionSelected={(option, value) => option.name === value.name}
          onChange={(_e, value) => {
            handleSettingChange(value, settingIndex);
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              required={setting.required}
              autoComplete="off"
              label={setting.name}
              variant="outlined"
              fullWidth
            />
          )}
        />
      </Grid>
      {!!settingOptions && (
        <Grid key={setting.name} item xs={fullWidth ? 6 : 12}>
          {!isNull(stepTimeSetting) && (
            <StepTimer
              timerLabel={settingOptions.name}
              // Need to get step time if exists
              // Use key of settingsOptions to get correct value
              // Would need state to be in redux to use a selector
              stepTimer={stepTimeSetting}
              timeRanges={settingOptions}
              onChangeTimer={onStepTimeSettingChange}
            />
          )}
          {isNull(stepTimeSetting) && (
            <Grid item xs={12}>
              <Button
                className={classes.settingTimeButton}
                fullWidth
                onClick={handleAddSettingTimer}
                color="primary"
                variant="outlined"
              >
                Add natural venting time
              </Button>
            </Grid>
          )}
        </Grid>
      )}
    </>
  );
});
