import { Grid, TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import {
  selectTermsAppliancesEntities,
  appliancePresetsFetchRequested,
  applianceSettingsFetchRequested,
  applianceAttachmentsFetchRequested,
  applianceContainersFetchRequested,
} from 'features/terms/appliancesSlice';
import type { FrescoAppliance } from 'shared/types/appliance';
import { FrescoApplianceType } from 'shared/types/appliance';

interface StepApplianceProps {
  stepAppliance: FrescoAppliance | null;
  onStepApplianceChange: (action: FrescoAppliance | null) => void;
}

export const StepAppliance: React.FC<StepApplianceProps> = React.memo(
  function StepAppliance({ stepAppliance, onStepApplianceChange }) {
    const dispatch = useDispatch();
    const appliances = useSelector(selectTermsAppliancesEntities);

    useEffect(() => {
      if (
        stepAppliance &&
        stepAppliance?.type === FrescoApplianceType.Smart &&
        !stepAppliance?.profile?.presets
      ) {
        dispatch(appliancePresetsFetchRequested(stepAppliance.id));
      }
      if (stepAppliance && !stepAppliance?.profile?.settings) {
        dispatch(applianceSettingsFetchRequested(stepAppliance.id));
      }
      if (stepAppliance && !stepAppliance?.profile?.attachments) {
        dispatch(applianceAttachmentsFetchRequested(stepAppliance.id));
      }
      if (stepAppliance) {
        dispatch(applianceContainersFetchRequested(stepAppliance.id));
      }
    }, [dispatch, stepAppliance]);

    return (
      <Grid item xs={12}>
        <Autocomplete
          value={stepAppliance}
          options={appliances}
          getOptionLabel={(value) => value.name}
          getOptionSelected={(option, value) => option.uri === value.uri}
          onChange={(_e, value) => {
            onStepApplianceChange(value);
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              type="text"
              label="Appliance"
              variant="outlined"
              fullWidth
            />
          )}
        />
      </Grid>
    );
  }
);
