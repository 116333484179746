import { Grid, TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import findKey from 'lodash/findKey';
import values from 'lodash/values';
import type { FC } from 'react';
import { memo } from 'react';

import type {
  FrescoAppliance,
  FrescoApplianceProfilePreset,
} from 'shared/types/appliance';
import type { FrescoStepPreset } from 'shared/types/step';

interface StepPresetProps {
  currentAppliance?: FrescoAppliance;
  stepPreset: FrescoStepPreset | null;
  onStepPresetChange: (action: FrescoStepPreset | null) => void;
}

export const StepPreset: FC<StepPresetProps> = memo(function StepPreset({
  currentAppliance,
  stepPreset,
  onStepPresetChange,
}) {
  const currentProfile = currentAppliance?.profile;
  const presetRequired = currentProfile?.requiredPresetId;
  const presets = values(currentProfile?.presets);

  const handlePresetChange = (value: FrescoApplianceProfilePreset | null) => {
    if (!value) {
      onStepPresetChange(null);
      return;
    }
    const presetKey = findKey(
      currentProfile?.presets,
      (preset) => preset.name === value?.name
    );
    const preset = currentProfile?.presets?.[presetKey || ''];
    if (preset && presetKey) {
      onStepPresetChange({
        id: presetKey,
        name: preset.name,
        uri: `/api/v1/presets/${presetKey}`,
      });
    }
  };

  return (
    <>
      {!!presets?.length && (
        <Grid item xs={6}>
          <Autocomplete
            value={stepPreset}
            options={presets}
            getOptionLabel={(value) => value.name}
            getOptionSelected={(option, value) => option.name === value.name}
            onChange={(_e, value) => {
              handlePresetChange(value);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                required={presetRequired}
                autoComplete="off"
                label="Preset"
                variant="outlined"
                fullWidth
              />
            )}
          />
        </Grid>
      )}
    </>
  );
});
