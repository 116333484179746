import { Popover, Grid, FormControlLabel, Switch } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';

import type { RootState } from 'app/rootReducer';
import type { FeatureFlag } from 'features/developerMenu/featureFlagsSlice';
import {
  selectDeveloperMenuOpen,
  featureFlagsDeveloperMenuOpenUpdated,
  featureFlagsUpdated,
  selectRecipeLintFlag,
  selectCreateAuthorFlag,
  selectCreateFromUrlFlag,
  selectExternalRecipesFlag,
} from 'features/developerMenu/featureFlagsSlice';
import { appConfig } from 'shared/config';

const useStyles = makeStyles((theme) => ({
  developerMenu: {
    padding: theme.spacing(3),
  },
}));

const FlagSwitch = ({
  selector,
}: {
  selector: (state: RootState) => FeatureFlag;
}) => {
  const dispatch = useDispatch();
  const featureFlag = useSelector(selector);

  if (appConfig.isProduction() && !featureFlag.safeForProduction) {
    return null;
  }

  return (
    <FormControlLabel
      labelPlacement="start"
      control={
        <Switch
          checked={featureFlag.enabled}
          onChange={(e, value) => {
            dispatch(
              featureFlagsUpdated({
                flagName: featureFlag.name,
                value: !!value,
              })
            );
          }}
          name={featureFlag.name}
          color="primary"
        />
      }
      label={featureFlag.name}
    />
  );
};

export const DeveloperMenu: React.FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const isDeveloperMenuOpen = useSelector(selectDeveloperMenuOpen);

  return (
    <Popover
      anchorReference="anchorPosition"
      open={isDeveloperMenuOpen}
      anchorPosition={{ top: 0, left: 0 }}
      anchorOrigin={{
        vertical: 'center',
        horizontal: 'center',
      }}
      onClose={() => dispatch(featureFlagsDeveloperMenuOpenUpdated(false))}
    >
      <Grid container className={classes.developerMenu}>
        <FlagSwitch selector={selectRecipeLintFlag} />
        <FlagSwitch selector={selectCreateAuthorFlag} />
        <FlagSwitch selector={selectCreateFromUrlFlag} />
        <FlagSwitch selector={selectExternalRecipesFlag} />
      </Grid>
    </Popover>
  );
};
