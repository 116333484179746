import { AppBar, makeStyles } from '@material-ui/core';
import type { CSSProperties } from '@material-ui/core/styles/withStyles';
import classNames from 'classnames';
import type React from 'react';

import { primaryAppBarHeaderHeight } from 'shared/constants';

const useStyles = makeStyles((theme) => ({
  appBar: {
    flexDirection: 'row',
    top: `${theme.spacing(primaryAppBarHeaderHeight)}px`,
    boxShadow:
      '0px 2px 4px -1px rgb(0 0 0 / 40%), 0px 4px 5px 0px rgb(0 0 0 / 20%), 0px 1px 10px -6px rgb(0 0 0 / 20%)',
  },
  leftPane: {
    flexGrow: 1,
    minWidth: '1px',
  } as CSSProperties,
  actionsPane: {
    flexShrink: 0,
    display: 'flex',
    alignItems: 'center',
    padding: '0 8px',
  } as CSSProperties,
  '& button[disabled]': {
    borderColor: 'currentColor',
    color: 'black',
    opacity: 0.7,
  },
}));

interface SecondaryAppBarProps {
  children: React.ReactNode;
  className?: string;
  actions?: React.ReactNode;
}

export const SecondaryAppBar = ({
  children,
  className,
  actions,
}: SecondaryAppBarProps) => {
  const classes = useStyles();
  return (
    <AppBar
      position="sticky"
      color="primary"
      className={classNames(classes.appBar, className)}
    >
      <div className={classes.leftPane}>{children}</div>
      <div className={classes.actionsPane}>{actions}</div>
    </AppBar>
  );
};
