import { Paper, Box, Grid, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import debounce from 'lodash/debounce';
import { useMemo, useEffect } from 'react';
import type { FC } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import {
  categoriesFetchRequested,
  categoriesFiltersReset,
  categoriesFiltersSetTranslationStatus,
  selectCategoriesFiltersTranslationStatus,
} from 'features/categories/categoriesSlice';
import { selectCurrentLocale } from 'features/translation/localeSwitcher/localeSlice';
import { TranslationStatusFilter } from 'shared/components/TranslationStatusFilter';
import { entityFiltersHeight } from 'shared/constants';

const useStyles = makeStyles((theme) => ({
  searchContainer: {
    marginTop: theme.spacing(0.5),
    marginBottom: theme.spacing(0.5),
    alignItems: 'center',
  },
  container: {
    height: `${theme.spacing(entityFiltersHeight)}px`,
  },
}));

export const CategoriesFilters: FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const status = useSelector(selectCategoriesFiltersTranslationStatus);
  const locale = useSelector(selectCurrentLocale);

  const debounceFilters = useMemo(
    () => debounce(() => dispatch(categoriesFetchRequested()), 500),
    [dispatch]
  );

  useEffect(() => {
    debounceFilters();
  }, [debounceFilters, locale, status]);

  return (
    <Box pt={3} pb={1} className={classes.container}>
      <Paper>
        <Box px={2} py={1}>
          <Grid container className={classes.searchContainer} spacing={2}>
            <TranslationStatusFilter
              filterSetStatusAction={categoriesFiltersSetTranslationStatus}
              selectFilterTranslationStatus={
                selectCategoriesFiltersTranslationStatus
              }
            />
            <Grid item xs={12} sm={2}>
              <Button
                fullWidth
                variant="contained"
                color="primary"
                onClick={() => {
                  dispatch(categoriesFiltersReset());
                }}
              >
                Reset
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Paper>
    </Box>
  );
};
