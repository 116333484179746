import snakecaseKeys from 'snakecase-keys';

import { categoryApiPath } from 'api/constants';
import { fetchJson } from 'api/fetchJson';
import type { ApiRequestFn } from 'api/types';
import { ApiResponseRequestVariant, HttpMethod } from 'api/types';
import { appConfig } from 'shared/config';
import type {
  FrescoCategoriesGetResponse,
  FrescoCategoryI18n,
} from 'shared/types/category';
import type { FrescoId } from 'shared/types/entity';
import type { TranslationStatus } from 'shared/types/i18n';
import { apiLocaleToAcceptLanguageMap, ApiLocale } from 'shared/types/i18n';

export interface GetCategoriesRequest {
  page: number;
  pageSize: number;
  translationStatus?: TranslationStatus;
  locale: ApiLocale;
}

export const apiGetCategories =
  ({
    page,
    pageSize,
    translationStatus,
    locale,
  }: GetCategoriesRequest): ApiRequestFn<FrescoCategoriesGetResponse> =>
  (apiContext) => {
    const params = new URLSearchParams({
      page: page.toString(),
      // eslint-disable-next-line @typescript-eslint/naming-convention
      per_page: pageSize.toString(),
      // eslint-disable-next-line @typescript-eslint/naming-convention
      order_by: 'name',
    });

    let headerLocale = ApiLocale.EnUS;

    if (translationStatus) {
      params.append('translation_status', translationStatus);
      headerLocale = locale;
    }

    return fetchJson<FrescoCategoriesGetResponse>({
      apiContext,
      locale: apiLocaleToAcceptLanguageMap[headerLocale],
      responseVariant: ApiResponseRequestVariant.Web,
      httpMethod: HttpMethod.Get,
      url: `${appConfig.apiUrl()}${categoryApiPath}?${params.toString()}`,
    });
  };

export interface GetCategoryRequest {
  categoryId: FrescoId;
  locale: ApiLocale;
}

export const apiGetCategoryTranslation =
  ({
    categoryId,
    locale,
  }: GetCategoryRequest): ApiRequestFn<FrescoCategoryI18n> =>
  (apiContext) =>
    fetchJson<FrescoCategoryI18n>({
      locale: apiLocaleToAcceptLanguageMap[locale],
      apiContext,
      responseVariant: ApiResponseRequestVariant.I18n,
      httpMethod: HttpMethod.Get,
      url: `${appConfig.apiUrl()}${categoryApiPath}/${categoryId}`,
    });

export interface PatchCategoryValues {
  name: string;
  description: string;
}

export interface PatchCategoryRequest {
  categoryId: FrescoId;
  category: PatchCategoryValues;
  locale: ApiLocale;
}

export const apiPatchCategoryTranslation =
  ({
    categoryId,
    category,
    locale,
  }: PatchCategoryRequest): ApiRequestFn<FrescoCategoryI18n> =>
  (apiContext) =>
    fetchJson<FrescoCategoryI18n>({
      apiContext,
      body: snakecaseKeys(category),
      httpMethod: HttpMethod.Patch,
      locale: apiLocaleToAcceptLanguageMap[locale],
      responseVariant: ApiResponseRequestVariant.I18n,
      url: `${appConfig.apiUrl()}${categoryApiPath}/${categoryId}`,
    });
