import { fetchJson } from 'api/fetchJson';
import type { ApiRequestFn, FrescoPaginatedResponse } from 'api/types';
import { ApiResponseRequestVariant, HttpMethod } from 'api/types';
import { appConfig } from 'shared/config';
import type {
  FrescoAppliance,
  FrescoAppliancePresetSetting,
  FrescoApplianceProfilePreset,
  FrescoApplianceAttachment,
} from 'shared/types/appliance';
import type { FrescoContainer } from 'shared/types/container';
import type { FrescoId, FrescoTerm } from 'shared/types/entity';
import type { FrescoIngredient } from 'shared/types/ingredient';
import type { FrescoTag } from 'shared/types/recipe';

export const apiActionTermsGet =
  (): ApiRequestFn<FrescoPaginatedResponse<FrescoContainer>> => (apiContext) =>
    fetchJson<FrescoPaginatedResponse<FrescoContainer>>({
      responseVariant: ApiResponseRequestVariant.Web,
      httpMethod: HttpMethod.Get,
      apiContext,
      url: `${appConfig.apiUrl()}/terms?type=action&per_page=-1`,
    });

export const apiContainerTermsGet =
  (): ApiRequestFn<FrescoPaginatedResponse<FrescoContainer>> => (apiContext) =>
    fetchJson<FrescoPaginatedResponse<FrescoContainer>>({
      responseVariant: ApiResponseRequestVariant.Web,
      httpMethod: HttpMethod.Get,
      apiContext,
      url: `${appConfig.apiV3Prefix()}/containers?per_page=-1`,
    });

interface ApiContainerGetByIdRequest {
  id: FrescoId;
}

export const apiContainerTermGet =
  ({ id }: ApiContainerGetByIdRequest): ApiRequestFn<FrescoContainer> =>
  (apiContext) =>
    fetchJson<FrescoContainer>({
      responseVariant: ApiResponseRequestVariant.Web,
      httpMethod: HttpMethod.Get,
      apiContext,
      url: `${appConfig.apiUrl()}/containers/${id}`,
    });

export const apiIngredientTermsGet =
  (): ApiRequestFn<FrescoPaginatedResponse<FrescoIngredient>> => (apiContext) =>
    fetchJson<FrescoPaginatedResponse<FrescoIngredient>>({
      responseVariant: ApiResponseRequestVariant.Web,
      httpMethod: HttpMethod.Get,
      apiContext,
      url: `${appConfig.apiUrl()}/ingredients?per_page=-1`,
    });
export const apiYieldTermsGet =
  (): ApiRequestFn<FrescoPaginatedResponse<FrescoTerm>> => (apiContext) =>
    fetchJson<FrescoPaginatedResponse<FrescoTerm>>({
      responseVariant: ApiResponseRequestVariant.Web,
      httpMethod: HttpMethod.Get,
      apiContext,
      url: `${appConfig.apiUrl()}/terms?type=yield&per_page=100`,
    });

export const apiPreparationsTermsGet =
  (): ApiRequestFn<FrescoPaginatedResponse<FrescoTerm>> => (apiContext) =>
    fetchJson<FrescoPaginatedResponse<FrescoTerm>>({
      responseVariant: ApiResponseRequestVariant.Web,
      httpMethod: HttpMethod.Get,
      apiContext,
      url: `${appConfig.apiUrl()}/terms?type=preparation&per_page=-1`,
    });

export const apiTagTermsGet =
  (): ApiRequestFn<FrescoPaginatedResponse<FrescoTag>> => (apiContext) =>
    fetchJson<FrescoPaginatedResponse<FrescoTag>>({
      responseVariant: ApiResponseRequestVariant.Web,
      httpMethod: HttpMethod.Get,
      apiContext,
      url: `${appConfig.apiUrl()}/tags?per_page=-1`,
    });

export const apiAppliancesTermsGet =
  (): ApiRequestFn<FrescoPaginatedResponse<FrescoAppliance>> => (apiContext) =>
    fetchJson<FrescoPaginatedResponse<FrescoAppliance>>({
      responseVariant: ApiResponseRequestVariant.Web,
      httpMethod: HttpMethod.Get,
      apiContext,
      url: `${appConfig.apiUrl()}/appliances?per_page=-1`,
    });

export const apiAppliancesPresetsGet =
  ({
    id,
  }: {
    id: FrescoId;
  }): ApiRequestFn<FrescoPaginatedResponse<FrescoApplianceProfilePreset>> =>
  (apiContext) =>
    fetchJson<FrescoPaginatedResponse<FrescoApplianceProfilePreset>>({
      responseVariant: ApiResponseRequestVariant.Web,
      httpMethod: HttpMethod.Get,
      apiContext,
      url: `${appConfig.apiUrl()}/appliances/${id}/presets?per_page=100`,
    });

export const apiAppliancesSettingsGet =
  ({
    id,
  }: {
    id: FrescoId;
  }): ApiRequestFn<FrescoPaginatedResponse<FrescoAppliancePresetSetting>> =>
  (apiContext) =>
    fetchJson<FrescoPaginatedResponse<FrescoAppliancePresetSetting>>({
      responseVariant: ApiResponseRequestVariant.Web,
      httpMethod: HttpMethod.Get,
      apiContext,
      url: `${appConfig.apiUrl()}/appliances/${id}/settings?per_page=100&order_by=position`,
    });

export const apiAppliancesAttachmentsGet =
  ({
    id,
  }: {
    id: FrescoId;
  }): ApiRequestFn<FrescoPaginatedResponse<FrescoApplianceAttachment>> =>
  (apiContext) =>
    fetchJson<FrescoPaginatedResponse<FrescoApplianceAttachment>>({
      responseVariant: ApiResponseRequestVariant.Web,
      httpMethod: HttpMethod.Get,
      apiContext,
      url: `${appConfig.apiUrl()}/appliances/${id}/attachments?per_page=100`,
    });

export const apiAppliancesContainersGet =
  ({
    id,
  }: {
    id: FrescoId;
  }): ApiRequestFn<FrescoPaginatedResponse<FrescoContainer>> =>
  (apiContext) =>
    fetchJson<FrescoPaginatedResponse<FrescoContainer>>({
      responseVariant: ApiResponseRequestVariant.Web,
      httpMethod: HttpMethod.Get,
      apiContext,
      url: `${appConfig.apiUrl()}/appliances/${id}/containers?per_page=100`,
    });
