import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

import type { RootState } from 'app/rootReducer';
import { logoutSuccess, loginSuccess } from 'features/login/loginSlice';
import { appConfig } from 'shared/config';
import { OrganisationId, getCurrentOrganizationId } from 'shared/constants';

export const isRecipeLintEnabled = () =>
  getCurrentOrganizationId() === OrganisationId.Fresco;
export const isCreateAuthorEnabled = () =>
  getCurrentOrganizationId() === OrganisationId.Fresco;
export const isCreateFromUrlEnabled = () =>
  getCurrentOrganizationId() === OrganisationId.Fresco;
export const isExternalRecipesEnabled = () =>
  getCurrentOrganizationId() === OrganisationId.Fresco;

export enum FeatureFlagType {
  RecipeLinting = 'Recipe Linting',
  CreateAuthor = 'Create Author',
  CreateFromUrl = 'Create From URL',
  ExternalRecipes = 'External Recipes Available',
}

export interface FeatureFlag {
  name: FeatureFlagType;
  description: string;
  enabled: boolean;
  safeForProduction: boolean;
}

export interface FeatureFlagsState
  extends Record<FeatureFlagType, FeatureFlag> {
  developerMenuOpen: boolean;
}

export const initialState: FeatureFlagsState = {
  developerMenuOpen: false,
  [FeatureFlagType.RecipeLinting]: {
    name: FeatureFlagType.RecipeLinting,
    description: 'Display Recipe Lint Errors',
    enabled: isRecipeLintEnabled(),
    safeForProduction: true,
  },
  [FeatureFlagType.CreateAuthor]: {
    name: FeatureFlagType.CreateAuthor,
    description: `Allow creating of author with ${appConfig.authorEmailPrefix}<name>${appConfig.authorEmailSuffix} default email`,
    enabled: isCreateAuthorEnabled(),
    safeForProduction: false,
  },
  [FeatureFlagType.CreateFromUrl]: {
    name: FeatureFlagType.CreateFromUrl,
    description: `Allow creating of recipe from urls`,
    enabled: isCreateFromUrlEnabled(),
    safeForProduction: false,
  },
  [FeatureFlagType.ExternalRecipes]: {
    name: FeatureFlagType.ExternalRecipes,
    description: `Show isExternal recipe flag`,
    enabled: isExternalRecipesEnabled(),
    safeForProduction: false,
  },
};

const featureFlagsSlice = createSlice({
  name: 'featureFlagsSlice',
  initialState,
  reducers: {
    featureFlagsUpdated(
      state,
      {
        payload: { flagName, value },
      }: PayloadAction<{ flagName: FeatureFlagType; value: boolean }>
    ) {
      state[flagName].enabled = value;
    },
    featureFlagsDeveloperMenuOpenUpdated(
      state,
      { payload }: PayloadAction<boolean>
    ) {
      state.developerMenuOpen = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(logoutSuccess, () => initialState);
    builder.addCase(loginSuccess, () => initialState);
  },
});

export const {
  reducer: featureFlagsReducer,
  actions: { featureFlagsUpdated, featureFlagsDeveloperMenuOpenUpdated },
} = featureFlagsSlice;

export const selectRecipeLintEnabled = (state: RootState): boolean =>
  state.featureFlags[FeatureFlagType.RecipeLinting].enabled;

export const selectRecipeLintFlag = (state: RootState): FeatureFlag =>
  state.featureFlags[FeatureFlagType.RecipeLinting];

export const selectCreateAuthorEnabled = (state: RootState): boolean =>
  state.featureFlags[FeatureFlagType.CreateAuthor].enabled;

export const selectCreateAuthorFlag = (state: RootState): FeatureFlag =>
  state.featureFlags[FeatureFlagType.CreateAuthor];

export const selectCreateFromUrlEnabled = (state: RootState): boolean =>
  state.featureFlags[FeatureFlagType.CreateFromUrl].enabled;

export const selectCreateFromUrlFlag = (state: RootState): FeatureFlag =>
  state.featureFlags[FeatureFlagType.CreateFromUrl];

export const selectExternalRecipesEnabled = (state: RootState): boolean =>
  state.featureFlags[FeatureFlagType.ExternalRecipes].enabled;

export const selectExternalRecipesFlag = (state: RootState): FeatureFlag =>
  state.featureFlags[FeatureFlagType.ExternalRecipes];

export const selectDeveloperMenuOpen = (state: RootState): boolean =>
  state.featureFlags.developerMenuOpen;
