import type { PayloadAction, Update } from '@reduxjs/toolkit';
import { createSlice, createAction } from '@reduxjs/toolkit';
import { call, takeLatest, put } from 'redux-saga/effects';

import { createRequestApiSaga } from 'api/createRequestApiSaga';
import type {
  ApiImageCreateResponse,
  ApiMediaCreateRequest,
  ApiVideoCreateResponse,
} from 'api/media';
import {
  apiImageCreate,
  apiImageGet,
  apiVideoCreate,
  apiVideoGet,
} from 'api/media';
import type { ApiResponse } from 'api/types';
import type { RootState } from 'app/rootReducer';
import { logoutSuccess } from 'features/login/loginSlice';
import {
  recipeFetchFinished,
  recipeFetchRequested,
  recipeFetchFailed,
} from 'features/recipe/recipeSlice/recipeActions';
import { createSaveableEntityAdapter } from 'shared/redux/saveableEntityAdapter';
import type {
  FrescoMedia,
  FrescoVideo,
  FrescoMediaClass,
} from 'shared/types/media';
import { FrescoMediaMimetype } from 'shared/types/media';
import { getErrorString } from 'shared/utils/common';

const mediaStorage: Record<string, MediaFileUpload> = {};

export interface MediaFileUpload {
  id: string;
  file: Blob;
  type:
    | FrescoMediaMimetype.Jpeg
    | FrescoMediaMimetype.Png
    | FrescoMediaMimetype.Mp4;
  sizes?: FrescoMediaClass;
}

export const addFileToMediaStorage = (file: MediaFileUpload) => {
  mediaStorage[file.id] = file;
};

export const getFileFromMediaStorage = (id: string): MediaFileUpload =>
  mediaStorage[id];

export const removeFileFromMediaStorage = (id: string) => {
  delete mediaStorage[id];
};

export interface Media {
  id: string;
  uri?: string;
  previewUrl?: string;
  type:
    | FrescoMediaMimetype.Jpeg
    | FrescoMediaMimetype.Png
    | FrescoMediaMimetype.Mp4;
}

export const mediaUploadAdapter = createSaveableEntityAdapter<Media>();

export const initialState = mediaUploadAdapter.getInitialState();

const mediaUploadSlice = createSlice({
  name: 'mediaUploadSlice',
  initialState,
  reducers: {
    mediaUploadAdded: (
      state,
      { payload }: PayloadAction<{ entity: Media }>
    ) => {
      mediaUploadAdapter.addOne(state, payload);
    },
    mediaUploadUpdated: (
      state,
      { payload }: PayloadAction<{ update: Update<Media> }>
    ) => {
      mediaUploadAdapter.updateOne(state, payload);
    },
    mediaUploadRequested: (
      state,
      { payload }: PayloadAction<{ id: string }>
    ) => {
      mediaUploadAdapter.saveRequest(state, payload);
    },
    mediaUploadFinished: (
      state,
      { payload }: PayloadAction<{ id: string }>
    ) => {
      mediaUploadAdapter.saveFinish(state, payload);
    },
    mediaUploadFailed: (
      state,
      { payload }: PayloadAction<{ id: string; error: string }>
    ) => {
      mediaUploadAdapter.saveFail(state, payload);
    },
    mediaUploadRemoved: (state, { payload }: PayloadAction<{ id: string }>) => {
      mediaUploadAdapter.removeOne(state, payload);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(recipeFetchRequested, () => initialState);
    builder.addCase(recipeFetchFinished, () => initialState);
    builder.addCase(recipeFetchFailed, () => initialState);
    builder.addCase(logoutSuccess, () => initialState);
  },
});

export const {
  reducer: mediaUploadReducer,
  actions: {
    mediaUploadAdded,
    mediaUploadRequested,
    mediaUploadUpdated,
    mediaUploadFinished,
    mediaUploadFailed,
    mediaUploadRemoved,
  },
} = mediaUploadSlice;

export const {
  selectAll: selectMediaUploadsAll,
  selectById: selectMediaUploadById,
  selectIsSavingById: selectMediaUploadsIsSavingById,
  selectIsSavedById: selectMediaUploadsIsSavedById,
  selectSaveErrorById: selectMediaUploadsSaveErrorById,
} = mediaUploadAdapter.getSelectors<RootState>((state) => state.mediaUpload);

export const apiCreateImageSaga = createRequestApiSaga(
  apiImageCreate,
  'Uploading Image'
);

export const apiCreateVideoSaga = createRequestApiSaga(
  apiVideoCreate,
  'Uploading Video'
);

export const apiGetImageSaga = createRequestApiSaga(
  apiImageGet,
  'Image loading'
);

export const apiGetVideoSaga = createRequestApiSaga(
  apiVideoGet,
  'Video loading'
);

export function* requestImageUpload(upload: MediaFileUpload) {
  yield put(mediaUploadRequested({ id: upload.id }));
  try {
    const postResponse: ApiResponse<ApiImageCreateResponse> = yield call(
      apiCreateImageSaga,
      {
        file: upload.file,
        sizes: upload.sizes,
      } as ApiMediaCreateRequest
    );
    if (!postResponse.ok) {
      yield put(
        mediaUploadFailed({
          id: upload.id,
          error: postResponse.details.message,
        })
      );
      return;
    }

    const getResponse: ApiResponse<FrescoMedia> = yield call(apiGetImageSaga, {
      uri: postResponse.data.imageUrl,
    });
    if (!getResponse.ok) {
      yield put(
        mediaUploadFailed({ id: upload.id, error: getResponse.details.message })
      );
      return;
    }

    const smallestPreviewUri = (): string => {
      const { copies } = getResponse.data.data;
      if (!copies) {
        return getResponse.data.uri;
      }
      const smallestRes = Object.keys(copies).sort((a, b) =>
        parseInt(a) > parseInt(b) ? 1 : -1
      )[0];

      return copies[smallestRes]?.uri || getResponse.data.data.uri;
    };

    yield put(
      mediaUploadUpdated({
        update: {
          id: upload.id,
          changes: {
            uri: getResponse.data.uri,
            previewUrl: smallestPreviewUri(),
          },
        },
      })
    );

    yield put(mediaUploadFinished({ id: upload.id }));
    yield call(removeFileFromMediaStorage, upload.id);
  } catch (e) {
    yield put(mediaUploadFailed({ id: upload.id, error: getErrorString(e) }));
  }
}

export function* requestVideoUpload(upload: MediaFileUpload) {
  yield put(mediaUploadRequested({ id: upload.id }));
  try {
    const postResponse: ApiResponse<ApiVideoCreateResponse> = yield call(
      apiCreateVideoSaga,
      {
        file: upload.file,
        sizes: upload.sizes,
      } as ApiMediaCreateRequest
    );

    if (!postResponse.ok) {
      yield put(
        mediaUploadFailed({
          id: upload.id,
          error: postResponse.details.message,
        })
      );
      return;
    }

    const getResponse: ApiResponse<FrescoVideo> = yield call(apiGetVideoSaga, {
      uri: postResponse.data.videoUrl,
    });
    if (!getResponse.ok) {
      yield put(
        mediaUploadFailed({ id: upload.id, error: getResponse.details.message })
      );
      return;
    }

    yield put(
      mediaUploadUpdated({
        update: {
          id: upload.id,
          changes: {
            uri: getResponse.data.uri,
            previewUrl: getResponse.data?.image?.data.uri || '',
          },
        },
      })
    );

    yield put(mediaUploadFinished({ id: upload.id }));
    yield call(removeFileFromMediaStorage, upload.id);
  } catch (e) {
    yield put(mediaUploadFailed({ id: upload.id, error: getErrorString(e) }));
  }
}

export function* requestUpload({ payload }: PayloadAction<string>) {
  const upload: MediaFileUpload = yield call(getFileFromMediaStorage, payload);

  yield put(mediaUploadAdded({ entity: { id: payload, type: upload.type } }));
  if (
    upload.type === FrescoMediaMimetype.Jpeg ||
    upload.type === FrescoMediaMimetype.Png
  ) {
    yield call(requestImageUpload, upload);
    return;
  }
  yield call(requestVideoUpload, upload);
}

export const imageMediaUploadRequested = createAction<string>(
  'mediaUpload/imageMediaUploadRequested'
);

export const videoMediaUploadRequested = createAction<string>(
  'mediaUpload/videoMediaUploadRequested'
);

function* requestImageMediaUploadWatcher() {
  yield takeLatest(imageMediaUploadRequested, requestUpload);
}

function* requestVideoMediaUploadWatcher() {
  yield takeLatest(videoMediaUploadRequested, requestUpload);
}

export const mediaUploadSagas = [
  requestImageMediaUploadWatcher,
  requestVideoMediaUploadWatcher,
];
