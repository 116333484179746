import snakecaseKeys from 'snakecase-keys';

import { fetchJson } from 'api/fetchJson';
import type {
  ApiRequestFn,
  LocationResponse,
  FrescoPaginatedResponse,
} from 'api/types';
import { ApiResponseRequestVariant, HttpMethod } from 'api/types';
import { appConfig } from 'shared/config';
import type { FrescoAttachment } from 'shared/types/appliance';
import type { FrescoId, FrescoEntityUri } from 'shared/types/entity';
import type {
  ApiStepWeb,
  FrescoStepTemperature,
  FrescoStepVesselFlags,
} from 'shared/types/step';

export enum ApiRecipeStepType {
  Action = 'action',
  Ingredient = 'ingredient',
}
export interface ApiCreateRecipeStepRequest {
  recipeId: string;
  action1?: FrescoEntityUri;
  toContainer?: FrescoEntityUri;
  ingredientGroup?: FrescoEntityUri;
  appliance?: FrescoEntityUri;
  attachment?: FrescoEntityUri;
  preset?: FrescoEntityUri;
  sentenceRaw?: string;
  setting?: FrescoEntityUri;
  setting2?: FrescoEntityUri;
  setting3?: FrescoEntityUri;
  setting4?: FrescoEntityUri;
  temperature?: FrescoStepTemperature;
  time?: number;
  flags?: {
    timed?: boolean;
    vessels?: {
      [vesselId: number]: {
        [flagKey: string]: boolean | undefined;
      };
    };
  };
  position?: number;
  timeSetting: number | null;
}

export const apiStepPost =
  ({
    recipeId,
    sentenceRaw,
    position,
    action1,
    toContainer,
    appliance,
    preset,
    setting,
    setting2,
    setting3,
    setting4,
    temperature,
    attachment,
    flags,
    ingredientGroup,
    time,
    timeSetting,
  }: ApiCreateRecipeStepRequest): ApiRequestFn<LocationResponse> =>
  (apiContext) => {
    const type =
      ingredientGroup && !appliance
        ? ApiRecipeStepType.Ingredient
        : ApiRecipeStepType.Action;
    return fetchJson<LocationResponse>({
      apiContext,
      responseVariant: ApiResponseRequestVariant.Web,
      httpMethod: HttpMethod.Post,
      url: `${appConfig.apiRecipesUrl()}/${recipeId}/steps`,
      mergeDataFromHeadersMap: {
        location: 'Location',
      },
      body: snakecaseKeys({
        sentenceRaw,
        type,
        position,
        toContainer,
        action1,
        appliance,
        preset,
        setting,
        setting2,
        setting3,
        setting4,
        temperature,
        attachment,
        flags,
        ingredientGroup,
        time,
        timeSetting,
      }),
    });
  };
export interface ApiStepPatchRequest {
  id: FrescoId;
  recipeId: FrescoId;
  sentenceRaw: string | null;
  toContainer: FrescoEntityUri | null;
  ingredientGroup?: FrescoEntityUri | null;
  action1: FrescoEntityUri | null;
  appliance: FrescoEntityUri | null;
  preset: FrescoEntityUri | null;
  setting: FrescoEntityUri | null;
  setting2: FrescoEntityUri | null;
  setting3: FrescoEntityUri | null;
  setting4: FrescoEntityUri | null;
  temperature: FrescoStepTemperature | null;
  attachment: FrescoAttachment | null;
  position?: number;
  time: number | null;
  flags?: {
    timed?: boolean;
    vessels?: FrescoStepVesselFlags;
  };
  timeSetting: number | null;
}

export type ApiStepPatchResponse = LocationResponse;

export const apiStepPatch =
  ({
    recipeId,
    id,
    action1,
    toContainer,
    sentenceRaw,
    preset,
    appliance,
    setting,
    setting2,
    setting3,
    setting4,
    temperature,
    attachment,
    flags,
    position,
    time,
    ingredientGroup,
    timeSetting,
  }: ApiStepPatchRequest): ApiRequestFn<ApiStepPatchResponse> =>
  (apiContext) => {
    const type =
      ingredientGroup && !appliance
        ? ApiRecipeStepType.Ingredient
        : ApiRecipeStepType.Action;
    return fetchJson<ApiStepPatchResponse>({
      apiContext,
      responseVariant: ApiResponseRequestVariant.Web,
      httpMethod: HttpMethod.Patch,
      url: `${appConfig.apiRecipesUrl()}/${recipeId}/steps/${id}`,
      mergeDataFromHeadersMap: {
        location: 'Location',
      },
      body: snakecaseKeys({
        toContainer,
        sentenceRaw,
        action1,
        preset,
        type,
        appliance,
        setting,
        setting2,
        setting3,
        setting4,
        temperature,
        attachment,
        flags,
        position,
        time,
        ingredientGroup,
        timeSetting,
      }),
    });
  };

export interface ApiStepGetRequest {
  recipeId: FrescoId;
  stepId: FrescoId;
}

export const apiStepsGet =
  ({
    recipeId,
  }: {
    recipeId: FrescoId;
  }): ApiRequestFn<FrescoPaginatedResponse<ApiStepWeb>> =>
  (apiContext) =>
    // TODO : Remove FrescoPaginatedResponse when platform updates response
    fetchJson<FrescoPaginatedResponse<ApiStepWeb>>({
      apiContext,
      responseVariant: ApiResponseRequestVariant.Web,
      httpMethod: HttpMethod.Get,
      url: `${appConfig.apiRecipesUrl()}/${recipeId}/steps`,
    });

export interface ApiStepDeleteRequest {
  recipeId: FrescoId;
  stepId: FrescoId;
}

export const apiStepDelete =
  ({
    recipeId,
    stepId,
  }: ApiStepDeleteRequest): ApiRequestFn<LocationResponse> =>
  (apiContext) =>
    fetchJson<LocationResponse>({
      apiContext,
      responseVariant: ApiResponseRequestVariant.Web,
      httpMethod: HttpMethod.Delete,
      url: `${appConfig.apiRecipesUrl()}/${recipeId}/steps/${stepId}`,
    });
