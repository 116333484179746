import { MoreMenuIcon } from '@dropkitchen/icons-react';
import { IconButton, makeStyles } from '@material-ui/core';
import { DataGrid as MuiDataGrid } from '@mui/x-data-grid';
import type {
  GridRowParams,
  GridRowData,
  GridColumns,
  GridValueGetterParams,
  GridRowId,
} from '@mui/x-data-grid';
import type { ActionCreatorWithPayload, EntityId } from '@reduxjs/toolkit';
import type { FC } from 'react';
import { useMemo, useState, useCallback, useEffect, memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import type { RootState } from 'app/rootReducer';
import type { DataGridAction } from 'shared/components/dataGrid/DataGridActionsMenu';
import { DataGridActionsMenu } from 'shared/components/dataGrid/DataGridActionsMenu';
import { appConfig } from 'shared/config';
import {
  primaryAppBarHeaderHeight,
  secondaryAppBarHeaderHeight,
} from 'shared/constants';

// Issue with DataGrid, doesn't render in js-dom without autoHeight flag
// https://github.com/mui-org/material-ui-x/issues/1151
const autoHeight = appConfig.isTestEnv();

const useStyles = ({
  filtersHeight,
  barHeadersHeight,
}: {
  filtersHeight: number;
  barHeadersHeight: number;
}) =>
  makeStyles((theme) => ({
    gridContainer: {
      height: `calc(100vh - ${theme.spacing(
        filtersHeight
      )}px  - ${theme.spacing(barHeadersHeight)}px)`,
    },
    gridHeight: {
      display: 'flex',
      height: '100%',
    },
    grid: {
      flexGrow: 1,
      paddingTop: theme.spacing(1),
    },
    tableImage: {
      width: theme.spacing(15),
      height: theme.spacing(15),
      backgroundSize: 'cover',
    },
    table: {
      backgroundColor: theme.palette.background.paper,
      borderRadius: theme.shape.borderRadiusTable,
    },
  }));

export interface DataGridProps {
  actions: DataGridAction[];
  columns: GridColumns;
  filtersHeight?: number;
  isSecondaryAppBarVisible?: boolean;
  viewItemPath: string;
  isPendingSelector: (state: RootState) => boolean;
  dataSelector: (state: RootState) => GridRowData[];
  pageSelector: (state: RootState) => number;
  pageSizeSelector: (state: RootState) => number;
  totalPagesSelector: (state: RootState) => number;
  dataFetchAction: ActionCreatorWithPayload<void, string>;
  pageSetAction: ActionCreatorWithPayload<number, string>;
  pageSizeSetAction: ActionCreatorWithPayload<number, string>;
}

export const DataGrid: FC<DataGridProps> = memo(function DataGrid({
  actions,
  columns,
  filtersHeight = 1,
  isSecondaryAppBarVisible = false,
  viewItemPath,
  isPendingSelector,
  dataSelector,
  pageSelector,
  pageSizeSelector,
  totalPagesSelector,
  dataFetchAction,
  pageSetAction,
  pageSizeSetAction,
}) {
  const barHeadersHeight =
    primaryAppBarHeaderHeight +
    (isSecondaryAppBarVisible ? secondaryAppBarHeaderHeight : 0);
  const classes = useStyles({ filtersHeight, barHeadersHeight })();
  const dispatch = useDispatch();
  const history = useHistory();

  const page = useSelector(pageSelector);
  const pageSize = useSelector(pageSizeSelector);
  const totalPages = useSelector(totalPagesSelector);
  const isPending = useSelector(isPendingSelector);
  const rows = useSelector(dataSelector);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedId, setSelectedId] = useState<EntityId | null>(null);

  useEffect(() => {
    dispatch(dataFetchAction());
  }, [dataFetchAction, dispatch, page, pageSize]);

  const handleOpenActionsMenu = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>, id: GridRowId) => {
      setSelectedId(id);
      setAnchorEl(event.currentTarget);
    },
    []
  );

  const columnsWithMenu = useMemo(
    () => [
      ...columns,
      {
        field: 'edit',
        width: 100,
        headerName: 'Edit',
        sortable: false,
        disableClickEventBubbling: true,
        renderCell: (params: GridValueGetterParams) => (
          <IconButton
            aria-label="List Menu"
            size="small"
            color="primary"
            edge="start"
            onClick={(event) => {
              event.stopPropagation();
              handleOpenActionsMenu(event, params.id);
            }}
          >
            <MoreMenuIcon />
          </IconButton>
        ),
      },
    ],
    [columns, handleOpenActionsMenu]
  );

  const handleCloseActionsMenu = () => {
    setAnchorEl(null);
    setSelectedId(null);
  };

  const handlePageChange = (pageNumber: number) => {
    dispatch(pageSetAction(pageNumber + 1));
  };

  const handlePageSizeChange = (pageSizeNumber: number) => {
    dispatch(pageSizeSetAction(pageSizeNumber));
  };

  const handleRowOnClick = (params: GridRowParams) => {
    history.push(`${viewItemPath}/${params.row.id}`);
  };

  return (
    <>
      <DataGridActionsMenu
        actions={actions}
        anchorEl={anchorEl}
        onClose={handleCloseActionsMenu}
        selectedId={selectedId}
      />
      <div className={classes.gridContainer}>
        <div className={classes.gridHeight}>
          <div className={classes.grid}>
            <MuiDataGrid
              className={classes.table}
              disableSelectionOnClick
              autoHeight={autoHeight}
              rows={rows}
              columnBuffer={columns.length}
              columns={columnsWithMenu}
              density="comfortable"
              rowHeight={100}
              paginationMode="server"
              rowCount={totalPages}
              loading={isPending}
              pageSize={pageSize}
              page={page - 1}
              onPageChange={handlePageChange}
              onPageSizeChange={handlePageSizeChange}
              onRowClick={handleRowOnClick}
            />
          </div>
        </div>
      </div>
    </>
  );
});
