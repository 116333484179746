import snakecaseKeys from 'snakecase-keys';

import { fetchJson } from 'api/fetchJson';
import type { ApiRequestFn, LocationResponse } from 'api/types';
import { ApiResponseRequestVariant, HttpMethod } from 'api/types';
import { appConfig } from 'shared/config';
import type { FrescoId, FrescoEntityUri } from 'shared/types/entity';

export interface ApiStepTipCreate {
  recipeId: FrescoId;
  stepId: FrescoId;
  image?: FrescoEntityUri;
  caption: string;
  video?: FrescoEntityUri;
}

export const apiTipCreate =
  ({
    recipeId,
    stepId,
    image,
    caption,
    video,
  }: ApiStepTipCreate): ApiRequestFn<LocationResponse> =>
  (apiContext) =>
    fetchJson<LocationResponse>({
      apiContext,
      responseVariant: ApiResponseRequestVariant.Web,
      httpMethod: HttpMethod.Post,
      url: `${appConfig.apiRecipesUrl()}/${recipeId}/steps/${stepId}/tips`,
      mergeDataFromHeadersMap: {
        location: 'Location',
      },
      body: snakecaseKeys({
        image,
        caption,
        video,
      }),
    });

export interface ApiStepTipUpdate {
  recipeId: FrescoId;
  stepId: FrescoId;
  tipId: FrescoId;
  image?: FrescoEntityUri;
  caption: string;
  video?: FrescoEntityUri;
}

export const apiTipUpdate =
  ({
    recipeId,
    stepId,
    tipId,
    image,
    caption,
    video,
  }: ApiStepTipUpdate): ApiRequestFn<LocationResponse> =>
  (apiContext) =>
    fetchJson<LocationResponse>({
      apiContext,
      responseVariant: ApiResponseRequestVariant.Web,
      httpMethod: HttpMethod.Patch,
      url: `${appConfig.apiRecipesUrl()}/${recipeId}/steps/${stepId}/tips/${tipId}`,
      mergeDataFromHeadersMap: {
        location: 'Location',
      },
      body: snakecaseKeys({
        image,
        caption,
        video,
      }),
    });

export interface ApiStepTipDelete {
  recipeId: FrescoId;
  stepId: FrescoId;
  tipId: FrescoId;
}

export const apiTipDelete =
  ({
    recipeId,
    stepId,
    tipId,
  }: ApiStepTipDelete): ApiRequestFn<LocationResponse> =>
  (apiContext) =>
    fetchJson<LocationResponse>({
      apiContext,
      responseVariant: ApiResponseRequestVariant.Web,
      httpMethod: HttpMethod.Delete,
      url: `${appConfig.apiRecipesUrl()}/${recipeId}/steps/${stepId}/tips/${tipId}`,
    });
