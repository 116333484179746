import { Grid, TextField, Fab, Button, makeStyles } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import SaveIcon from '@material-ui/icons/Save';
import Autocomplete from '@material-ui/lab/Autocomplete';
import produce from 'immer';
import differenceBy from 'lodash/differenceBy';
import find from 'lodash/find';
import findKey from 'lodash/findKey';
import isNull from 'lodash/isNull';
import set from 'lodash/set';
import uniqBy from 'lodash/uniqBy';
import type { FC, FormEvent } from 'react';
import { memo, useState, useMemo, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import {
  selectContainersAll,
  selectContainerById,
} from 'features/recipe/containers/containersSlice';
import { EditSidebarActions } from 'features/recipe/edit/EditSidebarActions';
import { EditSidebarContent } from 'features/recipe/edit/EditSidebarContent';
import { EditSidebarForm } from 'features/recipe/edit/EditSidebarForm';
import { EditSidebarTitle } from 'features/recipe/edit/EditSidebarTitle';
import {
  selectRecipeEditMode,
  RecipeEditMode,
  selectRecipeEditEntityId,
  selectRecipeCreateEntityPosition,
  recipeEditEntityFinished,
} from 'features/recipe/edit/editSlice';
import { selectIngredientsAll } from 'features/recipe/ingredients/ingredientsSlice';
import { StepAction } from 'features/recipe/steps/stepFormEntities/StepAction';
import { StepAppliance } from 'features/recipe/steps/stepFormEntities/StepAppliance';
import { StepAttachment } from 'features/recipe/steps/stepFormEntities/StepAttachment';
import type { IngredientsAutoCompleteHelper } from 'features/recipe/steps/stepFormEntities/StepIngredients';
import { StepIngredients } from 'features/recipe/steps/stepFormEntities/StepIngredients';
import { StepPreset } from 'features/recipe/steps/stepFormEntities/StepPreset';
import { StepSettings } from 'features/recipe/steps/stepFormEntities/StepSettings';
import { StepTemperature } from 'features/recipe/steps/stepFormEntities/StepTemperature';
import { StepTimer } from 'features/recipe/steps/stepFormEntities/StepTimer';
import { StepTip } from 'features/recipe/steps/stepFormEntities/StepTip';
import { StepVesselFlags } from 'features/recipe/steps/stepFormEntities/StepVesselFlags';
import {
  selectStepById,
  stepEditRequested,
  selectStepsTotal,
  selectStepNumber,
  stepAddRequested,
} from 'features/recipe/steps/stepsSlice';
import {
  selectTermsAppliancesEntities,
  selectTermsApplianceContainers,
} from 'features/terms/appliancesSlice';
import {
  selectTermsContainerById,
  containerTermsFetchRequested,
  selectTermsContainersEntities,
  selectTermsContainersApiPending,
} from 'features/terms/containersSlice';
import { useIngredientsInTempGroup } from 'shared/hooks/useIngredientsInTempGroup';
import type { FrescoAppliance, FrescoAttachment } from 'shared/types/appliance';
import type { FrescoContainer } from 'shared/types/container';
import type { ApiIngredientGroupIngredient } from 'shared/types/ingredient';
import type { FrescoMedia, FrescoVideo } from 'shared/types/media';
import { FrescoMediaMimetype } from 'shared/types/media';
import type { FrescoRecipeContainer } from 'shared/types/recipe';
import type {
  FrescoStepAction,
  FrescoStepPreset,
  FrescoStepSetting,
  FrescoStepTemperature,
  FrescoStepVesselFlags,
  FrescoTip,
} from 'shared/types/step';
import { getIdFromUri, getIngredientGroupId } from 'shared/utils/common';

const useStyles = makeStyles((theme) => ({
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
}));

const getCompatibleContainer = (
  containers: FrescoRecipeContainer[],
  applianceContainers: FrescoContainer[] | undefined
) =>
  containers.filter((container) =>
    applianceContainers?.some(
      (applianceContainer) => applianceContainer.id === container.container.id
    )
  );

export const StepForm: FC = memo(function StepForm() {
  const classes = useStyles();
  const dispatch = useDispatch();

  const isEditMode = useSelector(selectRecipeEditMode) === RecipeEditMode.Edit;
  const editingEnityId = useSelector(selectRecipeEditEntityId);
  const createAtPosition = useSelector(selectRecipeCreateEntityPosition);
  const editEntity = useSelector(selectStepById(editingEnityId ?? ''));
  const totalSteps = useSelector(selectStepsTotal);
  const stepIndex = useSelector(selectStepNumber(editingEnityId ?? ''));
  const containers = useSelector(selectContainersAll);
  const appliances = useSelector(selectTermsAppliancesEntities);
  const ingredients: ApiIngredientGroupIngredient[] =
    useSelector(selectIngredientsAll);
  const containersTerms = useSelector(selectTermsContainersEntities);
  const containersTermsPending = useSelector(selectTermsContainersApiPending);

  const { ingredientsInTemporaryGroup, temporaryGroupIds } =
    useIngredientsInTempGroup();

  const stepIngredientGroupId = editEntity?.ingredientGroup?.uri
    ? getIngredientGroupId(editEntity?.ingredientGroup?.uri)
    : null;

  const stepToContainer = useSelector(
    selectContainerById(getIdFromUri(editEntity?.toContainer?.uri || ''))
  );
  const [sentence, setSentence] = useState(editEntity?.sentence || '');
  const [toContainer, setToContainer] = useState<FrescoRecipeContainer | null>(
    stepToContainer ?? null
  );

  const [timeSetting, setTimeSetting] = useState(
    editEntity?.timeSetting || null
  );

  useEffect(() => {
    if (!containersTerms.length && !containersTermsPending) {
      dispatch(containerTermsFetchRequested());
    }
  }, [dispatch, containersTermsPending, containersTerms]);

  const currentContainerSelector = useMemo(
    () => selectTermsContainerById(toContainer?.container?.id || ''),
    [toContainer?.container?.id]
  );
  const currentContainer = useSelector(currentContainerSelector);

  const [stepAction, setStepAction] = useState<FrescoStepAction | null>(
    editEntity?.action1 ?? null
  );
  const [stepAppliance, setStepAppliance] = useState<FrescoAppliance | null>(
    editEntity?.appliance ?? null
  );
  const applianceContainers: FrescoContainer[] | undefined = useSelector(
    selectTermsApplianceContainers(stepAppliance?.id ?? null)
  );

  // Constrain selectable containers by appliance containers if returned
  // Else use recipe containers
  const [compatibleContainers, setCompatibleContainers] = useState<
    FrescoRecipeContainer[]
  >(() =>
    applianceContainers
      ? getCompatibleContainer(containers, applianceContainers)
      : containers
  );

  const [stepPreset, setStepPreset] = useState<FrescoStepPreset | null>(
    editEntity?.preset ?? null
  );
  const prevPreset = useRef(stepPreset);
  const [stepTemperature, setStepTemperature] =
    useState<FrescoStepTemperature | null>(editEntity?.temperature ?? null);

  const currentAppliance = useMemo(
    () => find(appliances, (x) => x.id === stepAppliance?.id),
    [appliances, stepAppliance]
  );

  const currentPresetKey = useMemo(
    () =>
      findKey(
        currentAppliance?.profile?.presets,
        (preset) => preset.name.toLowerCase() === stepPreset?.name.toLowerCase()
      ),
    [currentAppliance, stepPreset]
  );

  const currentPreset =
    currentAppliance?.profile?.presets?.[currentPresetKey || ''];

  const containerAutocompleteMessage =
    currentAppliance && containers.length
      ? 'No compatible containers'
      : 'No containers in recipe';

  const [stepSettings, setStepSettings] = useState<
    (FrescoStepSetting | null)[]
  >([
    editEntity?.setting || null,
    editEntity?.setting2 || null,
    editEntity?.setting3 || null,
    editEntity?.setting4 || null,
  ]);

  const originalStepIngredients = editEntity?.ingredientGroup?.uri
    ? ingredients.filter((ingredient) =>
        ingredient.uri.startsWith(editEntity?.ingredientGroup?.uri || '')
      )
    : [];

  const [stepIngredients, setStepIngredients] = useState<
    IngredientsAutoCompleteHelper[]
  >(originalStepIngredients.map((ingredient) => ({ ingredient })));

  useEffect(() => {
    setStepIngredients((prevState: IngredientsAutoCompleteHelper[]) =>
      prevState.reduce((filteredIngredients, ingredient) => {
        // Remove any ingredients that don't still exist in recipe ingredients state.
        // If a temporary ingredient is added to step and then split.
        // ingredient id changes as new ingredients in group are.
        const foundIngredient = ingredients.find(
          (recipeIngredient) => recipeIngredient.id === ingredient.ingredient.id
        );
        if (foundIngredient) {
          // Ingredients that are assigned to step and are split have updated amounts in state
          // Step ingredients doesn't update because uri isn't associated yet.
          // Update ingredient amount using recipe ingredient
          filteredIngredients.push({
            ...ingredient,
            ingredient: foundIngredient,
          });
        }
        return filteredIngredients;
      }, [] as IngredientsAutoCompleteHelper[])
    );
  }, [ingredients, setStepIngredients]);

  const [stepAttachment, setStepAttachment] = useState<FrescoAttachment | null>(
    editEntity?.attachment ?? null
  );
  const [stepVesselFlags, setStepVesselFlags] =
    useState<FrescoStepVesselFlags | null>(editEntity?.flags?.vessels ?? null);
  const [stepTips, setStepTips] = useState<FrescoTip[]>(editEntity?.tips ?? []);
  const [stepTimer, setStepTimer] = useState<number | null>(
    editEntity?.time ?? null
  );

  const [limitedActions, setLimitedActions] = useState<FrescoStepAction[]>([]);
  const [tipCount, setTipCount] = useState(0);

  useEffect(() => {
    if (stepAppliance && applianceContainers?.length === 1) {
      const usableContainer = containers.filter(
        (container) => container.container.id === applianceContainers[0].id
      );
      if (usableContainer?.length === 1) {
        setToContainer(usableContainer[0]);
      }
    }
    if (stepAppliance && applianceContainers?.length) {
      setCompatibleContainers(
        getCompatibleContainer(containers, applianceContainers)
      );
    } else {
      setCompatibleContainers(containers);
    }
  }, [stepAppliance, applianceContainers, containers]);

  useEffect(() => {
    const combinedCapabilities = uniqBy(
      [
        ...(stepAppliance?.capabilities || []),
        ...(stepAttachment?.capabilities || []),
        ...(currentContainer?.capabilities || []),
      ],
      'id'
    ).sort((a, b) => (a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1));

    if (stepAppliance) {
      setLimitedActions(combinedCapabilities);
      if (
        stepAction &&
        !!combinedCapabilities.length &&
        !!containersTerms.length &&
        !combinedCapabilities.some((action) => action.id === stepAction.id)
      ) {
        setStepAction(null);
      }
    }
  }, [stepAppliance, stepAttachment, currentContainer, stepAction, containersTerms]);

  const time = stepPreset
    ? stepAppliance?.profile?.presets?.[stepPreset.id]?.time
    : stepAppliance?.profile?.time;

  useEffect(() => {
    const defaultTime = time?.default;

    if (
      (isNull(stepTimer) && defaultTime) ||
      (prevPreset.current !== stepPreset && defaultTime)
    ) {
      setStepTimer(defaultTime);
    }
    prevPreset.current = stepPreset;
  }, [stepPreset, setStepTimer, stepTimer, time]);

  const setStepSettingsByIndex = (
    value: FrescoStepSetting | null,
    index: number
  ) => {
    const tempStepSettings = [...stepSettings];
    tempStepSettings[index] = value;
    setStepSettings(tempStepSettings);
  };

  const onPresetChange = (preset: FrescoStepPreset | null) => {
    setStepPreset(preset);
    setStepSettings([null, null, null, null]);
    setStepTemperature(null);
    setTimeSetting(null);
  };

  const onApplianceChange = (appliance: FrescoAppliance | null) => {
    setStepAppliance(appliance);
    setStepPreset(null);
    setStepSettings([null, null, null, null]);
    setToContainer(null);
    setStepAttachment(null);
    setStepTemperature(null);
    setStepVesselFlags(null);
    setLimitedActions([]);
    setTimeSetting(null);
  };

  const handleStepTipChangeCaption = (caption: string, index: number) => {
    setStepTips(
      produce(stepTips, (draft) => {
        draft[index].caption = caption;
      })
    );
  };

  const handleStepTipChangeImage = (
    media: FrescoMedia | FrescoVideo | undefined,
    index: number,
    type:
      | FrescoMediaMimetype.Jpeg
      | FrescoMediaMimetype.Png
      | FrescoMediaMimetype.Mp4
      | undefined
  ) => {
    setStepTips(() =>
      produce(stepTips, (draft) => {
        if (!media) {
          draft[index].image = null;
          draft[index].video = null;
          return;
        }
        if (type === FrescoMediaMimetype.Mp4) {
          draft[index].video = media as FrescoVideo;
          return;
        }
        draft[index].image = media;
      })
    );
  };

  const handleVesselFlagChange = (
    vesselFlagKey: string,
    flagKey: string,
    value: boolean
  ) => {
    setStepVesselFlags(() => {
      if (stepVesselFlags) {
        return produce(stepVesselFlags, (draft) => {
          set(draft, `${vesselFlagKey}.${flagKey}`, value);
        });
      }
      return {
        [vesselFlagKey]: {
          [flagKey]: value,
        },
      };
    });
  };

  const handleStepTipDelete = (index: number) => {
    setStepTips(
      produce(stepTips, (draft) => {
        draft.splice(index, 1);
      })
    );
  };

  const handleAddTip = () => {
    setStepTips([
      ...stepTips,
      {
        caption: '',
        image: null,
        video: null,
        id: `temp${tipCount}`,
        uri: 'temp',
      },
    ]);
    setTipCount(tipCount + 1);
  };

  const handleAddTimer = () => {
    setStepTimer(0);
  };

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (!sentence) {
      return;
    }

    const updatedStepIngredients = stepIngredients.map(
      (ingredient) => ingredient.ingredient
    );
    const removedIngredients = differenceBy(
      originalStepIngredients,
      updatedStepIngredients,
      'id'
    );
    const updatedTemporaryIngredients = [
      ...ingredientsInTemporaryGroup.filter(
        (ingredient) => !updatedStepIngredients.includes(ingredient)
      ),
      ...removedIngredients,
    ];

    if (isEditMode) {
      dispatch(
        stepEditRequested({
          stepIngredientGroup: {
            groupId: stepIngredientGroupId ?? undefined,
            ingredients: updatedStepIngredients,
          },
          temporaryIngredientGroup: {
            groupId: temporaryGroupIds[0] ?? undefined,
            ingredients: updatedTemporaryIngredients,
          },
          step: {
            ...editEntity,
            sentence,
            action1: stepAction,
            toContainer: toContainer?.uri
              ? {
                  uri: toContainer.uri,
                }
              : null,
            appliance: stepAppliance,
            preset: stepPreset,
            setting: stepSettings[0],
            setting2: stepSettings[1],
            setting3: stepSettings[2],
            setting4: stepSettings[3],
            temperature: stepTemperature,
            attachment: stepAttachment,
            flags: {
              vessels: stepVesselFlags ?? undefined,
              timed: stepTimer ? stepTimer > 0 : false,
            },
            time: stepTimer,
            tips: stepTips ?? undefined,
            timeSetting,
          },
        })
      );
      return;
    }
    dispatch(
      stepAddRequested({
        stepIngredientGroup: {
          groupId: stepIngredientGroupId ?? undefined,
          ingredients: updatedStepIngredients,
        },
        temporaryIngredientGroup: {
          groupId: temporaryGroupIds[0] ?? undefined,
          ingredients: updatedTemporaryIngredients,
        },
        step: {
          id: 'temp',
          uri: 'temp',
          sentence,
          action1: stepAction ?? null,
          toContainer: toContainer?.uri
            ? {
                uri: toContainer.uri,
              }
            : null,
          appliance: stepAppliance,
          preset: stepPreset,
          setting: stepSettings[0],
          setting2: stepSettings[1],
          setting3: stepSettings[2],
          setting4: stepSettings[3],
          temperature: stepTemperature,
          attachment: stepAttachment,
          flags: {
            vessels: stepVesselFlags ?? undefined,
            timed: stepTimer ? stepTimer > 0 : false,
          },
          time: stepTimer,
          position: createAtPosition,
          tips: stepTips ?? undefined,
          timeSetting,
        },
      })
    );
  };

  return (
    <EditSidebarForm
      aria-label="Step form"
      onSubmit={handleSubmit}
      onKeyDown={(event) => {
        if (event.key === 'Enter') event.preventDefault();
      }}
    >
      <EditSidebarTitle
        text={
          isEditMode ? `Editing Step ${stepIndex}/${totalSteps}` : `Add Step`
        }
        onClosePayloadAction={recipeEditEntityFinished()}
      />
      <EditSidebarContent>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <TextField
              autoFocus
              type="text"
              label="Preview Text"
              id="preview"
              variant="outlined"
              fullWidth
              multiline
              required
              value={sentence}
              onChange={(event) => {
                setSentence(event.target.value);
              }}
            />
          </Grid>
          <StepIngredients
            stepIngredients={stepIngredients}
            onStepIngredientsChange={setStepIngredients}
          />
          <StepAppliance
            stepAppliance={stepAppliance}
            onStepApplianceChange={onApplianceChange}
          />
          <StepPreset
            currentAppliance={currentAppliance}
            stepPreset={stepPreset}
            onStepPresetChange={onPresetChange}
          />
          <StepSettings
            stepTimeSetting={timeSetting}
            currentAppliance={currentAppliance}
            currentPreset={currentPreset}
            stepSettings={stepSettings}
            onStepSettingsChange={setStepSettingsByIndex}
            onStepTimeSettingChange={setTimeSetting}
          />
          <StepTemperature
            currentAppliance={currentAppliance}
            currentPreset={currentPreset}
            stepTemperature={stepTemperature}
            onSetStepTemperature={setStepTemperature}
          />
          <StepAttachment
            applianceId={stepAppliance?.id || null}
            stepAttachment={stepAttachment}
            onStepAttachmentChange={setStepAttachment}
          />
          <StepVesselFlags
            applianceId={stepAppliance?.id || null}
            stepVesselFlags={stepVesselFlags}
            onStepVesselFlagsChange={handleVesselFlagChange}
          />
          <StepAction
            stepAction={stepAction}
            onStepActionChange={setStepAction}
            limitedActions={limitedActions}
          />
          <Grid item xs={12}>
            <Autocomplete
              noOptionsText={containerAutocompleteMessage}
              value={toContainer}
              options={compatibleContainers}
              getOptionLabel={(value) => value.container.name}
              getOptionSelected={(option, value) => option.uri === value.uri}
              onChange={(_e, value) => {
                setToContainer(value);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Container"
                  variant="outlined"
                  fullWidth
                />
              )}
            />
          </Grid>
          {!!stepTips.length && (
            <Grid item xs={12}>
              {stepTips.map((tip, index) => (
                <StepTip
                  stepIndex={stepIndex}
                  key={index}
                  stepTip={tip}
                  index={index}
                  onChangeTipMedia={handleStepTipChangeImage}
                  onChangeTipCaption={handleStepTipChangeCaption}
                  onDeleteStepTip={handleStepTipDelete}
                />
              ))}
            </Grid>
          )}
          <Grid item xs={12}>
            {!isNull(stepTimer) && (
              <StepTimer
                timeRanges={time}
                stepTimer={stepTimer}
                onChangeTimer={setStepTimer}
              />
            )}
          </Grid>
          <Grid item xs={12}>
            <Grid
              spacing={3}
              container
              alignItems="center"
              justify="space-between"
            >
              {isNull(stepTimer) && (
                <Grid item xs={6}>
                  <Button
                    fullWidth
                    onClick={handleAddTimer}
                    color="primary"
                    variant="outlined"
                  >
                    ADD TIMER
                  </Button>
                </Grid>
              )}
              <Grid item xs={6}>
                <Button
                  fullWidth
                  onClick={handleAddTip}
                  color="primary"
                  variant="outlined"
                >
                  ADD TIP
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </EditSidebarContent>
      <EditSidebarActions>
        <Fab type="submit" color="primary" variant="extended">
          {isEditMode ? (
            <SaveIcon className={classes.extendedIcon} />
          ) : (
            <AddIcon className={classes.extendedIcon} />
          )}
          {isEditMode ? 'Save' : 'Add'}
        </Fab>
      </EditSidebarActions>
    </EditSidebarForm>
  );
});
