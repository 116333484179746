import { AddIcon } from '@dropkitchen/icons-react';
import {
  Grid,
  Typography,
  Button,
  Box,
  Paper,
  Fab,
  makeStyles,
} from '@material-ui/core';
import times from 'lodash/times';
import React, { useEffect, useRef } from 'react';
import type { DropResult } from 'react-beautiful-dnd';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { useSelector, useDispatch } from 'react-redux';

import {
  selectRecipeFetching,
  selectRecipeFetched,
} from 'features/recipe/api/apiSlice';
import {
  RecipeEditEntityType,
  recipeCreateEntityRequested,
  selectRecipeEditMode,
  RecipeEditMode,
  selectRecipeEditEntityType,
} from 'features/recipe/edit/editSlice';
import { Step } from 'features/recipe/steps/Step';
import { VirtualStep } from 'features/recipe/steps/VirtualStep';
import { selectStepsAll, stepMoved } from 'features/recipe/steps/stepsSlice';
import {
  selectTermsAppliancesEntities,
  selectTermsAppliancesApiPending,
  applianceTermsFetchRequested,
} from 'features/terms/appliancesSlice';
import { skeletonAriaLabel, StepSkeleton } from 'shared/components/Skeletons';

import { ReactComponent as DirectionsIllustraion } from './directions-empty-state.svg';

const useStyles = makeStyles((theme) => ({
  createButton: {
    position: 'fixed',
    bottom: theme.spacing(4),
    right: theme.spacing(4),
  },
  marginTop: {
    marginTop: theme.spacing(1),
  },
}));

export const DirectionsPage: React.FC = React.memo(function DirectionsPage() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const appliances = useSelector(selectTermsAppliancesEntities);
  const appliancesPending = useSelector(selectTermsAppliancesApiPending);
  const steps = useSelector(selectStepsAll);
  const isFetched = useSelector(selectRecipeFetched);
  const isFetching = useSelector(selectRecipeFetching);
  const isCreating = useSelector(selectRecipeEditMode);
  const editEntityType = useSelector(selectRecipeEditEntityType);
  const showCreatingText =
    isCreating === RecipeEditMode.Create &&
    editEntityType === RecipeEditEntityType.Step;

  const stepsEndRef = useRef<null | HTMLDivElement>(null);

  const scrollToBottom = () => {
    stepsEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(() => {
    if (!appliances.length && !appliancesPending) {
      dispatch(applianceTermsFetchRequested());
    }
  }, [dispatch, appliances, appliancesPending]);

  const onDragEnd = async (result: DropResult) => {
    if (!result.destination) {
      return;
    }

    if (result.destination.index !== result.source.index) {
      const id = parseInt(result.draggableId);
      dispatch(
        stepMoved({
          id,
          toIndex: result.destination.index,
        })
      );
    }
  };

  const handleCreateStepOnClick = (position: number) => {
    dispatch(
      recipeCreateEntityRequested({
        editEntityType: RecipeEditEntityType.Step,
        position,
      })
    );
  };

  return (
    <>
      {isFetched && !steps.length && (
        <Paper>
          <Box m={2} pt={3} pb={3}>
            <Grid
              direction="column"
              justify="center"
              alignItems="center"
              container
            >
              <DirectionsIllustraion className={classes.marginTop} />
              {showCreatingText ? (
                <Typography variant="h6" className={classes.marginTop}>
                  Once added, steps will show here
                </Typography>
              ) : (
                <>
                  <Typography variant="h6" className={classes.marginTop}>
                    Begin by adding a recipe step
                  </Typography>
                  <Button
                    className={classes.marginTop}
                    color="primary"
                    variant="contained"
                    onClick={() => handleCreateStepOnClick(0)}
                  >
                    Add Recipe Step
                  </Button>
                </>
              )}
            </Grid>
          </Box>
        </Paper>
      )}
      {!isFetching ? (
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable">
            {(droppableProvided) => (
              <div
                {...droppableProvided.droppableProps}
                ref={droppableProvided.innerRef}
              >
                {steps.map((step, index) =>
                  !step.isVirtual ? (
                    <Draggable
                      key={`${step.id}-${index}`}
                      draggableId={`${step.id}`}
                      index={index}
                    >
                      {(draggableProvided) => (
                        <div
                          ref={draggableProvided.innerRef}
                          {...draggableProvided.draggableProps}
                          {...draggableProvided.dragHandleProps}
                        >
                          <Box my={2}>
                            <Step stepId={step.id} index={index} />
                          </Box>
                        </div>
                      )}
                    </Draggable>
                  ) : (
                    <Box key={`${step.id}-${index}`} my={2}>
                      <VirtualStep stepId={step.id} index={index} />
                    </Box>
                  )
                )}
                {droppableProvided.placeholder}
                <div ref={stepsEndRef} />
              </div>
            )}
          </Droppable>
        </DragDropContext>
      ) : (
        <div aria-label={skeletonAriaLabel}>
          {times(5, (i) => (
            <Box my={2} key={i}>
              <StepSkeleton />
            </Box>
          ))}
        </div>
      )}
      <Fab
        className={classes.createButton}
        onClick={() => {
          scrollToBottom();
          handleCreateStepOnClick(steps.length);
        }}
        color="primary"
        aria-label="Add Step"
      >
        <AddIcon />
      </Fab>
    </>
  );
});
