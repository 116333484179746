import { configureStore } from '@reduxjs/toolkit';
import { routerMiddleware } from 'connected-react-router';
import type { History } from 'history';
import { createBrowserHistory } from 'history';
import createSagaMiddleware from 'redux-saga';

import { rootSaga } from 'app/rootSaga';

import type { RootState } from './rootReducer';
import { createRootReducer } from './rootReducer';

export const browserHistory = createBrowserHistory({
  basename: process.env.PUBLIC_URL,
});

export const createStore = (
  history: History,
  args?: {
    preloadedState?: RootState;
    disableTokenRefreshMiddleware?: boolean;
  }
) => {
  const combinedReducers = createRootReducer(history);

  const sagaMiddleware = createSagaMiddleware();

  const store = configureStore({
    preloadedState: args?.preloadedState,
    reducer: combinedReducers,
    middleware: [routerMiddleware(history), sagaMiddleware],
  });
  sagaMiddleware.run(rootSaga);
  return store;
};

export const store = createStore(browserHistory);

if (process.env.NODE_ENV === 'development' && module.hot) {
  module.hot.accept('./rootReducer', () => {
    const newRootReducer =
      // eslint-disable-next-line global-require, @typescript-eslint/no-var-requires
      require('./rootReducer').createRootReducer(browserHistory);
    store.replaceReducer(newRootReducer);
  });
}

export type AppDispatch = typeof store.dispatch;
