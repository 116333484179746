import type { PayloadAction, Update, EntityId } from '@reduxjs/toolkit';
import { createSlice, createSelector, createAction } from '@reduxjs/toolkit';
import isNil from 'lodash/isNil';
import uniqBy from 'lodash/uniqBy';

import type { RootState } from 'app/rootReducer';
import { logoutSuccess } from 'features/login/loginSlice';
import {
  recipeFetchFinished,
  recipeFetchRequested,
  recipeFetchFailed,
} from 'features/recipe/recipeSlice/recipeActions';
import { createSaveableEntityAdapter } from 'shared/redux/saveableEntityAdapter';
import type { FrescoId } from 'shared/types/entity';
import type { ApiIngredientGroupIngredient } from 'shared/types/ingredient';
import type { ApiStepWeb } from 'shared/types/step';
import { getIngredientGroupId } from 'shared/utils/common';

export const stepsAdapter = createSaveableEntityAdapter<ApiStepWeb>();

export const initialState = stepsAdapter.getInitialState();

const stepsSlice = createSlice({
  name: 'recipe/stepsSlice',
  initialState,
  reducers: {
    stepAdded: (state, { payload }: PayloadAction<{ entity: ApiStepWeb }>) => {
      stepsAdapter.addOne(state, payload);
      if (!isNil(payload.entity.position)) {
        stepsAdapter.moveOne(state, {
          id: payload.entity.id,
          toIndex: payload.entity.position,
        });
      }
    },
    stepUpdated: (
      state,
      { payload }: PayloadAction<{ update: Update<ApiStepWeb> }>
    ) => {
      stepsAdapter.updateOne(state, payload);
    },
    stepRemoveRequested: (
      state,
      { payload }: PayloadAction<{ id: EntityId }>
    ) => {
      stepsAdapter.saveRequest(state, payload);
    },
    stepRemoved: (state, { payload }: PayloadAction<{ id: EntityId }>) => {
      stepsAdapter.removeOne(state, payload);
    },
    stepMoved: (
      state,
      { payload }: PayloadAction<{ id: EntityId; toIndex: number }>
    ) => {
      stepsAdapter.moveOne(state, payload);
      stepsAdapter.saveRequest(state, payload);
    },
    stepSaveRequested: (
      state,
      {
        payload,
      }: PayloadAction<{
        id: EntityId;
        previousStepState?: ApiStepWeb;
        ingredientsUpdate?: StepIngredientGroupsUpdate;
      }>
    ) => {
      stepsAdapter.saveRequest(state, payload);
    },
    stepSaveFinished: (state, { payload }: PayloadAction<{ id: EntityId }>) => {
      stepsAdapter.saveFinish(state, payload);
    },
    stepSaveFailed: (
      state,
      { payload }: PayloadAction<{ id: EntityId; error: string }>
    ) => {
      stepsAdapter.saveFail(state, payload);
    },
    stepsFetchedSuccess: (
      state,
      { payload: steps }: PayloadAction<ApiStepWeb[]>
    ) => {
      stepsAdapter.loadAll(state, {
        entities: steps || [],
      });
    },
  },
  extraReducers: (builder) => {
    builder.addCase(recipeFetchRequested, () => initialState);
    builder.addCase(recipeFetchFinished, (state, { payload: recipe }) => {
      stepsAdapter.loadAll(state, {
        entities: recipe.steps || [],
      });
    });
    builder.addCase(recipeFetchFailed, () => initialState);
    builder.addCase(logoutSuccess, () => initialState);
  },
});

export const {
  reducer: stepsReducer,
  actions: {
    stepAdded,
    stepUpdated,
    stepRemoved,
    stepRemoveRequested,
    stepMoved,
    stepSaveRequested,
    stepSaveFinished,
    stepSaveFailed,
    stepsFetchedSuccess,
  },
} = stepsSlice;

export const {
  selectAll: selectStepsAll,
  selectById: selectStepById,
  selectIds: selectStepsIds,
  selectTotal: selectStepsTotal,
  selectIsSavedById: selectStepIsSavedById,
  selectIsSavingById: selectStepIsSavingById,
  selectSaveErrorById: selectStepSaveErrorById,
} = stepsAdapter.getSelectors<RootState>((state) => state.recipe.steps);

export const selectStepNumber = (id: EntityId | undefined) =>
  createSelector(
    selectStepsIds,
    (ids) => ids.findIndex((stepId) => stepId === id) + 1
  );

export const selectRecipeAppliances = createSelector(selectStepsAll, (steps) =>
  uniqBy(
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    steps.filter((x) => !!x.appliance).map((x) => x.appliance!),
    (x) => x?.id
  )
);

export const selectDoesContainerExistInStep = (uri: string | undefined) =>
  createSelector(selectStepsAll, (steps) =>
    steps.some(
      (step) =>
        step?.toContainer?.uri === uri || step.fromContainer?.uri === uri
    )
  );

export const selectDoesIngredientGroupExistInStep = (uri: string | undefined) =>
  createSelector(selectStepsAll, (steps) =>
    steps.some((step) => step?.ingredientGroup?.uri === uri)
  );

export const selectTemporaryIngredientGroup = (groupIds: string[] | []) =>
  createSelector(selectStepsAll, (steps) => {
    const stepGroupIds = steps
      .map(
        (step) =>
          step?.ingredientGroup?.uri &&
          getIngredientGroupId(step.ingredientGroup.uri)
      )
      .filter((uri) => uri);
    return groupIds.filter(
      (id) => !stepGroupIds.includes(id) && !id.includes('created')
    );
  });
export interface IngredientGroup {
  groupId?: FrescoId;
  ingredients: ApiIngredientGroupIngredient[];
}
export interface StepIngredientGroupsUpdate {
  /**
   * stepIngredientGroup contains an array of ingredients to overwrite current ingredients in step ingredient group
   * if no groupId exists in stepIngredientGroup a new group will be created with array of ingredients
   */
  stepIngredientGroup?: IngredientGroup;
  /**
   * temporaryIngredientGroup contains an array of ingredients to overwrite current ingredients in temporary recipe ingredient group
   * if no groupId exists in temporaryIngredientGroup a new group will be created with array of ingredients
   */
  temporaryIngredientGroup?: IngredientGroup;
}

export interface StepEditRequestedPayload extends StepIngredientGroupsUpdate {
  step: Partial<ApiStepWeb>;
}

export interface StepAddRequestedPayload extends StepIngredientGroupsUpdate {
  step: ApiStepWeb;
}

export const stepAddRequested = createAction<StepEditRequestedPayload>(
  'recipe/stepsSlice/stepAddRequested'
);

export const stepEditRequested = createAction<StepEditRequestedPayload>(
  'recipe/stepsSlice/stepEditRequested'
);
