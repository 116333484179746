import { jobApiPath } from 'api/constants';
import { fetchJson } from 'api/fetchJson';
import type { ApiRequestFn } from 'api/types';
import { HttpMethod } from 'api/types';
import { appConfig } from 'shared/config';
import type { ApiJob, GetJobRequest } from 'shared/types/job';

export const apiGetJobTranslation =
  ({ jobId }: GetJobRequest): ApiRequestFn<ApiJob> =>
  (apiContext) => {
    return fetchJson<ApiJob>({
      apiContext,
      httpMethod: HttpMethod.Get,
      url: `${appConfig.apiUrl()}${jobApiPath}/${jobId}`,
    });
  };
